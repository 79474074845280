import { Box, Grid, IconButton, MenuItem, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { DashboardTile } from './Common';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from '../../context/AppContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TransactionsStatisticDetailed from './TransactionsStatisticDetailed';
import { useHttp } from '../../hooks/authentication';
import RecordsStatusTile from './RecordsStatusTile';
import { DATA_LOAD_STATE, ROUTE_PATHS, PERIOD } from '../../common/Constants';
import EmptyDataWidget from './EmptyDataWidget';
import { AppText, AppTextField } from '../../common/components/AppText';
import { AppButton } from '../../common/components/AppButton';
import AppTitle from '../../common/components/AppTitle';
import { AppBox } from '../../common/components/AppBox';
import { useSetPathContext } from '../../context/PathContext';
import { calculateStartDate, isFewScreenWidth } from '../../utils/utils';

const DEFAULT_STATISTIC_PERIOD = PERIOD.THREE_MONTHS;

const calculateStatisticPerPeriod = (records, start, end, period) => {
  const data = records.filter((e) => e.date >= start && e.date <= end);
  const result = {
    count: 0,
    sum: 0,
  };
  if (period === PERIOD.ONE_MONTH) {
    for (let i = 0; i < data.length; i++) {
      result.count += data[i].count;
      result.sum += data[i].sum;
    }
    result.data = [...data];
    return result;
  }
  let item = {};
  result.data = [];
  for (let i = 0; i < data.length; i++) {
    result.count += data[i].count;
    result.sum += data[i].sum;
    if (!item.date) {
      item.date = data[i].date;
      item.count = data[i].count;
      item.sum = data[i].sum;
    } else {
      if (item.date.getMonth() !== data[i].date.getMonth() || item.date.getFullYear() !== data[i].date.getFullYear()) {
        result.data.push({ ...item });
        item.date = data[i].date;
        item.count = data[i].count;
        item.sum = data[i].sum;
      } else {
        item.count += data[i].count;
        item.sum += data[i].sum;
      }
    }
  }
  if (item.date) {
    result.data.push({ ...item });
  }
  let temp = new Date(start);
  while (temp <= end) {
    let found = result.data.find((e) => e.date.getMonth() === temp.getMonth() && e.date.getFullYear() === temp.getFullYear());
    if (!found) {
      result.data.push({
        date: new Date(temp),
        count: 0,
        sum: 0,
      });
    }
    temp.setMonth(temp.getMonth() + 1);
  }
  result.data.sort((a, b) => {
    return a.date - b.date;
  });
  return result;
};

const DonationsWidget = (props) => {
  const { dataAreaSize } = props;
  const navigate = useNavigate();
  const [dataLoadState, setDataLoadState] = useState(DATA_LOAD_STATE.INPROGRESS);
  const [rawData, setRawData] = useState();
  const [statusStatistic, setStatusStatistic] = useState({
    new: {
      records: 0,
      amount: 0,
    },
    approved: {
      records: 0,
      amount: 0,
    },
    questioned: {
      records: 0,
      amount: 0,
    },
  });
  const [statistic, setStatistic] = useState({});
  const [drillDown, setDrillDown] = useState(false);
  const { localization } = useAppContext();
  const setPath = useSetPathContext();
  const theme = useTheme();
  const { getHttp } = useHttp();

  const emptyColor = theme.palette.dashboard.error;
  const dataColor = theme.palette.dashboard.success;
  const periodBeforeDrillDown = useRef();

  const loadStatistic = async () => {
    try {
      setDataLoadState(DATA_LOAD_STATE.INPROGRESS);
      const response = await getHttp().get('/transactions/statistics?period=1y');
      setStatusStatistic({
        new: {
          records: response.data.countNew || 0,
          amount: response.data.sumNew || 0,
        },
        approved: {
          records: response.data.countApproved || 0,
          amount: response.data.sumApproved || 0,
        },
        questioned: {
          records: response.data.countQuestioned || 0,
          amount: response.data.sumQuestioned || 0,
        },
      });
      if (response.data.daily?.length > 0) {
        const raw = response.data.daily.map((e, i) => {
          return { ...e, date: new Date(e.date) };
        });
        setRawData(raw);
        const end = new Date();
        const start = calculateStartDate(end, DEFAULT_STATISTIC_PERIOD);
        setStatistic({
          period: DEFAULT_STATISTIC_PERIOD,
          start,
          end,
          data: calculateStatisticPerPeriod(raw, start, end, DEFAULT_STATISTIC_PERIOD),
        });
        setDataLoadState(DATA_LOAD_STATE.SUCCESS);
      } else {
        setDataLoadState(DATA_LOAD_STATE.NODATA);
      }
    } catch (error) {
      setDataLoadState(DATA_LOAD_STATE.ERROR);
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    const load = async () => {
      await loadStatistic();
    };
    load().catch(() => {});
  }, []);

  const onChangePeriod = (period, start, end) => {
    const newEnd = end || new Date();
    const newStart = start || calculateStartDate(newEnd, period);

    setStatistic({
      ...statistic,
      period,
      start: newStart,
      end: newEnd,
      data: calculateStatisticPerPeriod(rawData, newStart, newEnd, period),
    });
  };

  const handleDrillDown = (start, end) => {
    setDrillDown(true);
    periodBeforeDrillDown.current = statistic.period;
    onChangePeriod(PERIOD.ONE_MONTH, start, end);
  };

  const handleRollUp = () => {
    setDrillDown(false);
    onChangePeriod(periodBeforeDrillDown.current || PERIOD.THREE_MONTHS);
  };

  const handleNavigate = (
    filter = {
      items: [
        {
          id: 1,
          field: 'transactionDate',
          operator: 'between',
          value: [statistic.start, statistic.end],
        },
      ],
    }
  ) => {
    const to = `/${ROUTE_PATHS.Donations}`;
    navigate(to, {
      state: { ...filter },
    });
    setPath(to);
  };

  const onStatusSelect = (statusValue) => {
    handleNavigate({
      ...statusValue,
    });
  };

  return dataLoadState === DATA_LOAD_STATE.SUCCESS ? (
    <Grid container rowSpacing={1} columnSpacing={1}>
      <Grid item xs={12}>
        <RecordsStatusTile
          title={localization.translate('dashboard.donations_by_status')}
          onSelect={onStatusSelect}
          data={{ ...statusStatistic }}
          period={{
            from: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
            to: new Date(),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DashboardTile>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
              <AppBox flex sx={{ my: 1 }}>
                <AppTitle
                  sx={{ mx: 1 }}
                  primary={localization.translate('dashboard.donations_statistic')}
                  {...(statistic.data && {
                    secondary: `${localization.translate('dashboard.reported_period')} ${statistic.start.toLocaleDateString(
                      localization.locale
                    )} - ${statistic.end.toLocaleDateString(localization.locale)}`,
                  })}
                />
                <AppBox flex sx={{ flexGrow: 1 }} />
                <AppBox flex>
                  <IconButton onClick={handleRollUp} sx={{ visibility: drillDown ? 'visible' : 'hidden', mx: 1, color: (theme) => theme.palette.primary.main }}>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <AppTextField
                    select
                    label={localization.translate('common.period')}
                    value={statistic.period}
                    onChange={(v) => {
                      setDrillDown(false);
                      onChangePeriod(v);
                    }}
                    sx={{
                      width: isFewScreenWidth() ? '200px' : '250px',
                      float: 'right',
                      mt: 1,
                    }}
                  >
                    <MenuItem value={PERIOD.ONE_MONTH}>
                      <AppText>{localization.translate('common.month')}</AppText>
                    </MenuItem>
                    <MenuItem value={PERIOD.THREE_MONTHS}>
                      <AppText>{localization.translate('common.three_months')}</AppText>
                    </MenuItem>
                    <MenuItem value={PERIOD.SIX_MONTHS}>
                      <AppText>{localization.translate('common.six_months')}</AppText>
                    </MenuItem>
                    <MenuItem value={PERIOD.YEAR}>
                      <AppText>{localization.translate('common.twelve_months')}</AppText>
                    </MenuItem>
                    <MenuItem value={PERIOD.CURRENT_YEAR}>
                      <AppText>{localization.translate('common.from_beginning_year')}</AppText>
                    </MenuItem>
                  </AppTextField>
                </AppBox>
                <AppButton sx={{ my: 1, mx: 1, cursor: 'pointer' }} variant='text' onClick={(e) => handleNavigate()}>
                  {`${localization.translate('common.see_more')}...`}
                </AppButton>
              </AppBox>
            </Grid>
            <Grid item sm={12}>
              <TransactionsStatisticDetailed
                statistic={statistic}
                height={dataAreaSize}
                withProjectsColor={dataColor}
                noProjectsColor={emptyColor}
                onDrillDown={handleDrillDown}
              />
            </Grid>
          </Grid>
        </DashboardTile>
      </Grid>
    </Grid>
  ) : dataLoadState === DATA_LOAD_STATE.INPROGRESS ? (
    <EmptyDataWidget variant='loading' height='400px' />
  ) : dataLoadState === DATA_LOAD_STATE.NODATA ? (
    <EmptyDataWidget title={localization.translate('dashboard.no_donations_statistic')} height='400px' onReload={() => loadStatistic()} />
  ) : (
    <EmptyDataWidget
      title={`${localization.translate('dashboard.error_loading_donations_statistic')}. ${localization.translate('errors.try_again')}`}
      height='400px'
      variant='error'
      onReload={() => loadStatistic()}
    />
  );
};

export default DonationsWidget;
