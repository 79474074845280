import { createContext, useEffect, useState, useContext, useRef } from 'react';

const dialogsContext = createContext();
const DialogsContextProvider = ({ children }) => {
  const reportDialogRef = useRef(null);
  const receiptDialogRef = useRef(null);
  const deleteConfirmationDialogRef = useRef(null);
  const context = {
    reportDialogRef,
    receiptDialogRef,
    deleteConfirmationDialogRef,
  };

  return <dialogsContext.Provider value={context}>{children}</dialogsContext.Provider>;
};

const useDialogsContext = () => {
  const context = useContext(dialogsContext);
  if (context === undefined) {
    throw new Error('useTenantContext must be used within DialogsContextProvider');
  }
  return context;
};

export { DialogsContextProvider, useDialogsContext };
