import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';

const AppInnerAlert = (props) => {
  const { open, severity, onClose, content, vertical = 'bottom', horizontal = 'center' } = props;

  return (
    <Snackbar open={open} onClose={onClose} anchorOrigin={{ vertical: vertical, horizontal: horizontal }} autoHideDuration={6000} sx={{ width: '80%', mb: 6 }}>
      <Alert severity={severity} onClose={onClose}>
        {content}
      </Alert>
    </Snackbar>
  );
};

AppInnerAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

export default AppInnerAlert;
