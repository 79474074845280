import React from 'react';
import { Grid, IconButton, ListItemText, Toolbar } from '@mui/material';
import { useAppContext } from '../context/AppContext';
import { AppBox } from '../common/components/AppBox';
import { AppText } from '../common/components/AppText';
import { useHeaderContext } from '../context/HeaderContext';

const ToolbarButton = (props) => {
  const { icon: Icon, color, onClick } = props;
  return (
    <IconButton color={color ? color : 'primary'} onClick={onClick}>
      <Icon sx={{ fontSize: '.9em' }} />
    </IconButton>
  );
};

const MainAreaHeader = (props) => {
  const title = useHeaderContext();
  return (
    <AppBox flex sx={{ mx: 2 }}>
      <AppText variant='xlargeb'>{title.primary}</AppText>
    </AppBox>
  );
};
const MainAreaHeaderInner = (props) => {
  const title = useHeaderContext();
  return <AppText variant='largeb'>{title.primary}</AppText>;
};
export { MainAreaHeader, MainAreaHeaderInner };
