import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useAppContext } from '../../context/AppContext';
import { AppTextField } from './AppText';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';

export const AppDatePicker = (props) => {
  const { inputTextProps, onChange, value, ...rest } = props;
  const { localization } = useAppContext();
  const [date, setDate] = useState(DateTime.fromISO(''));

  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        setDate(DateTime.fromISO(value));
      } else if (value instanceof Date) {
        setDate(DateTime.fromJSDate(value));
      } else if (value instanceof DateTime) {
        setDate(value);
      }
    } else {
      setDate(DateTime.fromISO(''));
    }
  }, [value]);

  const handleChangeValue = (v) => {
    setDate(v);
    console.log(
      v.toISO({
        suppressSeconds: true,
        suppressMilliseconds: true,
      })
    );
    if (onChange) {
      onChange(
        new Date(
          v.toISO({
            suppressSeconds: true,
            suppressMilliseconds: true,
          })
        )
      );
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={localization.locale}>
      <DesktopDatePicker
        label={localization.translate('common.date')}
        {...rest}
        value={date}
        onChange={handleChangeValue}
        slots={{
          textField: AppTextField,
        }}
        slotProps={{
          textField: {
            ...inputTextProps,
          },
        }}
      />
    </LocalizationProvider>
  );
};
