import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { forwardRef, useRef, useState, useEffect, useImperativeHandle } from 'react';
import { AppButton } from './AppButton';
import { AppContainer } from './AppContainer';
import { useAppContext } from '../../context/AppContext';
import { AppBox } from './AppBox';
import { AppText } from './AppText';

const AppStyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AppDialogTitle = (props) => {
  const { title, onClose, simple, ...other } = props;
  const theme = useTheme();
  const textColor = simple ? theme.palette.layout.header : theme.palette.primary.contrastText;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        ...(!simple && { backgroundColor: (theme) => theme.palette.layout.header }),
        color: textColor,
      }}
      {...other}
    >
      <AppText
        sx={{
          color: textColor,
          mx: 1,
        }}
      >
        {title}
      </AppText>
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: textColor,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

AppDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

const AppDialogPaperComponent = (props) => {
  const nodeRef = useRef(null);
  return (
    <Draggable nodeRef={nodeRef} handle='#app-dialog' cancel={'[class*="MuiDialogContent-root"]'}>
      <AppContainer ref={nodeRef} {...props} />
    </Draggable>
  );
};

const AppDialog = (props) => {
  const { open, onClose, title, dialogContent, hasActions, useClose, closeTitle, dialogActionsContent, simple, minHeight = '200px', ...rest } = props;
  const { localization } = useAppContext();
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <div>
      <AppStyledDialog aria-labelledby='app-dialog' open={open} PaperComponent={AppDialogPaperComponent} fullWidth {...rest}>
        <AppDialogTitle id='app-dialog' style={{ cursor: 'move' }} onClose={onClose} title={title} simple={simple} />
        <DialogContent {...(!simple && { dividers: true })} sx={{ minHeight: minHeight, backgroundColor: (theme) => theme.palette.background.paper }}>
          {dialogContent}
        </DialogContent>

        {hasActions && (
          <DialogActions sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
            {dialogActionsContent}
            {useClose && (
              <AppButton variant='text' autoFocus onClick={handleClose}>
                {closeTitle ? closeTitle : localization.translate('common.close')}
              </AppButton>
            )}
          </DialogActions>
        )}
      </AppStyledDialog>
    </div>
  );
};

AppDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogTitleContent: PropTypes.node,
  dialogContent: PropTypes.node.isRequired,
  dialogActionsContent: PropTypes.node,
  hasActions: PropTypes.bool,
  useClose: PropTypes.bool,
  closeTitle: PropTypes.string,
};

const AppConfirmationDialog = (props) => {
  const { open, onOk, onCancel, message = 'Please, confirm', title = 'Confirmation dialog', ...rest } = props;
  const { localization } = useAppContext();
  return (
    <div>
      <AppStyledDialog open={open} aria-labelledby='app-dialog' PaperComponent={AppDialogPaperComponent} fullWidth {...rest}>
        <AppDialogTitle id='app-dialog' style={{ cursor: 'move' }} title={title} onClose={onCancel}></AppDialogTitle>
        <DialogContent dividers sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
          <Grid container>
            <Grid item xs={12} align='center'>
              <Box sx={{ mb: 1, mt: 1 }}>
                <AppText>{message}</AppText>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
          <AppButton variant='text' onClick={onCancel}>
            {localization.translate('common.cancel')}
          </AppButton>
          <AppButton variant='text' onClick={onOk}>
            {localization.translate('common.confirm')}
          </AppButton>
        </DialogActions>
      </AppStyledDialog>
    </div>
  );
};

const AppDeletionConfirmationDialog = forwardRef((props, ref) => {
  const { ...rest } = props;
  const { localization } = useAppContext();
  const [deleteType, setDeleteType] = useState('simple');
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({});
  const close = () => {
    setOpen(false);
    setConfig({});
    setDeleteType('simple');
    if (config.onClose) {
      config.onClose();
    }
  };

  useImperativeHandle(ref, () => ({
    open: (config) => {
      setConfig({ ...config });
      setOpen(true);
    },
    close,
  }));

  return (
    <div>
      <AppStyledDialog
        open={open}
        aria-labelledby='app-dialog'
        PaperComponent={AppDialogPaperComponent}
        fullWidth
        {...(config.advancedDelete && { maxWidth: 'md' })}
        {...rest}
      >
        <AppDialogTitle id='app-dialog' style={{ cursor: 'move' }} title={config.title || ''} onClose={() => close()}></AppDialogTitle>
        <DialogContent dividers sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
          <Grid container>
            <Grid item xs={12} align='center'>
              <AppBox sx={{ mb: 1, mt: 1 }}>
                <AppText variant={config.advancedDelete ? 'normalb' : 'normal'}>{config.message || ''}</AppText>
              </AppBox>
            </Grid>
            {config.advancedDelete && (
              <Grid item xs={12} align='center'>
                <RadioGroup aria-labelledby='delete-type-group' name='delete-type-group' value={deleteType} onChange={(e) => setDeleteType(e.target.value)}>
                  <AppBox flex center column sx={{ mt: 2 }}>
                    <AppText color='textSecondary'>{localization.translate('donations.delete_splitted')}</AppText>
                    <AppBox flex centery column>
                      <FormControlLabel
                        value='simple'
                        control={<Radio />}
                        label={localization.translate('donations.delete_splitted_all')}
                        slotProps={{
                          typography: {
                            variant: 'normal',
                            color: 'textSecondary',
                          },
                        }}
                      />
                      <FormControlLabel
                        value='advanced'
                        control={<Radio />}
                        label={localization.translate('donations.delete_splitted_only')}
                        slotProps={{
                          typography: {
                            variant: 'normal',
                            color: 'textSecondary',
                          },
                        }}
                      />
                    </AppBox>
                  </AppBox>
                </RadioGroup>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
          <AppButton variant='text' onClick={() => close()}>
            {localization.translate('common.cancel')}
          </AppButton>
          <AppButton
            variant='text'
            onClick={() => {
              close();
              if (config.onOk) {
                config.onOk(deleteType !== 'simple');
              }
            }}
          >
            {localization.translate('common.confirm')}
          </AppButton>
        </DialogActions>
      </AppStyledDialog>
    </div>
  );
});

export { AppDialog, AppConfirmationDialog, AppDeletionConfirmationDialog };
