import getExpenseColumns from './Columns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useAppContext } from '../../context/AppContext';
import ExpenseForm from './ExpenseForm';
import { AppDataGrid2 } from '../../common/components/datagrid/AppDataGrid2';
import useCampaigns from '../../hooks/campaigns';
import InnerCenteredCircularProgress from '../../common/components/InnerCenteredCircularProgress';
import { WORKING_STATE } from '../../common/Constants';
import { useSetPathContext } from '../../context/PathContext';
import { useSetHeaderContext } from '../../context/HeaderContext';
import { useDataContext } from '../../context/DataContext';
import { AppDadtaGridFooter, TotalSelectedOption } from '../../common/components/datagrid/AppDataGridFooter';
import AppDialogForm from '../../common/components/AppDialogForm';
import { isFewScreenHeight } from '../../utils/utils';
import { AssignItem } from '../../common/components/datagrid/toolbarItems/AssignItem';

const Expenses = ({ path }) => {
  const { localization, profile } = useAppContext();
  const dataContext = useDataContext();
  const campaigns = useCampaigns();
  const setPath = useSetPathContext();
  const setTitle = useSetHeaderContext();
  const gridRef = useRef(null);
  const theme = useTheme();

  const [campaignsData, setCampaignsData] = useState({
    data: [],
    state: WORKING_STATE.INPROGRESS,
  });

  useEffect(() => {
    dataContext.setName('expenses');
    (async () => {
      try {
        const data = await campaigns.load();
        if (data.length > 0) {
          const items = data.map((c, i) => {
            return { projectId: c.id, projectName: c.name };
          });
          setCampaignsData({ data: [...items], state: WORKING_STATE.SUCCESS });
        } else {
          setCampaignsData({ data: [], state: WORKING_STATE.DONE_NO_DATA });
        }
      } catch (error) {
        console.error(error);
        setCampaignsData({ data: [], state: WORKING_STATE.ERROR });
      }
    })();
  }, []);

  const columns = useMemo(() => {
    return getExpenseColumns({
      campaigns: campaignsData.data,
      onUpdate: (items) => gridRef.current.updateItems(items),
      localization: localization,
      theme: theme,
    });
  }, [campaignsData, localization, theme]);

  useEffect(() => {
    setPath(path);
    setTitle({
      primary: localization.translate('app.expenses'),
      secondary: localization.translate('expenses.subtitle'),
    });
  }, [profile.language]);

  return campaignsData.state === WORKING_STATE.SUCCESS || campaignsData.state === WORKING_STATE.DONE_NO_DATA ? (
    <AppDataGrid2
      ref={gridRef}
      urlPath='expenses'
      errorAlertProps={{
        title: localization.translate('expenses.load_expenses_error'),
        content: `${localization.translate('expenses.load_expenses_error_content')}. ${localization.translate('errors.try_again')}. ${localization.translate(
          'errors.contact_admin'
        )}`,
      }}
      notificationProps={{
        onDataError: localization.translate('expenses.update_expenses_error'),
        onSuccess: localization.translate('expenses.update_expenses_success'),
      }}
      detailsFormProps={{
        component: ExpenseForm,
        componentProps: {
          campaigns: campaignsData.data,
          formId: 'expense-details-form',
        },
      }}
      noRecordsTitle={localization.translate('expenses.no_expenses')}
      columns={columns.data}
      initialState={{
        columns: {
          columnVisibilityModel: { ...columns.visibilityModel },
        },
      }}
      slots={{
        footer: AppDadtaGridFooter,
      }}
      slotProps={{
        footer: {
          slot: <TotalSelectedOption />,
        },
      }}
      toolbarProps={{
        filterProps: {
          addQuickFilter: true,
        },
        layoutMenuProps: {},
        buttons: {
          items: [
            {
              reload: {
                onActivated: () => gridRef.current.onReload(),
              },
              filter: {},
            },
            {
              add: {
                tooltip: localization.translate('expenses.add_new_expense'),
                title: localization.translate('expenses.new_expense'),
                itemName: 'expense',
                onSubmit: (item) => gridRef.current.addItem(item),
                renderForm: (params) => {
                  return (
                    <AppDialogForm
                      {...params}
                      slot={{
                        component: ExpenseForm,
                        props: {
                          campaigns: campaignsData.data,
                        },
                      }}
                      minHeight={isFewScreenHeight() ? '500px' : '700px'}
                    />
                  );
                },
              },
              delete: {
                tooltip: localization.translate('expenses.delete_expenses'),
                onDelete: (items) =>
                  gridRef.current.deleteItems({
                    id: [...items.map((i) => i.id)],
                  }),
                confirmationTitle: localization.translate('expenses.delete_expenses'),
                confirmationMessage: localization.translate('expenses.delete_expenses_confirm'),
              },
            },
            {
              assign: {
                component: AssignItem,
                onUpdate: (items) => gridRef.current.updateItems(items),
                selectionAware: true,
              },
            },
          ],
        },

        getSearchId: () => gridRef.current.getSearchId(),
        onAdd: (item) => gridRef.current.addItem(item),
        onUpdate: (items) => gridRef.current.updateItems(items),
        onReload: () => gridRef.current.onReload(),
        onDelete: (items) => gridRef.current.deleteItems(items),
      }}
      hideFooterSelectedRowCount
      getRowClassName={(params) => `status-highlighted--${params.row.trackingState}`}
    />
  ) : (
    <InnerCenteredCircularProgress size={40} />
  );
};

export default Expenses;
