import { IconButton } from '@mui/material';
import { useState, useRef } from 'react';
import { useAppContext } from '../../context/AppContext';
import { AppButton } from './AppButton';
import GenerateReceiptDialog from './GenerateReceiptDialog';
import { AppBox } from './AppBox';
import { AppIcon, ICON } from './AppIcon';
import { useHttp } from '../../hooks/authentication';
import { INVOICE_STATE } from '../Constants';
import { AppText } from './AppText';
import { useDialogsContext } from '../../context/DialogsContext';

const GenerateReceiptSubmitter = (props) => {
  const { ids, data, iconOnly, color = 'primary', disabled, campaigns, ...rest } = props;
  const { localization } = useAppContext();
  const dialogs = useDialogsContext();

  const handleOpenDialog = async () => {
    await dialogs.receiptDialogRef.current.open({
      transaction: data,
      campaigns,
    });
  };

  return (
    <>
      {iconOnly && (
        <IconButton {...rest} disabled={!data || disabled} onClick={handleOpenDialog} color={color}>
          <AppIcon icon={ICON.GenerateInvoice} />
        </IconButton>
      )}
      {!iconOnly && (
        <AppBox flex center {...rest}>
          <AppButton disabled={!data || disabled} variant='text' onClick={handleOpenDialog} color={color} startIcon={<AppIcon icon={ICON.GenerateInvoice} />}>
            <AppText variant='small'> {localization.translate('donations.generate_receipt')}</AppText>
          </AppButton>
        </AppBox>
      )}
    </>
  );
};

export default GenerateReceiptSubmitter;
