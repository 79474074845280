import { Autocomplete, CircularProgress, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import useAuthentication from '../../hooks/authentication';
import useCampaigns from '../../hooks/campaigns';
import { AppText, AppTextField } from './AppText';
import { AutocompleteSelector } from './AutocompleteSelector';

const CampaignsSelector = (props) => {
  const { multiple = false, item, applyValue, textFieldProps, renderOption, activeOnly = true, filter, ...rest } = props;
  const campaigns = useCampaigns();
  const { localization } = useAppContext();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        const data = await campaigns.load(activeOnly);
        if (data.length > 0) {
          const items = data
            .filter((c) => (filter ? filter(c) : true))
            .map((c, i) => {
              return { projectId: c.id, projectName: c.name };
            });

          // considering we also have project "unassigned" transactions
          // always have such option in the list of projects
          items.unshift({ projectId: null, projectName: localization.translate('campaigns.no_campaign') });
          setOptions([...items]);
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <AutocompleteSelector
      {...rest}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      multiple={multiple}
      item={item}
      options={options}
      valuePropName='projectId'
      labelPropName='projectName'
      applyValue={applyValue}
      renderInput={(params) => {
        return (
          <AppTextField
            {...params}
            label={localization.translate('app.campaign')}
            {...textFieldProps}
            inputProps={{
              ...params.inputProps,
              ...(params?.inputProps?.value === localization.translate('campaigns.no_campaign') && {
                value: '',
              }),
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        return renderOption ? (
          renderOption(props, option)
        ) : (
          <li {...props} key={option.projectId}>
            <AppText
              sx={{
                ...(option.projectId === null && {
                  color: (theme) => theme.palette.text.disabled,
                  height: '30px',
                }),
              }}
            >
              {option.projectName || ' '}
            </AppText>
          </li>
        );
      }}
      optionToValueComparer={(option, value) => {
        if (!value.projectId) {
          return !option.projectId;
        }
        return option.projectId === value.projectId;
      }}
      loading={loading}
    />
  );
};

export default CampaignsSelector;
