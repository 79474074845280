import { useState } from 'react';
import { useAppContext } from '../../../../context/AppContext';
import { useJobsContext } from '../../../../context/JobsContext';
import { useHttp } from '../../../../hooks/authentication';
import { GridToolbarItem } from './GridToolbarItem';
import { AppIcon, ICON, ICON_SIZE } from '../../AppIcon';
import { AppDialog } from '../../AppDialog';
import { AppButton } from '../../AppButton';
import { AppBox } from '../../AppBox';
import AppSwitch from '../../AppSwitch';
import { AppText } from '../../AppText';
import { INVOICE_STATE } from '../../../Constants';

export const GenerateReceiptItem = (props) => {
  const { variant, onActivated, isDisabled, tooltip, onUpdate, apiRef } = props;
  const { getHttp } = useHttp();
  const jobs = useJobsContext();
  const { localization } = useAppContext();

  const [disabled, setDisabled] = useState(isDisabled);
  const [open, setOpen] = useState(false);
  const [sendEmails, setSendEmails] = useState(false);
  const selectedRows = apiRef.current.getSelectedRows();
  const id = open ? 'generate-invoice' : undefined;

  const disableGenerate = () => {
    if (selectedRows.size < 2) {
      return true;
    }
    for (const value of selectedRows.values()) {
      if (value?.invoice?.state !== INVOICE_STATE.absent) {
        return true;
      }
    }
    return false;
  };

  const openEmailConfirmationDialog = () => {
    setSendEmails(false);
    setOpen(true);
  };

  const generate = async (e) => {
    setOpen(false);
    const ids = [...Array.from(selectedRows.values()).filter((e) => e.invoice?.state === INVOICE_STATE.absent)].map((e, i) => e.id);

    if (ids?.length > 0) {
      setDisabled(true);
      try {
        await getHttp().post('/transactions/receipts', { id: [...ids], ...(sendEmails && { mail: true }) });
        await jobs.loadJobs();
      } catch (error) {
        console.error(error);
      } finally {
        setDisabled(undefined);
      }
    }
  };

  return (
    <>
      <GridToolbarItem
        variant={variant}
        aria-describedby={id}
        onClick={() => openEmailConfirmationDialog()}
        color='primary'
        startIcon={<AppIcon icon={ICON.GenerateInvoice} />}
        disabled={disabled !== undefined ? disabled : disableGenerate()}
        text={localization.translate('donations.generate_receipts')}
      />
      <AppDialog
        open={open}
        hasActions
        useClose
        simple
        closeTitle={localization.translate('common.cancel')}
        dialogActionsContent={<AppButton onClick={() => generate()}>{localization.translate('donations.generate_receipts')}</AppButton>}
        minHeight='100px'
        dialogContent={
          <AppBox flex centerx column sx={{ width: '100%', height: '100%', minHeight: '100px' }}>
            <AppIcon icon={ICON.EmailSend} size={ICON_SIZE.ExtraLarge} />
            <AppBox flex center>
              <AppSwitch checked={sendEmails} onClick={() => setSendEmails(!sendEmails)} />
              <AppBox flex column centery sx={{ mx: 2 }}>
                <AppText>{localization.translate('donations.send_generated_receipts')}</AppText>
                <AppText variant='small' sx={{ color: (theme) => theme.palette.text.secondary }}>
                  {localization.translate('donations.send_generated_receipts_note')}
                </AppText>
              </AppBox>
            </AppBox>
          </AppBox>
        }
        onClose={() => setOpen(false)}
      />
    </>
  );
};
