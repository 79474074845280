import { useContext, useState, createContext } from 'react';

const headerContext = createContext();
const setHeaderContext = createContext();

const HeaderContextProvider = ({ children }) => {
  const [title, setTitle] = useState({
    primary: '',
    secondary: '',
  });

  return (
    <headerContext.Provider value={title}>
      <setHeaderContext.Provider value={setTitle}>{children}</setHeaderContext.Provider>
    </headerContext.Provider>
  );
};

const useHeaderContext = () => {
  const context = useContext(headerContext);
  if (context === undefined) {
    throw new Error('useHeaderContext must be used within HeaderContextProvider');
  }
  return context;
};

const useSetHeaderContext = () => {
  const context = useContext(setHeaderContext);
  if (context === undefined) {
    throw new Error('useSetHeaderContext must be used within HeaderContextProvider');
  }
  return context;
};

export { HeaderContextProvider, useHeaderContext, useSetHeaderContext };
