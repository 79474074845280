import { getTransactionColumns, getTransactionsGroupColumn } from './Columns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import { TASK_STATE, TASK_TYPE, WORKING_STATE } from '../../common/Constants';
import { useHttp } from '../../hooks/authentication';
import { useAppContext } from '../../context/AppContext';
import ImportTransactionsDialog from './import/ImportTransactionsDialog';
import ExportTransactionsDialog from './ExportTransactionsDialog';
import DonationSplitDialog from './DonationSplitDialog';
import { useJobsContext } from '../../context/JobsContext';
import DonationForm from './DonationForm';
import InnerCenteredCircularProgress from '../../common/components/InnerCenteredCircularProgress';
import useCampaigns from '../../hooks/campaigns';
import { AppDataGrid2 } from '../../common/components/datagrid/AppDataGrid2';
import { useSetHeaderContext } from '../../context/HeaderContext';
import { useSetPathContext } from '../../context/PathContext';
import { useDataContext } from '../../context/DataContext';
import { isFewScreenWidth } from '../../utils/utils';
import { AppDadtaGridFooter, TotalSelectedOption } from '../../common/components/datagrid/AppDataGridFooter';
import AppDialogForm from '../../common/components/AppDialogForm';
import SendReceiptByEmailItem from '../../common/components/datagrid/toolbarItems/SendReceiptByEmailItem';
import { AssignItem } from '../../common/components/datagrid/toolbarItems/AssignItem';
import { ImportItem } from '../../common/components/datagrid/toolbarItems/ImportItem';
import { ExportItem } from '../../common/components/datagrid/toolbarItems/ExportItem';
import { GenerateReceiptItem } from '../../common/components/datagrid/toolbarItems/GenerateReceiptItem';
import { SplitItem } from '../../common/components/datagrid/toolbarItems/SplitItem';

const Transactions = ({ path }) => {
  const theme = useTheme();

  const { localization, profile } = useAppContext();
  const dataContext = useDataContext();
  const { getHttp } = useHttp();
  const campaigns = useCampaigns();
  const jobs = useJobsContext();
  const setTitle = useSetHeaderContext();
  const setPath = useSetPathContext();
  const gridRef = useRef(null);
  const [campaignsData, setCampaignsData] = useState({
    data: [],
    state: WORKING_STATE.INPROGRESS,
  });

  useEffect(() => {
    setPath(path);
    setTitle({
      primary: localization.translate('app.donations'),
      secondary: localization.translate('donations.subtitle'),
    });
  }, [profile.language]);

  useEffect(() => {
    dataContext.setName('donations');
    (async () => {
      try {
        const data = await campaigns.load();
        if (data.length > 0) {
          const items = data.map((c, i) => {
            return { projectId: c.id, projectName: c.name };
          });
          setCampaignsData({ data: [...items], state: WORKING_STATE.SUCCESS });
        } else {
          setCampaignsData({ data: [], state: WORKING_STATE.DONE_NO_DATA });
        }
      } catch (error) {
        console.error(error);
        setCampaignsData({ data: [], state: WORKING_STATE.ERROR });
      }
    })();
  }, []);

  const onGenerateReceipt = async (ids) => {
    if (ids?.length > 0) {
      try {
        await getHttp().post('transactions/receipts', { id: [...ids] });
        await jobs.loadJobs();
      } catch (error) {
        console.error(error);
        gridRef.current.updateWorkingState(WORKING_STATE.ERROR);
      }
    }
  };

  const columns = useMemo(() => {
    return getTransactionColumns({
      campaigns: campaignsData.data,
      onUpdate: (items) => gridRef.current.updateItems(items),
      localization: localization,
      displayCurrency: localization.displayCurrency,
      display: localization.display,
      theme: theme,
    });
  }, [campaignsData, localization, theme]);

  useEffect(() => {
    jobs.subscribeOnStateChanged({
      subscriber: 'donationsview',
      callback: async (updatedJobs) => {
        const importDonationsCompleted = updatedJobs.filter((job) => job.type === TASK_TYPE.IMPORT_DONATIONS && job.state === TASK_STATE.COMPLETED);

        if (importDonationsCompleted.length > 0) {
          try {
            await gridRef.current?.load({ updateDetails: true });
          } catch (error) {
            console.error(error);
          }
        }
      },
    });
    jobs.subscribeOnViewStateChanged({
      subscriber: 'donationsview',
      callback: async (updatedJobs) => {
        const trackedJobs = updatedJobs.filter(
          (job) => job.type === TASK_TYPE.CREATE_INVOICE || job.type === TASK_TYPE.LINK_INVOICE || job.type === TASK_TYPE.RECEIPT_BY_EMAIL
        );
        if (trackedJobs.length > 0) {
          try {
            await gridRef.current?.load({ updateDetails: true });
          } catch (error) {
            console.error(error);
          }
        }
      },
    });
    return () => {
      jobs.unsubscribe({
        subscriber: 'donationsview',
      });
    };
  }, []);

  return campaignsData.state === WORKING_STATE.SUCCESS || campaignsData.state === WORKING_STATE.DONE_NO_DATA ? (
    <AppDataGrid2
      treeDataProps={{
        column: { ...getTransactionsGroupColumn(localization, (parent) => gridRef.current.loadChildren(parent)) },
      }}
      ref={gridRef}
      urlPath='transactions'
      errorAlertProps={{
        title: localization.translate('donations.load_transactions_error'),
        content: `${localization.translate('donations.load_transactions_error_content')}. ${localization.translate(
          'errors.try_again'
        )}. ${localization.translate('errors.contact_admin')}`,
      }}
      notificationProps={{
        onDataError: localization.translate('donations.update_transactions_error'),
        onSuccess: localization.translate('donations.update_transactions_success'),
      }}
      /*
      extendedPanel={{
        component: TransactionDetailPanelContent,
      }}
      */
      detailsFormProps={{
        component: DonationForm,
        componentProps: {
          campaigns: campaignsData.data,
          formId: 'transaction-details-form',
          onGenerateReceipt: onGenerateReceipt,
        },
      }}
      noRecordsTitle={localization.translate('donations.no_donations')}
      columns={columns.data}
      initialState={{
        columns: {
          columnVisibilityModel: { ...columns.visibilityModel },
        },
      }}
      slots={{
        footer: AppDadtaGridFooter,
      }}
      slotProps={{
        footer: {
          slot: <TotalSelectedOption />,
        },
      }}
      toolbarProps={{
        filterProps: {
          addQuickFilter: true,
        },
        layoutMenuProps: {},
        buttons: {
          more: !isFewScreenWidth()
            ? []
            : [
                {
                  assign: {
                    component: AssignItem,
                    onUpdate: (items) => gridRef.current.updateItems(items),
                    selectionAware: true,
                  },
                },

                {
                  export: {
                    component: ExportItem,
                    renderForm: (params) => {
                      return <ExportTransactionsDialog {...params} />;
                    },
                    getSearchId: () => gridRef.current.getSearchId(),
                  },
                  import: {
                    component: ImportItem,
                    renderForm: (params) => {
                      return <ImportTransactionsDialog {...params} />;
                    },
                  },
                },
              ],
          items: [
            {
              reload: {
                onActivated: () => gridRef.current.onReload(),
              },
              filter: {},
            },

            {
              add: {
                tooltip: localization.translate('donations.add_new_donation'),
                title: localization.translate('donations.new_donation'),
                itemName: 'donation',
                onSubmit: (item) => gridRef.current.addItem(item),
                renderForm: (params) => {
                  return (
                    <AppDialogForm
                      {...params}
                      slot={{
                        component: DonationForm,
                        props: {
                          campaigns: campaignsData.data,
                        },
                      }}
                    />
                  );
                },
              },
              delete: {
                tooltip: localization.translate('donations.delete_transactions'),
                onDelete: (items, keepChildren) =>
                  gridRef.current.deleteItems({
                    id: [...items.map((i) => i.id)],
                    keepChildren: keepChildren === true ? true : false,
                  }),
                confirmationTitle: localization.translate('donations.delete_transactions'),
                confirmationMessage: localization.translate('donations.delete_transactions_confirm'),
              },
              split: {
                component: SplitItem,
                tooltip: localization.translate('donations.split_donation'),
                title: localization.translate('donations.split_donation'),
                itemName: 'donation',
                onSubmit: (item) => gridRef.current.addItem(item),
                renderForm: (params) => {
                  return <DonationSplitDialog {...params} campaigns={campaignsData.data} />;
                },
              },
            },
            {
              ...(!isFewScreenWidth() && {
                assign: {
                  component: AssignItem,
                  onUpdate: (items) => gridRef.current.updateItems(items),
                  selectionAware: true,
                },
              }),
            },
            {
              receipt: {
                component: GenerateReceiptItem,
                selectionAware: true,
              },
              /*
              sendReceipt: {
                component: SendReceiptByEmailItem,
                selectionAware: true,
              },
              */
            },
            {
              ...(!isFewScreenWidth() && {
                export: {
                  component: ExportItem,
                  renderForm: (params) => {
                    return <ExportTransactionsDialog {...params} />;
                  },
                  getSearchId: () => gridRef.current.getSearchId(),
                },
                import: {
                  component: ImportItem,
                  renderForm: (params) => {
                    return <ImportTransactionsDialog {...params} />;
                  },
                },
              }),
            },
          ],
        },
        getSearchId: () => gridRef.current.getSearchId(),
        onAdd: (item) => gridRef.current.addItem(item),
        onUpdate: (items) => gridRef.current.updateItems(items),
        onReload: () => gridRef.current.onReload(),
        onDelete: (items, keepChildren) => gridRef.current.deleteItems(items, keepChildren),
      }}
      hideFooterSelectedRowCount
      getRowClassName={(params) => `${!!params.row.parentId ? 'inner-in-tree' : ''} status-highlighted--${params.row.trackingState}`}
    />
  ) : (
    <InnerCenteredCircularProgress size={40} />
  );
};

export default Transactions;
