import { useTheme } from '@emotion/react';
import { AppButton } from '../../AppButton';
import { AppText } from '../../AppText';

export const GridToolbarItem = (props) => {
  const { variant, ariaDescribedby, onClick, text, sx, ...rest } = props;
  const theme = useTheme();
  return (
    <AppButton
      variant='text'
      //aria-describedby={ariaDescribedby}
      onClick={onClick}
      {...(theme.typography.fontSize <= 13 && { size: 'medium' })}
      sx={{
        ...sx,
        color: (theme) => theme.palette.text.primary,
      }}
      {...(variant === 'menuitem' && {
        fullWidth: true,
        style: { justifyContent: 'flex-start' },
      })}
      {...rest}
    >
      <AppText variant='small'>{text}</AppText>
    </AppButton>
  );
};
