import { useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppContext } from './AppContext';
import { COLOR_MODES, COLOR_SCHEMES, DARK_COLOR_SCHEMES, LIGHT_COLOR_SCHEMES } from '../common/Constants';

// see typography customization: https://mui.com/material-ui/customization/typography/
// see default theme: https://mui.com/material-ui/customization/default-theme/?expand-path=$.typography
/*
Opacity %   255 Step        2 digit HEX prefix
0%          0.00            00
5%          12.75           0C
10%         25.50           19
15%         38.25           26
20%         51.00           33
25%         63.75           3F
30%         76.50           4C
35%         89.25           59
40%         102.00          66
45%         114.75          72
50%         127.50          7F
55%         140.25          8C
60%         153.00          99
65%         165.75          A5
70%         178.50          B2
75%         191.25          BF
80%         204.00          CC
85%         216.75          D8
90%         229.50          E5
95%         242.25          F2
100%        255.00          FF
*/
//'-apple-system, BlinkMacSystemFont,  Inter, sans-serif, "Open Sans", "Helvetica Neue", Roboto'
/*
10px = 0.625rem
11px = 0.6875rem
12px = 0.75rem 
13px = 0.8125rem
14px = 0.875rem 
16px = 1rem (base)
18px = 1.125rem 
20px = 1.25rem  
22px = 1.375rem 
24px = 1.5rem   
30px = 1.875rem 
32px = 2rem     
*/
// FONT_FAMILY = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
// FONT_FAMILY = '-apple-system, BlinkMacSystemFont,  "Open Sans", sans-serif, Inter, Arial,   "Noto Sans Hebrew",  Roboto';

const Roboto_Typography_Theme = {
  FONT_FAMILY: '-apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", sans-serif',
  FONT_SIZE: 13,
  XLARGE_FONT_SIZE: '1.375rem', // 22px
  LARGE_FONT_SIZE: '0.875rem', // 14px
  NORMAL_FONT_SIZE: '0.8125rem', // 13px
  SMALL_FONT_SIZE: '0.75rem', // 12px
  XSMALL_FONT_SIZE: '0.6875rem', // 11px
  BOLD_FONT_WEIGHT: 500,
};

const Typography_Theme = {
  ...Roboto_Typography_Theme,
};

const ApplyTypographyTheme = (theme) => {
  return {
    bold: theme.BOLD_FONT_WEIGHT,
    fontFamily: theme.FONT_FAMILY,
    fontSize: theme.FONT_SIZE,
    xlarge: {
      fontFamily: theme.FONT_FAMILY,
      fontSize: theme.XLARGE_FONT_SIZE,
    },
    xlargeb: {
      fontFamily: theme.FONT_FAMILY,
      fontSize: theme.XLARGE_FONT_SIZE,
      fontWeight: theme.BOLD_FONT_WEIGHT,
    },
    large: {
      fontFamily: theme.FONT_FAMILY,
      fontSize: theme.LARGE_FONT_SIZE,
    },
    largeb: {
      fontFamily: theme.FONT_FAMILY,
      fontSize: theme.LARGE_FONT_SIZE,
      fontWeight: theme.BOLD_FONT_WEIGHT,
    },
    normal: {
      fontSize: theme.NORMAL_FONT_SIZE,
      fontFamily: theme.FONT_FAMILY,
    },
    normalb: {
      fontSize: theme.NORMAL_FONT_SIZE,
      fontFamily: theme.FONT_FAMILY,
      fontWeight: theme.BOLD_FONT_WEIGHT,
    },
    small: {
      fontSize: theme.SMALL_FONT_SIZE,
      fontFamily: theme.FONT_FAMILY,
    },
    smallb: {
      fontSize: theme.SMALL_FONT_SIZE,
      fontFamily: theme.FONT_FAMILY,
      fontWeight: theme.BOLD_FONT_WEIGHT,
    },
    xsmall: {
      fontFamily: theme.FONT_FAMILY,
      fontSize: theme.XSMALL_FONT_SIZE,
    },
    xsmallb: {
      fontFamily: theme.FONT_FAMILY,
      fontSize: theme.XSMALL_FONT_SIZE,
      fontWeight: theme.BOLD_FONT_WEIGHT,
    },
  };
};

const AppThemeProvider = ({ children }) => {
  const { profile, localization } = useAppContext();

  const getColorScheme = (mode, name) => {
    const schemes = mode === COLOR_MODES.DARK ? DARK_COLOR_SCHEMES : LIGHT_COLOR_SCHEMES;
    const scheme = schemes.find((e) => e.name === name);
    return scheme || schemes[0];
  };

  const getDesignTokens = (mode, schemeName) => {
    const theme = createTheme({
      palette: {
        mode: mode,
      },
    });
    const scheme = getColorScheme(mode, schemeName);

    const tokens = {
      palette: {
        mode: mode,
        alphaStep: 'B2', // 70% see above
        ...(mode === COLOR_MODES.LIGHT
          ? {
              background: {
                default: '#F7F8FA',
                paper: '#FFFFFF',
                paperDisabled: theme.palette.grey[200],
              },
              action: {
                selected: '#e0e0e0',
              },
              layout: {
                ...scheme.scheme.layout,
                empty: '#EEEEEE',
              },
              primary: {
                ...scheme.scheme.primary,
              },
              status: {
                new: '#1565c0',
                approved: '#43a047',
                questioned: '#ef6c00',
              },
              dashboard: {
                success: '#43a047',
                info: '#2196f3',
                warning: '#ef6c00',
                error: '#f4511e',
                expense: '#90a4ae',
              },
              text: {
                contrast: '#212121',
                primary: '#1E2E50',
                //secondary: 'rgb(66, 82, 110)',
                secondary: '#8FA2BF',
                label: '#42526E',
              },
            }
          : {
              background: {
                default: '#121212',
                paper: '#1A2030',
                paperDisabled: '#29334D',
              },
              primary: {
                ...scheme.scheme.primary,
                contrastText: '#FFFF',
              },
              layout: {
                ...scheme.scheme.layout,
                empty: '#0D1018',
              },
              status: {
                new: '#64b5f6',
                approved: '#81c784',
                questioned: '#f57c00',
              },
              dashboard: {
                success: '#81c784',
                info: '#42a5f5',
                warning: '#ef6c00',
                error: '#f4511e',
                expense: '#607d8b',
              },
              text: {
                ...theme.palette.text,
                label: theme.palette.text.secondary,
              },
            }),
      },
      direction: localization.dir,

      typography: {
        ...ApplyTypographyTheme(Typography_Theme),

        title: {
          fontSize: '0.9rem',
        },

        subtitle: {
          fontSize: '0.8rem',
        },

        subinfo: {
          fontSize: '0.78rem',
        },
      },
      shape: {
        // The borderRadius property multiplies the value it receives by the theme.shape.borderRadius value (the default for this value is 4px).
        // <Box sx={{ borderRadius: 2 }} /> - equivalent to borderRadius: theme => 2 * theme.shape.borderRadius
        // https://mui.com/system/getting-started/the-sx-prop/
        borderRadius: 2,
        borderRadiusPx: '4px',
      },
      components: {
        MuiInputBase: {
          styleOverrides: {
            input: {
              fontSize: `${Typography_Theme.FONT_SIZE}px`,
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              fontSize: `${Typography_Theme.FONT_SIZE}px`,
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: { direction: 'ltr' }, // hardcoded ltr for both directions - behaves correct
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: { direction: 'ltr' }, // hardcoded ltr for both directions - behaves correct
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: { direction: 'ltr' }, // hardcoded ltr for both directions - behaves correct
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: { direction: 'ltr' }, // hardcoded ltr for both directions - behaves correct
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: { direction: 'ltr' }, // hardcoded ltr for both directions - behaves correct
          },
        },
      },
    };
    return tokens;
  };

  const theme = useMemo(() => createTheme(getDesignTokens(profile.colorMode, profile.colorScheme)), [profile.colorMode, profile.colorScheme, localization.dir]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppThemeProvider;
