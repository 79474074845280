import { forwardRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import { ELEMENT_POSITION } from '../common/Constants';
import { AppBox } from '../common/components/AppBox';
import { useAppContext } from '../context/AppContext';
import { useDataContext } from '../context/DataContext';
import SplitPane, { Pane, SashContent } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { useEffect } from 'react';
import { MainAreaHeaderInner } from '../layout/MainAreaHeader';

// https://yyllff.github.io/split-pane-react/?path=/story/advanced--fluid-panes
// https://codesandbox.io/s/split-pane-themes-xmsqtt?file=/src/demos/style.scss:287-315

export const AppDataGridView = forwardRef((props, ref) => {
  const {
    viewProps: { headerPane, gridPane, detailsPane },
  } = props;

  const { profile } = useAppContext();
  const theme = useTheme();
  const dataContext = useDataContext();
  const detailsPosition = dataContext.getProfile()?.position || ELEMENT_POSITION.SIDE;

  const [layout, setLayout] = useState({
    size: detailsPosition === ELEMENT_POSITION.SIDE ? ['65%', 'auto'] : ['100%', 'auto'],
    split: detailsPosition === ELEMENT_POSITION.SIDE ? 'vertical' : 'horizontal',
    division: { max: '70%', min: '50%' },
  });

  useEffect(() => {
    if (!detailsPane.content) {
      setLayout({
        size: ['100%', 'auto'],
        split: 'vertical',
        division: { max: '100%', min: '100%' },
      });
      return;
    }
    switch (dataContext.profile[dataContext.name]?.position) {
      case ELEMENT_POSITION.OFF:
        setLayout({
          size: ['100%', 'auto'],
          split: 'vertical',
          division: { max: '100%', min: '100%' },
        });
        break;
      case ELEMENT_POSITION.BOTTOM:
        setLayout({
          size: ['50%', 'auto'],
          split: 'horizontal',
          division: { max: '70%', min: '40%' },
        });
        break;
      default:
        setLayout({
          size: ['65%', 'auto'],
          split: 'vertical',
          division: { max: '70%', min: '50%' },
        });
    }
  }, [dataContext.profile[dataContext.name]?.position, detailsPane.content]);

  return (
    <AppBox flex center column sx={{ width: '100%', height: '100%' }}>
      <AppBox flex centery sx={{ overflow: 'hidden', minHeight: '40px', width: '100%', marginBottom: '3px' }}>
        {!profile.sideMenu && <MainAreaHeaderInner />}
        {headerPane?.content}
      </AppBox>
      <SplitPane
        split={layout.split}
        sizes={layout.size}
        onChange={(sizes) => setLayout({ ...layout, size: [...sizes] })}
        resizerSize={2}
        sashRender={(index, active) => (
          <SashContent
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 1s',
            }}
          >
            <span
              style={{
                display: 'block',
                height: '100%',
                width: '2px',
                ...(active && {
                  backgroundColor: theme.palette.primary.main,
                }),
              }}
            />
          </SashContent>
        )}
      >
        <Pane
          minSize={layout.division.min}
          maxSize={layout.division.max}
          style={{ border: `1px solid ${theme.palette.divider}`, borderRadius: theme.shape.borderRadiusPx }}
        >
          <AppBox flex centery sx={{ height: '100%', width: '100%' }}>
            {/* Need to have some minimal height and width of grid's parent container to avoid warnings about empty size*/}
            <div style={{ width: '100%', height: '100%', minWidth: '300px', minHeight: '300px' }}>{gridPane.content}</div>
          </AppBox>
        </Pane>
        <Pane style={{ border: `1px solid ${theme.palette.divider}`, borderRadius: theme.shape.borderRadiusPx, marginLeft: '2px' }}>
          <AppBox flex centery>
            {detailsPane.content}
          </AppBox>
        </Pane>
      </SplitPane>
    </AppBox>
  );
});
