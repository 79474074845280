import { useTheme } from '@emotion/react';
import { Grid } from '@mui/material';
import { forwardRef, useImperativeHandle } from 'react';
import { AppBox } from '../../../../../common/components/AppBox';
import { AppIcon, ICON, ICON_SIZE } from '../../../../../common/components/AppIcon';
import AppSwitch from '../../../../../common/components/AppSwitch';
import { AppText, AppTextField } from '../../../../../common/components/AppText';
import { PAYMENT_PROVIDERS } from '../../../../../common/Constants';
import { useAppContext } from '../../../../../context/AppContext';

export const IsraelGivesSourceForm = forwardRef((props, ref) => {
  const { api } = props;
  const theme = useTheme();
  const { localization } = useAppContext();

  const deployingData = (details) => {
    let toDeploy = null;
    if (details) {
      toDeploy = { ...details };
    } else {
      toDeploy = {
        provider: PAYMENT_PROVIDERS.ISRAEL_GIVES,
        type: 'source',
        enabled: true,
      };
      api.setInvalidInputs({ userId: true, password: true });
    }
    return toDeploy;
  };

  const submittingData = (data) => {
    const toSubmit = { ...data };
    if (!toSubmit.clientSecret) {
      delete toSubmit.clientSecret;
    }
    return toSubmit;
  };

  useImperativeHandle(ref, () => ({
    deployingData,
    submittingData,
  }));

  return (
    <Grid container rowSpacing={2} columnSpacing={1}>
      <Grid item xs={12} md={3}>
        <AppBox flex centery sx={{ height: '100%' }}>
          <AppSwitch
            checked={api.data?.enabled || false}
            onChange={() => {
              api.setDataField({ key: 'enabled', value: !api.data?.enabled });
            }}
          />
          <AppIcon
            icon={ICON.Dot}
            size={ICON_SIZE.Small}
            style={{
              color: api.data?.enabled ? theme.palette.success.main : theme.palette.text.disabled,
            }}
          />
          <AppText sx={{ mx: 1 }}>
            {api.data?.enabled ? localization.translate('settings.fetach_auto') : localization.translate('settings.fetch_manual')}
          </AppText>
        </AppBox>
      </Grid>
      <Grid item xs={12} md={9}>
        <AppTextField
          fullWidth
          label={localization.translate('settings.display_name')}
          name='displayname'
          id='displayname'
          value={api.data.displayName || ''}
          onChange={(v) => api.setDataField({ key: 'displayName', value: v })}
        />
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          required
          error={!api.data.userId}
          fullWidth
          label='userId'
          name='userid'
          id='userid'
          value={api.data.userId || ''}
          onChange={(v) => api.setDataField({ key: 'userId', value: v })}
        />
      </Grid>
      <Grid item xs={12}>
        <AppTextField
          fullWidth
          required={!api.details}
          error={!api.details ? !api.data.password : false}
          label='password'
          name='password'
          id='password'
          onChange={(v) => api.setDataField({ key: 'password', value: v })}
        />
      </Grid>
      {api.details && (
        <>
          <Grid item xs={12} md={6}>
            <AppTextField
              variant='standard'
              readonly
              fullWidth
              id='created'
              label={localization.translate('common.created')}
              name='created'
              value={api.data.created ? localization.display(new Date(api.data.created)) : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField
              variant='standard'
              readonly
              fullWidth
              id='updated'
              label={localization.translate('common.updated')}
              name='update'
              value={api.data.updated ? localization.display(new Date(api.data.updated)) : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField
              variant='standard'
              readonly
              fullWidth
              id='fetched'
              label={localization.translate('settings.last_fetched')}
              name='fetched'
              value={api.data?.lastFetch ? localization.display(new Date(api.data.lastFetch)) : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppTextField
              variant='standard'
              readonly
              fullWidth
              id='triggered'
              label={localization.translate('settings.last_triggered')}
              name='triggered'
              value={api.data.lastTriggered ? localization.display(new Date(api.data.lastTriggered)) : ''}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
});
