import { Tooltip } from '@mui/material';
import { AppBox } from './AppBox';

export const AppTooltip = (props) => {
  const { useBox, children, title } = props;
  return useBox ? (
    <Tooltip title={title} enterDelay={1000}>
      <AppBox flex center>
        {children}
      </AppBox>
    </Tooltip>
  ) : (
    <Tooltip title={title} enterDelay={1000}>
      {children}
    </Tooltip>
  );
};
