import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { AppButton } from './AppButton';
import { useAppContext } from '../../context/AppContext';
import { AppIcon, ICON, ICON_SIZE } from './AppIcon';
import { AppText } from './AppText';

const StyledDataStateContainer = styled((props) => (
  <Box
    sx={{
      color: (theme) => props?.color || theme.palette.text.disabled,
      ...props?.sx,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: props?.width || '100%',
      minHeight: props?.height || '100%',
    }}
    children={props?.children}
  />
))(({ theme }) => ({}));

const EmptyData = (props) => {
  const { width, height, color, title, onReload, ...rest } = props;
  const { localization } = useAppContext();
  return (
    <StyledDataStateContainer width={width} height={height} color={color} {...rest}>
      <AppIcon icon={ICON.NoData} size={ICON_SIZE.ExtraLarge} />
      <AppText variant='small'>{title}</AppText>
      {onReload && (
        <AppButton variant='outlined' sx={{ my: 2 }} onClick={onReload}>
          {localization.translate('common.reload')}
        </AppButton>
      )}
    </StyledDataStateContainer>
  );
};

const NoData = (props) => {
  const { width, height, title, ...rest } = props;
  return <EmptyData width={width} height={height} title={title} {...rest} />;
};

const ErrorData = (props) => {
  const { width, height, title, ...rest } = props;
  const theme = useTheme();
  return <EmptyData width={width} height={height} title={title} color={`${theme.palette.error.main}${theme.palette.alphaStep}`} {...rest} />;
};

const LoadingData = (props) => {
  const { width, height } = props;
  return (
    <StyledDataStateContainer width={width} height={height}>
      <CircularProgress size={40} color='primary' />
    </StyledDataStateContainer>
  );
};
export { NoData, ErrorData, LoadingData };
