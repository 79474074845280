import { Autocomplete } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { PAYMENT_METHODS } from '../Constants';
import { AppText, AppTextField } from '../components/AppText';
import { useAppContext } from '../../context/AppContext';

const NO_PAYMENT_SOURCE = {
  code: '',
  name: '',
};

const PaymentSourceSelector = (props) => {
  const { required, selectedCode, onSelected } = props;
  const [value, setValue] = useState(null);
  const { localization } = useAppContext();
  const getPaymentSource = (code) => {
    let codeToSelect = code || '';
    return PAYMENT_METHODS.find((c) => c.code === codeToSelect) || NO_PAYMENT_SOURCE;
  };

  useEffect(() => {
    setValue(getPaymentSource(selectedCode));
  }, [selectedCode]);

  const handleSelected = (event, newValue) => {
    setValue(newValue);
    if (onSelected) {
      onSelected(newValue);
    }
  };

  const sourceOptions = useMemo(() => {
    const options = [...PAYMENT_METHODS];
    options.unshift({ ...NO_PAYMENT_SOURCE });
    return options;
  }, [PAYMENT_METHODS]);

  return (
    <Autocomplete
      options={sourceOptions}
      size='small'
      multiple={false}
      value={value}
      autoHighlight
      fullWidth
      disableListWrap
      disableClearable
      onChange={handleSelected}
      renderInput={(params) => {
        return (
          <AppTextField
            {...params}
            {...(required ? { required: true, error: !params.inputProps.value || !params.inputProps.value.trim() } : {})}
            label={localization.translate('donations.payment_method')}
          ></AppTextField>
        );
      }}
      isOptionEqualToValue={(option, value) => {
        return option.code === value.code;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.code}>
            <AppText>{option.code ? localization.translate(`app.${option.code}`) : ''}</AppText>
          </li>
        );
      }}
      getOptionLabel={(option) => {
        return option.code ? localization.translate(`app.${option.code}`) : '';
      }}
    />
  );
};

export default PaymentSourceSelector;
