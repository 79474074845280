import { Autocomplete, Chip, Grid, InputAdornment } from '@mui/material';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { SupportedLanguages } from '../../common/Constants';
import { getCurrencySymbol } from '../../utils/utils';
import { useAppContext } from '../../context/AppContext';
import { AppNumericField, AppText, AppTextField } from '../../common/components/AppText';
import { AppFormLayout } from '../../common/components/AppFormLayout';
import { AppBox } from '../../common/components/AppBox';
import { AppButton } from '../../common/components/AppButton';
import AppSwitch from '../../common/components/AppSwitch';
import { useTenantContext } from '../../context/TenantContext';

const CampaignForm = forwardRef((props, ref) => {
  const { api } = props;
  const { localization, tenant } = useAppContext();
  const { tenantSettings } = useTenantContext();
  //const [data, setData] = useState({ commission: '' });
  const [alternativeNames, setAlternativeNames] = useState({});
  const [alternativeDescriptions, setAlternativeDescriptions] = useState({});

  const deployingData = (details) => {
    let toDeploy = null;
    if (details) {
      setAlternativeNames(() => {
        const names = {};
        details.alternativeNames?.forEach((e, i) => {
          names[e.lang] = e.text;
        });
        return names;
      });
      setAlternativeDescriptions(() => {
        const descs = {};
        details.alternativeDescriptions?.forEach((e, i) => {
          descs[e.lang] = e.text;
        });
        return descs;
      });
      toDeploy = { ...details, commission: details.commission === null ? '' : details.commission };
    } else {
      api.setInvalidInputs({ name: true });
      toDeploy = { state: 'Active', commission: '' };
    }
    return toDeploy;
  };

  const submittingData = (data) => {
    return {
      ...data,
      commission: !data.commission ? null : Number(data.commission),
      alternativeNames: Object.keys(alternativeNames).map((k, i) => {
        return { lang: k, text: alternativeNames[k] };
      }),
      alternativeDescriptions: Object.keys(alternativeDescriptions).map((k, i) => {
        return { lang: k, text: alternativeDescriptions[k] };
      }),
    };
  };

  useImperativeHandle(ref, () => ({
    deployingData,
    submittingData,
  }));

  const setName = (lang, value) => {
    setAlternativeNames({ ...alternativeNames, [lang]: value });
    api.setDataField({
      key: 'name',
      value: value,
      changeValidator: (v) => {
        const campaignName = !api.details ? '' : api.details?.alternativeNames?.find((n) => n.lang === localization.language)?.text || api.details.name;
        return v !== campaignName;
      },
    });
  };

  const setDescription = (lang, value) => {
    setAlternativeDescriptions({ ...alternativeDescriptions, [lang]: value });
    api.setDataField({
      key: 'description',
      value: value,
      changeValidator: (v) => {
        let campaignDescription = !api.details
          ? ''
          : api.details.alternativeDescriptions?.find((n) => n.lang === localization.language)?.text || api.details.description;
        if (!campaignDescription) {
          campaignDescription = '';
        }
        return value !== campaignDescription;
      },
    });
  };

  const setGoal = (value) => {
    api.setDataField({
      key: 'targetGoal',
      value: Number(value),
    });
  };

  const setCommission = (value) => {
    api.setDataField({
      key: 'commission',
      value: value,
      validateInput: true,
      inputValidator: (v) => {
        return v < 0 || v > 100;
      },
    });
  };

  const setDefaultCommission = () => {
    if (api.data.commission !== null) {
      setCommission('');
    }
  };

  const setAlternativeName = (lang, value) => {
    setAlternativeNames({ ...alternativeNames, [lang]: value });
    let name = !api.details ? '' : api.details.alternativeNames?.find((n) => n.lang === lang)?.text;
    if (!name) {
      name = '';
    }
    if (value !== name) {
      api.setFieldToUpdate(`name_${lang}`);
    } else {
      api.unsetFieldToUpdate(`name_${lang}`);
    }
  };

  const setAlternativeDescription = (lang, value) => {
    setAlternativeDescriptions({ ...alternativeDescriptions, [lang]: value });
    let desc = !api.details ? '' : api.details.alternativeDescriptions?.find((d) => d.lang === lang)?.text;
    if (!desc) {
      desc = '';
    }
    if (value !== desc) {
      api.setFieldToUpdate(`desc_${lang}`);
    } else {
      api.unsetFieldToUpdate(`desc_${lang}`);
    }
  };

  const setPatterns = (value) => {
    api.setDataField({
      key: 'patterns',
      value: [...value],
      changeValidator: (v) => {
        let json1 = JSON.stringify(api.details?.patterns);
        if (!json1) {
          json1 = '';
        }
        let json2 = JSON.stringify(value);
        if (!json2) {
          json2 = '';
        }
        return json1 !== json2;
      },
    });
  };

  const setActive = () => {
    const state = api.data?.state === 'Active' ? 'Inactive' : 'Active';
    api.setDataField({
      key: 'state',
      value: state,
      changeValidator: (v) => {
        const campaignState = !api.details?.state ? 'Inactive' : api.details?.state;
        return v !== campaignState;
      },
    });
  };

  return api.data ? (
    <AppFormLayout
      inline={!!api.details}
      {...(api.details && {
        headerProps: {
          content: (
            <>
              <AppBox flex column sx={{ minWidth: '40%' }}>
                <AppText variant='normal'>{localization.translate('app.campaign')}</AppText>
                <AppText variant='small' sx={{ color: (theme) => theme.palette.text.secondary }}>
                  {api.details.name}
                </AppText>
                <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
                  {localization.displayCurrency(api.details.targetGoal || '-')}
                </AppText>
              </AppBox>
              <AppBox flex sx={{ width: '100%', justifyContent: 'flex-end' }}>
                <AppText variant='small' sx={{ mt: '3px', verticalAlign: 'center' }}>
                  {localization.translate('campaigns.active')}
                </AppText>
                <AppSwitch
                  sx={{ mx: 2 }}
                  size='small'
                  checked={api.data?.state === 'Active'}
                  onChange={() => {
                    setActive();
                  }}
                />
              </AppBox>
            </>
          ),
        },
      })}
      contentProps={{
        content: (
          <Grid container rowSpacing={2} columnSpacing={1} sx={{ pr: 1 }}>
            <Grid item xs={12}>
              <AppTextField
                sx={{ width: '100%' }}
                required
                id='name'
                label={localization.translate('campaigns.campaign_name')}
                name='name'
                value={api.data?.name || ''}
                error={!api.data?.name}
                onChange={(v) => {
                  setName(localization.language, v);
                }}
              />
            </Grid>
            <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
              <Grid item xs={6}>
                <AppNumericField
                  sx={{ width: '100%' }}
                  id='goal'
                  label={localization.translate('campaigns.campaign_goal')}
                  name='goal'
                  value={api.data?.targetGoal || ''}
                  onChange={(v) => {
                    setGoal(v);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>{getCurrencySymbol(localization.locale, tenant.getCurrency())}</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
              <Grid item xs={6}>
                <AppNumericField
                  sx={{ width: '100%', mr: 1 }}
                  id='commission'
                  label={localization.translate('campaigns.commission')}
                  name='commission'
                  value={api.data?.commission === undefined || api.data?.commission === null ? '' : api.data?.commission}
                  error={api.data?.commission < 0 || api.data?.commission > 100}
                  onChange={(v) => {
                    setCommission(v);
                  }}
                  {...(api.data?.commission !== '' && {
                    InputProps: { endAdornment: <InputAdornment position='end'>%</InputAdornment> },
                  })}
                />
              </Grid>
              <Grid item xs>
                <AppBox flex centery sx={{ height: '100%' }}>
                  <AppText
                    variant='small'
                    {...(api.data?.commission !== '' && {
                      sx: {
                        color: (theme) => theme.palette.text.disabled,
                      },
                    })}
                  >
                    {localization.translate('common.default')} {`(${tenantSettings.defaultCommissionPercentage}%)`}
                  </AppText>
                  <AppSwitch
                    sx={{ mx: 2 }}
                    checked={api.data?.commission === ''}
                    onChange={() => {
                      setDefaultCommission();
                    }}
                  />
                </AppBox>
              </Grid>
            </Grid>
            {/*
            <Grid item xs={12}>
              <AppText variant='xsmall'>{localization.translate('campaigns.alternative_names')}</AppText>
            </Grid>
            {SupportedLanguages.filter((l) => l.code !== localization.language).map((lang, index) => {
              return (
                <Grid item key={index} xs={12}>
                  <AppTextField
                    sx={{
                      width: '100%',
                      direction: localization.getLanguageDirection(lang.code),
                    }}
                    id={`name.${lang.code}`}
                    name={`name.${lang.code}`}
                    label={lang.name}
                    value={alternativeNames[lang.code] || ''}
                    onChange={(v) => {
                      setAlternativeName(lang.code, v);
                    }}
                  />
                </Grid>
              );
            })}
            */}
            <Grid item xs={12}>
              <AppTextField
                sx={{ width: '100%' }}
                multiline
                rows={3}
                id='description'
                label={localization.translate('campaigns.campaigns_description')}
                name='description'
                value={api.data?.description || ''}
                onChange={(v) => {
                  setDescription(localization.language, v);
                }}
              />
            </Grid>
            {/*
            <Grid item xs={12}>
              <AppText variant='xsmall'>{localization.translate('campaigns.alternative_descriptions')}</AppText>
            </Grid>
            {SupportedLanguages.filter((l) => l.code !== localization.language).map((lang, index) => {
              return (
                <Grid item key={index} xs={12}>
                  <AppTextField
                    sx={{
                      width: '100%',
                      direction: localization.getLanguageDirection(lang.code),
                    }}
                    multiline
                    maxRows={4}
                    id={`name.${lang.code}`}
                    name={`name.${lang.code}`}
                    label={lang.name}
                    value={alternativeDescriptions[lang.code] || ''}
                    onChange={(v) => {
                      setAlternativeDescription(lang.code, v);
                    }}
                  />
                </Grid>
              );
            })}
            */}
            <Grid item xs={12}>
              <Autocomplete
                sx={{ mb: 1 }}
                onChange={(event, value) => {
                  setPatterns(value);
                }}
                value={api.data?.patterns || []}
                size='small'
                multiple={true}
                options={[]}
                freeSolo
                renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant='outlined' label={option} {...getTagProps({ index })} />)}
                renderInput={(params) => (
                  <AppTextField
                    {...params}
                    variant='outlined'
                    id='patterns'
                    name='patterns'
                    label={localization.translate('app.patterns')}
                    helperText={localization.translate('app.type_pattern_enter')}
                  />
                )}
              />
            </Grid>
            {api.details && (
              <Grid item xs={12} sm={6}>
                <AppTextField
                  sx={{ mt: 1 }}
                  readonly
                  fullWidth
                  label={localization.translate('app.created_by')}
                  name='created_by'
                  id='created_by'
                  value={api.data?.createdBy || ''}
                />
              </Grid>
            )}
          </Grid>
        ),
      }}
      {...(api.details && {
        footerProps: {
          actionProps: {
            content: (
              <AppButton disabled={api.disableSubmit} onClick={api.submitData}>
                {localization.translate('common.save')}
              </AppButton>
            ),
          },
        },
      })}
    />
  ) : (
    <></>
  );
});

export default CampaignForm;
