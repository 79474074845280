import { forwardRef, useState, useImperativeHandle, useRef, useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import { AppDialog } from '../../common/components/AppDialog';
import { REPORT_TYPE } from '../../common/Constants';
import { AppBox } from '../../common/components/AppBox';
import CommonReportSettings from './CommonReportSettings';
import CampaignReport from './CampaignReport';

const dialogMediaStyle = {
  '@media screen and (max-height: 995px)': {
    height: '700px',
  },
  '@media screen and (max-height: 800px)': {
    height: '500px',
  },
};

/*
config: {
    date,
    title,
    type <campaign/period>
    settings: {},
    donations: {},
    expenses: {}
}
*/

const Reports = [
  {
    type: REPORT_TYPE.Campaign,
    component: CampaignReport,
  },
];

const ReportDialog = forwardRef((props, ref) => {
  const { localization, profile } = useAppContext();
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState();
  const [report, setReport] = useState();
  const reportRef = useRef();

  useEffect(() => {
    if (config?.type && config.type !== report?.type) {
      const _report = Reports.find((r) => r.type === config.type);
      if (!!_report) {
        setReport({ ..._report });
      }
    }
  }, [config]);

  const close = () => {
    setOpen(false);
    setConfig(null);
    setReport(null);
  };

  useImperativeHandle(ref, () => ({
    open: (config) => {
      setConfig({ ...config, translate: localization.translate, language: profile.language });
      setOpen(true);
    },
  }));

  return (
    <AppDialog
      fullScreen
      open={open}
      onClose={close}
      title={localization.translate(`reports.${config?.type || 'report'}`)}
      hasActions
      useClose
      dialogContent={
        config ? (
          <AppBox flex sx={{ width: '100%', height: '100%' }}>
            <AppBox flex sx={{ width: '40%', height: '100%', pr: 2 }}>
              <CommonReportSettings config={config} setConfig={setConfig} />
            </AppBox>
            <AppBox
              flex
              centerx
              sx={{
                direction: localization.getLanguageDirection(config.language),
                width: '60%',
                height: '100%',
                p: 2,
                border: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              {report && <report.component ref={reportRef} config={config} />}
            </AppBox>
          </AppBox>
        ) : (
          <></>
        )
      }
    />
  );
});

export default ReportDialog;
