import { IconButton } from '@mui/material';
import { useState, useEffect } from 'react';
import { AppBox } from '../../../../common/components/AppBox';
import { AppText } from '../../../../common/components/AppText';
import { useAppContext } from '../../../../context/AppContext';
import FileUploader from '../../../../common/components/FileUploader';
import { AppIcon, ICON } from '../../../../common/components/AppIcon';
import { useHttp } from '../../../../hooks/authentication';
import Resizer from 'react-image-file-resizer';

const MAX_LOGO_WIDTH = 200;
const MAX_LOGO_HEIGHT = MAX_LOGO_WIDTH / (16 / 9);

const OrganizationLogoSettings = (props) => {
  const { getHttp } = useHttp();
  const { localization } = useAppContext();
  const [logoUrl, setLogoUrl] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await getHttp().get('configurations/tenant/logo', { responseType: 'blob' });
        if (response.status === 204 || !response.data) {
          return;
        }
        setLogoUrl(URL.createObjectURL(response.data));
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        MAX_LOGO_WIDTH,
        MAX_LOGO_HEIGHT,
        file.type.includes('png') ? 'PNG' : 'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'blob'
      );
    });

  const onUpload = async (file) => {
    if (file) {
      if (file.size > 5000000) {
        setError('5Mb');
      } else {
        setError(null);
        try {
          const blob = await resizeFile(file);

          const formData = new FormData();
          formData.append('logo', blob, file.name);
          await getHttp({ headers: { 'Content-Type': 'multipart/form-data' } }).post('configurations/tenant/logo', formData);
          setLogoUrl(URL.createObjectURL(blob));
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const removeLogo = async () => {
    setLogoUrl(null);
    try {
      await getHttp().request({
        method: 'delete',
        url: 'configurations/tenant/logo',
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AppBox flex column sx={{ pr: 10, maxHeight: '400px', overflow: 'auto', width: '100%' }}>
      <AppBox flex column centery sx={{ flexGrow: 1 }}>
        <AppText variant='smallb'>{localization.translate('settings.tenant_logo')}</AppText>
        <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
          {localization.translate('settings.logo_size_description', {
            ratio: '16x9',
            dimension: `${MAX_LOGO_WIDTH.toFixed(0)}x${MAX_LOGO_HEIGHT.toFixed(0)}`,
            size: '50Mb',
          })}
        </AppText>
      </AppBox>

      <AppBox flex centery sx={{ mt: 2, width: '100%' }}>
        <AppBox flex column center>
          <AppBox
            flex
            centery
            sx={{
              position: 'relative',
              width: `${MAX_LOGO_WIDTH}px`,
              height: `${MAX_LOGO_HEIGHT}px`,
              ...(!logoUrl && { border: (theme) => `1px solid ${theme.palette.divider}` }),
            }}
          >
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
              <FileUploader
                showNotification={false}
                useOpacity={!logoUrl}
                accept='.png, .jpeg, .jpg'
                {...(logoUrl && {
                  content: () => {
                    return <img src={logoUrl} style={{ maxWidth: `${MAX_LOGO_WIDTH}px`, maxHeight: `${MAX_LOGO_HEIGHT}px` }} />;
                  },
                })}
                onUpload={onUpload}
                successNotificationProps={{}}
                errorNotificationProps={{}}
              />
            </div>
          </AppBox>
          {!!error && (
            <AppBox flex centery>
              <AppText variant='xsmall' sx={{ mt: 1, color: (theme) => theme.palette.error.main }}>
                {localization.translate('settings.logo_size_limit', { size: error })}
              </AppText>
            </AppBox>
          )}
        </AppBox>
        {!!logoUrl && (
          <AppBox flex centery sx={{ mx: 3 }}>
            <IconButton onClick={removeLogo}>
              <AppBox flex centery>
                <AppIcon icon={ICON.Delete} />
                <AppText variant='small' sx={{ mx: 1 }}>
                  {localization.translate('common.delete')}
                </AppText>
              </AppBox>
            </IconButton>
          </AppBox>
        )}
      </AppBox>
    </AppBox>
  );
};

export default OrganizationLogoSettings;
