import { Checkbox } from '@mui/material';
import { AppTooltip } from './AppTooltip';

export const AppCheckbox = (props) => {
  const { tooltip, ...rest } = props;
  return tooltip ? (
    <AppTooltip title={tooltip}>
      <Checkbox {...rest} />
    </AppTooltip>
  ) : (
    <Checkbox {...rest} />
  );
};
