import { CircularProgress, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import useAuthentication from '../../hooks/authentication';
import { usePaymentProviders } from '../../hooks/paymentProviders';
import { PAYMENT_PROVIDERS } from '../Constants';
import { AppText, AppTextField } from './AppText';
import { AutocompleteSelector } from './AutocompleteSelector';

export const PaymentProviderSelector = (props) => {
  const { multiple = false, item, applyValue, textFieldProps, renderOption, ...rest } = props;
  const paymentSources = usePaymentProviders();
  const { localization } = useAppContext();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const getPaymentProvider = (code) => {
    const found = Object.getOwnPropertyNames(PAYMENT_PROVIDERS).find((p) => PAYMENT_PROVIDERS[p] === code);
    return !!found ? localization.translate(`app.${code}`) : code;
  };

  const renderLabel = (source) => {
    let value = localization.translate('donations.no_payment_source');
    if (source.id) {
      value = source.displayName;
      const provider = getPaymentProvider(source.provider);
      if (value) {
        value = `${value} (${provider})`;
      } else {
        value = provider;
      }
    }
    return value;
  };

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        const data = await paymentSources.load();
        const items = [...data].map((e, i) => {
          return {
            ...e,
            label: renderLabel(e),
          };
        });
        items.unshift({ id: null, displayName: '', provider: '', label: localization.translate('donations.no_payment_source') });
        setOptions([...items]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  const itemWithLabel = {
    ...item,
    value: {
      ...item.value,
      label: renderLabel(item.value),
    },
  };
  return (
    <AutocompleteSelector
      {...rest}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      multiple={multiple}
      item={itemWithLabel}
      options={options}
      valuePropName='id'
      labelPropName='label'
      applyValue={applyValue}
      renderInput={(params) => {
        return (
          <AppTextField
            {...params}
            label={localization.translate('donations.payment_source')}
            {...textFieldProps}
            inputProps={{
              ...params.inputProps,
              ...(params?.inputProps?.value === localization.translate('donations.no_payment_source') && {
                value: '',
              }),
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        return renderOption ? (
          renderOption(props, option)
        ) : (
          <li {...props} key={option.id}>
            <AppText
              sx={{
                ...(option.id === null && {
                  color: (theme) => theme.palette.text.disabled,
                  height: '30px',
                }),
              }}
            >
              {option.label || ''}
            </AppText>
          </li>
        );
      }}
      optionToValueComparer={(option, value) => {
        if (!value.id) {
          return !option.id;
        }
        return option.id === value.id;
      }}
      loading={loading}
    />
  );
};
