import { useRef } from 'react';
import { useEffect, useContext, createContext } from 'react';
import { TASK_VIEWSTATE } from '../common/Constants';
import { useHttp } from '../hooks/authentication';
import { useInterval } from '../hooks/interval';
import { useSubscription } from '../hooks/subscription';

const JOB_TOPICS = {
  CHANGED: 'JobsChanged',
  RELOADED: 'JobsReloaded',
  STATE_CHANGED: 'StateChanged',
  VIEWSTATE_CHANGED: 'ViewstateChanged',
};

const jobsContext = createContext();

const JobsContextProvider = ({ children }) => {
  const data = useRef([]);
  const { getHttp } = useHttp();
  const subscription = useSubscription();

  const hasChanges = ({ orig, reloaded }) => {
    if (orig?.length !== reloaded?.length) {
      return true;
    }
    let orig_tmp = orig ? [...orig] : [];
    reloaded.forEach((reloaded_item, index) => {
      const orig_index = orig_tmp.findIndex((e) => e.id === reloaded_item.id);
      if (orig_index < 0) {
        // item with reloaded id not found in orig
        return true;
      }
      const orig_item = orig_tmp[orig_index];

      if (
        reloaded_item.state !== orig_item.state ||
        reloaded_item.viewState !== orig_item.viewState ||
        reloaded_item.stateDescription !== orig_item.stateDescription ||
        reloaded_item.updated !== orig_item.updated
      ) {
        return true; // item with reloaded id differs from the item with the same id in orig
      } else {
        orig_tmp.splice(orig_index, 1); // both items in reloaded and orig are same, remove it from org
      }
    });
    return orig_tmp.length > 0 ? true : false; // orig has or has not items which are not in reloaded
  };

  // Subscribe on any changes in loaded jobs
  const subscribeOnHasChanges = ({ subscriber, callback }) => {
    subscription.subscribe({
      topic: JOB_TOPICS.CHANGED,
      subscriber: subscriber,
      trigger: (arg) => {
        new Promise((resolve) => resolve(callback(arg.updated)));
      },
    });
  };

  const onHasChanges = ({ reloadedJobs }) => {
    subscription.notify({ topic: JOB_TOPICS.CHANGED, triggerArg: { updated: reloadedJobs } });
  };

  const subscribeOnViewStateChanged = ({ subscriber, callback }) => {
    subscription.subscribe({
      topic: JOB_TOPICS.VIEWSTATE_CHANGED,
      subscriber: subscriber,
      trigger: (arg) => {
        new Promise((resolve) => resolve(callback(arg.updated)));
      },
    });
  };

  const onViewStateChanged = ({ jobs }) => {
    subscription.notify({ topic: JOB_TOPICS.VIEWSTATE_CHANGED, triggerArg: { updated: jobs } });
  };

  const unsubscribeFromViewStateChanged = ({ subscriber }) => {
    unsubscribe({
      topic: JOB_TOPICS.VIEWSTATE_CHANGED,
      subscriber: subscriber,
    });
  };

  const subscribeOnStateChanged = ({ subscriber, callback }) => {
    subscription.subscribe({
      topic: JOB_TOPICS.STATE_CHANGED,
      subscriber: subscriber,
      trigger: (arg) => {
        const selected = arg.updated.filter((next) => {
          const orig = arg.original.find((e) => e.id === next.id);
          return !orig || orig.state !== next.state;
        });
        if (selected?.length > 0) {
          new Promise((resolve) => resolve(callback(selected)));
        }
      },
    });
  };

  const onStateChanged = ({ original, updated }) => {
    subscription.notify({
      topic: JOB_TOPICS.STATE_CHANGED,
      triggerArg: { updated: updated, original: original },
    });
  };

  const unsubscribeFromStateChanged = ({ subscriber }) => {
    unsubscribe({
      topic: JOB_TOPICS.STATE_CHANGED,
      subscriber: subscriber,
    });
  };

  const unsubscribe = ({ topic, subscriber, subscriptionSelector }) => {
    subscription.unsubscribe({ topic: topic, subscriber: subscriber, subscriptionSelector: subscriptionSelector });
  };

  const loadJobs = async () => {
    try {
      const response = await getHttp().get('jobs');
      const reloadedJobs = response.data || [];
      if (hasChanges({ orig: data.current, reloaded: reloadedJobs })) {
        new Promise((resolve) => resolve(onHasChanges({ reloadedJobs: reloadedJobs })));

        const withUpdatedViewState = reloadedJobs.filter((job) => job.viewState === TASK_VIEWSTATE.NEW);
        new Promise((resolve) => resolve(onViewStateChanged({ jobs: withUpdatedViewState })));
        new Promise((resolve) => resolve(onStateChanged({ original: data.current, updated: withUpdatedViewState })));
      }
      data.current = reloadedJobs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getJobs = () => {
    return [...data.current];
  };

  const updateJobs = async (jobs) => {
    try {
      const response = await getHttp().put('jobs', jobs);
      await loadJobs();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const runLoadJobs = async () => {
      await loadJobs();
    };
    runLoadJobs().catch(console.error);
  }, []);

  useInterval(async () => {
    try {
      await loadJobs();
    } catch (error) {
      console.error('Error in load jobs interval');
      console.error(error);
    }
  }, 30000);

  const context = {
    loadJobs,
    getJobs,
    updateJobs,
    subscribeOnHasChanges,
    subscribeOnViewStateChanged,
    subscribeOnStateChanged,
    unsubscribe,
  };

  return <jobsContext.Provider value={context}>{children}</jobsContext.Provider>;
};

const useJobsContext = () => {
  const context = useContext(jobsContext);
  if (context === undefined) {
    throw new Error('useJobsContext must be used within JobsContextProvider');
  }
  return context;
};

export { JobsContextProvider, useJobsContext };
