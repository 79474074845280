import { useHttp } from './authentication';
import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import { useDataContext } from '../context/DataContext';
import { useAppContext } from '../context/AppContext';
//import { useUserContext } from '../context/UserContext';
//import { useLocalizationContext } from '../context/LocalizationContext';
//import { useLayoutContext } from '../context/LayoutContext';

const DEFAULT_PROFILE = {
  userSettings: {},
  appSettings: {
    localizationSettings: {},
    layoutSettings: {},
    colorSettings: {},
  },
};

const DEBOUNCE_TIME = 1000;

export const ProfileWatchdog = (props) => {
  const dataContext = useDataContext();
  const appContext = useAppContext();
  const { getHttp } = useHttp();

  const saveProfile = async (profile) => {
    try {
      await getHttp().put('me', {
        profile: { ...profile },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const saveProfileWithDelay = debounce(async (profile) => {
    await saveProfile(profile);
  }, DEBOUNCE_TIME);

  useEffect(() => {
    const profile = {
      app: { ...appContext.profile },
      data: { ...dataContext.profile },
    };
    saveProfileWithDelay(profile);
  }, [dataContext.profile, appContext.profile]);

  return null;
};

export const useProfileLoader = () => {
  const dataContext = useDataContext();
  const appContext = useAppContext();
  const loadProfile = (profile) => {
    if (profile) {
      dataContext.loadProfile(profile);
      appContext.loadProfile(profile);
    }
  };

  return {
    loadProfile,
  };
};
