import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useAuthentication } from '../../hooks/authentication';
import ErrorModalAlert from '../../common/components/ErrorModalAlert';
import { useAppContext } from '../../context/AppContext';
import GoogleSignin from './GoogleSignin';
import { AppBox } from '../../common/components/AppBox';
import { AppText } from '../../common/components/AppText';
import AppleSignin from './AppleSignin';
import FacebookSignin from './FacebookSignin';
import logo from '../../assets/images/proxima_250.png';

const SignIn = () => {
  const { signin } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const { localization } = useAppContext();
  const theme = useTheme();
  const [progress, setProgress] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [alert, setAlert] = useState({
    isOpen: false,
    content: 'Some error message',
    title: 'Alert title',
    desctiption: '',
    onClose: () => {},
  });

  const onIdpResponse = async (clientid, idtoken) => {
    setProgress(true);
    try {
      await signin(clientid, idtoken);
    } catch (error) {
      console.error(error.message);
      setAlert({
        isOpen: true,
        title: localization.translate('auth.signsin_error'),
        content: localization.translate('auth.signin_error_content'),
        onClose: closeAlert,
      });
    }
  };

  const closeAlert = () => {
    setProgress(false);
    setAlert({
      isOpen: false,
      content: '',
      title: '',
    });
  };

  return (
    <Box className='welcomebox'>
      <Box className='container-centered'>
        {progress && (
          <>
            <Box className='container-centered'>
              <CircularProgress />
            </Box>
            <ErrorModalAlert open={alert.isOpen} content={alert.content} title={alert.title} description={alert.description} onClose={alert.onClose} />
          </>
        )}
        {!progress && (
          <Container maxWidth='sm'>
            <AppBox flex column centery>
              <AppText color='textSecondary' sx={{ mb: 2 }}>
                {localization.translate('app.welcome_to')}
              </AppText>
              <AppBox flex center sx={{ mx: 1 }}>
                <img src={logo} width='240px' />
              </AppBox>

              <AppText color='textSecondary' gutterBottom>
                {localization.translate('app.signin_on_app_with')}:
              </AppText>
            </AppBox>

            <AppBox flex column center sx={{ my: 1 }}>
              <GoogleSignin onIdpResponse={onIdpResponse} />
              <div style={{ height: '15px' }}></div>
              {/*
              <AppleSignin onIdpResponse={onIdpResponse} />
              */}
              {/*searchParams.get('fb') === '1' && <FacebookSignin onIdpResponse={onIdpResponse} />*/}
              <FacebookSignin onIdpResponse={onIdpResponse} />
            </AppBox>
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default SignIn;
