import { Avatar, Badge, Box, Checkbox, Collapse, Divider, FormControlLabel, IconButton, Radio, RadioGroup, TableRow, Tooltip, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { useAppContext } from '../../context/AppContext';
import { AppButton } from '../../common/components/AppButton';
import { AppConfirmationDialog, AppDialog } from '../../common/components/AppDialog';
import { AppDropdownMenu, AppDropdownMenuItem } from '../../common/components/AppDropdownMenu';
import { useJobsContext } from '../../context/JobsContext';
import { TASK_STATE, TASK_TYPE, TASK_VIEWSTATE } from '../../common/Constants';
import { AppBox } from '../../common/components/AppBox';
import { AppIcon, ICON, ICON_SIZE } from '../../common/components/AppIcon';
import { AppQuickFilter } from '../../common/components/AppQuickFilter';
import { AppText } from '../../common/components/AppText';
import { AppTable, AppTableBody, AppTableCell, AppTableHead, AppTableRow } from '../../common/components/AppTable';

export const TasksListDialog = (props) => {
  const { open, onClose, ...rest } = props;
  const jobs = useJobsContext();
  const theme = useTheme();
  const { localization } = useAppContext();

  const [displayOptions, setDisplayOptions] = useState({
    filter: false,
    sort: false,
  });
  const [tasks, setTasks] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [clearConfirmDialogOpen, setClearConfirmDialogOpen] = useState(false);
  const [dismissAnchorEl, setDismissAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);
  const openDismiss = Boolean(dismissAnchorEl);
  const quickFilter = useRef();
  const stateFilter = useRef([]);
  const newOnlyFilter = useRef(false);
  const sortBy = useRef('date');

  useEffect(() => {
    jobs.subscribeOnHasChanges({
      subscriber: 'TaskListDialog',
      callback: (loadedJobs) => {
        setTasksToDisplay(loadedJobs);
      },
    });
    jobs.loadJobs();
    return () => {
      jobs.unsubscribe({ subscriber: 'TaskListDialog' });
    };
  }, []);

  useEffect(() => {
    if (!open) {
      cleanup();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setTasksToDisplay(jobs.getJobs());
    }
  }, [sortBy.current, open]);

  useEffect(() => {
    const items = tasks.map((task, i) => {
      const stateColor = stateToColor(task.state);
      return {
        icon: <Avatar sx={{ bgcolor: stateColor, mx: 1 }}>{typeToIcon(task.type)}</Avatar>,
        type: `${typeToTitle(task.type)}`,
        name: task.name,
        date: localization.display(new Date(task.created), { hour: 'numeric', minute: 'numeric' }),
        state: stateToIcon(task.state),
        description: task.stateDescription || '',
      };
    });
    setRows(items);
  }, [tasks]);

  const setTasksToDisplay = (loadedJobs) => {
    const data = applyFilters([...loadedJobs]);
    applySort(sortBy.current, data);
    setTasks(data);
  };

  const cleanup = () => {
    // clear filters, sorting and selected items
    if (quickFilter.current) {
      quickFilter.current.value = '';
    }

    stateFilter.current = [];
    setSelectedItems([]);
    sortBy.current = 'date';
    setDisplayOptions({
      filter: false,
      sort: false,
    });
    setTasks([...jobs.getJobs()]);
  };

  const applyStateFilter = (event, state) => {
    const index = stateFilter.current.indexOf(state);
    if (event.target.checked) {
      if (index < 0) {
        stateFilter.current.push(state);
      }
    } else {
      if (index >= 0) {
        stateFilter.current.splice(index, 1);
      }
    }
    setTasksToDisplay(jobs.getJobs());
  };

  const applyNewOnlyFilter = (event) => {
    newOnlyFilter.current = event.target.checked;
    setTasksToDisplay(jobs.getJobs());
  };

  const applyFilters = (data) => {
    let filtered = data;
    if (stateFilter.current.length > 0) {
      filtered = filtered.filter((i) => stateFilter.current.indexOf(i.state) >= 0);
    }
    if (newOnlyFilter.current) {
      filtered = filtered.filter((i) => i.viewState === TASK_VIEWSTATE.NEW);
    }
    if (quickFilter.current) {
      filtered = filtered.filter((i) => i.name?.toLowerCase().includes(quickFilter.current.value?.toLowerCase()));
    }
    // make also sure uncheck items which are gone after filter
    const toSelect = selectedItems.filter((i) => filtered.findIndex((e) => e.id === i) >= 0);
    setSelectedItems(toSelect);

    return filtered;
  };

  const sortByDateDesc = (items) => {
    if (items?.length > 0) {
      items.sort((a, b) => {
        const dateA = new Date(a.updated);
        const dateB = new Date(b.updated);
        if (dateA > dateB) {
          return -1;
        }
        if (dateA < dateB) {
          return 1;
        }
        return 0;
      });
    }
  };

  const sortByName = (items) => {
    if (items?.length > 0) {
      items.sort((a, b) => a.name.localeCompare(b.name));
    }
  };

  const sortByState = (items) => {
    if (items?.length > 0) {
      items.sort((a, b) => a.state.localeCompare(b.state));
    }
  };

  const applySort = (by, items) => {
    switch (by) {
      case 'name':
        sortByName(items);
        break;
      case 'state':
        sortByState(items);
        break;
      default:
        sortByDateDesc(items);
        break;
    }
  };

  const selectItem = (id) => {
    const currentIndex = selectedItems.indexOf(id);

    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedItems(newChecked);
  };

  const selectAllItems = (e) => {
    const newSelected = e.target.checked ? [...tasks.map((e, i) => e.id)] : [];
    setSelectedItems(newSelected);
  };

  const clearSelected = async () => {
    if (selectedItems.length > 0) {
      const toClear = [];
      selectedItems.forEach((e, i) => toClear.push({ id: e, viewState: TASK_VIEWSTATE.ACKNOWLEDGED }));
      setClearConfirmDialogOpen(false);
      await jobs.updateJobs(toClear);
    } else {
      setClearConfirmDialogOpen(false);
    }
  };

  const dismissItems = async (completed) => {
    const items = completed ? tasks.filter((e) => e.state === TASK_STATE.COMPLETED) : tasks;
    const toClear = items.map((e, i) => {
      return { id: e.id, viewState: TASK_VIEWSTATE.ACKNOWLEDGED };
    });
    try {
      await jobs.updateJobs(toClear);
    } catch (error) {
      console.error(error);
    } finally {
      handleCloseDismiss();
    }
  };

  const typeToIcon = (type) => {
    switch (type) {
      case TASK_TYPE.IMPORT_DONATIONS:
        return <AppIcon icon={ICON.CloudUpload} size={ICON_SIZE.Medium} />;
      case TASK_TYPE.CREATE_INVOICE:
        return <AppIcon icon={ICON.GenerateInvoice} size={ICON_SIZE.Medium} />;
      case TASK_TYPE.LINK_INVOICE:
        return <AppIcon icon={ICON.Link} size={ICON_SIZE.Medium} />;
      case TASK_TYPE.RECEIPT_BY_EMAIL:
        return <AppIcon icon={ICON.EmailSend} size={ICON_SIZE.Medium} />;
      default:
        return <AppIcon icon={ICON.SettingsCog} size={ICON_SIZE.Medium} />;
    }
  };
  const stateToIcon = (state) => {
    switch (state.toLowerCase()) {
      case TASK_STATE.PENDING:
        return <AppIcon icon={ICON.Wait} size={ICON_SIZE.Medium} style={{ color: theme.palette.text.secondary }} />;
      case TASK_STATE.PROCESSING:
        return <AppIcon icon={ICON.Play} size={ICON_SIZE.Large} style={{ color: theme.palette.info.main }} />;
      case TASK_STATE.COMPLETED:
        return <AppIcon icon={ICON.Check} size={ICON_SIZE.Large} style={{ color: theme.palette.success.main }} />;
      case TASK_STATE.FAILED:
        return <AppIcon icon={ICON.Close} size={ICON_SIZE.Large} style={{ color: theme.palette.error.main }} />;
      default:
        return <AppIcon icon={ICON.SettingsCog} size={ICON_SIZE.Medium} style={{ color: theme.palette.text.secondary }} />;
    }
  };

  const stateToColor = (state) => {
    switch (state.toLowerCase()) {
      case TASK_STATE.PROCESSING:
        return theme.palette.info.main;
      case TASK_STATE.COMPLETED:
        return theme.palette.success.main;
      case TASK_STATE.FAILED:
        return theme.palette.error.main;
      default:
        return theme.palette.text.secondary;
    }
  };

  const typeToTitle = (type) => {
    switch (type) {
      case TASK_TYPE.IMPORT_DONATIONS:
        return localization.translate('tasks.import_donations');
      case TASK_TYPE.IMPORT_EXPENSES:
        return localization.translate('tasks.import_expenses');
      case TASK_TYPE.CREATE_INVOICE:
        return localization.translate('tasks.create_receipt');
      case TASK_TYPE.LINK_INVOICE:
        return localization.translate('tasks.link_receipt');
      case TASK_TYPE.RECEIPT_BY_EMAIL:
        return localization.translate('tasks.send_receipt');
      default:
        return localization.translate('tasks.task');
    }
  };

  const handleCloseDismiss = () => {
    setDismissAnchorEl(null);
  };
  const handleOpenDismiss = (event) => {
    setDismissAnchorEl(event.currentTarget);
  };

  return (
    <>
      <AppConfirmationDialog
        open={clearConfirmDialogOpen}
        onOk={clearSelected}
        onCancel={() => setClearConfirmDialogOpen(false)}
        title={localization.translate('tasks.confirm_clear_tasks')}
        message={localization.translate('tasks.confirm_clear_selected_tasks')}
      />
      <AppDialog
        maxWidth='lg'
        open={open}
        onClose={onClose}
        title={localization.translate('tasks.my_tasks')}
        dialogActionsContent={
          <AppBox flex center sx={{ width: '100%' }}>
            <AppText variant='small' sx={{ mx: 2, color: (theme) => theme.palette.text.secondary }}>
              {`${tasks.length} ${localization.translate('tasks.tasks').toLowerCase()}`}
            </AppText>
            <Box sx={{ flexGrow: 1 }} />
            {/*
            <AppButtonText disabled={selectedItems.length <= 0} onClick={() => setClearConfirmDialogOpen(true)}>
              {localization.translate('tasks.clear_selected')}
            </AppButtonText>
        */}
          </AppBox>
        }
        hasActions
        useClose
        dialogContent={
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', px: 2, my: 1 }}>
              <AppQuickFilter onChange={(e) => setTasksToDisplay(jobs.getJobs())} inputRef={quickFilter} sx={{ width: '300px' }} />

              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'right', mx: 2 }}>
                <Tooltip title={localization.translate('common.filter')}>
                  <IconButton
                    onClick={() =>
                      setDisplayOptions({
                        sort: false,
                        filter: !displayOptions.filter,
                      })
                    }
                  >
                    <Badge badgeContent={stateFilter.current.length + (newOnlyFilter.current ? 1 : 0)} color='primary'>
                      <AppIcon icon={ICON.Filter} size={ICON_SIZE.ExtraSmall} style={{ color: theme.palette.primary.main }} />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title={localization.translate('common.sort')}>
                  <IconButton
                    onClick={() =>
                      setDisplayOptions({
                        filter: false,
                        sort: !displayOptions.sort,
                      })
                    }
                  >
                    <AppIcon icon={ICON.Sort} size={ICON_SIZE.ExtraSmall} style={{ color: theme.palette.primary.main }} />
                  </IconButton>
                </Tooltip>
                <AppButton
                  variant='text'
                  disabled={tasks.length <= 0}
                  id='dismiss-button'
                  endIcon={Boolean(dismissAnchorEl) ? <AppIcon icon={ICON.ChevronUp} /> : <AppIcon icon={ICON.ChevronDown} />}
                  onClick={handleOpenDismiss}
                >
                  {localization.translate('app.dismiss')}
                </AppButton>
                <AppDropdownMenu id='dismiss-menu' aria-labelledby='dismiss-button' anchorEl={dismissAnchorEl} open={openDismiss} onClose={handleCloseDismiss}>
                  <AppDropdownMenuItem text={localization.translate('app.dismiss_all')} onClick={(e) => dismissItems()} />
                  <AppDropdownMenuItem text={localization.translate('app.dismiss_completed')} onClick={(e) => dismissItems(true)} />
                </AppDropdownMenu>
              </Box>
            </Box>
            <Collapse in={displayOptions.filter}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <FormControlLabel
                  control={<Checkbox onChange={(e) => applyNewOnlyFilter(e)} />}
                  label={<AppText>{localization.translate('tasks.not_viewed_only')}</AppText>}
                />
                <FormControlLabel
                  control={<Checkbox onChange={(e) => applyStateFilter(e, TASK_STATE.PENDING)} />}
                  label={<AppText>{localization.translate('tasks.pending')}</AppText>}
                />
                <FormControlLabel
                  control={<Checkbox onChange={(e) => applyStateFilter(e, TASK_STATE.PROCESSING)} />}
                  label={<AppText>{localization.translate('tasks.processing')}</AppText>}
                />
                <FormControlLabel
                  control={<Checkbox onChange={(e) => applyStateFilter(e, TASK_STATE.COMPLETED)} />}
                  label={<AppText>{localization.translate('tasks.completed')}</AppText>}
                />
                <FormControlLabel
                  control={<Checkbox onChange={(e) => applyStateFilter(e, TASK_STATE.FAILED)} />}
                  label={<AppText>{localization.translate('tasks.failed')}</AppText>}
                />
              </Box>
            </Collapse>

            <Collapse in={displayOptions.sort}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <RadioGroup
                  row
                  value={sortBy.current}
                  onChange={(e) => {
                    sortBy.current = e.target.value;
                    setTasksToDisplay(jobs.getJobs());
                  }}
                >
                  <FormControlLabel control={<Radio />} value='date' label={<AppText>{localization.translate('tasks.by_update_time')}</AppText>} />
                  <FormControlLabel control={<Radio />} value='name' label={<AppText>{localization.translate('tasks.by_name')}</AppText>} />
                  <FormControlLabel control={<Radio />} value='state' label={<AppText>{localization.translate('tasks.by_state')}</AppText>} />
                </RadioGroup>
              </Box>
            </Collapse>

            <Divider />
            <AppBox sx={{ mt: '5px', height: '450px', overflow: 'auto' }}>
              <AppTable containerProps={{ sx: { maxHeight: 450 } }} stickyHeader aria-label='jobs-table'>
                <AppTableHead>
                  <AppTableRow>
                    <AppTableCell></AppTableCell>
                    <AppTableCell sx={{ minWidth: '160px' }}>{localization.translate('tasks.task_type')}</AppTableCell>
                    <AppTableCell>{localization.translate('tasks.task_name')}</AppTableCell>
                    <AppTableCell>{localization.translate('common.date')}</AppTableCell>
                    <AppTableCell>{localization.translate('app.state')}</AppTableCell>
                    <AppTableCell>{localization.translate('common.description')}</AppTableCell>
                  </AppTableRow>
                </AppTableHead>
                <AppTableBody>
                  {rows.map((row, i) => (
                    <AppTableRow key={i}>
                      <AppTableCell>{row.icon}</AppTableCell>
                      <AppTableCell>
                        {' '}
                        <AppText>{row.type}</AppText>
                      </AppTableCell>
                      <AppTableCell>
                        <AppText sx={{ color: theme.palette.text.secondary }}>{row.name}</AppText>
                      </AppTableCell>
                      <AppTableCell>
                        <AppText sx={{ color: theme.palette.text.secondary }}>{row.date}</AppText>
                      </AppTableCell>
                      <AppTableCell>{row.state}</AppTableCell>
                      <AppTableCell>
                        <AppText sx={{ color: theme.palette.text.secondary }}>{row.description}</AppText>
                      </AppTableCell>
                    </AppTableRow>
                  ))}
                </AppTableBody>
              </AppTable>
            </AppBox>
          </>
        }
      />
    </>
  );
};
