import { Box, Typography } from '@mui/material';
import { AppBox } from './AppBox';
import { AppText } from './AppText';

const AppTitle = (props) => {
  const { primary, secondary, primaryProps, secondaryProps, dense, sx } = props;

  return (
    <AppBox flex column sx={{ ...sx, cursor: 'default' }}>
      <AppText variant='normalb' color='text.primary' {...primaryProps} {...(dense ? { style: { lineHeight: '1.1' } } : {})}>
        {primary}
      </AppText>
      {secondary && (
        <AppText variant='small' color='text.secondary' {...secondaryProps} {...(dense ? { style: { lineHeight: '1.1' } } : {})}>
          {secondary}
        </AppText>
      )}
    </AppBox>
  );
};

export default AppTitle;
