import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { AppBox } from './AppBox';
import { AppText } from './AppText';

const NamedAvatar = (props) => {
  const { name, avatarProps = {} } = props;
  const stringAvatar = (name) => {
    if (!name) {
      return {};
    }
    const names = name.trim().split(' ');
    const val = names.length > 1 ? `${names[0][0]}${names[1][0]}` : `${names[0][0]}`;
    return val;
  };

  return (
    <Avatar {...avatarProps}>
      <AppBox flex center>
        <AppText variant='large'>{stringAvatar(name)}</AppText>
      </AppBox>
    </Avatar>
  );
};

NamedAvatar.propTypes = {
  name: PropTypes.string,
};

export default NamedAvatar;
