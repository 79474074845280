import { useEffect } from 'react';
import useScript from '../../hooks/script';
import { loadScript } from '../../utils/utils';

const GoogleSignin = (props) => {
  const { onIdpResponse } = props;

  const handleGoogleResponse = async (response) => {
    console.log(response);
    var id_token = response.credential;
    if (onIdpResponse) {
      await onIdpResponse(process.env.REACT_APP_GOOGLE_CLIENT_ID, id_token);
    }
  };

  useEffect(() => {
    window.handleGoogleResponse = handleGoogleResponse;
    loadScript(
      'https://accounts.google.com/gsi/client',
      () => {
        console.log('google gsi client script was loaded successfully');
      },
      (error) => {
        console.error('An error occured loading google gsi client script');
        if (error) {
          console.error(error);
        }
      },
      {
        id: 'google-client-script',
      }
    );
    return () => {
      // Cleanup function that runs when component unmounts
      document.getElementById('google-client-script')?.remove();
    };
  }, []);

  return (
    <>
      <div
        id='g_id_onload'
        data-client_id='109626121024-ae027kd3hl7ld89qhp4j63cp56fs3vld.apps.googleusercontent.com'
        data-context='signin'
        data-ux_mode='popup'
        data-callback='handleGoogleResponse'
        data-close_on_tap_outside='false'
        data-use_fedcm_for_prompt='true'
      ></div>
      <div
        className='g_id_signin'
        data-type='standard'
        data-shape='pill'
        data-theme='outline'
        data-text='signin_with'
        data-size='large'
        data-logo_alignment='left'
        data-width='220'
      ></div>
    </>
  );
};

export default GoogleSignin;
