import { FormControlLabel, IconButton, MenuItem, Tooltip } from '@mui/material';
import { AppContainer } from '../../common/components/AppContainer';
import { useAppContext } from '../../context/AppContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppDropdownMenu, AppDropdownMenuItem } from '../../common/components/AppDropdownMenu';
import { useState } from 'react';
import { ICON } from '../../common/components/AppIcon';
import { AppBox } from '../../common/components/AppBox';
import { AppText } from '../../common/components/AppText';
import AppSwitch from '../../common/components/AppSwitch';
import { ROUTE_PATHS } from '../../common/Constants';

export const CAMPAIGN_TILE_DIMENSIONS = {
  WIDTH: 350,
  HEIGHT: 230,
};

export const DashboardTile = (props) => {
  const { background, sx = {}, children } = props;
  return (
    <AppContainer
      variant='outlined'
      sx={{
        ...sx,
        backgroundColor: (theme) => background || theme.palette.background.paper,
      }}
    >
      {children}
    </AppContainer>
  );
};

export const ControlledDashboardTile = (props) => {
  const {
    background,
    sx = {},
    children,
    onRemove,
    onReload,
    onSeeMore,
    enableSeeMoreDonations = true,
    enableSeeMoreExpenses = true,
    title,
    titleColor,
    tooltip,
    iconSx = {
      color: (theme) => theme.palette.layout.text.title,
    },
    buttonSx = {
      color: (theme) => theme.palette.background.paper,
      '&:hover': {
        color: (theme) => theme.palette.layout.text.title,
        backgroundColor: (theme) => theme.palette.background.paper,
      },
    },
    menuItems,
  } = props;
  const { localization } = useAppContext();
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const openActions = Boolean(actionsAnchorEl);
  const more = onRemove || onReload || onSeeMore;

  const closeActions = () => {
    setActionsAnchorEl(null);
  };

  const handleOnRemoveAction = () => {
    closeActions();
    onRemove();
  };

  const handleOnReloadAction = () => {
    closeActions();
    onReload();
  };
  const handleOnSeeMoreAction = (path) => {
    closeActions();
    onSeeMore(path);
  };
  return (
    <DashboardTile background={background} sx={{ ...sx, position: 'relative' }}>
      <AppBox flex centery sx={{ width: '100%' }}>
        <AppBox flex sx={{ flexGrow: 1, px: 1, overflow: 'hidden' }}>
          {title && (
            <Tooltip title={tooltip || title} placement='top-start'>
              <AppText
                sx={{
                  ...(titleColor && { color: titleColor }),
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  cursor: 'default',
                }}
              >
                {title}
              </AppText>
            </Tooltip>
          )}
        </AppBox>
        {more && (
          <AppBox flex center>
            <IconButton
              size='small'
              onClick={(e) => {
                setActionsAnchorEl(e.currentTarget);
              }}
            >
              <MoreVertIcon
                fontSize='small'
                sx={{
                  ...iconSx,
                }}
                id='actions-button'
              />
            </IconButton>
            <AppDropdownMenu id='actions-menu' aria-labelledby='actions-button' anchorEl={actionsAnchorEl} open={openActions} onClose={closeActions}>
              <AppDropdownMenuItem icon={ICON.Close} text={localization.translate('common.close')} onClick={handleOnRemoveAction} />
              <AppDropdownMenuItem icon={ICON.Reload} text={localization.translate('common.reload')} onClick={handleOnReloadAction} />
              {enableSeeMoreDonations && (
                <AppDropdownMenuItem
                  icon={ICON.SeeMore}
                  text={localization.translate('donations.transactions_details')}
                  onClick={() => handleOnSeeMoreAction(ROUTE_PATHS.Donations)}
                />
              )}
              {enableSeeMoreExpenses && (
                <AppDropdownMenuItem
                  icon={ICON.SeeMore}
                  text={localization.translate('expenses.transactions_details')}
                  onClick={() => handleOnSeeMoreAction(ROUTE_PATHS.Expenses)}
                />
              )}
              {menuItems?.length > 0 &&
                menuItems
                  .filter((i) => !!i.renderMenuItem)
                  ?.map((m, i) => {
                    return m.renderMenuItem(i, closeActions);
                  })}
            </AppDropdownMenu>
          </AppBox>
        )}
      </AppBox>
      {children}
      {/*
      {onRemove && (
        <IconButton size='small' sx={{ position: 'absolute', top: 0, right: 0, zIndex: 10 }} onClick={() => onRemove()}>
          <CloseIcon
            fontSize='small'
            sx={{
              ...iconSx,
            }}
          />
        </IconButton>
      )}
      {onReload && (
        <IconButton
          sx={{
            position: 'absolute',
            bottom: 0,
          }}
          onClick={() => {
            onReload();
          }}
          size='small'
        >
          <ReplayIcon
            fontSize='small'
            sx={{
              ...iconSx,
            }}
          />
        </IconButton>
      )}
      {onSeeMore && (
        <AppButtonText
          onClick={() => onSeeMore()}
          sx={{
            ...buttonSx,
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          {localization.translate('common.see_more')}
        </AppButtonText>
      )}
        */}
    </DashboardTile>
  );
};
