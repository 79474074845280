import { FormControlLabel, Grid, MenuItem, Radio, RadioGroup } from '@mui/material';
import { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { AppBox } from '../../../common/components/AppBox';
import { AppDatePicker } from '../../../common/components/AppDatePicker';
import { AppTextField } from '../../../common/components/AppText';
import { PaymentProviderSelector } from '../../../common/components/PaymentProviderSelector';
import { PERIOD } from '../../../common/Constants';
import { useAppContext } from '../../../context/AppContext';
import { useHttp } from '../../../hooks/authentication';
import { calculateStartDate, getUtcDateInterpretation } from '../../../utils/utils';

const PaymentSourceImportProvider = forwardRef((props, ref) => {
  const { setShowImportButton, setEnableImportButton } = props;
  const { localization } = useAppContext();
  const { getHttp } = useHttp();
  const [intervalType, setIntervalType] = useState('between');
  const [importPeriod, setImportPeriod] = useState('');
  const [provider, setProvider] = useState({
    id: null,
    displayName: '',
    provider: '',
  });
  const [period, setPeriod] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    setShowImportButton(true);
    return () => {
      setShowImportButton(false);
    };
  }, []);

  useEffect(() => {
    const enable = !!provider.id && !!period.from && !!period.to;
    setEnableImportButton(enable);
  });

  const changeIntervalType = (type) => {
    setIntervalType(type);
    setPeriod({
      from: null,
      to: null,
    });
    setImportPeriod('');
  };

  const changeImportPeriod = (value) => {
    setImportPeriod(value);
    const to = new Date();
    setPeriod({
      from: calculateStartDate(to, value),
      to: to,
    });
  };

  useImperativeHandle(ref, () => ({
    handleImport: async () => {
      const data = {
        start: getUtcDateInterpretation(period.from),
        end: getUtcDateInterpretation(period.to),
      };
      await getHttp().post(`sources/${provider.id}/jobs`, data);
    },
  }));

  return (
    <Grid container rowSpacing={2}>
      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <PaymentProviderSelector
            textFieldProps={{ sx: { width: '360px' }, required: true, error: !provider.id }}
            applyValue={(item) => {
              setProvider({
                id: item.value.id,
                displayName: item.value.displayName,
                provider: item.value.provider,
              });
            }}
            item={{
              value: {
                id: provider.id,
                displayName: provider.displayName,
                provider: provider.provider,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup aria-labelledby='time-period-group' name='time-period-group' value={intervalType} onChange={(e) => changeIntervalType(e.target.value)}>
          <AppBox flex column centery>
            <AppBox flex centery>
              <AppBox flex centery sx={{ minWidth: '160px' }}>
                <FormControlLabel
                  value='between'
                  control={<Radio />}
                  label={localization.translate('common.between_date')}
                  componentsProps={{
                    typography: { fontSize: (theme) => theme.typography.normal.fontSize },
                  }}
                />
              </AppBox>
              <AppBox flex centery>
                <AppDatePicker
                  value={intervalType === 'between' ? period.from : null}
                  disabled={intervalType !== 'between'}
                  label={localization.translate('app.from_date')}
                  inputTextProps={{
                    sx: { width: 200 },
                    required: intervalType === 'between',
                    error: intervalType === 'between' && !period.from,
                  }}
                  onChange={(date) => {
                    if (period.to && date > period.to) {
                      return;
                    }
                    setPeriod({
                      ...period,
                      from: period.to && date > period.to ? null : date,
                    });
                  }}
                />
                <AppDatePicker
                  value={intervalType === 'between' ? period.to : null}
                  disabled={intervalType !== 'between'}
                  label={localization.translate('app.to_date')}
                  inputTextProps={{
                    sx: { mx: 2, width: 200 },
                    required: intervalType === 'between',
                    error: intervalType === 'between' && !period.to,
                  }}
                  onChange={(date) => {
                    setPeriod({
                      ...period,
                      to: period.from && date < period.from ? null : date,
                    });
                  }}
                />
              </AppBox>
            </AppBox>
            <AppBox flex centery sx={{ mt: 2 }}>
              <AppBox flex centery sx={{ minWidth: '160px' }}>
                <FormControlLabel
                  value='period'
                  control={<Radio />}
                  label={localization.translate('common.in_period')}
                  componentsProps={{
                    typography: { fontSize: (theme) => theme.typography.normal.fontSize },
                  }}
                />
              </AppBox>
              <AppBox flex centery>
                <AppTextField
                  required={intervalType === 'period'}
                  error={intervalType === 'period' && importPeriod === ''}
                  value={importPeriod}
                  readonly={intervalType !== 'period'}
                  select
                  label={localization.translate('common.period')}
                  onChange={changeImportPeriod}
                  sx={{ width: '200px', float: 'right' }}
                >
                  <MenuItem value={PERIOD.WEEK}>{localization.translate('common.week')}</MenuItem>
                  <MenuItem value={PERIOD.THREE_WEEKS}>{localization.translate('common.three_weeks')}</MenuItem>
                  <MenuItem value={PERIOD.ONE_MONTH}>{localization.translate('common.month')}</MenuItem>
                </AppTextField>
              </AppBox>
            </AppBox>
          </AppBox>
        </RadioGroup>
      </Grid>
    </Grid>
  );
});

export default PaymentSourceImportProvider;
