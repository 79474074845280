import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { LANGUAGE_CODE, LANGUAGE_DIRECTION, ROUTE_PATHS } from '../common/Constants';
import { useAppContext } from '../context/AppContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { AppBox } from '../common/components/AppBox';
import { AppIcon, ICON, ICON_SIZE } from '../common/components/AppIcon';
import { usePathContext, useSetPathContext } from '../context/PathContext';
import { AppText } from '../common/components/AppText';

/*
<Sidebar>
 <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
   <div>header</div>
   <div style={{ flex: 1 }}>content</div>
   <div>footer</div>
 </div>
</Sidebar>
*/

// https://github.com/azouaoui-med/react-pro-sidebar/tree/v0.7.1

const SidebarIcon = (props) => {
  const { icon } = props;
  return <AppIcon icon={icon} size={ICON_SIZE.Medium} />;
};

const StyledSidebar = styled(ProSidebar)(({ theme }) => ({
  '&.pro-sidebar': {
    color: theme.palette.layout.text.main,
    '& .pro-sidebar-inner': {
      background: theme.palette.layout.main, // `linear-gradient(to right, ${theme.palette.layout.main}, ${theme.palette.layout.light})`,
    },
    '& .pro-menu': {
      '& .pro-menu-item': {
        '&.active': {
          color: theme.palette.layout.text.highlight,
          //background: theme.palette.layout.dark,
        },
        '& .pro-inner-item': {
          fontSize: '.8rem',
          '&:hover': {
            color: theme.palette.layout.text.highlight,
            background: theme.palette.layout.dark,
          },
          '&:focus': {
            color: theme.palette.layout.text.highlight,
            //background: theme.palette.layout.dark,
          },
        },
      },
      '& .pro-sub-menu': {
        '& .pro-inner-item': {
          '&:focus': {
            color: theme.palette.layout.text.main,
          },
        },
        '& .pro-inner-list-item': {
          backgroundColor: theme.palette.layout.main,
          '&:hover': {
            color: theme.palette.layout.text.highlight,
            background: theme.palette.layout.dark,
          },
          '&:focus': {
            color: theme.palette.layout.text.highlight,
          },
          '& .pro-menu-item': {
            '&.active': {
              '& .pro-inner-item': {
                '&:focus': {
                  color: theme.palette.layout.text.highlight,
                },
              },
            },
          },
        },
      },
    },
    '&.collapsed': {
      '& .pro-sidebar-inner': {
        '& .pro-menu': {
          '& .pro-menu-item': {
            '& .pro-inner-list-item': {
              '& .popper-inner': {
                backgroundColor: theme.palette.layout.main,
                '& .pro-menu-item': {
                  '& .pro-inner-item': {
                    backgroundColor: theme.palette.layout.main,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}));

const StyledSidebarFooter = styled(SidebarFooter)(({ theme }) => ({
  textAlign: 'center',
  padding: 15,
  color: theme.palette.layout.text.darkened,
}));

const StyledSidebarHeader = styled(SidebarHeader)(({ theme }) => ({
  textAlign: 'center',
  minHeight: '86px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Sidebar = (props) => {
  const { closed = false } = props;
  const [finMenuOpen, setFinMenuOpen] = useState(false);
  const { localization, profile } = useAppContext();
  const navigate = useNavigate();
  const theme = useTheme();

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const collapsed = !isMdUp ? true : closed;
  const path = usePathContext();
  const setPath = useSetPathContext();

  const handleNavigate = (path) => {
    setPath(path);
    navigate(path);
  };

  useEffect(() => {
    if (['expenses', 'income'].find((e) => e === path)) {
      setFinMenuOpen(true);
    }
  }, [path]);

  return (
    <StyledSidebar collapsed={!!collapsed} width={profile.language === LANGUAGE_CODE.RUSSIAN ? 250 : 240} rtl={theme.direction === LANGUAGE_DIRECTION.RTL}>
      <StyledSidebarHeader {...(!!collapsed ? { style: { minHeight: '56px' } } : {})}>
        {!collapsed && (
          <AppBox flex centery>
            <AppIcon icon={ICON.ProximaWhite} size={ICON_SIZE.Large} />
            <AppBox flex column sx={{ mx: 2 }}>
              <span
                style={{
                  display: 'block',
                  lineHeight: 1,
                  textAlign: 'start',
                  color: 'white',
                  fontSize: theme.typography.xsmall.fontSize,
                }}
              >
                3Dir
              </span>
              <span
                style={{
                  display: 'block',
                  lineHeight: 1,
                  textAlign: 'start',
                  color: 'white',
                  fontWeight: theme.typography.bold,
                  fontSize: '1.75rem', //theme.typography.xlargeb.fontSize,
                }}
              >
                Proxima
              </span>
            </AppBox>
          </AppBox>
        )}
        {collapsed && <AppIcon icon={ICON.Proxima} size={ICON_SIZE.Medium} style={{ color: 'white' }} />}
      </StyledSidebarHeader>
      <SidebarContent>
        <Menu>
          <MenuItem icon={<SidebarIcon icon={ICON.Dashboard} />} onClick={() => handleNavigate(ROUTE_PATHS.Dashboard)} active={path === ROUTE_PATHS.Dashboard}>
            <AppText> {localization.translate('app.dashboard')}</AppText>
          </MenuItem>
          <MenuItem icon={<SidebarIcon icon={ICON.Campaign} />} onClick={() => handleNavigate(ROUTE_PATHS.Campaigns)} active={path === ROUTE_PATHS.Campaigns}>
            <AppText>{localization.translate('app.campaigns')}</AppText>
          </MenuItem>
          <MenuItem icon={<SidebarIcon icon={ICON.Donation} />} onClick={() => handleNavigate(ROUTE_PATHS.Donations)} active={path === ROUTE_PATHS.Donations}>
            <AppText>{localization.translate('app.donations')}</AppText>
          </MenuItem>
          <MenuItem icon={<SidebarIcon icon={ICON.Expenses} />} onClick={() => handleNavigate(ROUTE_PATHS.Expenses)} active={path === ROUTE_PATHS.Expenses}>
            <AppText>{localization.translate('app.expenses')}</AppText>
          </MenuItem>
          {/*
            <SubMenu
            icon={<SidebarIcon icon={TbReportMoney} />}
            title={AppText>{localization.translate('app.financial_data')}</AppText>}
            open={finMenuOpen}
            onOpenChange={(v) => setFinMenuOpen(v)}
          >
            <MenuItem icon={<SidebarIcon icon={BiExport} />} onClick={() => handleNavigate('expenses')} active={layout.selectedPath === 'expenses'}>
              <AppText>{localization.translate('app.expenses')}</AppText>
            </MenuItem>
            
              <MenuItem icon={<SidebarIcon icon={BiImport} />} onClick={() => handleNavigate('income')} active={layout.selectedPath === 'income'}>
                {t('Income')}
              </MenuItem>
              
          </SubMenu>
            */}
        </Menu>
      </SidebarContent>
      <StyledSidebarFooter>
        {!collapsed && (
          <AppBox flex column>
            <AppText variant='xsmall'>3Dir Technologies, &copy; 2023</AppText>
          </AppBox>
        )}
      </StyledSidebarFooter>
    </StyledSidebar>
  );
};

export default Sidebar;
