import { useState } from 'react';
import { useAppContext } from '../../../../context/AppContext';
import { GridToolbarItem } from './GridToolbarItem';
import { AppIcon, ICON } from '../../AppIcon';

export const ExportItem = (props) => {
  const { variant, getSearchId, onActivated, onClose, isDisabled, renderForm, apiRef } = props;
  const { localization } = useAppContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onClick = (e) => {
    handleOpenDialog(e);
    if (onActivated) {
      onActivated();
    }
  };

  const handleOpenDialog = (event) => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <GridToolbarItem
        variant={variant}
        aria-describedby='open-export-dialog'
        onClick={onClick}
        startIcon={<AppIcon icon={ICON.Export} />}
        disabled={isDisabled !== undefined ? isDisabled : apiRef.current.getRowsCount() <= 0}
        text={localization.translate('app.export')}
      />
      {renderForm &&
        renderForm({
          open: dialogOpen,
          onClose: handleCloseDialog,
          getSearchId: getSearchId,
          apiRef: apiRef,
        })}
    </>
  );
};
