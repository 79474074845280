import { useState } from 'react';
import { Divider, IconButton } from '@mui/material';
import { useTheme } from '@emotion/react';
import { AppBox } from '../../common/components/AppBox';
import { AppIcon, ICON } from '../../common/components/AppIcon';
import { AppText } from '../../common/components/AppText';
import { useAppContext } from '../../context/AppContext';
import { AppDropdownMenu, AppDropdownMenuItem } from '../../common/components/AppDropdownMenu';
import { usePathContext, useSetPathContext } from '../../context/PathContext';
import { ROUTE_PATHS, USER_ROLES } from '../../common/Constants';
import { useNavigate } from 'react-router-dom';
import SwitchTenantDialog from './SwitchTenantDialog';

const TenantMenu = (props) => {
  const theme = useTheme();
  const { user, localization } = useAppContext();
  const navigate = useNavigate();
  const path = usePathContext();
  const setPath = useSetPathContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [switchTenantDialogOpen, setSwitchTenantDialogOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenTenantConfiguration = () => {
    const path = ROUTE_PATHS.TenantConfig;
    setPath(path);
    navigate(path);
    handleClose();
  };
  const handleOpenSwitchTenantDialog = () => {
    setSwitchTenantDialogOpen(true);
    handleClose();
  };
  const handleCloseSwitchTenantDialog = () => {
    setSwitchTenantDialogOpen(false);
    handleClose();
  };
  return (
    <AppBox flex centery>
      <AppBox flex centery column sx={{ alignItems: 'end' }}>
        <AppText sx={{ color: (theme) => theme.palette.text.primary }}>{user.tenant}</AppText>
      </AppBox>
      <IconButton onClick={handleClick} color='inherit'>
        <AppIcon icon={ICON.Tenant} style={{ color: theme.palette.primary.main }} />
      </IconButton>
      <AppDropdownMenu
        id='tenant-menu'
        MenuListProps={{
          'aria-labelledby': 'tenant-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <AppDropdownMenuItem text={user.tenant} />
        <Divider variant='middle' flexItem />

        {user.tenants.length > 1 && (
          <AppDropdownMenuItem icon={ICON.Tenant} text={localization.translate('app.switch_tenant')} onClick={handleOpenSwitchTenantDialog} />
        )}
        {user.isInRole(USER_ROLES.Admin) && (
          <div>
            <AppDropdownMenuItem
              disabled={path === ROUTE_PATHS.TenantConfig}
              icon={ICON.SettingsCog}
              text={localization.translate('app.settings')}
              onClick={handleOpenTenantConfiguration}
            />
            <Divider variant='middle' flexItem />
            <AppDropdownMenuItem disabled icon={ICON.Bill} text={`${localization.translate('app.billing')} 3Dir`} onClick={() => {}} />
          </div>
        )}
      </AppDropdownMenu>
      <SwitchTenantDialog open={switchTenantDialogOpen} onClose={handleCloseSwitchTenantDialog} />
    </AppBox>
  );
};

export default TenantMenu;
