import { useAppContext } from '../context/AppContext';
import { useHttp } from './authentication';

const useCampaigns = () => {
  const { getHttp } = useHttp();
  const { localization } = useAppContext();

  const localizeCampaign = (campaign) => {
    return {
      ...campaign,
      name: campaign.alternativeNames.find((i) => i.lang === localization.language)?.text || campaign.name,
      description: campaign.alternativeDescriptions.find((i) => i.lang === localization.language)?.text || campaign.description,
    };
  };

  const localizeCampaigns = (campaigns) => {
    if (campaigns) {
      return campaigns.map((e, i) => {
        return localizeCampaign(e);
      });
    }
  };

  const sortCampaignsByDateUpdatedAsc = (campaigns) => {
    campaigns.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
  };

  const load = async (active) => {
    try {
      let queryStr = 'projects?version=2&skip=0&limit=-1';
      if (active) {
        queryStr = queryStr + '&active=1';
      }
      const response = await getHttp().get(queryStr);
      //const localized = localizeCampaigns(response.data.records);
      sortCampaignsByDateUpdatedAsc(response.data.records);
      return response.data.records;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return {
    load,
  };
};

export default useCampaigns;
