import { IconButton } from '@mui/material';
import { AppBox } from '../../common/components/AppBox';
import { AppIcon, ICON, ICON_SIZE } from '../../common/components/AppIcon';

export const InlineValueEditor = (props) => {
  const { edited, name, onEdit, onCancel } = props;

  return (
    <AppBox flex centery>
      <IconButton
        aria-label={`edit-${name}-button`}
        onClick={() => {
          if (onEdit) {
            onEdit();
          }
        }}
      >
        <AppIcon icon={edited ? ICON.Save : ICON.Edit} size={ICON_SIZE.Small} />
      </IconButton>
      {edited && (
        <IconButton
          aria-label={`cancel-edit-${name}-button`}
          onClick={() => {
            if (onCancel) {
              onCancel();
            }
          }}
        >
          <AppIcon icon={ICON.Close} />
        </IconButton>
      )}
    </AppBox>
  );
};
