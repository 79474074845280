import { Box, ListItemIcon, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { SupportedLanguages } from '../Constants';
import { AppText, AppTextField } from './AppText';
import { AppBox } from './AppBox';

// flags definition examples: https://codepen.io/Fowerld/full/JjbMvew
const LanguageSelect = (props) => {
  const { languages, code, onChange } = props;
  const { profile, localization } = useAppContext();
  const [language, setLanguauge] = useState();

  const displayedLanguages = languages || SupportedLanguages;

  const getLanguage = (code) => {
    return SupportedLanguages.find((l) => l.code === code);
  };

  useEffect(() => {
    if (code) {
      setLanguauge(getLanguage(code));
    }
    //setLanguauge(getLanguage(profile.language));
  }, [code]);

  const handleOnChange = (lang) => {
    setLanguauge(getLanguage(lang));
    if (onChange) {
      onChange(lang);
    }
  };

  return (
    <AppTextField
      sx={{
        '& .MuiSelect-select': {
          height: '18px',
        },
      }}
      select
      error={!language}
      fullWidth
      label={localization.translate('common.language')}
      value={language?.code || ''}
      onChange={(v) => handleOnChange(v)}
    >
      {displayedLanguages.map((lang, i) => {
        return (
          <MenuItem value={lang.code} key={lang.code} disabled={lang.code === language?.code}>
            <AppBox flex center>
              <span className={`fi fi-${lang.country}`}></span>
              <AppText sx={{ mx: 1 }}>{lang.name}</AppText>
            </AppBox>
          </MenuItem>
        );
      })}
    </AppTextField>
  );
};

export default LanguageSelect;
