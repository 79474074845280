import { useState } from 'react';
import { IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../hooks/authentication';
import NamedAvatar from '../../common/components/NamedAvatar';
import { AppBox } from '../../common/components/AppBox';
import { AppDropdownMenu, AppDropdownMenuItem } from '../../common/components/AppDropdownMenu';
import { useAppContext } from '../../context/AppContext';
import { ICON } from '../../common/components/AppIcon';
import { usePathContext, useSetPathContext } from '../../context/PathContext';
import { ROUTE_PATHS } from '../../common/Constants';
import { AppText } from '../../common/components/AppText';

export default function ProfileMenu() {
  const { signout } = useAuthentication();
  const { user, localization } = useAppContext();
  const setPath = useSetPathContext();
  const path = usePathContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleOpenUserConfiguration = () => {
    const path = ROUTE_PATHS.UserConfig;
    setPath(path);
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    signout();
  };

  return (
    <>
      <AppBox flex>
        <AppBox flex centery column sx={{ alignItems: 'end' }}>
          <AppText sx={{ color: (theme) => theme.palette.text.primary }}>{user.name}</AppText>
          <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
            {user.email}
          </AppText>
        </AppBox>
        <IconButton color='inherit' onClick={handleClick}>
          <NamedAvatar name={user.name} avatarProps={{ sx: { bgcolor: (theme) => theme.palette.primary.main } }} />
        </IconButton>

        <AppDropdownMenu
          id='customized-profile-menu'
          MenuListProps={{
            'aria-labelledby': 'customized-profile-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <AppDropdownMenuItem
            disabled={path === ROUTE_PATHS.UserConfig}
            icon={ICON.User}
            text={localization.translate('app.user_settings')}
            onClick={handleOpenUserConfiguration}
          />

          <Divider variant='middle' flexItem />
          <AppDropdownMenuItem icon={ICON.Logout} text={localization.translate('app.signout')} onClick={handleLogout} />
        </AppDropdownMenu>
      </AppBox>
    </>
  );
}
