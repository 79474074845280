import { Box, Tooltip as MuiTooltip } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useTheme } from '@mui/material/styles';
import { Doughnut } from 'react-chartjs-2';
import { useAppContext } from '../../../context/AppContext';
import { LANGUAGE_DIRECTION } from '../../Constants';
import { AppText } from '../AppText';

ChartJS.register(ArcElement, Tooltip, Legend);

const calculateFontSize = (text) => {
  let size = '';

  if (text && text.length > 0) {
    const length = text.length;
    if (length <= 8) {
      size = '.8rem';
    } else if (length > 8 && length <= 10) {
      size = '.7rem';
    } else {
      size = '.65rem';
    }
  }
  return size;
};

const PercentChart = (props) => {
  const { localization } = useAppContext();
  const theme = useTheme();
  const { size, color, tooltip, goal, current, percent } = props;
  const backColor = theme.palette.background.paperDisabled;

  const config = {
    data: {
      datasets: [
        {
          data: localization.dir === LANGUAGE_DIRECTION.LTR ? [current, current >= goal ? 0 : goal - current] : [current >= goal ? 0 : goal - current, current],
          backgroundColor: localization.dir === LANGUAGE_DIRECTION.LTR ? [color, backColor] : [backColor, color],
          borderWidth: 0,
          hoverOffset: 5,
        },
      ],
    },
    options: {
      locale: localization.locale,
      radius: '95%',
      cutout: '55%',
      responsive: true,
    },
  };
  const amountText = current !== undefined ? localization.displayCurrency(current) : '';
  const fontSize = calculateFontSize(amountText);

  return (
    <Box sx={{ width: size, height: size, position: 'relative' }}>
      <Doughnut data={config.data} options={config.options} style={{ position: 'absolute', left: '50%', top: '44%', transform: 'translate(-50%, -50%)' }} />
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          top: '47%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <MuiTooltip title={tooltip || ''} placement='bottom-start'>
          <AppText variant='small' sx={{ color: color, cursor: 'default' }}>
            {percent ? percent.toFixed(1) + '%' : ''}
          </AppText>
        </MuiTooltip>
      </Box>
    </Box>
  );
};

export default PercentChart;
