import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import CampaignsWidget from './CampaignsWidget';
import { useAppContext } from '../../context/AppContext';
import DonationsWidget from './DonationsWidget';
import ExpensesWidget from './ExpensesWidget';
import { useSetHeaderContext } from '../../context/HeaderContext';
import { useSetPathContext } from '../../context/PathContext';
import { useDataContext } from '../../context/DataContext';

const DATA_AREA_SIZE = 400;

export default function Dashboard({ path }) {
  const [campaignsLoaded, setCampaignsLoaded] = useState(false);
  const setTitle = useSetHeaderContext();
  const setPath = useSetPathContext();
  const { localization, profile } = useAppContext();
  const dataContext = useDataContext();

  useEffect(() => {
    dataContext.setName('dashboard');
  }, []);

  useEffect(() => {
    setPath(path);
    setTitle({
      primary: localization.translate('app.dashboard'),
      secondary: localization.translate('dashboard.subtitle'),
    });
  }, [profile.language]);

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      <Grid item xs={12}>
        <CampaignsWidget onLoaded={() => setCampaignsLoaded(true)} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <DonationsWidget dataAreaSize={DATA_AREA_SIZE} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ExpensesWidget dataAreaSize={DATA_AREA_SIZE} />
      </Grid>
    </Grid>
  );
}
