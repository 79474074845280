import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { DialogFormContainer } from '../../common/components/DialogFormContainer';
import AppForm from './AppForm';

const AppDialogForm = forwardRef((props, ref) => {
  const { details, onSubmit, slot, title, formId, onClose, onSetDisableSubmit, ...rest } = props;
  const formRef = useRef(null);
  const containerRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [disable, setDisable] = useState(true);

  const close = () => {
    setDialogOpen(false);
    if (onClose) {
      onClose();
    }
  };

  useImperativeHandle(ref, () => ({
    dialogOpen,
    setDisable,
    close,
    open: () => {
      setDialogOpen(true);
    },
  }));

  return (
    <DialogFormContainer
      ref={containerRef}
      title={title}
      open={dialogOpen}
      onClose={close}
      formId={formId}
      onSubmit={(e) => formRef.current.submitData(e)}
      disableSubmit={disable}
      {...rest}
    >
      <AppForm ref={formRef} details={details} slot={slot} onSubmit={onSubmit} setDisableSubmit={setDisable} onSetDisableSubmit={onSetDisableSubmit} />
    </DialogFormContainer>
  );
});

export default AppDialogForm;
