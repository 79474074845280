import { useAppContext } from '../../../../context/AppContext';
import { AppIcon, ICON } from '../../AppIcon';
import { GridToolbarItem } from './GridToolbarItem';

export const ReloadItem = (props) => {
  const { variant, onActivated, isDisabled } = props;
  const { localization } = useAppContext();
  return (
    <GridToolbarItem
      variant={variant}
      aria-describedby='reload-option'
      onClick={() => {
        if (onActivated) {
          onActivated();
        }
      }}
      startIcon={<AppIcon icon={ICON.Reload} />}
      disabled={isDisabled !== undefined ? isDisabled : false}
      text={localization.translate('common.reload')}
    />
  );
};
