import { Avatar, Box, Input, InputAdornment, List, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { AppListItemButton } from '../../common/components/AppListItem';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import { useAppContext } from '../../context/AppContext';
import { AppDialog } from '../../common/components/AppDialog';
import { useState } from 'react';
import { useAuthentication } from '../../hooks/authentication';
import { AppButton } from '../../common/components/AppButton';
import { AppIcon, ICON } from '../../common/components/AppIcon';
import { AppText } from '../../common/components/AppText';

const SelectModeButton = (props) => {
  const { icon, selected, onClick, primary, secondary, disabled = false } = props;

  return (
    <AppListItemButton selected={selected} onClick={onClick} disabled={disabled}>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} primaryTypographyProps={{ variant: 'normal' }} secondaryTypographyProps={{ variant: 'normal' }} />
      {selected && <CheckSharpIcon color='success' sx={{ mx: 2, fontWeight: '900' }} />}
    </AppListItemButton>
  );
};

const SwitchTenantForm = (props) => {
  const { selectedTenant, setSelectedTenant } = props;
  const { user, localization } = useAppContext();
  const [tenants, setTenants] = useState(user.tenants);

  const filter = (e) => {
    setTenants(user.tenants.filter((i) => i.includes(e.target.value)));
  };

  return (
    <>
      <Box sx={{ mb: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <AppText variant='normalb'>{localization.translate('app.select_tenant_to_switch')}</AppText>
        <Box sx={{ flexGrow: 1 }} />
        <Input
          id='tenant-search'
          startAdornment={
            <InputAdornment position='start'>
              <AppIcon icon={ICON.Search} />
            </InputAdornment>
          }
          onChange={filter}
        />
      </Box>
      <List sx={{ maxHeight: 300, overflow: 'auto' }}>
        {tenants.map((e, i) => {
          return (
            <SelectModeButton
              key={i}
              onClick={() => setSelectedTenant(e)}
              disabled={e === user.tenant}
              selected={selectedTenant === e}
              icon={<AppIcon icon={ICON.Tenant} />}
              primary={e}
              secondary={e}
            />
          );
        })}
      </List>
    </>
  );
};

const SwitchTenantDialog = (props) => {
  const { open, onClose, tenants, ...other } = props;
  const [selectedTenant, setSelectedTenant] = useState(null);
  const { switchTenant } = useAuthentication();
  const { localization } = useAppContext();
  const handleClose = () => {
    setSelectedTenant(null);
    onClose();
  };

  const handleSelect = () => {
    if (selectedTenant) {
      handleClose();
      switchTenant(selectedTenant);
    }
  };

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={localization.translate('app.switch_tenant')}
      dialogContent={<SwitchTenantForm tenants={tenants} selectedTenant={selectedTenant} setSelectedTenant={setSelectedTenant} />}
      dialogActionsContent={<AppButton onClick={handleSelect}>{localization.translate('common.switch')}</AppButton>}
      hasActions
      useClose
    />
  );
};

export default SwitchTenantDialog;
