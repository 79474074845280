import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useTheme } from '@mui/material/styles';
import { Doughnut } from 'react-chartjs-2';
import { useAppContext } from '../../../context/AppContext';
import { LANGUAGE_DIRECTION } from '../../Constants';
import { useState, useEffect } from 'react';
import { AppBox } from '../AppBox';

ChartJS.register(ArcElement, Tooltip, Legend);

const AmountChart = (props) => {
  const { size, color } = props;
  const { localization } = useAppContext();
  const theme = useTheme();
  const backColor = theme.palette.background.paperDisabled;
  const [config, setConfig] = useState({
    data: {
      datasets: [
        {
          data: [100],
          backgroundColor: [theme.palette.primary.main],
          borderWidth: 0,
          hoverOffset: 5,
        },
      ],
    },
    options: {
      locale: localization.locale,
      radius: '95%',
      cutout: '55%',
      responsive: true,
      circumference: 270,
      ...(localization.dir === LANGUAGE_DIRECTION.RTL && { rotation: 90 }),
    },
  });
  useEffect(() => {
    setConfig({
      ...config,
      options: {
        ...config.options,
        ...(localization.dir === LANGUAGE_DIRECTION.RTL ? { rotation: 90 } : { rotation: 0 }),
      },
    });
  }, [localization.dir]);

  return (
    <AppBox sx={{ width: size, height: size, position: 'relative' }}>
      <Doughnut data={config.data} options={config.options} style={{ position: 'absolute', left: '50%', top: '44%', transform: 'translate(-50%, -50%)' }} />
    </AppBox>
  );
};

export default AmountChart;
