import { AppContainer } from '../../common/components/AppContainer';
import { ErrorData, LoadingData, NoData } from '../../common/components/DataLoadState';

const EmptyDataWidget = (props) => {
  const { variant, title, height, onReload } = props;

  const variantToState = () => {
    switch (variant) {
      case 'error':
        return <ErrorData title={title} height={height} onReload={onReload} />;
      case 'loading':
        return <LoadingData height={height} />;
      default:
        return <NoData title={title} height={height} onReload={onReload} />;
    }
  };

  return (
    <AppContainer
      variant='outlined'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      {variantToState()}
    </AppContainer>
  );
};

export default EmptyDataWidget;
