import { IconButton, InputAdornment } from '@mui/material';
import { forwardRef, useState, useImperativeHandle } from 'react';
import { useAppContext } from '../../context/AppContext';
import { AppBox } from './AppBox';
import { AppIcon, ICON } from './AppIcon';
import { AppTextField } from './AppText';

export const AppQuickFilter = forwardRef((props, ref) => {
  const { onChange, onReset, width, inputRef, ...rest } = props;
  const { localization } = useAppContext();
  const [value, setValue] = useState('');
  const handleChangeValue = (value) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleReset = () => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
    handleChangeValue('');
    if (onReset) {
      onReset();
    }
  };

  useImperativeHandle(ref, () => ({
    clearValue: () => {
      setValue('');
    },
  }));

  return (
    <AppBox flex center>
      <AppTextField
        value={value}
        onChange={handleChangeValue}
        variant='outlined'
        //hovered
        {...rest}
        placeholder={localization.translate('common.quick_search')}
        {...(inputRef && {
          inputProps: {
            ref: inputRef,
          },
        })}
        InputProps={{
          sx: {
            paddingRight: 0,
            ...(width && {
              width: width,
            }),
          },
          startAdornment: (
            <InputAdornment position='start'>
              <AppIcon icon={ICON.Search} />
            </InputAdornment>
          ),
          ...(!!value && {
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={handleReset}>
                  <AppIcon icon={ICON.Close} />
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
      />
    </AppBox>
  );
});
