import { Backdrop, CircularProgress } from '@mui/material';
import { useState, useRef, forwardRef, useEffect, useImperativeHandle, useCallback } from 'react';
import { DialogFormContainer } from '../../common/components/DialogFormContainer';
import ErrorModalAlert from '../../common/components/ErrorModalAlert';
import { WORKING_STATE } from '../../common/Constants';
import { useAppContext } from '../../context/AppContext';
import { useHttp } from '../../hooks/authentication';
import DonationForm from './DonationForm';
import AppForm from '../../common/components/AppForm';
import AppDialogForm from '../../common/components/AppDialogForm';

const DonationSplitDialog = forwardRef((props, ref) => {
  const { title, campaigns, formId, onSubmit, splitProps } = props;
  const { getHttp } = useHttp();
  const { localization } = useAppContext();
  const formRef = useRef(null);
  const containerRef = useRef(null);
  const [maxSplitAmount, setMaxSplitAmount] = useState(0);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [workingState, setWorkingState] = useState(WORKING_STATE.INPROGRESS);
  const [error, setError] = useState({
    title: '',
    content: '',
  });
  const close = () => {
    setBackdropOpen(false);
    setWorkingState(WORKING_STATE.INPROGRESS);
  };

  const handleSubmit = async (data) => {
    try {
      await onSubmit(data);
      if (splitProps?.parent) {
        const available = await loadAvailableSplit();
        if (!available) {
          containerRef.current.close();
        }
      } else {
        containerRef.current.close();
      }
    } catch (error) {
      console.error(error);
      setError({
        title: localization.translate('errors.data_load_error'),
        content: `${localization.translate('errors.data_load_error_content')} ${localization.translate('errors.try_again')}. ${localization.translate(
          'errors.contact_admin'
        )}`,
      });
      setWorkingState(WORKING_STATE.ERROR);
    }
  };

  const loadAvailableSplit = async () => {
    const response = await getHttp().get(`transactions?parent=${splitProps.parent.id}`);
    let usedAmount = 0;
    response.data.records.forEach((r) => (usedAmount += r.amount));
    const availableForSplit = new Number((splitProps.parent.amount - usedAmount).toFixed(2));
    if (availableForSplit > 0) {
      setMaxSplitAmount(availableForSplit);
    }

    return availableForSplit <= 0 ? false : true;
  };

  const open = async () => {
    if (splitProps?.parent) {
      setBackdropOpen(true);
      try {
        const available = await loadAvailableSplit();
        if (available) {
          containerRef.current.open();
          setBackdropOpen(false);
        } else {
          setError({
            title: localization.translate('donations.no_split_amount'),
            content: `${localization.translate('donations.no_split_amount_description', {
              donation: `${localization.displayCurrency(splitProps.parent.amount)} - ${splitProps.parent.sourceName}`,
            })}`,
          });
          setWorkingState(WORKING_STATE.ERROR);
        }
      } catch (error) {
        console.error(error);
        setError({
          title: localization.translate('errors.data_load_error'),
          content: `${localization.translate('errors.data_load_error_content')} ${localization.translate('errors.try_again')}. ${localization.translate(
            'errors.contact_admin'
          )}`,
        });
        setWorkingState(WORKING_STATE.ERROR);
      }
    } else {
      containerRef.current.open();
      setBackdropOpen(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <AppDialogForm
        ref={containerRef}
        title={title}
        formId={formId}
        onSubmit={(e) => handleSubmit(e)}
        slot={{
          component: DonationForm,
          props: {
            campaigns: campaigns,
            splitProps: splitProps,
            maxSplitAmount: maxSplitAmount,
          },
        }}
        closeOnSubmit={false}
        onClose={close}
        onSetDisableSubmit={() => {
          return maxSplitAmount > splitProps.parent?.amount;
        }}
      />
      {backdropOpen && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!containerRef.current?.dialogOpen}>
          {workingState === WORKING_STATE.INPROGRESS && <CircularProgress color='inherit' />}

          <ErrorModalAlert open={workingState === WORKING_STATE.ERROR} onClose={() => close()} title={error.title} content={error.content} />
        </Backdrop>
      )}
    </>
  );
});

export default DonationSplitDialog;
