import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from '../../context/AppContext';
import { AppBox } from '../../common/components/AppBox';
import { Doughnut } from 'react-chartjs-2';
import { AppText } from '../../common/components/AppText';

const CampaignChartLabels = (props) => {
  const { options, size } = props;
  const { localization } = useAppContext();
  const getTitle = (name) => {
    switch (name) {
      case 'goal':
        return localization.translate('dashboard.campaign_goal');
      case 'donations':
        return localization.translate('app.donations');
      default:
        return localization.translate('app.expenses');
    }
  };
  return (
    <AppBox flex column centery sx={{ width: '100%', height: size, mt: 1, ml: 1 }}>
      {options.map((e, i) => {
        return (
          <div key={i} style={{ marginBottom: '5px' }}>
            <AppBox flex centery>
              <AppBox style={{ width: 15, height: 15, backgroundColor: e.color, borderRadius: '2px' }} />
              <AppText variant='xsmall' sx={{ ml: '3px', color: (theme) => theme.palette.text.secondary }}>
                {getTitle(e.pie)}
              </AppText>
            </AppBox>
            <AppBox flex centery>
              <AppBox style={{ width: 15, height: 15 }} />
              <AppText variant='small' sx={{ ml: '3px' }}>
                {localization.displayCurrency(e.value || '-')}
              </AppText>
            </AppBox>
            {e.count && (
              <AppBox flex centery>
                <AppBox style={{ width: 15, height: 15 }} />
                <AppText variant='small' sx={{ ml: '3px' }}>
                  {e.count}
                </AppText>
              </AppBox>
            )}
          </div>
        );
      })}
    </AppBox>
  );
};

const CampaignChart = (props) => {
  const { data, campaign, size } = props;
  const { localization } = useAppContext();
  const theme = useTheme();
  const [chartProps, setChartProps] = useState();
  const [labels, setLabels] = useState();

  const setPieChart = () => {
    const goal = data.goal || 0;
    const donations = data.amount || 0;
    const expenses = data.expenseAmount || 0;

    const datasets = [];
    if (!!goal) {
      if (!donations && !expenses) {
        datasets.push({
          data: [goal],
          backgroundColor: [theme.palette.background.paperDisabled],
          borderWidth: 0,
        });
      }
      if (!!donations) {
        datasets.push({
          data: [donations, donations >= goal ? 0 : goal - donations],
          backgroundColor: [calculateColor('donation', !!data.goal ? data.percent : -1), theme.palette.background.paperDisabled],
          borderWidth: 0,
          //hoverOffset: 5,
        });
      }
      if (!!expenses) {
        datasets.push({
          data: [expenses, expenses >= goal ? 0 : goal - expenses],
          backgroundColor: [calculateColor('expense', expenses / (goal / 100)), theme.palette.background.paperDisabled],
          borderWidth: 0,
          //hoverOffset: 5,
        });
      }
    } else {
      datasets.push({
        data: [donations],
        backgroundColor: [calculateColor('donation', -1)],
        borderWidth: 0,
      });
      datasets.push({
        data: [expenses, expenses >= donations ? 0 : donations - expenses],
        backgroundColor: [calculateColor('expense', expenses / (donations / 100)), calculateColor('donation', -1)],
        borderWidth: 0,
      });
    }
    setChartProps({
      data: {
        datasets: [...datasets],
      },
      options: {
        locale: localization.locale,
        radius: '95%',
        cutout: '55%',
        responsive: true,
      },
    });

    const pies = [goal, donations, expenses].sort((a, b) => a - b).filter((i) => i > 0);
    const colors = getColors(pies, goal, donations, expenses);
    const legend = [];
    for (let i = 0; i < pies.length; i++) {
      switch (pies[i]) {
        case goal:
          legend.push({
            order: 1,
            pie: 'goal',
            color: colors[i],
            value: pies[i],
          });
          break;
        case donations:
          legend.push({
            order: 2,
            pie: 'donations',
            count: data.count,
            color: colors[i],
            value: pies[i],
          });
          break;
        case expenses:
          legend.push({
            order: 3,
            pie: 'expenses',
            count: data.expenseCount,
            color: colors[i],
            value: pies[i],
          });
      }
    }

    setLabels(legend.sort((a, b) => a.order - b.order));
  };

  useEffect(() => {
    setPieChart();
  }, [data, campaign]);

  const calculateColor = (mode, percent) => {
    const BEST = '#43a047'; // green
    const GOOD = '#afb42b'; // lemon
    const WARN = '#fb8c00'; // orange
    const ALERT = theme.palette.dashboard.error;

    let color = mode === 'donation' ? '#7e57c2' : theme.palette.dashboard.error; // when exceeds 100%
    if (mode === 'donation') {
      if (percent < 0) {
        color = theme.palette.primary.main;
      } else if (percent <= 25) {
        color = ALERT;
      } else if (percent > 25 && percent <= 50) {
        color = WARN;
      } else if (percent > 50 && percent <= 90) {
        color = GOOD;
      } else if (percent > 90 && percent <= 100) {
        color = BEST;
      }
    } else {
      if (percent < 100) {
        color = theme.palette.dashboard.info;
      }
    }
    return color;
  };

  const getColors = (pies, goalPie, donationsPie, expensesPie) => {
    const colors = [];
    for (let i = 0; i < pies.length; i++) {
      if (pies[i] === goalPie) {
        colors.push(theme.palette.background.paperDisabled);
      } else if (pies[i] === donationsPie) {
        colors.push(calculateColor('donation', !!data.goal ? data.percent : -1));
      } else {
        colors.push(calculateColor('expense', !!data.goal ? expensesPie / (data.goal / 100) : expensesPie / (donationsPie / 100)));
      }
    }
    return colors;
  };

  return chartProps ? (
    <AppBox flex center sx={{ width: '100%', height: size, position: 'relative' }}>
      <Doughnut data={chartProps.data} options={chartProps.options} />

      <AppBox
        flex
        column
        center
        sx={{
          position: 'absolute',
          left: '25%',
          top: '52%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        {!!data.goal
          ? labels.map((l, i) => {
              switch (l.pie) {
                case 'donations':
                  return (
                    <AppText key={i} variant='small' sx={{ color: l.color, cursor: 'default' }}>
                      {`${((l.value / data.goal) * 100).toFixed(1)}%`}
                    </AppText>
                  );
                case 'expenses':
                  return (
                    <AppText key={i} variant='small' sx={{ color: l.color, cursor: 'default' }}>
                      {`${((l.value / data.goal) * 100).toFixed(1)}%`}
                    </AppText>
                  );
              }
            })
          : !!data.amount && (
              <AppText variant='small' sx={{ color: calculateColor('expense', data.expenseAmount / (data.amount / 100)), cursor: 'default' }}>
                {`${((data.expenseAmount / data.amount) * 100).toFixed(1)}%`}
              </AppText>
            )}
      </AppBox>

      {labels && <CampaignChartLabels options={labels} size={size} />}
    </AppBox>
  ) : (
    <></>
  );
};

export default CampaignChart;
