import { createContext, useEffect, useState, useContext } from 'react';
import { useHttp } from '../hooks/authentication';
import { useAppContext } from './AppContext';

const tenantContext = createContext();
const TenantContextProvider = ({ children }) => {
  const { user, tenant, setTenant } = useAppContext();
  const { getHttp } = useHttp();

  useEffect(() => {
    (async () => {
      if (user.tenant) {
        try {
          const response = await getHttp().get('/configurations/tenant');
          setTenant({ ...tenant, settings: { ...response.data } });
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [user.tenant]);

  const setTenantSettings = async (value) => {
    try {
      await getHttp().put('/configurations/tenant', { ...value });
      setTenant({ ...tenant, settings: { ...value } });
    } catch (error) {
      console.error(error);
    }
  };

  const context = {
    tenantSettings: { ...tenant.settings },
    setTenantSettings,
  };

  return <tenantContext.Provider value={context}>{children}</tenantContext.Provider>;
};

const useTenantContext = () => {
  const context = useContext(tenantContext);
  if (context === undefined) {
    throw new Error('useTenantContext must be used within TenantContextProvider');
  }
  return context;
};

export { TenantContextProvider, useTenantContext };
