import { useTheme } from '@emotion/react';
import { gridColumnVisibilityModelSelector, useGridApiRef } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useImperativeHandle, useState, forwardRef, useReducer, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext';
import { useHttp } from '../../../hooks/authentication';
import { isNullOrEmpty, isNullOrEmptyArray } from '../../../utils/utils';
import { AppDataGridView } from '../../../views/AppView';
import { LANGUAGE_DIRECTION, NOTIFICATION_SEVIRITY, WORKING_STATE } from '../../Constants';
import ErrorModalAlert from '../ErrorModalAlert';
import AppNotification from '../AppNotification';
import { NoData } from '../DataLoadState';
import { AppDataGridToolbar } from './AppDataGridToolbar';
import { AppGridLinerProgress, getFilterPanel, isGridInitialized, requireTreeData, rowsPerPage, StyledAppDataGrid } from './AppDataGridUtils';
import InnerCenteredContainer from '../InnerCenteredContainer';
import { AppIcon, ICON, ICON_SIZE } from '../AppIcon';
import { AppBox } from '../AppBox';
import { AppText } from '../AppText';
import { useDataContext } from '../../../context/DataContext';
import { normalizeFilterItems } from './GridFilters';
import { AppContainer } from '../AppContainer';
import AppForm from '../AppForm';

const DEFAULT_SERVER_CONTROLLED_GRID_STATE = {
  openError: true,
  notificationSeverity: null,
  workingState: WORKING_STATE.NOTSTARTED,
  offset: 0,
  page: 0,
  pageSize: 50,
  rows: [],
  total: 0,
};

const GRID_STATE_ACTIONS = {
  OPEN_ERROR: 'open_error',
  NOTIFICATION_SEVERITY: 'notification_severity',
  WORKING_STATE: 'working_state',
  WORKING_STATE_AND_NOTIFICATION_SEVERITY: 'state_severity',
  STATE: 'state',
  ROWS: 'grid_rows',
  UPDATE_ROWS: 'grid_update_rows',
  PAGE_SIZE: 'grid_pagesize',
};

function stateReducer(state, action) {
  switch (action.type) {
    case GRID_STATE_ACTIONS.OPEN_ERROR:
      return { ...state, openError: action.payload };
    case GRID_STATE_ACTIONS.NOTIFICATION_SEVERITY:
      return { ...state, notificationSeverity: action.payload };
    case GRID_STATE_ACTIONS.WORKING_STATE:
      return { ...state, workingState: action.payload };
    case GRID_STATE_ACTIONS.WORKING_STATE_AND_NOTIFICATION_SEVERITY:
      return { ...state, workingState: action.payload.workingState, notificationSeverity: action.payload.notificationSeverity };
    case GRID_STATE_ACTIONS.STATE:
      return { ...state, ...action.payload };
    case GRID_STATE_ACTIONS.ROWS:
      return { ...state, rows: [...action.payload] };
    case GRID_STATE_ACTIONS.UPDATE_ROWS: {
      const updatedRows = [...state.rows];
      action.payload.forEach((element) => {
        const index = updatedRows.findIndex((e) => e.id === element.id);
        if (index >= 0) {
          updatedRows.splice(index, 1, { ...updatedRows[index], ...element });
        }
      });
      return { ...state, rows: [...updatedRows] };
    }
    case GRID_STATE_ACTIONS.PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    default:
      return state;
  }
}

export const AppDataGrid2 = forwardRef((props, ref) => {
  const {
    urlPath,
    onPreLoad,
    onPostLoad,
    extendedPanelProps,
    detailsFormProps,
    treeDataProps,
    toolbarProps,
    notificationProps,
    errorAlertProps,
    columns,
    slots,
    slotProps,
    noRecordsTitle,
    rowsCheckboxSelection = true,
    ...rest
  } = props;
  const theme = useTheme();
  const { localization, layout } = useAppContext();
  const { getHttp } = useHttp();
  const location = useLocation();
  const navigate = useNavigate();
  const dataContext = useDataContext();

  const apiRef = useGridApiRef();
  const detailsFormRef = useRef(null);
  const toolbarRef = useRef(null);
  const appViewRef = useRef(null);
  const dataRef = useRef({
    offset: 0,
    sortModel: null,
    quickFilter: null,
    searchId: null,
    loadedParent: null,
    expansionLookup: new Map(),
  });
  const [details, setDetails] = useState(null);
  const [state, stateDispatch] = useReducer(stateReducer, DEFAULT_SERVER_CONTROLLED_GRID_STATE);

  const getHierarchy = ({ rows, row }) => {
    if (!row) {
      return [];
    }
    if (!row.parentId) {
      return [row.id];
    }
    const parent = rows ? rows.find((r) => r.id === row.parentId) : apiRef.current.getRow(row.parentId);
    if (!parent) {
      return [row.id];
    }
    const hierarchy = getHierarchy({ rows: rows, row: parent });
    hierarchy.push(row.id);
    return hierarchy;
  };

  const handleGridStateChanged = () => {
    const exportedState = apiRef.current.exportState();
    // we use server side sorting and filtering, so not required
    delete exportedState.sorting;
    delete exportedState.filter;
    delete exportedState.pagination;
    const visibilityModel = gridColumnVisibilityModelSelector(apiRef);
    dataContext.setProfile({
      gridState: exportedState,
      visibilityModel: visibilityModel,
    });
  };

  const updateWorkingState = (wstate) => {
    let severity = null;
    switch (wstate) {
      case WORKING_STATE.ERROR:
        severity = NOTIFICATION_SEVIRITY.ERROR;
        break;
      case WORKING_STATE.DONE_DATA_ERROR:
        severity = NOTIFICATION_SEVIRITY.ERROR;
        break;
      case WORKING_STATE.SUCCESS:
        severity = NOTIFICATION_SEVIRITY.SUCCESS;
        break;
    }
    stateDispatch({ type: GRID_STATE_ACTIONS.WORKING_STATE_AND_NOTIFICATION_SEVERITY, payload: { workingState: wstate, notificationSeverity: severity } });
  };

  const load = async ({ parent, size, pageNum, http, updateDetails = false }) => {
    if (pageNum === undefined || pageNum === null) {
      pageNum = state.page || 0;
    }
    let limit = 0;
    let skip = 0;
    let tmpSearchId = dataRef.current.searchId;
    if (size) {
      // fetch data by changing page size
      limit = size;
      skip = dataRef.current.offset;
      pageNum = skip / limit;
    } else {
      // fetch data by changing page number
      limit = state.pageSize;
      skip = state.pageSize * pageNum;
    }
    if (!http) {
      http = getHttp();
    }
    let getRowsUrlQuery = `${urlPath}?version=2&skip=${skip}&limit=${limit}`;
    if (dataRef.current.searchId) {
      tmpSearchId = dataRef.current.searchId;
      getRowsUrlQuery = getRowsUrlQuery + `&searchId=${dataRef.current.searchId}`;
    }
    if (dataRef.current.sortModel) {
      getRowsUrlQuery = getRowsUrlQuery + `&sort=${dataRef.current.sortModel}`;
    }
    if (dataRef.current.quickFilter) {
      getRowsUrlQuery = getRowsUrlQuery + `&q=${dataRef.current.quickFilter}`;
    }
    if (parent) {
      getRowsUrlQuery = getRowsUrlQuery + `&parent=${parent.id}`;
    }
    try {
      const response = await http.get(getRowsUrlQuery);
      // Special case: filter race condition.
      // When run filter A and immediate then filter B, the B filter might finish before A.
      // So, "cancel" filter results if searchId has changed (B is active already) during running the current filter (A).
      // In other words - always apply filter results on last executed filter
      if (tmpSearchId !== dataRef.current.searchId) {
        return;
      }
      dataRef.current.offset = skip;

      let records = null;
      if (requireTreeData(treeDataProps)) {
        records = [];
        for (let i = 0; i < response.data.records.length; i++) {
          const _row = response.data.records[i];
          const _hierarchy = getHierarchy({ rows: response.data.records, row: _row });
          records.push({
            ..._row,
            hierarchy: _hierarchy,
          });
        }
      } else {
        records = response.data.records;
      }

      stateDispatch({
        type: GRID_STATE_ACTIONS.STATE,
        payload: {
          offset: skip,
          page: Math.floor(pageNum),
          pageSize: limit,
          rows: records,
          total: response.data.total,
        },
      });

      if (updateDetails && details) {
        const detailsData = response.data.records.find((e) => e.id === details.id);
        setDetails(detailsData);
      }
      return response.data.records.length > 0 ? WORKING_STATE.DONE : WORKING_STATE.DONE_NO_DATA;
    } catch (error) {
      console.error(error);
      return WORKING_STATE.ERROR;
    }
  };

  const loadChildren = async (parent) => {
    let getRowsUrlQuery = `${urlPath}?parent=${parent.id}`;
    if (dataRef.current.searchId) {
      getRowsUrlQuery = getRowsUrlQuery + `&searchId=${dataRef.current.searchId}`;
    }
    if (dataRef.current.sortModel) {
      getRowsUrlQuery = getRowsUrlQuery + `&sort=${dataRef.current.sortModel}`;
    }
    if (dataRef.current.quickFilter) {
      getRowsUrlQuery = getRowsUrlQuery + `&q=${dataRef.current.quickFilter}`;
    }
    try {
      const response = await getHttp().get(getRowsUrlQuery);
      const children = response.data.records.map((r, i) => {
        return {
          ...r,
          hierarchy: getHierarchy({ row: r }),
        };
      });

      const updated = [...state.rows];
      const parentIndex = updated.findIndex((e) => e.id === parent.id);
      if (parentIndex >= 0) {
        let nextIndex = parentIndex + 1;
        for (let i = 0; i < children.length; i++) {
          updated.splice(nextIndex, 0, children[i]);
          nextIndex += 1;
        }
        dataRef.current.loadedParent = parent.id;
        stateDispatch({
          type: GRID_STATE_ACTIONS.ROWS,
          payload: updated,
        });
      }
    } catch (error) {
      console.error(error);
      updateWorkingState(WORKING_STATE.DONE_DATA_ERROR);
    }
  };

  const reload = async ({ pageNum, size, http, updateState = true }) => {
    let state = WORKING_STATE.SUCCESS;
    let page = pageNum === undefined || pageNum === null ? state.page : pageNum;
    try {
      let tmpSearchId = dataRef.current.searchId;
      state = await load({ pageNum: page, size: size, http: http });
      if (state === WORKING_STATE.ERROR) {
        state = WORKING_STATE.DONE_DATA_ERROR;
      }
      if (updateState) {
        // special case - filter race condition:
        // When run filter A and immediate then filter B, the B filter might finish before A.
        // But if A ends prior to B it changes working state to "IDLE" and filter B loses "loading" indicator.
        // So, always changes working state when the last run filter ends
        if (tmpSearchId === dataRef.current.searchId) {
          updateWorkingState(state);
        }
      }
    } catch (error) {
      console.error(error);
      updateWorkingState(WORKING_STATE.DONE_DATA_ERROR);
    }
  };

  const addItem = async (item) => {
    if (item) {
      updateWorkingState(WORKING_STATE.INPROGRESS);
      const http = getHttp();
      try {
        const response = await http.post(urlPath, item);
        await reload({ http: http, updateState: false });
        updateWorkingState(WORKING_STATE.SUCCESS);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };

  const deleteItems = async (data) => {
    updateWorkingState(WORKING_STATE.INPROGRESS);
    try {
      const http = getHttp();
      await http.request({
        method: 'delete',
        url: urlPath,
        ...(data && { data: { ...data } }),
      });
      await reload({ http: http, updateState: false });
      selectRow();
      updateWorkingState(WORKING_STATE.SUCCESS);
    } catch (error) {
      console.error(error);
      updateWorkingState(WORKING_STATE.DONE_DATA_ERROR);
    }
  };

  const updateItems = async (items) => {
    const toUpdate = !Array.isArray(items) ? [{ ...items }] : items;
    try {
      if (!isNullOrEmptyArray(toUpdate)) {
        updateWorkingState(WORKING_STATE.INPROGRESS);
        await getHttp().put(urlPath, toUpdate);
        stateDispatch({ type: GRID_STATE_ACTIONS.UPDATE_ROWS, payload: toUpdate });

        updateWorkingState(WORKING_STATE.SUCCESS);
        const updatedForDetails = toUpdate.find((e) => e.id === details.id);
        if (updatedForDetails) {
          setDetails(updatedForDetails);
        }
      }
    } catch (error) {
      console.error(error);
      updateWorkingState(WORKING_STATE.DONE_DATA_ERROR);
      throw error;
    }
  };

  const onPaginationModelChanged = async (model) => {
    let reloadOps = undefined;
    if (model.page !== state.page) {
      reloadOps = {
        pageNum: model.page,
      };
    }
    if (model.pageSize !== state.pageSize) {
      reloadOps = {
        ...reloadOps,
        size: model.pageSize,
      };
    }
    if (reloadOps) {
      updateWorkingState(WORKING_STATE.INPROGRESS);
      await reload(reloadOps);
    }
  };

  const onProcessRowUpdateError = useCallback((error) => {
    updateWorkingState(WORKING_STATE.DONE_DATA_ERROR);
  }, []);

  const setSearchId = async (model) => {
    if (!isNullOrEmpty(model.items)) {
      const filter = normalizeFilterItems(model.items);
      if (filter !== null) {
        try {
          const response = await getHttp().post(`${urlPath}/search`, { query: [...filter] });
          if (response?.data?.id) {
            dataRef.current.searchId = response.data.id;
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const changeFilter = async (filter) => {
    console.log(filter);

    if (isNullOrEmpty(filter)) {
      console.log('detect grid filter has been reset');
      dataRef.current.searchId = null;
      updateWorkingState(WORKING_STATE.INPROGRESS);
      await reload({});
      return;
    }
    updateWorkingState(WORKING_STATE.INPROGRESS);
    try {
      const http = getHttp();
      const query = { query: [...filter] };
      let response;
      if (dataRef.current.searchId) {
        response = await http.put(`${urlPath}/search/${dataRef.current.searchId}`, query);
      } else {
        response = await http.post(`${urlPath}/search`, query);
      }
      if (response?.data?.id) {
        dataRef.current.searchId = response.data.id;
      }
      await reload({ http: http });
    } catch (error) {
      console.error(error);
      updateWorkingState(WORKING_STATE.DONE_DATA_ERROR);
    }
  };

  const changeQuickFilter = async (filter) => {
    dataRef.current.quickFilter = filter;
    updateWorkingState(WORKING_STATE.INPROGRESS);
    await reload({});
  };

  const onSelectedRowsChanged = (newSelectionModel) => {
    if (newSelectionModel.length === 1) {
      const row = apiRef.current.getRow(newSelectionModel[0]);
      setDetails(row);
    }
    toolbarRef.current.propagateSelectionModel(newSelectionModel);
  };

  const onSortModelChanged = useCallback(async (sortModel) => {
    if (!sortModel || sortModel.length <= 0) {
      dataRef.current.sortModel = null;
    } else {
      dataRef.current.sortModel = `${sortModel[0].sort && sortModel[0].sort === 'asc' ? '+' : '-'}${sortModel[0].field}`;
    }
    try {
      updateWorkingState(WORKING_STATE.INPROGRESS);
      await reload({});
    } catch (error) {
      console.error(error);
      updateWorkingState(WORKING_STATE.DONE_DATA_ERROR);
    }
  }, []);

  const onReload = async () => {
    try {
      apiRef.current.setFilterModel({ items: [], reason: 'reset' });
      dataRef.current.searchId = 0;
      dataRef.current.quickFilter = '';
      updateWorkingState(WORKING_STATE.INPROGRESS);
      await reload({});
    } catch (error) {
      console.error(error);
      updateWorkingState(WORKING_STATE.DONE_DATA_ERROR);
    }
  };

  const findNextRowIdToSelect = (selectedIndex, isUp) => {
    if (selectedIndex >= 0 && selectedIndex < state.rows.length) {
      if (isUp) {
        for (let i = selectedIndex - 1; i >= 0; i--) {
          if (state.rows[i].parentId) {
            const parentNode = apiRef.current.getRowNode(state.rows[i].parentId);
            if (!parentNode.childrenExpanded) {
              return state.rows[i].parentId;
            }
          }
          return state.rows[i].id;
        }
      } else {
        for (let i = selectedIndex + 1; i < state.rows.length; i++) {
          if (state.rows[i].parentId) {
            const parentNode = apiRef.current.getRowNode(state.rows[i].parentId);
            if (!parentNode.childrenExpanded) {
              continue;
            }
          }
          return state.rows[i].id;
        }
      }
    }
    return null;
  };

  const selectRow = (id, key) => {
    let idToSelect = null;
    if (!id) {
      if (state.rows.length > 0) {
        idToSelect = state.rows[0].id;
      }
    } else {
      const selectedIndex = state.rows.findIndex((r) => r.id === id);

      switch (key) {
        case 'ArrowDown':
          idToSelect = findNextRowIdToSelect(selectedIndex, false);
          break;
        case 'ArrowUp':
          idToSelect = findNextRowIdToSelect(selectedIndex, true);
          break;
        default:
          idToSelect = null;
      }
    }
    if (idToSelect) {
      apiRef.current.setRowSelectionModel([idToSelect]);
    }
  };

  const getDetailPanelHeight = useCallback(() => 'auto', []);
  const getDetailPanelContent = useCallback(
    ({ row }) => {
      return <extendedPanelProps.component row={row} localization={localization} {...extendedPanelProps.componentProps} />;
    },
    [localization.language]
  );

  useImperativeHandle(ref, () => ({
    onReload: async () => await onReload(),
    addItem: async (item) => {
      return await addItem(item);
    },
    deleteItems: async (data) => await deleteItems(data),
    getSearchId: () => dataRef.current.searchId,
    updateItems: async (items) => await updateItems(items),
    load: async ({ size, pageNum, http, updateDetails = false }) => await load({ size: size, pageNum: pageNum, http: http, updateDetails: updateDetails }),
    reload: async ({ pageNum, size, http }) => await reload({ pageNum: pageNum, size: size, http: http }),
    loadChildren: loadChildren,
    updateWorkingState: (wstate) => updateWorkingState(wstate),
  }));

  //----------------------------
  const gridInitialized = isGridInitialized(apiRef);

  const restoreGridState = () => {
    const profile = dataContext.getProfile();
    if (profile?.visibilityModel) {
      apiRef.current.setColumnVisibilityModel(profile.visibilityModel);
    }
    if (profile?.gridState) {
      apiRef.current.restoreState(profile.gridState);
    }
  };

  useEffect(() => {
    updateWorkingState(WORKING_STATE.INPROGRESS);
    (async () => {
      try {
        if (location.state) {
          const filterModel = { items: [...location.state.items] };
          dataContext.setFilterModel(filterModel);
          await setSearchId(filterModel);
          navigate(location.pathname, { replace: true });
        }
        if (onPreLoad) {
          await onPreLoad();
        }
        const state = await load({ pageNum: 0 });
        if (onPostLoad) {
          await onPostLoad();
        }

        updateWorkingState(state);
      } catch (error) {
        console.error(error);
        updateWorkingState(WORKING_STATE.ERROR);
      }
    })();
  }, []);

  useEffect(() => {
    if (gridInitialized) {
      if (state?.rows?.length > 0) {
        let idToSelect = null;
        if (details) {
          idToSelect = state.rows.find((e) => e.id === details.id)?.id;
        }
        if (!idToSelect) {
          idToSelect = state.rows[0].id;
        }
        apiRef.current.setRowSelectionModel([idToSelect]);

        const parents = state.rows.filter((e) => e.childrenCount > 0);
        if (parents && parents.length > 0) {
          for (let i = 0; i < parents.length; i++) {
            const parentIndex = state.rows.findIndex((e) => e.id === parents[i].id);
            const closedChildIndex = parentIndex + 1;
            if (closedChildIndex < state.rows.length) {
              const childHierarchy = state.rows[closedChildIndex].hierarchy;
              if (childHierarchy && childHierarchy.length > 0) {
                if (childHierarchy[0] === parents[i].id) {
                  apiRef.current.updateRows([{ id: parents[i].id, childrenFetched: true }]);
                }
              }
            }
          }
          if (dataRef.current.loadedParent) {
            apiRef.current.setRowChildrenExpansion(dataRef.current.loadedParent, true);
            dataRef.current.loadedParent = null;
          }
        }
      }
    }
  }, [state.rows, gridInitialized, localization.dir]);

  useEffect(() => {
    if (gridInitialized) {
      restoreGridState();
    }
  }, [gridInitialized, localization]);

  useEffect(() => {
    if (gridInitialized) {
      apiRef.current.subscribeEvent('rowExpansionChange', (node) => {
        dataRef.current.expansionLookup.set(node.id, node.childrenExpanded);
      });
      // subscribe grid state related events here but not via grid properties
      // to avoid calling handler for the very first time when grid was loaded and initialized with default (not modified) state
      apiRef.current.subscribeEvent('columnOrderChange', handleGridStateChanged);
      apiRef.current.subscribeEvent('columnResize', handleGridStateChanged);
      apiRef.current.subscribeEvent('columnVisibilityModelChange', handleGridStateChanged);
    }
  }, [gridInitialized]);

  const isGroupExpandedByDefault = useCallback(
    (node) => {
      return !!dataRef.current.expansionLookup.get(node.id);
    },
    [dataRef.current.expansionLookup]
  );

  const gridToolbarProps = {
    ...toolbarProps,
    filterProps: {
      ...toolbarProps?.filterProps,
      onFilterItemsChange: (filterItems) => {
        changeFilter(filterItems);
      },
      onFilterItemsReset: () => {
        changeFilter();
      },
      onQuickFilterChange: (value) => {
        changeQuickFilter(value);
      },
      onOnQuickFilterReset: () => {
        changeQuickFilter('');
      },
    },
  };

  const cellClicked = (props, event, details) => {
    if (props?.colDef?.type !== 'checkboxSelection') {
      event.preventDefault();
      details.api.setRowSelectionModel([props.id]);
    }
  };

  return state.workingState === WORKING_STATE.ERROR ? (
    <>
      <ErrorModalAlert
        open={state.openError}
        onClose={() => stateDispatch({ type: GRID_STATE_ACTIONS.OPEN_ERROR, payload: false })}
        title={errorAlertProps.title}
        content={errorAlertProps.content}
      />
      <InnerCenteredContainer>
        <AppBox flex column center sx={{ maxWidth: '500px' }}>
          <AppIcon icon={ICON.Error} size={ICON_SIZE.ExtraLarge} style={{ color: theme.palette.text.disabled }} />
          <AppText sx={{ my: 2, color: theme.palette.text.disabled }}>{errorAlertProps.content}</AppText>
        </AppBox>
      </InnerCenteredContainer>
    </>
  ) : (
    <AppDataGridView
      ref={appViewRef}
      viewProps={{
        ...(gridInitialized && {
          headerPane: {
            content: (
              <AppDataGridToolbar
                {...gridToolbarProps}
                ref={toolbarRef}
                apiRef={apiRef}
                upDownProps={{
                  onMoveRow: selectRow,
                }}
              />
            ),
          },
        }),

        gridPane: {
          content: (
            <AppContainer sx={{ height: '100%' }}>
              <StyledAppDataGrid
                sx={{
                  padding: 0,
                  '@media print': {
                    '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
                  },
                }}
                apiRef={apiRef}
                loading={state.workingState === WORKING_STATE.NOTSTARTED || state.workingState === WORKING_STATE.INPROGRESS}
                {...(rowsCheckboxSelection && {
                  checkboxSelection: true,
                  disableRowSelectionOnClick: true,
                  checkboxSelectionVisibleOnly: true,
                })}
                columns={columns}
                density={dataContext.getProfile()?.density || 'compact'}
                onCellClick={cellClicked}
                pagination={true}
                rows={state.rows}
                rowCount={state.total}
                paginationModel={{ page: state.page, pageSize: state.pageSize }}
                onPaginationModelChange={onPaginationModelChanged}
                pageSizeOptions={rowsPerPage}
                onRowSelectionModelChange={onSelectedRowsChanged}
                onSortModelChange={onSortModelChanged}
                onProcessRowUpdateError={onProcessRowUpdateError}
                paginationMode='server'
                sortingMode='server'
                filterMode='server'
                slots={{
                  ...slots,
                  loadingOverlay: AppGridLinerProgress,
                  NoRowsOverlay: () => {
                    return <NoData title={noRecordsTitle || localization.translate('app.no_data')} />;
                  },
                }}
                slotProps={{
                  ...slotProps,
                  csvOptions: {
                    utf8WithBom: true,
                  },
                  baseTooltip: {
                    enterDelay: 500,
                  },
                  pagination: {
                    showFirstButton: true,
                    showLastButton: true,
                    sx: { color: (theme) => theme.palette.text.secondary },
                    dir: localization.dir,
                  },
                  filterPanel: { ...getFilterPanel(localization), disableRemoveAllButton: true },
                }}
                // https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
                localeText={{
                  toolbarColumns: '',
                  toolbarDensity: '',
                  filterPanelRemoveAll: localization.translate('common.reset'),
                  filterPanelColumns: localization.translate('app.columns'),
                  filterPanelOperators: localization.translate('app.operator'),
                  filterPanelInputLabel: localization.translate('app.value'),
                  filterPanelAddFilter: (
                    <AppText variant='small' sx={{ ...(localization.dir === LANGUAGE_DIRECTION.RTL && { mx: 1 }) }}>
                      {localization.translate('app.add_filter')}
                    </AppText>
                  ),
                  filterPanelInputPlaceholder: localization.translate('app.value'),
                  footerRowSelected: (numOfSelectedRows) =>
                    numOfSelectedRows > 1
                      ? `${numOfSelectedRows} ${localization.translate('app.rows_selected')}`
                      : `${numOfSelectedRows} ${localization.translate('app.row_selected')}`,
                  MuiTablePagination: {
                    labelRowsPerPage: localization.translate('app.rows_per_page'),
                    labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} ${localization.translate('app.num_pages_of')} ${count}`,
                  },
                }}
                {...(requireTreeData(treeDataProps) && {
                  treeData: true,
                  getTreeDataPath: (row) => row.hierarchy,
                  groupingColDef: treeDataProps.column,
                  disableChildrenFiltering: true,
                  isGroupExpandedByDefault: isGroupExpandedByDefault,
                })}
                {...(extendedPanelProps
                  ? {
                      getDetailPanelHeight: getDetailPanelHeight,
                      getDetailPanelContent: getDetailPanelContent,
                    }
                  : {})}
                {...rest}
              />
              {state.notificationSeverity && (
                <AppNotification
                  open={state.workingState === WORKING_STATE.SUCCESS || state.workingState === WORKING_STATE.DONE_DATA_ERROR}
                  onClose={() => {
                    stateDispatch({
                      type: GRID_STATE_ACTIONS.WORKING_STATE_AND_NOTIFICATION_SEVERITY,
                      payload: { workingState: WORKING_STATE.IDLE, notificationSeverity: null },
                    });
                  }}
                  severity={state.notificationSeverity}
                  message={state.workingState === WORKING_STATE.DONE_DATA_ERROR ? notificationProps.onDataError : notificationProps.onSuccess}
                />
              )}
            </AppContainer>
          ),
        },
        detailsPane: {
          content: details ? (
            <AppForm
              ref={detailsFormRef}
              details={details}
              slot={{
                component: detailsFormProps.component,
                props: detailsFormProps.componentProps,
              }}
              onSubmit={updateItems}
            />
          ) : null,
        },
      }}
    />
  );
});
