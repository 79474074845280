import { Box, Grid, InputAdornment, MenuItem, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ELEMENT_POSITION, RECORD_CREATION_MODE, RECORD_STATE } from '../../common/Constants';
import { getDateTimeFormat, compareDates, getCurrencySymbol } from '../../utils/utils';
import { useAppContext } from '../../context/AppContext';
import { AppIcon, ICON_SIZE } from '../../common/components/AppIcon';
import { AppNumericField, AppText, AppTextField } from '../../common/components/AppText';
import { getStatusColor, StatusItems } from '../../common/components/StatusSelector';
import { AppFormLayout } from '../../common/components/AppFormLayout';
import { AppBox } from '../../common/components/AppBox';
import { useTheme } from '@emotion/react';
import { AppButton } from '../../common/components/AppButton';
import CurrencySelector from '../../common/components/CurrencySelector';
import { getCampaignName } from '../../common/components/datagrid/AppDataGridUtils';
import CampaignsSelector from '../../common/components/CampaignsSelector';
import { AppDatePicker } from '../../common/components/AppDatePicker';

const ExpenseDetails = forwardRef((props, ref) => {
  const { api, campaigns } = props;
  const theme = useTheme();
  const { localization, tenant } = useAppContext();

  const deployingData = (details) => {
    let toDeploy = null;
    if (details) {
      toDeploy = { ...details };
    } else {
      toDeploy = {
        creationMode: RECORD_CREATION_MODE.MANUAL,
        transactionDate: new Date(),
        originalDate: new Date(),
      };
      api.setInvalidInputs({ amount: true });
    }
    return toDeploy;
  };

  const submittingData = (data) => {
    return { ...data };
  };

  useImperativeHandle(ref, () => ({
    deployingData,
    submittingData,
  }));

  const setStatus = (value) => {
    api.setDataField({ key: 'trackingState', value: value, validateInput: true });
  };

  const setAmount = (value) => {
    api.setDataField({
      key: 'amount',
      value: value,
      validateInput: true,
      inputValidator: (v) => {
        return v <= 0;
      },
    });
  };
  const setOriginalAmount = (value) => {
    api.setDataField({
      key: 'originalAmount',
      value: value,
      validateInput: true,
      inputValidator: (v) => {
        return !!v && v <= 0;
      },
    });
  };
  const setCurrency = (value) => {
    api.setDataField({ key: 'currency', value: value, validateInput: true });
  };
  const setOriginalCurrency = (value) => {
    api.setDataField({ key: 'originalCurrency', value: value });
  };
  const setCategory = (value) => {
    api.setDataField({ key: 'category', value: value, validateInput: true });
  };

  const setSubCategory = (value) => {
    api.setDataField({ key: 'subCategory', value: value, validateInput: true });
  };
  const setProjectId = (value) => {
    api.setDataField({ key: 'projectId', value: value });
  };

  const setComment = (value) => {
    api.setDataField({ key: 'comment', value: value || null });
  };
  const setPaidBy = (value) => {
    api.setDataField({ key: 'paidBy', value: value, validateInput: true });
  };

  const setPaidTo = (value) => {
    api.setDataField({ key: 'paidTo', value: value, validateInput: true });
  };

  const setDate = (value) => {
    api.setDataField({
      key: 'transactionDate',
      value: value,
      validateInput: true,
      changeValidator: (v) => {
        return api.details ? !compareDates(v, new Date(api.details.transactionDate)) : true;
      },
    });
  };
  const setOriginalDate = (value) => {
    api.setDataField({
      key: 'originalDate',
      value: value,
      changeValidator: (v) => {
        return api.details ? !compareDates(v, new Date(api.details.transactionDate)) : true;
      },
    });
  };

  return api.data ? (
    <Grid container rowSpacing={2} columnSpacing={1}>
      <Grid container item xs={12} rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <AppTextField
            select
            required
            fullWidth
            label={localization.translate('app.status')}
            value={api.data.trackingState || RECORD_STATE.NEW}
            onChange={(v) => setStatus(v)}
            InputProps={{
              sx: { color: (theme) => getStatusColor(api.data.trackingState || RECORD_STATE.NEW, theme) },
            }}
          >
            {StatusItems.map((status) => {
              return (
                <MenuItem
                  sx={{ color: (theme) => getStatusColor(status.value, theme) }}
                  value={status.value}
                  disableRipple
                  variant='small'
                  key={status.value}
                  disabled={status.value === api.data.trackingState}
                >
                  <AppBox flex center>
                    <AppIcon icon={status.icon} size={ICON_SIZE.Small} />
                    <AppText sx={{ mx: 1 }}> {localization.translate(`app.${status.text.toLowerCase()}`)}</AppText>
                  </AppBox>
                </MenuItem>
              );
            })}
          </AppTextField>
        </Grid>
      </Grid>
      <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <AppTextField
            fullWidth
            label={localization.translate('donations.bank_reference')}
            name='bank_reference'
            id='bank_reference'
            value={api.data.bankReference || ''}
            onChange={(v) => api.setDataField({ key: 'bankReference', value: v, onInvalidInputValidate: null })}
            //error={data.sourceReference && isNaN(data.sourceReference)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CampaignsSelector
            item={{
              value: {
                projectName: getCampaignName(campaigns, api.data.projectId, localization),
                projectId: api.data.projectId || null,
              },
            }}
            applyValue={(item) => {
              setProjectId(item.value.projectId);
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <AppNumericField
            sx={{ width: '100%' }}
            required
            label={localization.translate('app.sum')}
            name='amount'
            id='amount'
            value={api.data.amount || ''}
            error={!api.data.amount || api.data.amount <= 0}
            onChange={(v) => {
              setAmount(v);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>{getCurrencySymbol(localization.locale, api.data.currency || tenant.getCurrency())}</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencySelector required selectedCode={api.data.currency} onSelected={(c) => setCurrency(c.code)} />
        </Grid>
      </Grid>
      <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <AppNumericField
            error={!!api.data.originalAmount && api.data.originalAmount <= 0}
            sx={{ width: '100%' }}
            label={localization.translate('app.original_sum')}
            name='originalAmount'
            id='originalAmount'
            value={api.data.originalAmount || ''}
            onChange={(v) => {
              setOriginalAmount(v);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>{getCurrencySymbol(localization.locale, api.data.originalCurrency || tenant.getCurrency())}</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencySelector
            addNoCurrency
            selectedCode={api.data.originalCurrency}
            onSelected={(c) => setOriginalCurrency(c.code)}
            label={localization.translate('app.original_currency')}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <AppDatePicker
            label={localization.translate('common.date')}
            name='date'
            id='date'
            required
            value={api.data.transactionDate}
            onChange={(date) => {
              setDate(date);
            }}
            inputTextProps={{
              required: true,
              sx: { width: '100%' },
              error: !api.data.transactionDate || isNaN(new Date(api.data.transactionDate).getTime()),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppDatePicker
            label={localization.translate('common.original_date')}
            name='original_date'
            id='original_date'
            value={api.data.originalDate}
            onChange={(date) => {
              setOriginalDate(date);
            }}
            inputTextProps={{
              required: true,
              sx: { width: '100%' },
              error: api.data.originalDate ? isNaN(new Date(api.data.originalDate).getTime()) : false,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          sx={{ width: '100%' }}
          id='category'
          label={localization.translate('expenses.category')}
          name='category'
          value={api.data.category || ''}
          onChange={(v) => {
            setCategory(v);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AppTextField
          sx={{ width: '100%' }}
          id='subcategory'
          label={localization.translate('expenses.sub_category')}
          name='subcategory'
          value={api.data.subCategory || ''}
          onChange={(v) => {
            setSubCategory(v);
          }}
        />
      </Grid>
      <Grid item sm={12}>
        <AppTextField
          multiline
          rows={4}
          fullWidth
          label={localization.translate('app.comments')}
          id='comment'
          name='comment'
          value={api.data.comment || ''}
          onChange={(v) => {
            setComment(v);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AppTextField
          sx={{ width: '100%' }}
          id='paidby'
          label={localization.translate('expenses.paid_by')}
          name='paidby'
          value={api.data.paidBy || ''}
          onChange={(v) => {
            setPaidBy(v);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AppTextField
          sx={{ width: '100%' }}
          id='paidTo'
          label={localization.translate('expenses.paid_to')}
          name='paidto'
          value={api.data.paidTo || ''}
          onChange={(v) => {
            setPaidTo(v);
          }}
        />
      </Grid>
      <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <AppTextField
            readonly
            fullWidth
            id='created'
            label={localization.translate('common.created')}
            name='created'
            value={localization.display(new Date(api.data.created)) || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppTextField fullWidth readonly id='createdby' label={localization.translate('app.created_by')} name='createdby' value={api.data.createdBy || ''} />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
});

export default ExpenseDetails;
