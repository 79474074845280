import PropTypes from 'prop-types';
import { PERIOD, USER_ROLES } from '../common/Constants';

export const loadScript = (jsSrc, onLoad, onError, scriptProps) => {
  const element = document.getElementsByTagName('script')[0];
  const fjs = element;
  let js = element;
  js = document.createElement('script');

  if (scriptProps) {
    Object.getOwnPropertyNames(scriptProps).forEach((p) => (js[p] = scriptProps[p]));
  }

  js.src = jsSrc;

  if (fjs && fjs.parentNode) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    document.head.appendChild(js);
  }
  js.onerror = onError;
  js.onload = onLoad;
};

export const distinctArray = (array, keyName, mapper) => {
  const distinctItems = [...new Map(array.map((p, i) => [p[keyName], p])).values()];
  return mapper ? distinctItems.map(mapper) : distinctItems;
};

// Gets updatedArray as base. Adds missing elements from origArray
export const mergeArrays = (origArray, updatedArray, key) => {
  if (!origArray) {
    return updatedArray;
  }
  if (!updatedArray) {
    return origArray;
  }
  const updatedKeys = [...new Map(updatedArray.map((e, i) => [e[key], e])).keys()];
  const extraInOrig = origArray.filter((e) => !updatedKeys.includes(e[key]));
  return updatedArray.concat(extraInOrig);
};

mergeArrays.propTypes = {
  origArray: PropTypes.array,
  updatedArray: PropTypes.array,
  key: PropTypes.string.isRequired,
};

export const isNullOrEmptyArray = (array) => {
  if (!array) {
    return true;
  }
  if (!Array.isArray(array)) {
    console.error('not array');
    throw new Error('not array');
  }
  return array.length <= 0;
};

export const isNullOrEmpty = (value) => {
  if (value === undefined || value === null) {
    return true;
  }
  if (value instanceof Array) {
    return value.length <= 0;
  }
  if (value instanceof Map || value instanceof Set) {
    return value.size <= 0;
  }
  return value === '';
};

export const generateRandomColor = () => {
  let maxVal = 0xffffff; // 16777215
  let randomNumber = Math.random() * maxVal;
  randomNumber = Math.floor(randomNumber);
  randomNumber = randomNumber.toString(16);
  let randColor = randomNumber.padStart(6, 0);
  return `#${randColor.toUpperCase()}`;
};

export const getDateTimeFormat = (locale) => {
  const getPatternForPart = (part) => {
    switch (part.type) {
      case 'day':
        return 'dd';
      case 'month':
        return 'MM';
      case 'year':
        return 'yyyy';
      case 'literal':
        return part.value;
      default:
        console.error('Unsupported date part', part);
        return '';
    }
  };

  //return new Intl.DateTimeFormat(locale).formatToParts().map(getPatternForPart).join('');
  return new Intl.DateTimeFormat().formatToParts().map(getPatternForPart).join('');
};

export const getCurrencySymbol = (locale, currency) => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).formatToParts().find((e) => e.type === 'currency')?.value || '';
};

export const compareDates = (date1, date2) => {
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    throw new Error('both arguments have to be a valid dates');
  }
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export const validateNumericInput = (value) => {
  const res = !value || (!isNaN(parseFloat(value)) && isFinite(value));
  return !res ? res : value.length <= 9;
};

export const validateEmailInput = (value) => {
  const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return re.test(value);
};

export const getUtcDateInterpretation = (date, dateOnly = true, useTimesone = false) => {
  const date1 = dateOnly
    ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    : new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
  let isoString = date1.toISOString();

  if (useTimesone) {
    const offset = date1.getTimezoneOffset();
    const offsetAbs = Math.abs(offset);

    isoString = `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(Math.floor(offsetAbs / 60)).padStart(2, '0')}${String(offsetAbs % 60).padStart(
      2,
      '0'
    )}`;
  }
  return isoString;
};

export const calculateStartDate = (end, period) => {
  let start = new Date(end);
  switch (period) {
    case PERIOD.WEEK:
      start.setDate(start.getDate() - 7);
      break;
    case PERIOD.THREE_WEEKS:
      start.setDate(start.getDate() - 21);
      break;
    case PERIOD.ONE_MONTH:
      start.setMonth(start.getMonth() - 1);
      break;
    case PERIOD.THREE_MONTHS:
      start.setMonth(start.getMonth() - 3);
      break;
    case PERIOD.SIX_MONTHS:
      start.setMonth(start.getMonth() - 6);
      break;
    case PERIOD.YEAR:
      start.setMonth(start.getMonth() - 12);
      break;
    case PERIOD.CURRENT_YEAR:
      start = new Date(end.getFullYear(), 0, 1, 0, 0, 0, 0);
      break;
    default:
      throw new Error('Invalid period provided');
  }
  return start;
};

export const isFewScreenWidth = () => {
  return window.innerWidth < 1800;
};

export const isFewScreenHeight = () => {
  return window.innerHeight < 800;
};

export const getHighestRole = (roles) => {
  let highest = null;
  if (roles?.length > 0) {
    const rolesArray = Object.getOwnPropertyNames(USER_ROLES).map((role, i) => {
      return { ...USER_ROLES[role] };
    });
    for (let i = 0; i < roles.length; i++) {
      const role = rolesArray.find((r) => r.name === roles[i]);
      if (!role) {
        continue;
      }
      if (!highest) {
        highest = { ...role };
      } else {
        if (role.value > highest.value) {
          highest = { ...role };
        }
      }
    }
  }
  return highest;
};
