import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { IconButton, useMediaQuery, Divider } from '@mui/material';
import { useAppContext } from '../context/AppContext';
import { AppBox } from '../common/components/AppBox';
import LanguageMenu from './mainMenu/LanguageMenu';
import { TasksMenu } from './mainMenu/TasksMenu';
import ProfileMenu from './mainMenu/ProfileMenu';
import { AppIcon, ICON, ICON_SIZE } from '../common/components/AppIcon';
import TenantMenu from './mainMenu/TenantMenu';
import { COLOR_MODES } from '../common/Constants';
import Navbar from './Navbar';
import logo from '../assets/images/proxima.png';
import logoDark from '../assets/images/proxima_dark.png';
import UISettingsMenu from './mainMenu/UISettingsMenu';

const HeaderCommon = (props) => {
  return (
    <>
      <AppBox flex center id='actions_menu'>
        <TasksMenu />
        <Divider orientation='vertical' variant='middle' flexItem sx={{ mx: 1 }} />
      </AppBox>
      {/*
      <AppBox flex center id='settings_menu' sx={{ mx: 1 }}>
        <LanguageMenu />
        <Divider orientation='vertical' variant='middle' flexItem />
        <SettingsMenu />
      </AppBox>
      */}
      {/*<Divider orientation='vertical' variant='middle' flexItem sx={{ my: 2 }} />*/}
      <AppBox flex center id='profile_menu' sx={{ mx: 1 }}>
        <ProfileMenu />
      </AppBox>

      <AppBox flex center id='tennat_menu' sx={{ mr: 2 }}>
        <TenantMenu />
        <Divider orientation='vertical' variant='middle' flexItem sx={{ mx: 1 }} />
        <LanguageMenu />
        <Divider orientation='vertical' variant='middle' flexItem sx={{ mx: 1 }} />
        <UISettingsMenu />
      </AppBox>
    </>
  );
};

const HeaderForSideMenu = (props) => {
  const { sidebarClosed, setSidebarClosed, headerStyle, setHeaderStyle } = props;
  const { user } = useAppContext();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (setHeaderStyle) {
      setHeaderStyle({
        color: (theme) => theme.palette.text.primary,
        height: '50px',
      });
    }
  }, []);

  return (
    <>
      <AppBox sx={{ mx: 2 }}>
        <IconButton
          sx={{ color: (theme) => theme.palette.layout.text.title }}
          aria-label='open drawer'
          onClick={() => isMdUp && setSidebarClosed(!sidebarClosed)}
          edge='start'
        >
          <AppIcon icon={ICON.Menu} size={ICON_SIZE.Large} />
        </IconButton>
      </AppBox>
      <AppBox sx={{ flexGrow: 1 }} />
    </>
  );
};

const HeaderForTopMenu = (props) => {
  const { headerStyle, setHeaderStyle, ...rest } = props;
  const theme = useTheme();

  useEffect(() => {
    if (setHeaderStyle) {
      setHeaderStyle({
        backgroundColor: (theme) => theme.palette.background.paper,
        color: (theme) => theme.palette.text.primary,
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: '64px',
      });
    }
  }, []);

  return (
    <>
      <AppBox flex center sx={{ mx: 1 }}>
        <img src={theme.palette.mode === COLOR_MODES.LIGHT ? logo : logoDark} style={{ transform: 'scale(0.8)' }} />
      </AppBox>
      <Navbar />
    </>
  );
};

const Header = (props) => {
  const { profile } = useAppContext();
  const [headerStyle, setHeaderStyle] = useState({});
  return (
    <AppBox
      flex
      center
      sx={{
        ...headerStyle,
      }}
    >
      {profile.sideMenu ? (
        <HeaderForSideMenu {...props} headerStyle={headerStyle} setHeaderStyle={setHeaderStyle} />
      ) : (
        <HeaderForTopMenu {...props} headerStyle={headerStyle} setHeaderStyle={setHeaderStyle} />
      )}
      <HeaderCommon />
    </AppBox>
  );
};

export default Header;
