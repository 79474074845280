import { DataGridPro, getDataGridUtilityClass, GridLogicOperator, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro';
import { LANGUAGE_DIRECTION } from '../../Constants';
import { CircularProgress, LinearProgress, unstable_composeClasses as composeClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { AppBox } from '../AppBox';
import { AppIcon, ICON, ICON_SIZE } from '../AppIcon';
import { AppText } from '../AppText';
import { useEffect, useState } from 'react';

export const rowsPerPage = [20, 30, 50];
// https://github.com/mui/mui-x/tree/380c12ad998d54b49e6ae798b249b925db125f00/packages/grid/x-data-grid/src/components/panel/filterPanel
export const getFilterPanel = (localization) => {
  return {
    // Force usage of "And" operator
    logicOperators: [GridLogicOperator.And],
    // Display columns by ascending alphabetical order
    columnsSort: 'asc',

    //disableRemoveAllButton: true,
    filterFormProps: {
      // Customize inputs by passing props
      logicOperatorInputProps: {
        variant: 'outlined',
        size: 'small',
      },
      columnInputProps: {
        variant: 'outlined',
        size: 'small',
        sx: { mt: 'auto' },
      },
      operatorInputProps: {
        variant: 'outlined',
        size: 'small',
        sx: { mt: 'auto' },
      },
      valueInputProps: {
        variant: 'outlined',
        size: 'small',
        ...(localization.dir === LANGUAGE_DIRECTION.RTL && {
          sx: { mt: 'auto', direction: 'ltr' },
        }),
      },
      deleteIconProps: {
        sx: {
          '& .MuiSvgIcon-root': { color: '#d32f2f' },
        },
      },
    },
    sx: {
      // Customize inputs using css selectors
      '& .MuiDataGrid-filterForm': { p: 2 },
      '& .MuiDataGrid-filterForm:nth-of-type(even)': {
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'),
      },
      '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
      '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 'auto' },
      '& .MuiDataGrid-filterFormOperatorInput': { mr: 2, width: 'auto' },
      '& .MuiDataGrid-filterFormValueInput': { width: 'auto', mr: 2 },
    },
  };
};

export const StyledAppDataGrid = styled(DataGridPro)(({ theme }) => ({
  //borderRadius: theme.shape.borderRadiusPx,
  border: 'none',
  '& .MuiDataGrid-cellContent': {
    fontSize: theme.typography.small.fontSize,
    padding: 5,
  },

  '& .MuiDataGrid-detailPanel': {
    backgroundColor: theme.palette.background.paper,
  },

  '& .MuiDataGrid-footerContainer': {
    '& .MuiDataGrid-selectedRowCount': {
      fontSize: theme.typography.small.fontSize,
    },
  },

  '& .MuiDataGrid-row': {
    cursor: 'default',
    '&.Mui-selected': {
      //backgroundColor: theme.palette.action.selected, // WHEN SPECIFIED - FONT BOLD ISSUE ON SCROLL!!!
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
      },
    },
  },
  '& .inner-in-tree': {
    backgroundColor: `${theme.palette.primary.main}0C`,
  },
  '& .status-highlighted--new': {
    color: theme.palette.status.new,
  },
  '& .status-highlighted--questioned': {
    color: theme.palette.status.questioned,
  },
  '& .status-highlighted--inactive': {
    color: theme.palette.text.disabled,
    fontStyle: 'italic',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme.palette.layout.header,
  },
  '& .MuiDataGrid-columnHeaders': {
    fontSize: theme.typography.small.fontSize,
    //...theme.typography.title,
    backgroundColor: theme.palette.layout.header,
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    color: theme.palette.primary.contrastText,
    '& .MuiDataGrid-checkboxInput': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      color: theme.palette.primary.contrastText,
      padding: 5,
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 400,
      },
    },
    '& .MuiDataGrid-filterIcon': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDataGrid-sortIcon': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDataGrid-columnSeparator': {
      color: theme.palette.primary.contrastText,
      '&:hover': {},
    },
    '& .MuiDataGrid-menuIconButton': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

// LinearProgress activated on top of first row is overlapped by selected row and thus is not visible
export const AppGridLinerProgress = styled(LinearProgress)(({ theme }) => ({
  zIndex: 1000,
}));

export const DEF_GRID_COLUMN_PROPS = {
  headerAlign: 'center',
  align: 'center',
};

export const FormatGridColumnsDefinitionData = (cols) => {
  const columns = [];
  const visibilityModel = {};
  for (let i = 0; i < cols.length; i++) {
    const column = { ...cols[i], ...DEF_GRID_COLUMN_PROPS };
    if (column.hide !== undefined) {
      if (column.hide) {
        visibilityModel[column.field] = false;
      }
      delete column.hide;
    }
    columns.push(column);
  }
  columns.columnVisibilityModel = { ...visibilityModel };
  return {
    data: [...columns],
    visibilityModel: { ...visibilityModel },
  };
};

export const GroupingCellWithLazyLoading = (props) => {
  const { id, field, rowNode, row, loadChildren, valueGetter } = props;
  const apiRef = useGridApiContext();
  const [loading, setLoading] = useState(false);
  const [iconPointer, setIconPointer] = useState();

  useEffect(() => {
    setIconPointer(rowNode.childrenExpanded ? ICON.ChevronDown : ICON.ChevronRight);
  }, [rowNode.childrenExpanded]);

  const handleClick = async (event) => {
    event.stopPropagation();
    if (row.childrenFetched) {
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    } else {
      if (loadChildren) {
        try {
          setLoading(true);
          await loadChildren(row);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <AppBox flex centery sx={{ width: '100%' }}>
      <AppBox flex centery sx={{ minWidth: '25%', mr: 1 }}>
        {row.childrenCount > 0 && (
          <AppBox flex centery onClick={handleClick} tabIndex={-1}>
            <AppText variant='small'>{row.childrenCount}</AppText>
            {loading && <CircularProgress size='1rem' />}
            {!loading && <AppIcon icon={iconPointer} size={ICON_SIZE.Small} />}
          </AppBox>
        )}
      </AppBox>
      <AppBox flex centery sx={{ flexGrow: 1, px: !!row.parentId ? 1 : 0 }}>
        {valueGetter(row)}
      </AppBox>
    </AppBox>
  );
};
GroupingCellWithLazyLoading.propTypes = {
  hideDescendantCount: PropTypes.bool,
};

export const requireTreeData = (props) => {
  const res = !!props && !!props.column;
  return res;
};

export const isGridInitialized = (apiRef) => {
  return !!apiRef?.current && Object.getOwnPropertyNames(apiRef.current).length > 0;
};

export const getCampaignName = (campaigns, campaignId) => {
  if (campaigns) {
    return campaigns.find((e) => e.projectId === campaignId)?.projectName || '';
  } else {
    return '';
  }
};
