import { useEffect, useState } from 'react';
import { AppTab, AppTabPanel, AppTabs } from '../../../../common/components/AppTabs';
import { AppText } from '../../../../common/components/AppText';
import { useAppContext } from '../../../../context/AppContext';
import UserAccountsConfiguration from './UserAccountsConfiguration';

const CONFIGURATION_INDEX = {
  USERS: 1,
};

const Configurations = [
  {
    index: CONFIGURATION_INDEX.USERS,
    component: UserAccountsConfiguration,
    label: 'settings.user_accounts',
  },
];

export const TenantSecurityConfiguration = (props) => {
  const { localization } = useAppContext();
  const [index, setIndex] = useState(CONFIGURATION_INDEX.USERS);
  const [configuration, setConfiguration] = useState();

  useEffect(() => {
    const config = Configurations.find((c) => c.index === index) || Configurations[0];
    setConfiguration(config);
  }, [index]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <AppTabs value={index} onChange={(e, v) => setIndex(v)} aria-label='config-tabs'>
        {Configurations.map((c, i) => {
          return (
            <AppTab key={i} value={c.index} disabled={c.disabled}>
              <AppText>{localization.translate(c.label)}</AppText>
            </AppTab>
          );
        })}
      </AppTabs>
      <AppTabPanel sx={{ p: 2 }}>{configuration?.component && <configuration.component {...configuration.componentProps} />}</AppTabPanel>
    </div>
  );
};
