import { useState, useEffect, forwardRef } from 'react';
import { GridFooterContainer, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro';
import { AppBox } from '../AppBox';
import { useTheme } from '@emotion/react';
import { AppText } from '../AppText';
import { useAppContext } from '../../../context/AppContext';

const TotalSelectedOption = (props) => {
  const theme = useTheme();
  const { localization } = useAppContext();
  const apiRef = useGridApiContext();
  const [selectionData, setSelectionData] = useState(null);

  const handleRowSelectionChanged = () => {
    // By default we always have first row selected. Trying to apply filter
    // which doesn't bring any results, the grid still keeps reference to the
    // previously selected row by its id, but with 'undefined' as value. So,
    // keep filter of 'undefined' here
    const selectedRows = Array.from(apiRef.current.getSelectedRows().values()).filter((e) => e !== undefined);
    const numOfSelectedRows = selectedRows.length;

    const rowsSelectedPhrase =
      numOfSelectedRows > 1
        ? `${numOfSelectedRows} ${localization.translate('app.rows_selected')}`
        : `${numOfSelectedRows} ${localization.translate('app.row_selected')}`;

    const total = Number(selectedRows.map((r, i) => r.amount).reduce((s, a) => s + a, 0));

    const selection = isNaN(total)
      ? null
      : {
          rowsSelectedPhrase,
          total,
        };

    setSelectionData(selection);
  };

  useEffect(() => {
    handleRowSelectionChanged();
    return apiRef.current.subscribeEvent('rowSelectionChange', handleRowSelectionChanged);
  }, []);

  useEffect(() => {
    handleRowSelectionChanged();
  }, [localization.locale]);

  return (
    <>
      {selectionData && (
        <AppBox flex centery sx={{ mx: 2, visibility: selectionData.total >= 0 ? 'visible' : 'hidden' }}>
          <AppText color={theme.palette.text.secondary}>{selectionData.rowsSelectedPhrase}</AppText>
          <AppText color={theme.palette.text.secondary} sx={{ mx: 1 }}>
            {localization.displayCurrency(selectionData.total)}
          </AppText>
        </AppBox>
      )}
    </>
  );
};

const AppDadtaGridFooter = forwardRef((props, ref) => {
  const { slot, pagination = true, ...rest } = props;
  const rootProps = useGridRootProps();
  return (
    <GridFooterContainer
      ref={ref}
      sx={{
        '& .MuiMenuItem-root': {
          fontSize: (theme) => theme.typography.small.fontSize,
        },
      }}
    >
      {slot && slot}
      <AppBox flex sx={{ flexGrow: 1 }} />
      <AppBox sx={{ mx: 1 }} flex centery endx>
        <rootProps.slots.pagination
          {...rootProps.slotProps?.pagination}
          sx={{
            '& .MuiTablePagination-displayedRows': {
              fontSize: (theme) => theme.typography.small.fontSize,
            },
            '& .MuiTablePagination-select': {
              fontSize: (theme) => theme.typography.small.fontSize,
            },
            '& .MuiTablePagination-selectLabel': {
              fontSize: (theme) => theme.typography.small.fontSize,
            },
            '& .MuiTablePagination-actions': {
              '& .MuiButtonBase-root': {
                '& .MuiSvgIcon-root': {
                  fontSize: '18px',
                },
              },
            },
          }}
        />
      </AppBox>
    </GridFooterContainer>
  );
});

export { AppDadtaGridFooter, TotalSelectedOption };
