import { useTheme } from '@emotion/react';
import { Divider, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { useHttp } from '../../hooks/authentication';
import { LANGUAGE_DIRECTION, SupportedLanguages } from '../Constants';
import { AppBox } from './AppBox';
import { AppButton } from './AppButton';
import { AppDropdownMenu, AppDropdownMenuItem } from './AppDropdownMenu';
import { AppIcon, ICON, ICON_SIZE } from './AppIcon';
import AppLink from './AppLink';
import { AppText } from './AppText';

const ReceiptDownloader = (props) => {
  const { data, icon, title, text, iconProps, buttonProps, filename = 'filename' } = props;
  const theme = useTheme();
  const { getHttp } = useHttp();
  const { localization } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [links, setLinks] = useState({});
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (data?.invoice?.links) {
      setLinks({
        direct: true,
        items: Object.keys(data.invoice.links).map((key, i) => {
          const language = SupportedLanguages.find((l) => l.code === key);
          return {
            code: key,
            name: language?.name || key,
            link: data.invoice.links[key],
          };
        }),
      });
    } else {
      if (data?.invoice?.variations && data.invoice.variations.length > 0) {
        setLinks({
          direct: false,
          items: data.invoice.variations.map((key, i) => {
            const language = SupportedLanguages.find((l) => l.code === key);
            return {
              code: key,
              name: language?.name || key,
              link: `transactions/${data.id}/receipts/${key}`,
            };
          }),
        });
      }
    }
  }, [data]);

  const handleClick = (event) => {
    if (!links.items || links.items.length == 0) {
      console.error('No files do download provided');
      return;
    }
    if (links.items?.length == 1) {
      handleDownload(links.items[0]);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async (item) => {
    try {
      const response = await getHttp().get(`transactions/${data.id}/receipts/${item.code || ''}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = url;

      const ext = response.data.type.substring(response.data.type.indexOf('/') + 1);

      link.setAttribute('download', `${filename || 'file'}_${item.code}.${ext || 'pdf'}`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const generateDownloadLink = ({ icon }) => {
    if (icon) {
      if (data?.invoice?.number) {
        return (
          <AppBox flex center>
            <AppLink onClick={handleClick} sx={{ mx: 1 }}>
              {data.invoice.number}
            </AppLink>
          </AppBox>
        );
      } else {
        return (
          <IconButton {...iconProps} onClick={handleClick}>
            <AppIcon icon={ICON.Download} />
          </IconButton>
        );
      }
    } else {
      if (data?.invoice?.number) {
        return (
          <AppBox flex center>
            <AppText>{`${localization.translate('donations.receipt')}:`}</AppText>
            <AppLink onClick={handleClick} sx={{ mx: 1 }}>
              {data.invoice.number}
            </AppLink>
          </AppBox>
        );
      } else {
        return (
          <AppButton variant='text' {...buttonProps} startIcon={<AppIcon icon={ICON.Download} />} onClick={handleClick}>
            {text}
          </AppButton>
        );
      }
    }
  };

  return (
    <AppBox flex sx={{ height: '100%' }}>
      {generateDownloadLink({ icon: icon })}
      {links.items?.length > 1 && (
        <AppDropdownMenu
          id='download_menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: localization.dir === LANGUAGE_DIRECTION.LTR ? 'left' : 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: localization.dir === LANGUAGE_DIRECTION.LTR ? 'right' : 'left' }}
        >
          {title && (
            <div>
              <AppText sx={{ mx: 2 }}>{title}</AppText>
              <Divider />
            </div>
          )}

          {links.items.map((item, i) => {
            return (
              <AppDropdownMenuItem icon={ICON.Link} iconSize={ICON_SIZE.Small} key={i} text={item.name} type='link' onClick={() => handleDownload(item)} />
            );
          })}
        </AppDropdownMenu>
      )}
    </AppBox>
  );
};

export default ReceiptDownloader;
