import { Box, CircularProgress } from '@mui/material';
import InnerCenteredContainer from './InnerCenteredContainer';

const InnerCenteredCircularProgress = (props) => {
  const { size, color } = props;

  return (
    <InnerCenteredContainer color={color}>
      <CircularProgress size={size || 80} color={color ? 'inherit' : 'primary'} />
    </InnerCenteredContainer>
  );
};

export default InnerCenteredCircularProgress;
