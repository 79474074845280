import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useAppContext } from '../../../../context/AppContext';
import { useHttp } from '../../../../hooks/authentication';
import { AppBox } from '../../../../common/components/AppBox';
import { Grid, IconButton, LinearProgress, MenuItem } from '@mui/material';
import { AppQuickFilter } from '../../../../common/components/AppQuickFilter';
import { AppButton } from '../../../../common/components/AppButton';
import { AppIcon, ICON, ICON_SIZE } from '../../../../common/components/AppIcon';
import { AppText, AppTextField, InlineTextField } from '../../../../common/components/AppText';
import { useTheme } from '@emotion/react';
import { RECEIPT_PROVIDERS, RECEIPT_PROVIDER_TYPES } from '../../../../common/Constants';
import AppSwitch from '../../../../common/components/AppSwitch';
import { DialogFormContainer } from '../../../../common/components/DialogFormContainer';
import GreenInvoiceProviderForm from './GreenInvoiceProviderForm';
import EasyCountProviderForm from './EasyCountProviderForm';
import { useDialogsContext } from '../../../../context/DialogsContext';

const ProviderForms = {
  [RECEIPT_PROVIDER_TYPES.GreenInvoice]: GreenInvoiceProviderForm,
  [RECEIPT_PROVIDER_TYPES.EasyCount]: EasyCountProviderForm,
};

const ProviderDialogForm = forwardRef((props, ref) => {
  const { onSubmit, ...rest } = props;
  const { localization } = useAppContext();
  const formRef = useRef(null);
  const containerRef = useRef(null);
  const [dialogTitle, setDialogTitle] = useState('');
  const [details, setDetails] = useState(null);
  const [providers, setProviders] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogState, setDialogState] = useState({});
  const [errorAlert, setErrorAlert] = useState(null);
  const [data, setData] = useState({});
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const [invalidInputs, setInvalidInputs] = useState({});

  useEffect(() => {
    const res = !hasFieldsToUpdate() || hasInvalidInputs();
    setDisableSubmit(res);
  }, [fieldsToUpdate, invalidInputs]);

  const close = () => {
    setDialogOpen(false);
    setDisableSubmit(true);
    setDialogState({});
    setErrorAlert(null);
    setDetails(null);
    setProviders([]);
    setFieldsToUpdate({});
    setInvalidInputs({});
    setData({
      provider: '',
      isDefault: false,
      name: '',
    });
  };

  const hasFieldsToUpdate = () => {
    const keys = Object.keys(fieldsToUpdate);
    if (!keys || keys.length <= 0) {
      return false;
    }
    for (const key of keys) {
      if (fieldsToUpdate[key]) {
        return true;
      }
    }
    return false;
  };

  const hasInvalidInputs = (obj) => {
    const keys = Object.keys(invalidInputs);
    if (!keys || keys.length <= 0) {
      return false;
    }
    for (const key of keys) {
      if (invalidInputs[key]) {
        return true;
      }
    }
    return false;
  };
  const setFieldToUpdate = (name) => {
    setFieldsToUpdate({ ...fieldsToUpdate, [name]: true });
  };
  const unsetFieldToUpdate = (name) => {
    setFieldsToUpdate({ ...fieldsToUpdate, [name]: false });
  };

  const setInvalidInput = (name) => {
    setInvalidInputs({ ...invalidInputs, [name]: true });
  };

  const unsetInvalidInput = (name) => {
    const data = { ...invalidInputs, [name]: false };
    setInvalidInputs(data);
  };

  const setDataField = (props) => {
    const {
      key,
      value,
      onChangedValidate = (v) => {
        if (!details?.id) {
          return true;
        }
        if (!Boolean(details[key]) && !Boolean(v)) {
          return false;
        }
        return details[key] !== v;
      },
      onInvalidInputValidate = (v) => {
        return !v;
      },
    } = props;
    unsetInvalidInput(key); // make sure no invalid input marked for this key

    setData({ ...data, [key]: value });
    if (onInvalidInputValidate) {
      if (onInvalidInputValidate(value)) {
        // value is invalid
        setInvalidInput(key);
      }
    }
    if (onChangedValidate(value)) {
      // value has been changed
      setFieldToUpdate(key);
    } else {
      unsetFieldToUpdate(key);
    }
  };

  const deployState = (details, providers) => {
    if (details) {
      setData({
        ...details.data,
        id: details.id,
        isDefault: Boolean(details.isDefault),
        provider: details.provider,
        name: details.name,
      });
      setDialogState({
        ...dialogState,
        form: ProviderForms[details.provider],
      });
      setDetails({
        ...details.data,
        id: details.id,
        isDefault: Boolean(details.isDefault),
        provider: details.provider,
        name: details.name,
      });
    } else {
      setData({
        provider: providers[0]?.provider || '',
        isDefault: providers.length === RECEIPT_PROVIDERS.length,
        name: '',
      });
      setDialogState({
        ...dialogState,
        form: ProviderForms[providers[0]?.provider],
      });
    }
  };

  useImperativeHandle(ref, () => ({
    open: (details, providers) => {
      setProviders(providers);
      deployState(details, providers);
      setDialogTitle(details ? 'edit_account' : 'add_account');
      setDialogOpen(true);
    },
  }));

  const handleSubmit = async (e) => {
    setErrorAlert(null);
    if (onSubmit) {
      const account = { data: {} };
      Object.getOwnPropertyNames(data).forEach((p) => {
        switch (p) {
          case 'id':
          case 'isDefault':
          case 'provider':
          case 'name':
            account[p] = data[p];
            break;
          default:
            account.data[p] = data[p];
            break;
        }
      });
      await onSubmit(account);
    }
  };

  return (
    <DialogFormContainer
      ref={containerRef}
      title={localization.translate(`settings.${dialogTitle}`)}
      open={dialogOpen}
      onClose={close}
      formId='account_form'
      onSubmit={handleSubmit}
      disableSubmit={disableSubmit}
      width='sm'
      errorAlert={errorAlert}
    >
      <Grid container rowSpacing={2} columnSpacing={1} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <AppBox flex centery>
            <AppSwitch
              checked={data.isDefault}
              disabled={!data.provider}
              sx={{ mx: 2 }}
              onChange={() => {
                setDataField({
                  key: 'isDefault',
                  value: !data.isDefault,
                  onChangedValidate: (v) => {
                    if (!details?.id) {
                      return true;
                    }
                    return Boolean(details?.isDefault) !== Boolean(v);
                  },
                  onInvalidInputValidate: null,
                });
              }}
            />
            <AppText variant='small'>{localization.translate('common.use_bydefault')}</AppText>
          </AppBox>
        </Grid>
        <Grid item xs={12} md={6}>
          {details || providers?.length === 0 ? (
            <AppTextField
              fullWidth
              readonly
              label={localization.translate('settings.provider')}
              defaultValue={RECEIPT_PROVIDERS.find((p) => p.provider === data.provider)?.name || data.provider}
              {...rest}
            />
          ) : (
            <AppTextField
              fullWidth
              select
              readonly={!!details || providers.length === 1}
              label={localization.translate('settings.provider')}
              value={data.provider || ''}
              onChange={(v) => {
                setDataField({ key: 'provider', value: v, onInvalidInputValidate: null });
                setDialogState({
                  ...dialogState,
                  form: ProviderForms[v],
                });
              }}
              {...rest}
            >
              {providers.map((t, i) => {
                return (
                  <MenuItem key={i} value={t.provider}>
                    <AppBox flex center>
                      {RECEIPT_PROVIDERS.find((p) => p.provider === t.provider)?.icon && (
                        <AppIcon icon={ICON[RECEIPT_PROVIDERS.find((p) => p.provider === t.provider)?.icon]} size={ICON_SIZE.ExtraSmall} sx={{ mx: 1 }} />
                      )}
                      <AppText>{t.name}</AppText>
                    </AppBox>
                  </MenuItem>
                );
              })}
            </AppTextField>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            label={localization.translate('settings.display_name')}
            name='name'
            id='name'
            value={data.name || ''}
            onChange={(v) => {
              setDataField({ key: 'name', value: v, onInvalidInputValidate: null });
            }}
          />
        </Grid>
      </Grid>

      {dialogState.form && (
        <dialogState.form ref={formRef} data={data} setDisableSubmit={setDisableSubmit} setDataField={setDataField} setInvalidInputs={setInvalidInputs} />
      )}
    </DialogFormContainer>
  );
});

const ReceiptProvidersConfiguration = (props) => {
  const { getHttp } = useHttp();
  const theme = useTheme();
  const { localization } = useAppContext();
  const dialogs = useDialogsContext();
  const [accounts, setAccounts] = useState([]);
  const [availableProviders, setAvailableProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toDelete, setToDelete] = useState(null);
  const formRef = useRef(null);

  const load = async () => {
    try {
      const response = await getHttp().get('configurations/receipts');
      setAccounts(
        response.data.map((a, i) => {
          return {
            visible: true, // used for quick filter
            displayName: RECEIPT_PROVIDERS.find((p) => p.provider === a.provider)?.name || a.provider,
            icon: RECEIPT_PROVIDERS.find((p) => p.provider === a.provider)?.icon,
            account: { ...a },
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await load();
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const available = [];
    for (let i = 0; i < RECEIPT_PROVIDERS.length; i++) {
      if (!accounts.find((a) => a.account.provider === RECEIPT_PROVIDERS[i].provider)) {
        available.push(RECEIPT_PROVIDERS[i]);
      }
    }
    setAvailableProviders([...available]);
  }, [accounts]);

  const filterAccounts = (pattern) => {
    setAccounts(
      accounts.map((a, i) => {
        return {
          ...a,
          visible: !!pattern ? a.account.provider.includes(pattern) || a.account.name?.includes(pattern) || a.displayName?.includes(pattern) : true,
        };
      })
    );
  };

  const handleChangeIsDefault = async (id) => {
    const item = accounts.find((a) => a.account.id === id);
    if (item) {
      await handleUpdateAccount({
        ...item.account,
        isDefault: !Boolean(item.account.isDefault),
      });
    }
  };

  const handleDelete = async () => {
    const item = accounts.find((a) => a.account.id === toDelete);

    if (item) {
      await handleUpdateAccount(item.account, true);
    }
    setToDelete(null);
    dialogs.deleteConfirmationDialogRef.current.close();
  };

  const handleUpdateAccount = async (account, deleted) => {
    try {
      if (deleted) {
        await getHttp().request({
          method: 'delete',
          url: `configurations/receipts/${account.id}`,
        });
      } else {
        const response = account.id
          ? await getHttp().put(`configurations/receipts/${account.id}`, account)
          : await getHttp().post('configurations/receipts', account);
      }
      await load();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return loading ? (
    <LinearProgress sx={{ mt: 0 }} />
  ) : (
    <AppBox flex column sx={{ pr: 10, maxHeight: '400px', overflow: 'auto', width: '80%' }}>
      <AppBox flex>
        <AppBox sx={{ flexGrow: 1 }} />
        <AppQuickFilter sx={{ mx: 2 }} onChange={filterAccounts} onReset={filterAccounts} />
        <AppButton
          disabled={availableProviders.length <= 0}
          startIcon={<AppIcon icon={ICON.Add} />}
          onClick={() => formRef.current.open(null, availableProviders)}
        >
          {localization.translate('settings.add_account')}
        </AppButton>
        <ProviderDialogForm ref={formRef} onSubmit={handleUpdateAccount} />
      </AppBox>
      <AppBox flex centery>
        <AppText variant='smallb' sx={{ width: '170px' }}>
          {localization.translate('common.use_bydefault')}
        </AppText>
        <AppText variant='smallb' sx={{ mx: 1 }}>
          {localization.translate('settings.provider')}
        </AppText>
      </AppBox>
      {accounts
        .filter((a) => a.visible)
        .map((a, i) => {
          return (
            <AppBox key={i} flex centery sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 1 }}>
              <AppBox flex centery sx={{ width: '170px' }}>
                <AppSwitch checked={Boolean(a.account.isDefault)} onChange={() => handleChangeIsDefault(a.account.id)} />
              </AppBox>
              <AppBox flex centery sx={{ width: '300px' }}>
                {a.icon && <AppIcon icon={ICON[a.icon]} size={ICON_SIZE.ExtraSmall} />}
                <InlineTextField
                  readonly
                  value={a.displayName}
                  inputProps={{
                    style: {
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </AppBox>
              <AppBox flex centery sx={{ width: '250px' }}>
                <InlineTextField
                  readonly={true}
                  value={a.account.name || ''}
                  placeholder={localization.translate('settings.no_display_name')}
                  InputProps={{
                    sx: {
                      paddingRight: 0,
                      width: '250px',
                    },
                  }}
                  inputProps={{
                    style: {
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </AppBox>
              <AppBox flex centery>
                <IconButton aria-label='edit-account-button' onClick={() => formRef.current.open(a.account, availableProviders)}>
                  <AppIcon icon={ICON.SettingsCog} size={ICON_SIZE.Small} />
                </IconButton>
                <IconButton
                  disabled={Boolean(a.account.isDefault) && accounts.length > 1}
                  aria-label='delete-account-button'
                  onClick={() => {
                    setToDelete(a.account.id);
                    dialogs.deleteConfirmationDialogRef.current.open({
                      onOk: handleDelete,
                      onClose: () => setToDelete(null),
                      title: localization.translate('app.delete_account'),
                      message: localization.translate('app.confirm_delete_account'),
                    });
                  }}
                >
                  <AppIcon icon={ICON.Delete} size={ICON_SIZE.Small} />
                </IconButton>
              </AppBox>
            </AppBox>
          );
        })}
    </AppBox>
  );
};

export default ReceiptProvidersConfiguration;
