import { AppBox } from '../../../../common/components/AppBox';
import OrganizationNameSettings from './OrganizationName';
import OrganizationLogoSettings from './OrganizationLogo';

const CommonConfiguration = (props) => {
  return (
    <AppBox flex column sx={{ pr: 10, height: '100%', overflow: 'auto', width: '100%' }}>
      <OrganizationNameSettings />
      <div style={{ height: '30px' }} />
      <OrganizationLogoSettings />
    </AppBox>
  );
};

export default CommonConfiguration;
