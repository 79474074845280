import { useAppContext } from '../../../../context/AppContext';
import { AppIcon, ICON } from '../../AppIcon';
import { GridToolbarItem } from './GridToolbarItem';

export const PrintItem = (props) => {
  const { variant, fileName, onActivated, isDisabled, apiRef } = props;
  const { localization } = useAppContext();

  const handleClick = (event) => {
    if (onActivated) {
      onActivated();
    } else {
      apiRef.current.exportDataAsPrint({
        allColumns: false,
        copyStyles: true,
        hideFooter: true,
        hideToolbar: true,
        fileName: { fileName },
      });
    }
  };

  return (
    <GridToolbarItem
      variant={variant}
      aria-describedby='print-option'
      onClick={handleClick}
      startIcon={<AppIcon icon={ICON.Print} />}
      disabled={isDisabled !== undefined ? isDisabled : apiRef.current.getRowsCount() <= 0}
      text={localization.translate('common.print')}
    />
  );
};
