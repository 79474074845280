import { useRef } from 'react';

export const useSubscription = () => {
  // [
  //    topic => [subscriber => [subscription, ...],
  //              ...],
  //    ...]
  const subscribers = useRef(new Map());

  const subscribe = ({ topic, subscriber, trigger }) => {
    if (!topic) {
      throw new Error('subscription topic cannot be null or empty');
    }
    if (!subscriber) {
      throw new Error('subscriber name cannot be null or empty');
    }
    if (!trigger) {
      throw new Error('subscription trigger cannot be null');
    }
    if (!subscribers.current.has(topic)) {
      subscribers.current.set(topic, new Map());
    }
    const selected_topic = subscribers.current.get(topic);
    if (!selected_topic.has(subscriber)) {
      selected_topic.set(subscriber, []);
    }
    const selected_subscriber = selected_topic.get(subscriber);
    selected_subscriber.push({
      paused: false,
      trigger: trigger,
    });
  };

  const unsubscribe = ({ topic, subscriber, subscriptionSelector }) => {
    if (!topic) {
      // unsubscribe subscriber on all topics
      if (!subscriber) {
        throw new Error('When topic is not specified on unsubscribe, the subscriber has to be provided');
      }
      subscribers.current.forEach((value, key) => {
        unsubscribeSubscriber({ topic: value, subscriber: subscriber, subscriptionSelector: subscriptionSelector });
      });
    } else {
      unsubscribeSubscriber({ topic: subscribers.current.get(topic), subscriber: subscriber, subscriptionSelector: subscriptionSelector });
    }
  };

  const unsubscribeSubscriber = ({ topic, subscriber, subscriptionSelector }) => {
    if (topic) {
      const subscriptions = topic.get(subscriber);
      if (subscriptions) {
        if (subscriptionSelector) {
          const filtered = subscriptions.filter((i) => !subscriptionSelector(i));
          topic.set(subscriber, [...filtered]);
        } else {
          topic.delete(subscriber);
        }
      }
    }
  };

  const suspend = ({ topic, subscriber, subscriptionSelector }) => {
    if (!topic) {
      if (!subscriber) {
        throw new Error('When topic is not specified on suspend, the subscriber has to be provided');
      }
      subscribers.current.forEach((value, key) => {
        suspendResumeSubscriber({ topic: value, subscriber: subscriber, subscriptionSelector: subscriptionSelector, resume: false });
      });
    } else {
      suspendResumeSubscriber({ topic: subscribers.current.get(topic), subscriber: subscriber, subscriptionSelector: subscriptionSelector, resume: false });
    }
  };

  const resume = ({ topic, subscriber, subscriptionSelector }) => {
    if (!topic) {
      if (!subscriber) {
        throw new Error('When topic is not specified on resume, the subscriber has to be provided');
      }
      subscribers.current.forEach((value, key) => {
        suspendResumeSubscriber({ topic: value, subscriber: subscriber, subscriptionSelector: subscriptionSelector, resume: true });
      });
    } else {
      suspendResumeSubscriber({ topic: subscribers.current.get(topic), subscriber: subscriber, subscriptionSelector: subscriptionSelector, resume: true });
    }
  };

  const suspendResumeSubscriber = ({ topic, subscriber, subscriptionSelector, resume }) => {
    if (topic) {
      const subscriptions = topic.get(subscriber);
      if (subscriptions) {
        if (subscriptionSelector) {
          const filtered = subscriptions.filter((i) => subscriptionSelector(i));
          filtered.forEach((e, i) => (e.paused = !resume));
        } else {
          subscriptions.forEach((e, i) => (e.paused = !resume));
        }
      }
    }
  };

  const notify = ({ topic, triggerArg }) => {
    const selected_topic = subscribers.current.get(topic);
    if (selected_topic) {
      selected_topic.forEach((value, key) => {
        value.forEach((subscription, index) => {
          if (!subscription.paused) {
            subscription.trigger(triggerArg);
          }
        });
      });
    }
  };

  return {
    subscribe,
    unsubscribe,
    suspend,
    resume,
    notify,
  };
};
