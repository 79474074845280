import { createContext, useContext, useState } from 'react';

const dataContext = createContext();
export const DataContextProvider = ({ children }) => {
  const [name, setName] = useState('context');
  const [profile, _setProfile] = useState({});
  const [filterModel, setFilterModel] = useState(null);
  const loadProfile = (data) => {
    if (data?.data) {
      _setProfile({ ...data.data });
    }
  };

  const getProfile = () => {
    return profile[name];
  };

  const setProfile = (data) => {
    const _profile = {
      ...profile,
      [name]: {
        ...profile[name],
        ...data,
      },
    };
    _setProfile(_profile);
  };
  const resetProfile = (keepInitState = true) => {
    if (profile[name]) {
      const _profile = {
        ...profile,
      };
      if (keepInitState && profile[name].initState) {
        _profile[name] = {
          initState: { ...profile[name].initState },
        };
      } else {
        delete _profile[name];
      }
      _setProfile(_profile);
    }
  };

  const context = {
    name,
    profile,
    filterModel,
    setFilterModel,
    setName,
    loadProfile,
    getProfile,
    setProfile,
    resetProfile,
  };
  return <dataContext.Provider value={context}>{children}</dataContext.Provider>;
};

export const useDataContext = () => {
  const context = useContext(dataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within DataContextProvider');
  }
  return context;
};
