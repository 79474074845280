import { useTheme } from '@mui/material/styles';
import { useAppContext } from '../../context/AppContext';
import { AppIcon, ICON, ICON_SIZE } from '../../common/components/AppIcon';
import { DashboardTile } from './Common';
import { Box, Grid, Typography } from '@mui/material';
import AppTitle from '../../common/components/AppTitle';
import { RECORD_STATE } from '../../common/Constants';
import { styled } from '@mui/material/styles';
import { StatusItems } from '../../common/components/StatusSelector';
import { AppText } from '../../common/components/AppText';

const StyledStatusBox = styled(Box)(({ theme }) => ({
  transition: 'background-color .25s ease-in-out',
  minHeight: '45px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const RecordsStatusTile = (props) => {
  const { title, data, onSelect = () => {}, period } = props;
  const theme = useTheme();
  const { localization } = useAppContext();

  const handleSelect = (status) => {
    const option = StatusItems.find((e) => e.value === status);
    if (option) {
      onSelect({
        items: [
          {
            id: 1,
            field: 'trackingState',
            operator: 'isAnyOf',
            value: [{ text: option.text, trackingState: option.value }],
          },
          {
            id: 2,
            field: 'transactionDate',
            operator: 'between',
            value: [period.from, period.to],
          },
        ],
      });

      //onSelect({ text: option.text, trackingState: option.value });
    }
  };

  return (
    <DashboardTile sx={{ height: '125px' }}>
      <Grid container>
        <Grid item xs={12} sx={{ mx: 1, my: 1 }}>
          <AppTitle
            primary={title}
            secondary={`${localization.translate('dashboard.reported_period')} ${localization.display(period.from)} - ${localization.display(period.to)}`}
          />
        </Grid>
        {!data && (
          <Grid item xs={12} sx={{ mx: 1, my: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <AppText>{localization.translate('app.no_data')}</AppText>
          </Grid>
        )}
        {data && (
          <>
            <Grid item xs={12} sm={4} sx={{ px: 1 }}>
              <StyledStatusBox onClick={() => handleSelect(RECORD_STATE.NEW)}>
                <AppIcon
                  icon={ICON.AddFill}
                  size={ICON_SIZE.ExtraLarge}
                  tooltip={localization.translate('dashboard.new')}
                  style={{ color: theme.palette.status.new }}
                />
                {!!data.new?.records ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }}>
                    <AppText variant='xlarge' sx={{ color: theme.palette.status.new }}>
                      {localization.display(data.new?.records || 0)}
                    </AppText>
                    <AppText variant='normal' sx={{ color: `${theme.palette.status.new}${theme.palette.alphaStep}` }}>
                      {localization.displayCurrency(data.new?.amount || 0)}
                    </AppText>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }}>
                    <AppText variant='xlarge' sx={{ color: theme.palette.status.new }}>
                      -
                    </AppText>
                  </Box>
                )}
              </StyledStatusBox>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ px: 1 }}>
              <StyledStatusBox onClick={() => handleSelect(RECORD_STATE.APPROVED)}>
                <AppIcon
                  icon={ICON.ConfirmedFill}
                  size={ICON_SIZE.ExtraLarge}
                  tooltip={localization.translate('dashboard.approved')}
                  style={{ color: theme.palette.status.approved }}
                />
                {!!data.approved?.records ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }}>
                    <AppText variant='xlarge' sx={{ color: theme.palette.status.approved }}>
                      {localization.display(data.approved.records)}
                    </AppText>
                    <AppText sx={{ color: `${theme.palette.status.approved}${theme.palette.alphaStep}` }}>
                      {localization.displayCurrency(data.approved.amount)}
                    </AppText>
                  </Box>
                ) : (
                  <Box sx={{ mx: 2 }}>
                    <AppText variant='xlarge' sx={{ color: theme.palette.status.approved }}>
                      -
                    </AppText>
                  </Box>
                )}
              </StyledStatusBox>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ px: 1 }}>
              <StyledStatusBox onClick={() => handleSelect(RECORD_STATE.QUESTIONED)}>
                <AppIcon
                  icon={ICON.QuestionedFill}
                  size={ICON_SIZE.ExtraLarge}
                  tooltip={localization.translate('dashboard.questioned')}
                  style={{ color: theme.palette.status.questioned }}
                />
                {!!data.questioned?.records ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }}>
                    <AppText variant='xlarge' sx={{ color: theme.palette.status.questioned }}>
                      {localization.display(data.questioned?.records || 0)}
                    </AppText>
                    <AppText sx={{ color: `${theme.palette.status.questioned}${theme.palette.alphaStep}` }}>
                      {localization.displayCurrency(data.questioned?.amount || 0)}
                    </AppText>
                  </Box>
                ) : (
                  <Box sx={{ mx: 2, my: 1 }}>
                    <AppText variant='xlarge' sx={{ color: theme.palette.status.questioned }}>
                      -
                    </AppText>
                  </Box>
                )}
              </StyledStatusBox>
            </Grid>
          </>
        )}
      </Grid>
    </DashboardTile>
  );
};

export default RecordsStatusTile;
