import { Box, Divider, FormControlLabel, MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { REPORT_TYPE, ROUTE_PATHS, WORKING_STATE } from '../../common/Constants';
import { useHttp } from '../../hooks/authentication';
import { useAppContext } from '../../context/AppContext';
import { CAMPAIGN_TILE_DIMENSIONS, ControlledDashboardTile } from './Common';
import InnerCenteredContainer from '../../common/components/InnerCenteredContainer';
import InnerCenteredCircularProgress from '../../common/components/InnerCenteredCircularProgress';
import PercentChart from '../../common/components/charts/PercentChart';
import { useSetPathContext } from '../../context/PathContext';
import { AppBox } from '../../common/components/AppBox';
import { AppText } from '../../common/components/AppText';
import AmountChart from '../../common/components/charts/AmountChart';
import CampaignDonationsChart from './CampaignDonationsChart';
import CampaignExpensesChart from './CampaignExpensesChart';
import AppSwitch from '../../common/components/AppSwitch';
import CampaignChart from './CampaignChart';
import { AppDropdownMenuItem } from '../../common/components/AppDropdownMenu';
import { useDialogsContext } from '../../context/DialogsContext';
import { ICON } from '../../common/components/AppIcon';

const CHART_SIZE = 125;
const CONSOLIDATED_CHART_SIZE = 170;

const CampaignTile = (props) => {
  const { campaign, onRemove } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const { localization } = useAppContext();
  const setPath = useSetPathContext();
  const { getHttp } = useHttp();
  const dialogs = useDialogsContext();
  const [workingState, setWorkingState] = useState(WORKING_STATE.INPROGRESS);
  const [data, setData] = useState({});

  const [consolidated, setConsolidated] = useState(true);

  const loadData = async () => {
    if (campaign) {
      setWorkingState(WORKING_STATE.INPROGRESS);
      try {
        const response = await getHttp().get(`projects/${campaign.id}/statistics`);
        const stats = {
          goal: campaign.targetGoal || 0,
          percent: response.data.percentCompleted,
          amount: response.data.sum,
          last: response.data.last,
          count: response.data.count,
          expenseCount: response.data.countExpenses,
          expenseAmount: response.data.sumExpenses,
          expensePercent: response.data.sumExpenses <= 0 ? 0 : response.data.sum <= 0 ? -1 : (response.data.sumExpenses / response.data.sum) * 100,
        };

        setWorkingState(WORKING_STATE.SUCCESS);
        return stats;
      } catch (error) {
        console.error(error);
        setWorkingState(WORKING_STATE.ERROR);
        return {};
      }
    } else {
      setWorkingState(WORKING_STATE.ERROR);
      return {};
    }
  };

  const reloadData = () => {
    setData({}); // to force animation effect on reload
    (async () => {
      const stats = await loadData();
      setData(stats);
    })();
  };

  const handleNavigate = (path) => {
    const to = `/${path}`;
    setPath(to);
    navigate(to, {
      state: {
        items: [
          {
            id: 1,
            field: 'projectId',
            operator: 'isAnyOf',
            value: [{ projectId: campaign.id, projectName: campaign.name }],
          },
        ],
      },
    });
  };

  const openReportDialog = () => {
    dialogs.reportDialogRef.current.open({
      type: REPORT_TYPE.Campaign,
      campaign,
    });
  };

  useEffect(() => {
    (async () => {
      const stats = await loadData();
      setData(stats);
    })();
  }, [campaign.id]);

  // to keep animation effect
  useEffect(() => {
    (async () => {
      const stats = await loadData();
      setData(stats);
    })();
  }, [campaign, theme]);

  return (
    <ControlledDashboardTile
      title={campaign.name || ''}
      tooltip={`${campaign.name}. ${campaign.description || ''}`}
      sx={{ width: `${CAMPAIGN_TILE_DIMENSIONS.WIDTH}px`, height: `${CAMPAIGN_TILE_DIMENSIONS.HEIGHT}px` }}
      onSeeMore={handleNavigate}
      onReload={reloadData}
      onRemove={() => onRemove(campaign)}
      menuItems={[
        /*
        {
          renderMenuItem: (key, closeActions) => {
            return (
              <AppDropdownMenuItem
                key={key}
                icon={ICON.Report}
                text={localization.translate('reports.generate_report')}
                onClick={() => {
                  openReportDialog();
                  closeActions();
                }}
              />
            );
          },
        },
      */
        {
          renderMenuItem: (key) => {
            return <Divider key={key} variant='middle' flexItem />;
          },
        },
        {
          renderMenuItem: (key, closeActions) => {
            return (
              <MenuItem key={key}>
                <FormControlLabel
                  control={
                    <AppSwitch
                      checked={consolidated}
                      onChange={(e) => {
                        setConsolidated(!consolidated);
                        closeActions();
                      }}
                    />
                  }
                  label={localization.translate('dashboard.consolidated_view')}
                  sx={{ ml: '0px', mr: 1 }}
                  componentsProps={{
                    typography: {
                      variant: 'normal',
                      mx: 1,
                    },
                  }}
                />
              </MenuItem>
            );
          },
        },
      ]}
    >
      {workingState === WORKING_STATE.INPROGRESS && <InnerCenteredCircularProgress />}
      {workingState === WORKING_STATE.ERROR && (
        <InnerCenteredContainer>
          <AppText variant='small'>{localization.translate('app.no_data')}</AppText>
        </InnerCenteredContainer>
      )}
      {(workingState === WORKING_STATE.SUCCESS || workingState === WORKING_STATE.IDLE) && (
        <AppBox flex column centery sx={{ width: '100%' }}>
          <AppBox flex centery>
            <Tooltip title={localization.translate('dashboard.campaign_goal')} placement='bottom-start'>
              <AppText variant='smallb' sx={{ ml: 1, cursor: 'default' }}>
                {localization.displayCurrency(campaign?.targetGoal || '-')}
              </AppText>
            </Tooltip>
          </AppBox>
          <AppBox flex centery space='around' sx={{ width: '100%', height: '100%', ml: 1 }}>
            {!!consolidated ? (
              <CampaignChart data={data} campaign={campaign} size={CONSOLIDATED_CHART_SIZE} />
            ) : (
              <>
                <CampaignDonationsChart data={data} campaign={campaign} size={CHART_SIZE} />
                <CampaignExpensesChart data={data} campaign={campaign} size={CHART_SIZE} />
              </>
            )}
          </AppBox>
        </AppBox>
      )}
    </ControlledDashboardTile>
  );
};

export default CampaignTile;
