import { Autocomplete } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { CURRENCY } from '../Constants';
import { AppText, AppTextField, StyledTextField } from '../components/AppText';
import { useAppContext } from '../../context/AppContext';
import { useTheme } from '@emotion/react';

const CurrencySelector = (props) => {
  const { required, selectedCode, onSelected, label, addNoCurrency } = props;
  const theme = useTheme();
  const { localization, tenant } = useAppContext();
  const [value, setValue] = useState(null);

  const currencyOptions = useMemo(() => {
    const options = [...CURRENCY];
    if (addNoCurrency) {
      options.unshift({
        code: null,
        symbol: '',
        name: '',
      });
    }
    return options;
  }, [CURRENCY, addNoCurrency]);

  useEffect(() => {
    let codeToSelect = null;
    if (!addNoCurrency) {
      codeToSelect = selectedCode || tenant.getCurrency();
    } else {
      codeToSelect = selectedCode || null;
    }
    const selectedValue = currencyOptions.find((c) => c.code === codeToSelect);
    setValue(selectedValue);
  }, [selectedCode, addNoCurrency]);

  const handleSelected = (event, newValue) => {
    if (newValue) {
      setValue(newValue);
      if (onSelected) {
        onSelected(newValue);
      }
    }
  };

  return (
    <Autocomplete
      options={currencyOptions}
      size='small'
      multiple={false}
      value={value}
      autoHighlight
      fullWidth
      disableListWrap
      disableClearable
      onChange={handleSelected}
      renderInput={(params) => {
        return (
          <AppTextField
            {...params}
            {...(required ? { required: true, error: !params.inputProps.value || !params.inputProps.value.trim() } : {})}
            label={label || localization.translate('app.currency')}
          ></AppTextField>
        );
      }}
      isOptionEqualToValue={(option, value) => {
        return option.code === value.code;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.code}>
            {option.code ? (
              <AppText>{`${option.symbol} ${option.name}`}</AppText>
            ) : (
              <span style={{ color: theme.palette.text.disabled }}>{localization.translate('app.no_currency')}</span>
            )}
          </li>
        );
      }}
      getOptionLabel={(option) => {
        return `${option.symbol} ${option.name}`;
      }}
    />
  );
};

export default CurrencySelector;
