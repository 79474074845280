import { useAppContext } from '../../../../context/AppContext';
import { useDialogsContext } from '../../../../context/DialogsContext';
import { AppIcon, ICON } from '../../AppIcon';
import { GridToolbarItem } from './GridToolbarItem';

export const DeleteItem = (props) => {
  const { variant, text, tooltip, onDelete, confirmationTitle, confirmationMessage, onActivated, isDisabled, apiRef } = props;
  const { localization } = useAppContext();
  const dialogs = useDialogsContext();
  //const apiRef = useGridApiContext();
  const selectedRows = apiRef.current.getSelectedRows();

  const onClick = (e) => {
    if (onActivated) {
      onActivated(Array.from(selectedRows.values()));
    } else {
      handleOpenDialog(e);
    }
  };

  const handleOpenDialog = (event) => {
    dialogs.deleteConfirmationDialogRef.current.open({
      onOk: handleOk,
      title: confirmationTitle,
      message: confirmationMessage,
      advancedDelete: !!Array.from(selectedRows.values()).find((r) => !!r && r.childrenCount > 0),
    });
  };

  const handleOk = async (keepChildren) => {
    if (onDelete) {
      await onDelete(Array.from(selectedRows.values()), keepChildren);
    }
    dialogs.deleteConfirmationDialogRef.current.close();
  };

  return (
    <>
      <GridToolbarItem
        variant={variant}
        aria-describedby='open-delete-confirm-dialog'
        onClick={onClick}
        startIcon={<AppIcon icon={ICON.Delete} />}
        disabled={isDisabled !== undefined ? isDisabled : selectedRows.size <= 0}
        text={text || localization.translate('common.delete')}
      />
    </>
  );
};

export const DeleteCampaignsItem = (props) => {
  const { variant, text, onActivated, apiRef } = props;
  const { localization } = useAppContext();
  const selectedRows = apiRef.current.getSelectedRows();
  const onClick = (e) => {
    if (onActivated) {
      onActivated(Array.from(selectedRows.values()));
    }
  };
  return (
    <>
      <GridToolbarItem
        variant={variant}
        aria-describedby='open-delete-confirm-dialog'
        onClick={onClick}
        startIcon={<AppIcon icon={ICON.Delete} />}
        disabled={selectedRows.size !== 1}
        text={text || localization.translate('common.delete')}
      />
    </>
  );
};
