import { Grid } from '@mui/material';
import { forwardRef, useState } from 'react';
import { AppBox } from '../../common/components/AppBox';
import { AppText } from '../../common/components/AppText';
import { useAppContext } from '../../context/AppContext';
import { useHttp } from '../../hooks/authentication';
import { useEffect } from 'react';
import CampaignChart from '../dashboard/CampaignChart';

const CampaignReport = forwardRef((props, ref) => {
  const { config } = props;
  const { getHttp } = useHttp();
  const { localization } = useAppContext();
  const [data, setData] = useState();
  const [stats, setStats] = useState({});

  const load = async () => {
    try {
      const response = await getHttp().get(`projects/${config.campaign.id}/statistics`);
      const locale = localization.getLocale(config.language);

      setStats({
        goal: config.campaign?.targetGoal || 0,
        percent: response.data.percentCompleted,
        amount: response.data.sum,
        last: response.data.last,
        count: response.data.count,
        expenseCount: response.data.countExpenses,
        expenseAmount: response.data.sumExpenses,
        expensePercent: response.data.sumExpenses <= 0 ? 0 : response.data.sum <= 0 ? -1 : (response.data.sumExpenses / response.data.sum) * 100,
      });

      setData({
        donations: {
          percentOfGoal: !response.data.percentCompleted ? '-' : `${response.data.percentCompleted.toFixed(1)}%`,
          sum: localization.displayCurrency(response.data.sum || 0, null, {}, locale),
          count: localization.display(response.data.count || 0, {}, locale),
        },
        expenses: {
          ...(config.campaign.targetGoal && {
            percentOfGoal: response.data.sumExpenses <= 0 ? '-' : `${((response.data.sumExpenses / config.campaign.targetGoal) * 100).toFixed(1)}%`,
          }),
          ...(response.data.sum > 0 && {
            percentOfDonations: response.data.sumExpenses <= 0 ? '-' : `${((response.data.sumExpenses / response.data.sum) * 100).toFixed(1)}%`,
          }),
          sum: localization.displayCurrency(response.data.sumExpenses || 0, null, {}, locale),
          count: localization.display(response.data.countExpenses || 0, {}, locale),
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    (async () => {
      await load();
    })();
  }, [config]);

  return data ? (
    <AppBox flex column sx={{ width: '100%' }}>
      <AppBox flex column center>
        <AppText variant='normalb'>{config.title}</AppText>
        <AppText variant='small'>Report date: {new Date().toDateString()}</AppText>
      </AppBox>
      <AppText sx={{ mt: 1 }} variant='smallb'>
        Campaign information
      </AppText>
      <Grid container sx={{ mx: 2 }}>
        <Grid item container xs={12}>
          <Grid item xs={3}>
            <AppText>{config.translate('common.description')}</AppText>
          </Grid>
          <Grid item xs>
            <AppText>{config.campaign.description}</AppText>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3}>
            <AppText>{config.translate('campaigns.goal')}</AppText>
          </Grid>
          <Grid item xs>
            <AppText>{localization.displayCurrency(config.campaign?.targetGoal || '-')}</AppText>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={3}>
            <AppText>{config.translate('campaigns.commission')}</AppText>
          </Grid>
          <Grid item xs>
            <AppText>{config.campaign?.commission !== undefined && config.campaign?.commission !== null ? `${config.campaign?.commission}%` : '-'}</AppText>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={6}>
          <AppText sx={{ mt: 1 }} variant='smallb'>
            {config.translate('app.donations')}
          </AppText>
        </Grid>
        <Grid item xs={6}>
          <AppText sx={{ mt: 1 }} variant='smallb'>
            {config.translate('app.expenses')}
          </AppText>
        </Grid>
      </Grid>

      <Grid container sx={{ mx: 2 }}>
        <Grid item container xs={6}>
          <Grid item xs={6}>
            <AppText>{config.translate('app.sum')}</AppText>
          </Grid>
          <Grid item xs={6}>
            <AppText>{localization.displayCurrency(data.donations?.sum || '-')}</AppText>
          </Grid>
          <Grid item xs={6}>
            <AppText>{config.translate('dashboard.number_of_donations')}</AppText>
          </Grid>
          <Grid item xs={6}>
            <AppText>{localization.display(data.donations?.count || '-')}</AppText>
          </Grid>
          {config.campaign?.targetGoal && (
            <>
              <Grid item xs={6}>
                <AppText>{config.translate('dashboard.percent_of_goal')}</AppText>
              </Grid>
              <Grid item xs={6}>
                <AppText>{data.donations?.percentOfGoal}</AppText>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={6}>
            <AppText>{config.translate('app.sum')}</AppText>
          </Grid>
          <Grid item xs={6}>
            <AppText>{localization.displayCurrency(data.expenses?.sum || '-')}</AppText>
          </Grid>
          {data.expenses?.percentOfGoal && (
            <>
              <Grid item xs={6}>
                <AppText>{config.translate('dashboard.percent_of_goal')}</AppText>
              </Grid>
              <Grid item xs={6}>
                <AppText>{data.expenses?.percentOfGoal}</AppText>
              </Grid>
            </>
          )}
          {data.expenses?.percentOfDonations && (
            <>
              <Grid item xs={6}>
                <AppText>{config.translate('dashboard.percent_of_donations')}</AppText>
              </Grid>
              <Grid item xs={6}>
                <AppText>{data.expenses?.percentOfDonations}</AppText>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <AppBox flex center sx={{ width: '350px', height: '350px' }}>
            <CampaignChart data={stats} campaign={config.campaign} />
          </AppBox>
        </Grid>
      </Grid>
    </AppBox>
  ) : (
    <></>
  );
});

export default CampaignReport;
