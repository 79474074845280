import { useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { getGridDateOperators, getGridNumericOperators, getGridStringOperators } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import SyncIcon from '@mui/icons-material/Sync';
import { useAppContext } from '../../../context/AppContext';
import { AppNumericField } from '../AppText';
import { AppDatePicker } from '../AppDatePicker';
import { getUtcDateInterpretation, isNullOrEmpty } from '../../../utils/utils';

// ------------------------------- TEXT FILTERS ---------------------------------
export const getTextFilters = (props) => {
  const operators = getGridStringOperators();
  return operators.map((i, index) => {
    return {
      ...i,
      ...(i.value === 'isAnyOf' && {
        InputComponentProps: {
          size: 'small',
          sx: { width: '200px' },
        },
      }),
    };
  });
};

// ------------------------------- DATE FILTERS ---------------------------------

const getApplyFilterFnForDate = (filterItem) => {
  if (!filterItem.value) {
    return null;
  }

  return ({ value }) => {
    if (value === null) {
      return false;
    }
    const filterTime = new Date(filterItem.value.setHours(0, 0, 0, 0)).getTime();
    const valueTime = new Date(value.setHours(0, 0, 0, 0)).getTime();

    switch (filterItem.operatorValue) {
      case 'is':
        return filterTime === valueTime;
      case 'not':
        return filterTime !== valueTime;
      case 'after':
        return valueTime > filterTime;
      case 'onOrAfter':
        return valueTime >= filterTime;
      case 'before':
        return valueTime < filterTime;
      case 'onOrBefore':
        return valueTime <= filterTime;
      default:
        return false;
    }
  };
};

const DateRangeFilter = (props) => {
  const { item, applyValue, focusElementRef = null } = props;
  const [filterValueState, setFilterValueState] = useState(item.value ?? [null, null]);
  const { localization } = useAppContext();
  useEffect(() => {
    const itemValue = item.value ?? [null, null];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound, upperBound) => {
    setFilterValueState([lowerBound, upperBound]);
    applyValue({ ...item, value: [lowerBound, upperBound] });
  };

  const handleUpperFilterChange = (newUpperBound) => {
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (newLowerBound) => {
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
      }}
    >
      <AppDatePicker
        label={localization.translate('app.from_date')}
        value={filterValueState[0]}
        onChange={handleLowerFilterChange}
        inputTextProps={{
          sx: { width: 160 },
        }}
        inputSx={{ width: 160 }}
      />
      <AppDatePicker
        label={localization.translate('app.to_date')}
        value={filterValueState[1]}
        onChange={handleUpperFilterChange}
        inputTextProps={{
          sx: { mx: 2, width: 160 },
        }}
      />
    </Box>
  );
};

const DateFilter = (props) => {
  const { item, applyValue, focusElementRef = null } = props;
  const [value, setValue] = useState(item.value ?? null);
  useEffect(() => {
    const itemValue = item.value ?? null;
    setValue(itemValue);
  }, [item.value]);

  const handleUpdateValue = (newValue) => {
    //console.log(`Year: ${newValue.getFullYear()} month: ${newValue.getMonth()} day: ${newValue.getDate()}`);
    setValue(newValue);
    applyValue({ ...item, value: newValue });
  };

  return <AppDatePicker value={value} onChange={handleUpdateValue} inputSx={{ width: 160 }} />;
};

const getDateInRangeFilterOperator = (props) => {
  return {
    label: 'Between',
    value: 'between',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }
      return ({ value }) => {
        return value != null && filterItem.value[0] <= value && value <= filterItem.value[1];
      };
    },
    InputComponent: DateRangeFilter,
  };
};

const getDateFilters = (props) => {
  const operators = getGridDateOperators();
  operators.unshift(getDateInRangeFilterOperator());

  return operators.map((i, index) => {
    if (i.value !== 'isEmpty' && i.value !== 'isNotEmpty' && i.value !== 'between') {
      return {
        ...i,
        InputComponent: DateFilter,
        getApplyFilterFn: getApplyFilterFnForDate,
      };
    }
    return i;
  });
};

// ----------------- NUMERIC FILTERS -----------------------------------------

const SUBMIT_FILTER_STROKE_TIME = 500;

const validateNumericInput = (value) => {
  return /^\d*\d*$/.test(value);
};

const NumericRangeFilter = (props) => {
  const { item, applyValue, focusElementRef = null } = props;
  const { localization } = useAppContext();
  const filterTimeout = useRef();
  const [filterValueState, setFilterValueState] = useState(item.value ?? ['', '']);

  const [applying, setIsApplying] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);
  useEffect(() => {
    const itemValue = item.value ?? ['', ''];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound, upperBound) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, SUBMIT_FILTER_STROKE_TIME);
  };

  const handleUpperFilterChange = (value) => {
    updateFilterValue(filterValueState[0], value);
  };
  const handleLowerFilterChange = (value) => {
    updateFilterValue(value, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
      }}
    >
      <AppNumericField
        name='lower-bound-input'
        placeholder={localization.translate('app.from')}
        label={localization.translate('app.from')}
        value={filterValueState[0]}
        onChange={handleLowerFilterChange}
        inputRef={focusElementRef}
        sx={{ mr: 2, width: 150 }}
      />
      <AppNumericField
        name='upper-bound-input'
        placeholder={localization.translate('app.to')}
        label={localization.translate('app.to')}
        value={filterValueState[1]}
        onChange={handleUpperFilterChange}
        sx={{ mr: 1, width: 150 }}
        InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
      />
    </Box>
  );
};

const NumericFilter = (props) => {
  const { item, applyValue, focusElementRef = null } = props;
  const [value, setValue] = useState(item.value ?? '');
  const filterTimeout = useRef();
  const [applying, setIsApplying] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item.value ?? '';
    setValue(itemValue);
  }, [item.value]);

  const handleUpdateValue = (value) => {
    clearTimeout(filterTimeout.current);
    setValue(value);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: value });
    }, SUBMIT_FILTER_STROKE_TIME);
  };
  return (
    <AppNumericField
      onChange={handleUpdateValue}
      value={value}
      placeholder='Value'
      label='Value'
      sx={{ width: 150 }}
      InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
    />
  );
};

const getNumberInRangeFilterOperator = (props) => {
  return {
    label: 'Between',
    value: 'between',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }
      return ({ value }) => {
        return value != null && filterItem.value[0] <= value && value <= filterItem.value[1];
      };
    },
    InputComponent: NumericRangeFilter,
  };
};

const getNumericFilters = (props) => {
  const operators = getGridNumericOperators().filter((e) => e.value !== 'isAnyOf' && e.value !== '!=');
  operators.unshift(getNumberInRangeFilterOperator());
  return operators.map((i, index) => {
    if (i.value !== 'isEmpty' && i.value !== 'isNotEmpty' && i.value !== 'between') {
      return {
        ...i,
        InputComponent: NumericFilter,
      };
    }
    return i;
  });
};

// ----------------------------- SELECT FILTERS --------------------------------------

const IsAnyOfFilter = (props) => {
  const {
    item,
    valuePropName,
    labelPropName,
    options,
    applyValue,
    renderOption,
    renderInput,
    optionToValueComparer,
    label = '',
    width = 200,
    size = 'small',
    open,
    apiRef,
    focusElementRef,
    ...rest
  } = props;

  const getValue = (item, valuePropName) => {
    if (!item?.value || !Array.isArray(item.value) || item.value.length <= 0) {
      return [];
    }
    return Object.getOwnPropertyNames(item.value[0]).includes(valuePropName) ? item.value : [];
  };

  return (
    <Autocomplete
      {...(open !== undefined ? { open: open } : {})}
      size={size}
      multiple
      value={getValue(item, valuePropName)}
      onChange={(e, v) => applyValue({ ...item, value: v })}
      options={options}
      getOptionLabel={(option) => {
        return option ? option[labelPropName] || '' : '';
      }}
      isOptionEqualToValue={(option, value) => {
        return optionToValueComparer ? optionToValueComparer(option, value) : option[valuePropName] === value[valuePropName];
      }}
      autoHighlight
      fullWidth
      disableClearable
      renderOption={(props, option) => {
        return renderOption ? (
          renderOption(props, option)
        ) : (
          <li {...props} key={option[valuePropName]}>
            {option[labelPropName]}
          </li>
        );
      }}
      disableListWrap
      renderInput={(params) => {
        return renderInput ? renderInput(params) : <TextField {...params} label={label} variant='outlined' size={size} sx={{ width: width }} />;
      }}
      {...rest}
    />
  );
};

const getIsAnyOfSelectableFilterOperator = (props) => {
  const { options, alternateValues, valuePropName, labelPropName, label, filterLabel, inputComponent, ...rest } = props;
  return {
    label: filterLabel || 'is any of',
    value: 'isAnyOf',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value || filterItem.value.length === 0) {
        return true;
      }
      if (!filterItem?.columnField || !filterItem?.operatorValue) {
        return null;
      }

      return (params) => {
        return filterItem.value.map((i) => i[valuePropName]).includes(params.value[valuePropName]);
      };
    },
    InputComponent: inputComponent || IsAnyOfFilter,
    InputComponentProps: { ...rest, options, valuePropName, labelPropName, label: label },
  };
};

const getIsEmptySelectableFilterOperator = (props) => {
  const { selectValueKey } = props;
  return {
    label: 'is empty',
    value: 'isEmpty',
    getApplyFilterFn: (filterItem) => {
      return (params) => {
        return !params.value[selectValueKey];
      };
    },
  };
};

const getIsNotEmptySelectableFilterOperator = (props) => {
  const { selectValueKey } = props;
  return {
    label: 'is not empty',
    value: 'isNotEmpty',
    getApplyFilterFn: (filterItem) => {
      return (params) => {
        return params.value[selectValueKey];
      };
    },
  };
};

const getAlternateValueSelectableFilterOperator = (props) => {
  const { value, label } = props;
  return {
    label: label,
    value: `is#${value}`,
    requiresFilterValue: false,
    getApplyFilterFn: (filterItem) => {
      return (params) => {
        return params.value === value;
      };
    },
  };
};

const getAlternateValueFilters = (props) => {
  const { values } = props;
  return values.map((v, i) => {
    return { ...getAlternateValueSelectableFilterOperator({ value: v.value, label: v.label }) };
  });
};

const getSelectFilters = (props) => {
  return [
    {
      ...getIsAnyOfSelectableFilterOperator(props),
    },
    {
      ...getIsEmptySelectableFilterOperator(props),
    },
    {
      ...getIsNotEmptySelectableFilterOperator(props),
    },
  ];
};

const getEnumSelectFilters = (props) => {
  return [
    {
      ...getIsAnyOfSelectableFilterOperator(props),
    },
  ];
};

// --------------- SPECIAL FILTERS - PAYMENT SOURCE ----

const getPaymentSourceTextFilters = (props) => {
  const operators = getTextFilters(props).filter((e) => e.value === 'contains');
  return operators.map((o, i) => {
    return {
      ...o,
      label: `name ${o.value.toLowerCase()}`,
      value: `displayName.${o.value}`,
    };
  });
};
const getPaymentSourceProviderFilters = (props) => {
  const operator = getIsAnyOfSelectableFilterOperator({
    ...props,
    filterLabel: 'provider',
  });
  return [{ ...operator, value: `provider.${operator.value}` }];
};
const getPaymentSourceFilters = (props) => {
  return [...getPaymentSourceTextFilters(props), ...getPaymentSourceProviderFilters(props)];
};

//---------------- SPECIAL FILTERS - INVOICE -----------

const getReceiptNumberFilters = (props) => {
  const operators = getGridNumericOperators().filter((e) => e.value !== 'isAnyOf' && e.value !== 'isEmpty' && e.value !== 'isNotEmpty' && e.value !== '!=');
  operators.unshift(getNumberInRangeFilterOperator());

  return operators.map((i, index) => {
    if (i.value !== 'isAnyOf' && i.value !== 'between') {
      return {
        ...i,
        label: `number ${i.label?.toLowerCase() || i.value}`,
        value: `number.${i.value}`,
        InputComponent: NumericFilter,
      };
    }
    return {
      ...i,
      label: `number ${i.value === 'isAnyOf' ? 'is any of' : i.label ? i.label.toLowerCase() : i.value}`,
      value: `number.${i.value}`,
      InputComponentProps: {
        size: 'small',
        sx: { width: '200px' },
      },
    };
  });
};

const getReceiptStatusFilters = (props) => {
  const operator = getIsAnyOfSelectableFilterOperator({
    ...props,
    filterLabel: 'status',
  });
  return [{ ...operator, value: `state.${operator.value}` }];
};

const getReceiptSentFilters = (props) => {
  const { alternateValues } = props;
  const filters = getAlternateValueFilters({
    values: [...alternateValues],
  });

  return filters.map((f, i) => {
    return { ...f, value: `mailState.${f.value}` };
  });
};

const getReceiptFilters = (props) => {
  return [...getReceiptNumberFilters(props), ...getReceiptStatusFilters(props), ...getReceiptSentFilters(props)];
};
// --------------- END SPECIAL FILTERS - INVOICE -----------

const normalizeSpecialFilters = (filters) => {
  if (!filters) {
    return filters;
  }
  return filters.map((filter, i) => {
    if (!filter.operatorValue) {
      return filter;
    }
    const res = { ...filter };

    // Generally, the operatorValue might come in form of <column subfield>.<operator value>#<filter value>
    // Examples:
    // 1. Receipt status filter: <column subfield>.<operator value> - "state.isAnyOf"
    // 2. Creation mode filter: <operator value>#<filter value> - "is#manual"
    // 3. Receipt sent status filter: <column subfield>.<operator value>#<filter value> - "mailState.is#sent"

    let template = filter.operatorValue;
    let parts = template.split('.');
    let operatorValue = null;
    let columnField = null;
    let value = null;

    if (parts.length > 1) {
      template = parts[1];
      columnField = `${filter.columnField}.${parts[0]}`;
      operatorValue = parts[1];
    }

    parts = template.split('#');
    if (parts.length > 1) {
      operatorValue = parts[0];
      value = parts[1];
    }
    if (!!operatorValue) {
      res.operatorValue = operatorValue;
    }
    if (!!columnField) {
      res.columnField = columnField;
    }
    if (!!value) {
      res.value = value;
    }
    return res;
  });
};

const normalizeFilterItems = (items) => {
  const filters = normalizeSpecialFilters(
    items.map((next) => {
      if (!next.value || !Array.isArray(next.value)) {
        return {
          operatorValue: next.operator,
          columnField: next.field === '__tree_data_group__' ? 'amount' : next.field,
          value: next.value && next.value instanceof Date ? getUtcDateInterpretation(next.value, true, true) : next.value,
        };
      }
      return {
        operatorValue: next.operator,
        columnField: next.field === '__tree_data_group__' ? 'amount' : next.field,
        value: next.value.map((v) => {
          if (v && v instanceof Date) {
            return getUtcDateInterpretation(v, true, true);
          }
          if (v && typeof v === 'object') {
            return v[next.field];
          }
          return v;
        }),
      };
    })
  ).filter((filter) => {
    // undefined value is allowed and only applicable only for isEmpty and isNotEmpty filters
    if (filter.operatorValue === 'isEmpty' || filter.operatorValue === 'isNotEmpty') {
      return filter.value === undefined;
    }
    return !isNullOrEmpty(filter.value);
    //return filter.operatorValue !== 'isEmpty' && filter.operatorValue !== 'isNotEmpty' ? filter.value !== undefined : true;
  });
  return filters.length <= 0 ? null : filters;
};

const normalizeQuickFilter = (filter) => {
  if (!filter) {
    return filter;
  }
  return encodeURIComponent(filter.join(' '));
};
const normalizeFilter = (filterModel) => {
  return {
    filterItems: normalizeFilterItems(filterModel?.items),
    quickFilter: normalizeQuickFilter(filterModel.quickFilterValues),
  };
};

export {
  normalizeFilter,
  normalizeFilterItems,
  normalizeQuickFilter,
  getDateFilters,
  getNumericFilters,
  getSelectFilters,
  getEnumSelectFilters,
  getReceiptFilters,
  getAlternateValueFilters,
  getPaymentSourceFilters,
};
