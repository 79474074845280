export const ROUTE_PATHS = {
  Signin: 'signin',
  Signup: 'signup',
  Dashboard: 'dashboard',
  Campaigns: 'campaigns',
  Donations: 'donations',
  Expenses: 'expenses',
  TenantConfig: 'tenantconfig',
  UserConfig: 'userconfig',
};

export const USER_ROLES = {
  Admin: {
    name: 'admin',
    value: 100,
  },
  User: {
    name: 'user',
    value: 1,
  },
};

export const PERIOD = {
  WEEK: 1,
  THREE_WEEKS: 2,
  ONE_MONTH: 11,
  THREE_MONTHS: 12,
  SIX_MONTHS: 13,
  YEAR: 21,
  CURRENT_YEAR: 99,
};

export const PAYMENT_METHODS = [
  {
    code: 'banktransfer',
    name: 'Bank Transfer',
  },
  {
    code: 'bit',
    name: 'Bit',
  },
  {
    code: 'paypal',
    name: 'PayPal',
  },
  {
    code: 'paybox',
    name: 'PayBox',
  },
  {
    code: 'creditcard',
    name: 'Credit or Debit Card',
  },
  {
    code: 'cash',
    name: 'Cash',
  },
  {
    code: 'check',
    name: 'Cheque',
  },
  {
    code: 'inkind',
    name: 'Monetary equivalent',
  },
  {
    code: 'interest',
    name: 'Investment',
  },
];

export const PAYMENT_PROVIDERS = {
  PAYPAL: 'paypal',
  ISRAEL_GIVES: 'israelgives',
};

export const RECEIPT_PROVIDER_TYPES = {
  GreenInvoice: 'greeninvoice',
  EasyCount: 'ezcount',
};

export const RECEIPT_PROVIDERS = [
  {
    provider: RECEIPT_PROVIDER_TYPES.GreenInvoice,
    name: 'Morning (GreenInvoice)',
    icon: 'GreenInvoice',
  },

  {
    provider: RECEIPT_PROVIDER_TYPES.EasyCount,
    name: 'EasyCount',
    icon: 'EasyCount',
  },
];

export const REPORT_TYPE = {
  Campaign: 'campaign',
  Period: 'period',
};

// https://en.wikipedia.org/wiki/ISO_4217
// https://www.eurochange.co.uk/travel/tips/world-currency-abbreviations-symbols-and-codes-travel-money

export const CURRENCY = [
  {
    code: 'ILS',
    symbol: '₪',
    name: 'Israel Shekel',
  },
  {
    code: 'USD',
    symbol: '$',
    name: 'United States Dollar',
  },
  {
    code: 'EUR',
    symbol: '€',
    name: 'Euro',
  },
  {
    code: 'RUB',
    symbol: '₽',
    name: 'Russian Ruble',
  },
  {
    code: 'GBP',
    symbol: '£',
    name: 'United Kingdom Pound',
  },
  {
    code: 'CHF',
    symbol: 'CHF',
    name: 'Switzerland Franc',
  },
  {
    code: 'CAD',
    symbol: '$',
    name: 'Canadian Dollar',
  },
  {
    code: 'AUD',
    symbol: '$',
    name: 'Australian Dollar',
  },
  {
    code: 'CNY',
    symbol: '¥',
    name: 'China Yuan Renminbi',
  },
  {
    code: 'JPY',
    symbol: '¥',
    name: 'Japan Yen',
  },
  {
    code: 'SEK',
    symbol: 'kr',
    name: 'Sweden Krona',
  },
  {
    code: 'KRW',
    symbol: '₩',
    name: 'South Korean Won',
  },
  {
    code: 'SGD',
    symbol: '$',
    name: 'Singapore Dollar',
  },
  {
    code: 'AED',
    symbol: 'د.إ',
    name: 'UAE Dirham',
  },
  {
    code: 'CZK',
    symbol: 'Kč',
    name: 'Czech Koruna',
  },
  {
    code: 'UAH',
    symbol: '₴',
    name: 'Ukranian Hryvnia',
  },
  {
    code: 'NZD',
    symbol: '$',
    name: 'New Zealand Dollar',
  },
  {
    code: 'HKD',
    symbol: '$',
    name: 'Hong Kong Dollar',
  },
];

export const LANGUAGE_CODE = {
  ENGLISH: 'en',
  HEBREW: 'he',
  RUSSIAN: 'ru',
};

export const LOCALES = {
  EN_US: 'en-US',
  RU_RU: 'ru-RU',
  HE_IL: 'he-IL',
};

export const COLOR_MODES = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const LANGUAGE_DIRECTION = {
  LTR: 'ltr',
  RTL: 'rtl',
};

export const SupportedLanguages = [
  {
    name: 'English',
    code: LANGUAGE_CODE.ENGLISH,
    locale: LOCALES.EN_US,
    country: 'gb',
  },
  {
    name: 'Русский',
    code: LANGUAGE_CODE.RUSSIAN,
    locale: LOCALES.RU_RU,
    country: 'ru',
  },
  {
    name: 'עברית',
    code: LANGUAGE_CODE.HEBREW,
    locale: LOCALES.HE_IL,
    country: 'il',
  },
];

export const WORKING_STATE = {
  NOTSTARTED: 1,
  IDLE: 2,
  INPROGRESS: 3,
  SUCCESS: 4,
  DONE: 5,
  DONE_NO_DATA: 6,
  ERROR: 10,
  DONE_DATA_ERROR: 11,
};

export const NOTIFICATION_SEVIRITY = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const DATA_LOAD_STATE = {
  NOTSTARTED: 1,
  INPROGRESS: 2,
  SUCCESS: 3,
  ERROR: 4,
  NODATA: 5,
};

export const RECORD_STATE = {
  NEW: 'new',
  APPROVED: 'approved',
  QUESTIONED: 'questioned',
};

export const RECORD_CREATION_MODE = {
  IMPORTED: 'imported',
  MANUAL: 'manual',
};

export const TASK_STATE = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

export const TASK_VIEWSTATE = {
  NEW: 'new',
  READ: 'read',
  ACKNOWLEDGED: 'acknowledged',
};

export const TASK_TYPE = {
  IMPORT_DONATIONS: 'ImportTransactions',
  IMPORT_EXPENSES: 'ImportExpenses',
  CREATE_INVOICE: 'CreateInvoice',
  LINK_INVOICE: 'LinkInvoice',
  RECEIPT_BY_EMAIL: 'MailReceipt',
};

export const INVOICE_STATE = {
  absent: 'absent',
  failed: 'failed',
  inprogress: 'inprogress',
  ready: 'ready',
  notrequired: 'notrequired',
  invalid: 'invalid',
};

export const CAMPAIGN_STATE = {
  active: 'Active',
  inactive: 'Inactive',
};

export const ELEMENT_POSITION = {
  OFF: 1,
  SIDE: 2,
  BOTTOM: 3,
};

export const LIGHT_COLOR_SCHEMES = [
  {
    name: 'Indigo',
    scheme: {
      primary: {
        main: '#345E9E',
        light: '#3864A8',
        dark: '#274575',
      },
      layout: {
        main: '#345E9E',
        light: '#3864A8',
        dark: '#274575',
        header: '#345E9E',
        header2: '#F5F5F5',
        control: '#345E9E',
        text: {
          main: '#A7B1C2',
          highlight: '#DCE9FF',
          darkened: '#7D8591',
          title: '#345E9E',
        },
      },
    },
  },
  {
    name: 'Hoki',
    scheme: {
      primary: {
        main: '#404E5F',
        light: '#465B72',
        dark: '#394654',
      },
      layout: {
        main: '#404E5F',
        light: '#465B72',
        dark: '#394654',
        header: '#404E5F',
        header2: '#F5F5F5',
        text: {
          main: '#A7B1C2',
          highlight: '#DCE9FF',
          darkened: '#7D8591',
          title: '#404E5F',
        },
      },
    },
  },
  {
    name: 'Wild Blue Yonder',
    scheme: {
      primary: {
        main: '#4E4F79',
        light: '#4A6389',
        dark: '#45476D',
      },
      layout: {
        main: '#4E4F79',
        light: '#4A6389',
        dark: '#45476D',
        header: '#4E4F79',
        header2: '#F5F5F5',
        text: {
          main: '#A7B1C2',
          highlight: '#DCE9FF',
          darkened: '#7D8591',
          title: '#4E4F79',
        },
      },
    },
  },
  {
    name: 'Tradewind',
    scheme: {
      primary: {
        main: '#2B4D82',
        light: '#3864A8',
        dark: '#274575',
      },
      layout: {
        main: '#2B4D82',
        light: '#3864A8',
        dark: '#274575',
        header: '#2B4D82',
        header2: '#F5F5F5',
        text: {
          main: '#A7B1C2',
          highlight: '#DCE9FF',
          darkened: '#7D8591',
          title: '#2B4D82',
        },
      },
    },
  },
];

export const DARK_COLOR_SCHEMES = [
  {
    name: 'Mono',
    scheme: {
      primary: {
        main: '#A7B1C2',
        light: '#BBC6D9',
        dark: '#79808C',
      },
      layout: {
        main: '#1A2030',
        light: '#e3f2fd',
        dark: '#3F4451',
        header: '#32363B',
        header2: '#1C1C1C',
        control: '#0A95FF',
        text: {
          main: '#A7B1C2',
          highlight: '#DCE9FF',
          darkened: '#7D8591',
          title: '#A7B1C2',
        },
      },
    },
  },
  {
    name: 'Sky Blue',
    scheme: {
      primary: {
        main: '#89C1ED',
        light: '#e3f2fd',
        dark: '#42a5f5',
      },
      layout: {
        main: '#1A2030',
        light: '#e3f2fd',
        dark: '#3F4451',
        header: '#3E576B',
        header2: '#1C1C1C',
        text: {
          main: '#A7B1C2',
          highlight: '#DCE9FF',
          darkened: '#7D8591',
          title: '#89C1ED',
        },
      },
    },
  },
  {
    name: 'Deep Orange',
    scheme: {
      primary: {
        main: '#F08838',
        light: '#FC8F3B',
        dark: '#BD6B2C',
      },
      layout: {
        main: '#1A2030',
        light: '#e3f2fd',
        dark: '#3F4451',
        header: '#613717',
        header2: '#1C1C1C',
        text: {
          main: '#A7B1C2',
          highlight: '#DCE9FF',
          darkened: '#7D8591',
          title: '#F08838',
        },
      },
    },
  },
  {
    name: 'Nature Green',
    scheme: {
      primary: {
        main: '#42D15C',
        light: '#48E364',
        dark: '#35A84A',
      },
      layout: {
        main: '#1A2030',
        light: '#e3f2fd',
        dark: '#3F4451',
        header: '#133D1B',
        header2: '#1C1C1C',
        text: {
          main: '#A7B1C2',
          highlight: '#DCE9FF',
          darkened: '#7D8591',
          title: '#42D15C',
        },
      },
    },
  },
];

export const DEFAULT_USER_CONTEXT = {
  displayName: null,
  email: null,
  tenant: null,
  tenants: [],
  roles: [],
  profile: {
    language: LANGUAGE_CODE.ENGLISH,
    colorMode: COLOR_MODES.LIGHT,
    colorScheme: null,
    campaignDetailsPosition: ELEMENT_POSITION.SIDE,
    transactionDetailsPosition: ELEMENT_POSITION.SIDE,
    expenseDetailsPosition: ELEMENT_POSITION.SIDE,
    dashboardCampaignsLayout: [],
    views: {},
  },
};

export const DEFAULT_LAYOUT_CONTEXT = {
  sidebarOpen: true,
  selectedPath: null,
  colorMode: COLOR_MODES.LIGHT,
  colorScheme: null,
  pageTitle: null,
  pageSubtitle: null,
  transactionDetailsPosition: ELEMENT_POSITION.SIDE,
  campaignDetailsPosition: ELEMENT_POSITION.SIDE,
  expensesDetailsPosition: ELEMENT_POSITION.SIDE,
  dashboardCampaignsLayout: [],
  views: {},
};

export const DEFAULT_LOCALIZATION_CONTEXT = {
  language: LANGUAGE_CODE.ENGLISH,
  dir: LANGUAGE_DIRECTION.LTR,
  locale: LOCALES.EN_US,
};
