import { getDateFilters, getEnumSelectFilters, getNumericFilters, getSelectFilters } from '../../common/components/datagrid/GridFilters';
import { getGridStringOperators } from '@mui/x-data-grid-pro';
import { RECORD_CREATION_MODE, RECORD_STATE } from '../../common/Constants';
import { AppIcon, ICON, ICON_SIZE } from '../../common/components/AppIcon';
import { StatusItems, StatusSelector } from '../../common/components/StatusSelector';
import { FormatGridColumnsDefinitionData, getCampaignName } from '../../common/components/datagrid/AppDataGridUtils';
import CampaignsSelector from '../../common/components/CampaignsSelector';

export default function getExpenseColumns(props) {
  const { campaigns, onUpdate, theme, localization } = props;

  const cols = [
    {
      field: 'trackingState',
      headerName: localization.translate('app.status'),
      headerAlign: 'left',
      editable: true,
      width: 100,
      renderCell: (props) => {
        switch (props.value) {
          case RECORD_STATE.NEW:
            return <AppIcon icon={ICON.AddCircle} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.new')} />;
          case RECORD_STATE.APPROVED:
            return <AppIcon icon={ICON.Confirmed} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.approved')} />;
          case RECORD_STATE.QUESTIONED:
            return <AppIcon icon={ICON.Questioned} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.questioned')} />;
          default:
            return <span>{props.value}</span>;
        }
      },
      renderEditCell: (props) => {
        return <StatusSelector {...props} onUpdate={onUpdate} />;
      },
      filterOperators: [
        ...getEnumSelectFilters({
          options: StatusItems.map((e, i) => {
            return { text: localization.translate(`app.${e.text.toLowerCase()}`), trackingState: e.value };
          }),
          valuePropName: 'trackingState',
          labelPropName: 'text',
          label: localization.translate('app.status'),
        }),
      ],
    },
    {
      field: 'creationMode',
      headerName: localization.translate('app.creation_mode'),
      width: 140,
      hide: true,
      filterOperators: [
        ...getEnumSelectFilters({
          options: [
            { creationMode: RECORD_CREATION_MODE.IMPORTED, label: localization.translate('app.imported') },
            { creationMode: RECORD_CREATION_MODE.MANUAL, label: localization.translate('app.added_manually') },
          ],
          valuePropName: 'creationMode',
          labelPropName: 'label',
          label: localization.translate('app.creation_mode'),
        }),
      ],
      renderCell: (props) => {
        switch (props.value) {
          case RECORD_CREATION_MODE.IMPORTED:
            return <AppIcon icon={ICON.Imported} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.imported')} />;
          case RECORD_CREATION_MODE.MANUAL:
            return <AppIcon icon={ICON.Add} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.added_manually')} />;
          default:
            return <span>{props.value}</span>;
        }
      },
    },
    {
      field: 'amount',
      headerName: localization.translate('app.sum'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 100,
      /*
      valueFormatter: (params) => {
        console.log(params);
        if (params.value == null) {
          return '0';
        }
        return localization.displayCurrency(params.value);
      },
*/
      valueGetter: (params) => {
        if (params.value == null) {
          return '0';
        }
        return localization.displayCurrency(params.value, params.row.currency);
      },

      filterOperators: [...getNumericFilters()],
    },
    {
      field: 'currency',
      headerName: localization.translate('app.currency'),
      width: 80,
      filterable: false,
    },
    {
      field: 'transactionDate',
      headerName: localization.translate('expenses.transaction_date'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => {
        return localization.display(params.value);
        //return display(params.value, { hour: 'numeric', minute: 'numeric' });
      },
      width: 160,
      filterOperators: [...getDateFilters()],
    },
    {
      field: 'category',
      headerName: localization.translate('expenses.category'),
      width: 160,
      filterOperators: getGridStringOperators().filter((o) => o.value === 'contains' || o.value === 'isEmpty'),
    },
    {
      field: 'subCategory',
      headerName: localization.translate('expenses.sub_category'),
      width: 160,
      filterOperators: getGridStringOperators().filter((o) => o.value === 'contains' || o.value === 'isEmpty'),
    },
    {
      field: 'projectId',
      headerName: localization.translate('app.campaign'),
      width: 160,
      valueSetter: (params) => {
        if (params.value?.projectId) {
          return {
            ...params.row,
            projectId: params.value.projectId,
          };
        } else {
          return params.row;
        }
      },

      valueGetter: (params) => {
        return {
          projectId: params.value,
          projectName: getCampaignName(campaigns, params.value, localization),
        };
      },

      valueFormatter: (params) => {
        return getCampaignName(campaigns, params.value.projectId, localization);
      },
      filterOperators: [
        ...getSelectFilters({
          inputComponent: CampaignsSelector,
          multiple: true,
          autoHighlight: true,
          fullWidth: true,
          disableClearable: true,
          textFieldProps: {
            sx: { width: '200px', label: localization.translate('app.campaign') },
          },
          valuePropName: 'projectId',
          labelPropName: 'projectName',
          label: localization.translate('app.campaign'),
        }),
      ],
    },
    {
      field: 'paidBy',
      headerName: localization.translate('expenses.paid_by'),
      width: 160,
      filterOperators: getGridStringOperators().filter((o) => o.value === 'contains' || o.value === 'isEmpty'),
    },
    {
      field: 'paidTo',
      headerName: localization.translate('expenses.paid_to'),
      width: 160,
      filterOperators: getGridStringOperators().filter((o) => o.value === 'contains' || o.value === 'isEmpty'),
    },
    {
      field: 'originalAmount',
      headerName: localization.translate('app.original_sum'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 120,
      valueFormatter: (params) => {
        return localization.displayCurrency(params.value);
      },
      filterOperators: [...getNumericFilters()],
    },
    {
      field: 'originalCurrency',
      headerName: localization.translate('app.original_currency'),
      width: 120,
      filterable: false,
    },
    {
      field: 'originalDate',
      headerName: localization.translate('common.original_date'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => {
        return localization.display(params.value);
        //return display(params.value, { hour: 'numeric', minute: 'numeric' });
      },
      width: 160,
      filterOperators: [...getDateFilters()],
    },
    {
      field: 'comment',
      headerName: localization.translate('app.comments'),
      hide: true,
      filterable: false,
    },
    {
      field: 'bankReference',
      headerName: localization.translate('donations.bank_reference'),
      hide: true,
      filterable: false,
    },
    {
      field: 'createdBy',
      headerName: localization.translate('app.created_by'),
      width: 180,
      filterOperators: getGridStringOperators().filter((o) => o.value === 'contains' || o.value === 'isEmpty'),
    },
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      filterable: false,
    },
    {
      field: 'updated',
      headerName: localization.translate('common.updated'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      hide: true,
      filterable: false,
    },
  ];

  return FormatGridColumnsDefinitionData(cols);
}
