import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AppBox } from './AppBox';

export const AppFormLayout = (props) => {
  const { inline, headerProps, contentProps = {}, footerProps } = props;
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        ...(inline && {
          position: 'absolute',
          top: 0,
          bottom: 0,
        }),
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {headerProps && (
        <AppBox
          flex
          centery
          style={{
            padding: '10px',
            minHeight: '80px',
            borderLeft: `10px solid ${theme.palette.primary.main}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            //backgroundColor: theme.palette.layout.header2,
          }}
        >
          {headerProps.content}
        </AppBox>
      )}

      <div
        style={{
          paddingTop: '10px',
          margin: '7px',
          ...contentProps.containerStyle,
          flex: 1,
          overflow: 'auto',
        }}
      >
        {contentProps.content}
      </div>
      {footerProps && (
        <AppBox flex endx centery style={{ padding: '10px' }}>
          {footerProps.actionProps.content}
        </AppBox>
      )}
    </div>
  );
};
