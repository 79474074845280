import {
  getAlternateValueFilters,
  getDateFilters,
  getEnumSelectFilters,
  getReceiptFilters,
  getNumericFilters,
  getPaymentSourceFilters,
  getSelectFilters,
  getTextFilters,
} from '../../common/components/datagrid/GridFilters';
import { getGridStringOperators } from '@mui/x-data-grid-pro';
import { INVOICE_STATE, PAYMENT_METHODS, PAYMENT_PROVIDERS, RECORD_CREATION_MODE, RECORD_STATE, SupportedLanguages } from '../../common/Constants';
import { AppIcon, ICON, ICON_SIZE } from '../../common/components/AppIcon';
import { StatusItems, StatusSelector } from '../../common/components/StatusSelector';
import { IconButton, Tooltip } from '@mui/material';
import ReceiptDownloader from '../../common/components/ReceiptDownloader';
import GenerateReceiptSubmitter from '../../common/components/GenerateReceiptSubmitter';
import { AppBox } from '../../common/components/AppBox';
import CampaignsSelector from '../../common/components/CampaignsSelector';
import {
  DEF_GRID_COLUMN_PROPS,
  FormatGridColumnsDefinitionData,
  getCampaignName,
  GroupingCellWithLazyLoading,
} from '../../common/components/datagrid/AppDataGridUtils';
import { AppText } from '../../common/components/AppText';

// styling react-icons: https://stackoverflow.com/questions/56636280/how-to-style-react-icons

const getPaymentMethod = (code) => {
  const found = PAYMENT_METHODS.find((s) => s.code === code);
  return found?.name || '';
};

const getPaymentProvider = (code, localization) => {
  const found = Object.getOwnPropertyNames(PAYMENT_PROVIDERS).find((p) => PAYMENT_PROVIDERS[p] === code);
  return !!found ? localization.translate(`app.${code}`) : code;
};

export function getTransactionsGroupColumn(localization, loadChildren) {
  return {
    ...DEF_GRID_COLUMN_PROPS,
    field: 'amount',
    headerName: localization.translate('app.sum'),
    type: 'number',
    resizable: false,
    flex: 1,
    minWidth: 150,
    maxWidth: 200,
    filterable: true,
    valueFormatter: (params) => {
      if (params.value == null) {
        return '0';
      }
      return localization.displayCurrency(params.value);
    },
    filterOperators: [...getNumericFilters()],
    renderCell: (params) => (
      <GroupingCellWithLazyLoading
        {...params}
        loadChildren={loadChildren}
        valueGetter={(row) => {
          return !row.amount ? '0' : localization.displayCurrency(row.amount, row.currency);
        }}
      />
    ),
  };
}

export function getTransactionColumns(props) {
  const { campaigns, onUpdate, localization, display, theme } = props;
  const cols = [
    /*
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      type: 'string',
      //field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      hideable: false,
      renderCell: (params) => <AppGridDetailPanelToggle id={params.id} value={params.value}></AppGridDetailPanelToggle>,
    },
*/
    {
      field: 'transactionDate',
      headerName: localization.translate('donations.transaction_date'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => {
        return display(params.value);
        //return display(params.value, { hour: 'numeric', minute: 'numeric' });
      },
      width: 170,
      filterOperators: [...getDateFilters()],
    },

    {
      field: 'invoice',
      headerName: localization.translate('donations.receipt'),
      filterOperators: [
        ...getReceiptFilters({
          options: [
            { invoice: INVOICE_STATE.ready, label: localization.translate('donations.receipt_status_ready') },
            { invoice: INVOICE_STATE.absent, label: localization.translate('donations.receipt_status_absent') },
            { invoice: INVOICE_STATE.inprogress, label: localization.translate('donations.receipt_status_inprogress') },
            { invoice: INVOICE_STATE.failed, label: localization.translate('donations.receipt_status_failed') },
            { invoice: INVOICE_STATE.notrequired, label: localization.translate('donations.receipt_status_notrequired') },
          ],
          alternateValues: [
            {
              value: 'sent',
              label: localization.translate('donations.sent_by_email'),
            },
            {
              value: 'notsent',
              label: localization.translate('donations.not_sent_by_email'),
            },
          ],
          valuePropName: 'invoice',
          labelPropName: 'label',
          label: localization.translate('app.status'),
        }),
      ],
      editable: false,
      width: 120,
      renderCell: (props) => {
        const state = !props.value?.state
          ? INVOICE_STATE.absent
          : props.value.state === INVOICE_STATE.ready && !props.value.links && !props.value.variations
          ? INVOICE_STATE.invalid
          : props.value.state;
        switch (state) {
          case INVOICE_STATE.absent:
            return (
              <AppBox flex centery sx={{ width: '100%', pl: 2 }}>
                <GenerateReceiptSubmitter ids={[props.row.id]} data={props.row} iconOnly campaigns={campaigns} />
              </AppBox>
            );
          case INVOICE_STATE.ready:
            return (
              <AppBox flex centery sx={{ width: '100%', pl: 2 }}>
                {props.value?.mailState === 'sent' && <AppIcon icon={ICON.EmailSend} size={ICON_SIZE.ExtraSmall} />}
                <ReceiptDownloader
                  data={props.row}
                  icon
                  iconProps={{ color: 'primary' }}
                  tooltip={localization.translate('donations.download_invoice')}
                  filename={`invoice_${props.value.number || ''}`}
                />
              </AppBox>
            );
          case INVOICE_STATE.inprogress:
            return (
              <AppBox flex centery sx={{ width: '100%', pl: 2 }}>
                <Tooltip title={localization.translate('donations.receipt_generating')}>
                  <div>
                    <IconButton disabled>
                      <AppIcon icon={ICON.InProgress} />
                    </IconButton>
                  </div>
                </Tooltip>
              </AppBox>
            );
          case INVOICE_STATE.failed:
            return (
              <AppBox flex centery sx={{ width: '100%', pl: 2 }}>
                <GenerateReceiptSubmitter color='error' ids={[props.row.id]} data={props.row} iconOnly campaigns={campaigns} />
              </AppBox>
            );
          default:
            return (
              <AppBox flex centery sx={{ width: '100%', pl: 3, opacity: 0.5 }}>
                <AppIcon icon={ICON.NoFile} tooltip={localization.translate('donations.receipt_status_notrequired')} />
              </AppBox>
            );
        }
      },
    },
    {
      field: 'sourceType',
      headerName: localization.translate('donations.payment_method'),
      width: 160,
      filterable: false,
      valueFormatter: (params) => getPaymentMethod(params.value),
    },
    {
      field: 'source',
      headerName: localization.translate('donations.payment_source'),
      filterOperators: [
        ...getPaymentSourceFilters({
          options: Object.getOwnPropertyNames(PAYMENT_PROVIDERS).map((p, i) => {
            return {
              source: PAYMENT_PROVIDERS[p],
              label: localization.translate(`app.${PAYMENT_PROVIDERS[p]}`),
            };
          }),

          valuePropName: 'source',
          labelPropName: 'label',
          label: localization.translate('donations.payment_source'),
        }),
      ],
      valueFormatter: (params) => {
        let value = params.value?.displayName;
        const provider = getPaymentProvider(params.value?.provider, localization);
        if (value) {
          value = `${value} (${provider})`;
        } else {
          value = provider;
        }
        return value;
      },
    },
    {
      field: 'trackingState',
      headerName: localization.translate('app.status'),
      editable: true,
      width: 70,
      renderCell: (props) => {
        switch (props.value) {
          case RECORD_STATE.NEW:
            return <AppIcon icon={ICON.AddCircle} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.new')} />;
          case RECORD_STATE.APPROVED:
            return <AppIcon icon={ICON.Confirmed} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.approved')} />;
          case RECORD_STATE.QUESTIONED:
            return <AppIcon icon={ICON.Questioned} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.questioned')} />;
          default:
            return <span>{props.value}</span>;
        }
      },
      renderEditCell: (props) => {
        return <StatusSelector {...props} onUpdate={onUpdate} />;
      },
      filterOperators: [
        ...getEnumSelectFilters({
          options: StatusItems.map((e, i) => {
            return { text: localization.translate(`app.${e.text.toLowerCase()}`), trackingState: e.value };
          }),
          valuePropName: 'trackingState',
          labelPropName: 'text',
          label: localization.translate('app.status'),
        }),
      ],
    },
    {
      field: 'creationMode',
      headerName: localization.translate('app.creation_mode'),
      width: 120,
      hide: true,
      filterOperators: [
        ...getAlternateValueFilters({
          values: [
            {
              value: RECORD_CREATION_MODE.IMPORTED,
              label: localization.translate('app.imported'),
            },
            {
              value: RECORD_CREATION_MODE.MANUAL,
              label: localization.translate('app.added_manually'),
            },
          ],
        }),
      ],
      renderCell: (props) => {
        switch (props.value) {
          case RECORD_CREATION_MODE.IMPORTED:
            return <AppIcon icon={ICON.Imported} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.imported')} />;
          case RECORD_CREATION_MODE.MANUAL:
            return <AppIcon icon={ICON.Add} size={ICON_SIZE.ExtraSmall} tooltip={localization.translate('app.added_manually')} />;
          default:
            return <span>{props.value}</span>;
        }
      },
    },
    /*
    {
      field: 'amount',
      headerName: localization.translate('app.sum'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 120,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '0';
        }
        return localization.displayCurrency(params.value);
      },
      filterOperators: [...getNumericFilters()],
    },
    */
    {
      field: 'sourceName',
      headerName: localization.translate('donations.source_name'),
      width: 160,
      filterable: true,
      filterOperators: [...getTextFilters()],
    },
    {
      field: 'projectId',
      headerName: localization.translate('app.campaign'),
      width: 160,
      valueSetter: (params) => {
        if (params.value?.projectId) {
          return {
            ...params.row,
            projectId: params.value.projectId,
            //projectName: params.value.projectName,
          };
        } else {
          return params.row;
        }
      },

      valueGetter: (params) => {
        return {
          projectId: params.value,
          projectName: getCampaignName(campaigns, params.value, localization),
        };
      },

      valueFormatter: (params) => {
        return getCampaignName(campaigns, params.value.projectId, localization);
      },
      filterOperators: [
        ...getSelectFilters({
          inputComponent: CampaignsSelector,
          multiple: true,
          autoHighlight: true,
          fullWidth: true,
          disableClearable: true,
          textFieldProps: {
            sx: { width: '200px', label: localization.translate('app.campaign') },
          },
          valuePropName: 'projectId',
          labelPropName: 'projectName',
          label: localization.translate('app.campaign'),
        }),
      ],
    },
    {
      field: 'currency',
      headerName: localization.translate('app.currency'),
      width: 80,
      filterable: false,
    },
    {
      field: 'businessNumber',
      headerName: localization.translate('donations.business_number'),
      width: 120,
      filterable: false,
    },
    {
      field: 'sourceEmail',
      headerName: localization.translate('donations.source_email'),
      hide: true,
      filterable: false,
    },
    {
      field: 'sourceAmount',
      headerName: localization.translate('donations.source_amount'),
      valueFormatter: (params) => {
        return params.value ? localization.displayCurrency(params.value) : '';
      },
      hide: true,
      filterable: false,
    },
    {
      field: 'sourceComment',
      headerName: localization.translate('donations.source_comment'),
      hide: true,
      filterable: false,
    },
    {
      field: 'sourceBank',
      headerName: localization.translate('donations.source_bank'),
      hide: true,
      filterable: false,
    },
    {
      field: 'sourceBranch',
      headerName: localization.translate('donations.source_branch'),
      hide: true,
      filterable: false,
    },
    {
      field: 'sourceAccount',
      headerName: localization.translate('donations.source_account'),
      hide: true,
      filterable: false,
    },
    {
      field: 'sourceReference',
      headerName: localization.translate('donations.source_reference'),
      hide: true,
      filterable: false,
    },
    {
      field: 'bankReference',
      headerName: localization.translate('donations.bank_reference'),
      hide: true,
      filterable: false,
    },
    {
      field: 'createdBy',
      headerName: localization.translate('app.created_by'),
      hide: true,
      filterOperators: getGridStringOperators().filter((o) => o.value === 'contains' || o.value === 'isEmpty'),
    },
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      filterable: false,
    },
    {
      field: 'importRequestId',
      headerName: 'Import Request Id',
      hide: true,
      filterable: false,
    },
    {
      field: 'updated',
      headerName: localization.translate('common.updated'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      hide: true,
      filterable: false,
    },
    {
      field: 'created',
      headerName: localization.translate('common.created'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      hide: true,
      filterable: false,
    },
  ];

  return FormatGridColumnsDefinitionData(cols);
}
