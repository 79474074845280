import { ListItem, ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AppListItem = styled(ListItem)(({ theme }) => ({
  cursor: 'default',
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

export const AppListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  paddingRight: 1,
  ...(selected && {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  }),
  color: theme.palette.primary.main,
}));
