import { Link } from '@mui/material';

const AppLink = (props) => {
  const { sx, ...rest } = props;
  return (
    <Link
      variant='normal'
      {...rest}
      sx={{
        ...sx,
        cursor: 'pointer',
      }}
    />
  );
};

export default AppLink;
