import { Tooltip, useTheme } from '@mui/material';
import { AppBox } from '../../common/components/AppBox';
import AmountChart from '../../common/components/charts/AmountChart';
import PercentChart from '../../common/components/charts/PercentChart';
import { useAppContext } from '../../context/AppContext';
import { AppText } from '../../common/components/AppText';

const calculateFontSize = (text) => {
  let size = '';

  if (text && text.length > 0) {
    const length = text.length;
    if (length <= 8) {
      size = '.8rem';
    } else if (length > 8 && length <= 10) {
      size = '.7rem';
    } else {
      size = '.65rem';
    }
  }
  return size;
};

const CampaignDonationsChart = (props) => {
  const { data, campaign, size } = props;
  const { localization } = useAppContext();
  const theme = useTheme();
  const calculateColor = (percent) => {
    let color = '#7e57c2';
    if (percent < 0) {
      color = theme.palette.primary.main;
    } else if (percent <= 25) {
      color = '#ff5722'; // red
    } else if (percent > 25 && percent <= 50) {
      color = '#fb8c00'; // orange
    } else if (percent > 50 && percent <= 90) {
      color = '#afb42b'; // lemon
    } else if (percent > 90 && percent <= 100) {
      color = '#43a047'; // green
    }
    return color;
  };

  const color = calculateColor(!!data.goal ? data.percent : -1);
  const amountText = !!data.amount ? localization.displayCurrency(data.amount) : '-';

  return (
    <AppBox column flex center>
      <AppBox flex column centery>
        <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
          {localization.translate('app.donations')}
        </AppText>
      </AppBox>
      {!!data.goal ? (
        <AppBox flex column center>
          <PercentChart
            size={size}
            color={color}
            percent={data.percent}
            tooltip={localization.translate('dashboard.percent_of_goal')}
            goal={data.goal || 0}
            current={data.amount}
          />
          <AppBox flex column center sx={{ flex: 1 }}>
            <AppBox flex column center>
              <Tooltip title={localization.translate('dashboard.amount_achieved')} placement='top-end'>
                <AppText variant='small' sx={{ cursor: 'default' }}>
                  {amountText}
                </AppText>
              </Tooltip>
              <Tooltip title={localization.translate('dashboard.number_of_donations')} placement='top-end'>
                <AppText variant='small' sx={{ cursor: 'default' }}>
                  {localization.display(data.count)}
                </AppText>
              </Tooltip>
            </AppBox>
          </AppBox>
        </AppBox>
      ) : (
        <AppBox flex column center sx={{ position: 'relative' }}>
          <AmountChart size={size} />
          <AppBox flex column centery endx sx={{ position: 'absolute', width: '50%', top: '14%', left: '0%', px: 1 }}>
            <Tooltip title={localization.translate('dashboard.amount_achieved')} placement='top-end'>
              <AppText variant='small' sx={{ cursor: 'default', fontSize: calculateFontSize(amountText) }}>
                {amountText}
              </AppText>
            </Tooltip>
            <Tooltip title={localization.translate('dashboard.number_of_donations')} placement='top-end'>
              <AppText variant='small' sx={{ cursor: 'default', fontSize: calculateFontSize(localization.display(data.count)) }}>
                {localization.display(data.count)}
              </AppText>
            </Tooltip>
          </AppBox>
          <AppBox flex column center sx={{ flex: 1 }}>
            <AppBox flex column center>
              <Tooltip title={localization.translate('dashboard.amount_achieved')} placement='top-end'>
                <AppText variant='small' sx={{ cursor: 'default' }}>
                  {amountText}
                </AppText>
              </Tooltip>
              <Tooltip title={localization.translate('dashboard.number_of_donations')} placement='top-end'>
                <AppText variant='small' sx={{ cursor: 'default' }}>
                  {localization.display(data.count)}
                </AppText>
              </Tooltip>
            </AppBox>
          </AppBox>
        </AppBox>
      )}
    </AppBox>
  );
};

export default CampaignDonationsChart;
