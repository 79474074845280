import { Box, CssBaseline, Grid } from '@mui/material';
import { useState } from 'react';
import { AppBox } from '../common/components/AppBox';
import MainArea from './MainArea';
import Sidebar from './Sidebar';
import Header from './Header';
import { useAppContext } from '../context/AppContext';

const Layout = () => {
  const [sidebarClosed, setSidebarClosed] = useState(false);
  const { profile } = useAppContext();

  return (
    <Box sx={{ display: 'flex', width: '100%', backgroundColor: (theme) => theme.palette.background.default }}>
      <CssBaseline />
      {profile.sideMenu && <Sidebar closed={sidebarClosed} />}
      <AppBox flex column sx={{ flexGrow: 1 }}>
        <Header sidebarClosed={sidebarClosed} setSidebarClosed={setSidebarClosed} />
        <MainArea />
      </AppBox>
    </Box>
  );
};

export default Layout;
