import { FormatGridColumnsDefinitionData } from '../../common/components/datagrid/AppDataGridUtils';
import { getAlternateValueFilters, getNumericFilters } from '../../common/components/datagrid/GridFilters';
import { CAMPAIGN_STATE } from '../../common/Constants';

export default function getProjectsColumns(props) {
  const { localization, displayCurrency } = props;
  const cols = [
    {
      field: 'name',
      headerName: localization.translate('campaigns.name'),
      width: 160,
    },
    {
      field: 'description',
      headerName: localization.translate('common.description'),
      width: 260,
    },
    {
      field: 'targetGoal',
      headerName: localization.translate('campaigns.goal'),
      width: 160,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      valueFormatter: (params) => {
        return !!params.value ? displayCurrency(params.value) : '-';
      },
      filterOperators: [...getNumericFilters(localization)],
    },
    {
      field: 'commission',
      headerName: localization.translate('campaigns.commission_percent'),
      width: 160,
      type: 'number',
      filterable: false,
    },
    {
      field: 'createdBy',
      headerName: localization.translate('app.created_by'),
      width: 200,
    },
    {
      field: 'state',
      headerName: localization.translate('app.state'),
      hide: true,
      hideable: false,
      filterOperators: [
        ...getAlternateValueFilters({
          values: [
            {
              value: CAMPAIGN_STATE.active,
              label: localization.translate('campaigns.active'),
            },
            {
              value: CAMPAIGN_STATE.inactive,
              label: localization.translate('campaigns.inactive'),
            },
          ],
        }),
      ],
    },
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      hideable: false,
      filterable: false,
    },
    {
      field: 'updated',
      headerName: localization.translate('common.updated'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => {
        return localization.display(params.value);
      },
      width: 160,

      filterable: false,
    },
  ];

  return FormatGridColumnsDefinitionData(cols);
}
