import { useEffect } from 'react';
import { useState } from 'react';
import { AppTable, AppTableBody, AppTableCell, AppTableRow } from '../../../common/components/AppTable';
import { InlineTextField } from '../../../common/components/AppText';
import { useAppContext } from '../../../context/AppContext';
import { useHttp } from '../../../hooks/authentication';
import { InlineValueEditor } from '../InlineValueEditor';

const UserProfileConfiguration = (props) => {
  const { localization, user } = useAppContext();
  const { getHttp } = useHttp();
  const [edited, setEdited] = useState({});
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    setSettings({
      ...settings,
      username: user.name,
    });
  }, [user]);

  const handleEditUsername = async () => {
    const edit = !edited?.username;
    setEdited({
      ...edited,
      username: !edited?.username,
    });
    if (!edit) {
      await getHttp().put('me', {
        name: settings.username,
      });
      user.setUserName(settings.username);
    }
  };

  const cancelEditUsername = () => {
    setEdited({
      ...edited,
      username: !edited?.username,
    });
  };

  return settings ? (
    <AppTable containerProps={{ sx: { width: '60%' } }}>
      <AppTableBody noBorder>
        <AppTableRow noHover>
          <AppTableCell sx={{ minWidth: '150px' }}>{localization.translate('settings.user_name')}</AppTableCell>
          <AppTableCell sx={{ minWidth: '250px' }}>
            <InlineTextField
              readonly={!edited?.username}
              value={settings.username}
              sx={{ width: '100%', mr: 1 }}
              InputProps={{
                sx: {
                  paddingRight: 0,
                },
              }}
              onChange={(v) => setSettings({ ...settings, username: v })}
            />
          </AppTableCell>
          <AppTableCell>
            <InlineValueEditor edited={edited?.username} name='username' onEdit={handleEditUsername} onCancel={cancelEditUsername} />
          </AppTableCell>
        </AppTableRow>
      </AppTableBody>
    </AppTable>
  ) : (
    <></>
  );
};

export default UserProfileConfiguration;
