import { Configuration } from '../Configuration';
import UserCommonConfiguration from './CommonConfiguration';

const CONFIGURATION_INDEX = {
  COMMON: 1,
};

const Configurations = [
  {
    index: CONFIGURATION_INDEX.COMMON,
    component: UserCommonConfiguration,
    componentProps: {},
    label: 'common.common',
  },
];

const UserConfiguration = (props) => {
  const { path } = props;
  return <Configuration path={path} title='settings.user_configuration_title' configurations={Configurations} indexes={CONFIGURATION_INDEX} />;
};

export default UserConfiguration;
