import { Box, LinearProgress } from '@mui/material';
import { useState, forwardRef, useImperativeHandle } from 'react';
import { AppDialog } from './AppDialog';
import AppInnerAlert from './AppInnerAlert';
import { WORKING_STATE } from '../Constants';
import { AppButton } from './AppButton';
import { useAppContext } from '../../context/AppContext';
import { AppBox } from './AppBox';

export const DialogFormContainer = forwardRef((props, ref) => {
  const { title, open, onClose, closeOnSubmit = true, formId, onSubmit, disableSubmit, minHeight, width = 'md', errorAlert } = props;
  const [workingState, setWorkingState] = useState(WORKING_STATE.IDLE);
  const { localization } = useAppContext();
  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      await onSubmit(event);
      if (onClose && closeOnSubmit) {
        setWorkingState(WORKING_STATE.IDLE);
        onClose();
      } else {
        setWorkingState(WORKING_STATE.IDLE);
      }
    } catch (error) {
      console.error(error);
      setWorkingState(WORKING_STATE.ERROR);
    }
  };

  useImperativeHandle(ref, () => ({
    setWorkingState: (state) => {
      setWorkingState(state);
    },
  }));

  return (
    <AppDialog
      maxWidth={width}
      scroll='paper'
      open={open}
      onClose={onClose}
      title={title}
      hasActions
      useClose
      closeTitle={!disableSubmit ? localization.translate('common.cancel') : localization.translate('common.close')}
      dialogContent={
        <AppBox
          flex
          column
          sx={{
            ...(minHeight && {
              height: minHeight,
            }),
            mb: 1,
          }}
        >
          {workingState === WORKING_STATE.INPROGRESS && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress sx={{ mt: 0 }} />
            </Box>
          )}
          {workingState === WORKING_STATE.SUCCESS && (
            <AppInnerAlert
              open
              severity='success'
              onClose={() => setWorkingState(WORKING_STATE.IDLE)}
              content={localization.translate('app.data_save_success')}
              vertical='top'
            />
          )}
          {workingState === WORKING_STATE.ERROR && (
            <AppInnerAlert
              open
              severity='error'
              onClose={() => setWorkingState(WORKING_STATE.IDLE)}
              content={localization.translate(errorAlert || 'app.data_save_error')}
              vertical='top'
            />
          )}
          <AppBox
            sx={{
              flexGrow: 1,
              px: 1,
              mt: 2,
              height: '100%',
            }}
            component='form'
            id={formId}
            onSubmit={handleSubmit}
            noValidate
          >
            {props.children}
          </AppBox>
        </AppBox>
      }
      dialogActionsContent={
        <AppButton disabled={disableSubmit} type='submit' form={formId}>
          {localization.translate('common.save')}
        </AppButton>
      }
    />
  );
});
