import { Grid, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import CampaignTile from './CampaignTile';
import { useEffect } from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import useAuthentication from '../../hooks/authentication';
import { useAppContext } from '../../context/AppContext';
import EmptyTransactionsTile from './EmptyTransactionsTile';
import { WORKING_STATE } from '../../common/Constants';
import EmptyDataWidget from './EmptyDataWidget';
import { AppButton } from '../../common/components/AppButton';
import { AppIcon, ICON, ICON_SIZE } from '../../common/components/AppIcon';
import CampaignsSelector from '../../common/components/CampaignsSelector';
import useCampaigns from '../../hooks/campaigns';
import { useDataContext } from '../../context/DataContext';
import { AppText } from '../../common/components/AppText';
import { isFewScreenWidth } from '../../utils/utils';

const CampaignsWidget = (props) => {
  const { onLoaded } = props;
  const { localization } = useAppContext();
  const campaigns = useCampaigns();
  const dataContext = useDataContext();
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [displayedCampaigns, setDisplayedCampaigns] = useState([]);

  const [widgetLayoutChanged, setWidgetLayoutChanged] = useState(false);
  const [dataState, setDataState] = useState(WORKING_STATE.INPROGRESS);

  const profileCampaigns = dataContext.getProfile()?.campaigns || [];
  const tilesInRow = isFewScreenWidth() ? 4 : 5;
  const getLayout = (data, layoutSchema) => {
    if (!layoutSchema) {
      layoutSchema = profileCampaigns;
    }
    if (layoutSchema.length === 0) {
      const layoutLength = data.length >= tilesInRow - 1 ? tilesInRow - 1 : data.length;
      const array = [...data];
      array.sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        return dateA <= dateB ? 1 : -1;
      });
      return array.slice(0, layoutLength);
    } else {
      const array = [];
      // keep exact order as it appears in ayoutContext.layout.dashboardCampaignsLayout
      layoutSchema.forEach((id) => {
        const found = data.find((e) => e.id === id);
        if (found) {
          array.push(found);
        }
      });
      return array;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await campaigns.load(true);
        if (data.length > 0) {
          setAllCampaigns(data);
          setDataState(WORKING_STATE.SUCCESS);
        } else {
          setDataState(WORKING_STATE.DONE_NO_DATA);
        }
      } catch (error) {
        console.error(error);
        setDataState(WORKING_STATE.ERROR);
      }
    })();
  }, []);

  useEffect(() => {
    if (allCampaigns.length > 0) {
      const items = getLayout(allCampaigns);
      setDisplayedCampaigns([...items]);
      if (onLoaded) {
        onLoaded();
      }
    }
  }, [allCampaigns]);

  const addItem = (item) => {
    const updated = [...displayedCampaigns];
    updated.unshift(item);
    applyProfileLayout(
      updated.map((e, i) => {
        return e.id;
      })
    );
    setDisplayedCampaigns(updated);
    setWidgetLayoutChanged(true);
  };

  const removeItem = (item) => {
    const found = displayedCampaigns.find((e) => e.id === item.id);
    if (found) {
      const updated = [...displayedCampaigns];
      updated.splice(updated.indexOf(found), 1);
      applyProfileLayout(
        updated.map((e, i) => {
          return e.id;
        })
      );
      setDisplayedCampaigns(updated);
      setWidgetLayoutChanged(true);
    }
  };

  const isSelected = (id) => {
    return !!displayedCampaigns.find((e) => {
      return e.id === id;
    });
  };

  const changeSelected = (item) => {
    if (isSelected(item.projectId)) {
      removeItem({ id: item.projectId, name: item.projectName });
    } else {
      addItem({ id: item.projectId, name: item.projectName });
    }
  };

  const applyProfileLayout = (value) => {
    dataContext.setProfile({
      campaigns: [...value],
    });
  };

  const handleRestoreLayout = () => {
    const items = getLayout(allCampaigns, []);
    setDisplayedCampaigns([...items]);
    applyProfileLayout([]);
    setWidgetLayoutChanged(false);
  };

  return dataState === WORKING_STATE.SUCCESS ? (
    <Grid container>
      <Grid item xs={12}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          {allCampaigns.length > displayedCampaigns.length && (
            <Grid item xs={12}>
              <Stack direction='row' spacing={2} sx={{ pt: 1 }}>
                <CampaignsSelector
                  disableCloseOnSelect
                  fullWidth={false}
                  renderOption={(props, option) => (
                    <li {...props} key={option.projectId} onClick={() => changeSelected(option)}>
                      {isSelected(option.projectId) ? (
                        <AppText sx={{ color: (theme) => theme.palette.primary.main }}>{option.projectName || ''}</AppText>
                      ) : (
                        <AppText sx={{ color: (theme) => theme.palette.text.disabled }}>{option.projectName || ''}</AppText>
                      )}
                    </li>
                  )}
                  textFieldProps={{
                    sx: { width: '300px' },
                    label: localization.translate('dashboard.select_campaigns'),
                  }}
                />
                <AppButton
                  variant='text'
                  aria-describedby='restore-layout'
                  onClick={handleRestoreLayout}
                  startIcon={<AppIcon icon={ICON.Reset} size={ICON_SIZE.Small} />}
                  disabled={!widgetLayoutChanged && profileCampaigns.length === 0}
                >
                  <AppText variant='small'>{localization.translate('dashboard.use_default_layout')}</AppText>
                </AppButton>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} sm='auto' id={0}>
            <EmptyTransactionsTile />
          </Grid>
          {displayedCampaigns.map((e, i) => {
            return (
              <Grid item xs={12} sm='auto' key={e.id}>
                <CampaignTile campaign={e} onRemove={removeItem} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  ) : dataState === WORKING_STATE.INPROGRESS ? (
    <EmptyDataWidget height='130px' variant='loading' />
  ) : dataState === WORKING_STATE.DONE_NO_DATA ? (
    <EmptyDataWidget title={localization.translate('campaigns.no_campaigns')} height='130px' onReload={() => campaigns.reloadCampaigns()} />
  ) : (
    <EmptyDataWidget
      title={`${localization.translate('campaigns.load_campaigns_error')}. ${localization.translate('errors.try_again')}}`}
      height='130px'
      variant='error'
      onReload={() => campaigns.reloadCampaigns()}
    />
  );
};

export default CampaignsWidget;
