import { useState, useEffect } from 'react';
import { useAppContext } from '../../../../context/AppContext';
import { GridToolbarItem } from './GridToolbarItem';
import { AppIcon, ICON, ICON_SIZE } from '../../AppIcon';
import { Autocomplete, Box, MenuItem, Popover, TextField } from '@mui/material';
import { AppText } from '../../AppText';
import CampaignsSelector from '../../CampaignsSelector';
import { AppDropdownMenu } from '../../AppDropdownMenu';
import { StatusItems, getStatusColor } from '../../StatusSelector';

export const AssignStatusItem = (props) => {
  const { variant, onActivated, isDisabled, onUpdate, apiRef } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { localization } = useAppContext();

  const selectedRows = apiRef.current.getSelectedRows();

  const onClick = (e) => {
    if (onActivated) {
      onActivated();
    } else {
      handleOpenDialog(e);
    }
  };

  const handleOpenDialog = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (event, newVal) => {
    const rows = Array.from(selectedRows.values());
    rows.forEach((e, i) => {
      e.trackingState = newVal.value;
    });

    handleClose();
    if (onUpdate) {
      onUpdate(rows).then(
        (result) => {
          apiRef.current.updateRows(rows);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      apiRef.current.updateRows(rows);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'assign-project-dialog' : undefined;

  return (
    <>
      <GridToolbarItem
        variant={variant}
        aria-describedby={id}
        onClick={onClick}
        startIcon={<AppIcon icon={ICON.AssignStatus} />}
        disabled={isDisabled !== undefined ? isDisabled : selectedRows.size <= 1}
        text={localization.translate('app.assign_status')}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'end',
            height: 48,
          }}
        >
          <Autocomplete
            onChange={handleChange}
            options={StatusItems.map((e, i) => {
              return {
                label: localization.translate(`app.${e.text.toLowerCase()}`),
                value: e.value,
                icon: e.icon,
              };
            })}
            getOptionLabel={(option) => {
              return option ? option.label || '' : '';
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', color: (theme) => getStatusColor(option.value, theme) }}>
                    <AppIcon icon={option.icon} size={ICON_SIZE.Small} />
                    <AppText sx={{ mx: 1 }}>{option.label}</AppText>
                  </Box>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label={localization.translate('app.status')} variant='outlined' size='small' sx={{ width: 200, m: 2 }} />
            )}
            ListboxProps={{
              sx: {
                resize: 'both',
              },
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export const AssignProjectItem = (props) => {
  const { variant, onActivated, isDisabled, onUpdate, apiRef } = props;
  const { localization } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedRows = apiRef.current.getSelectedRows();

  const onClick = (e) => {
    if (onActivated) {
      onActivated();
    } else {
      handleClick(e);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (newVal) => {
    const rows = Array.from(selectedRows.values());
    rows.forEach((e, i) => {
      e.projectId = newVal.value.projectId;
      e.projectName = newVal.value.projectName;
    });

    handleClose();
    if (onUpdate) {
      onUpdate(rows).then(
        (result) => {
          apiRef.current.updateRows(rows);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      apiRef.current.updateRows(rows);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'assign-project-dialog' : undefined;

  return (
    <>
      <GridToolbarItem
        variant={variant}
        aria-describedby={id}
        onClick={onClick}
        startIcon={<AppIcon icon={ICON.AssignProject} />}
        disabled={isDisabled !== undefined ? isDisabled : selectedRows.size <= 1}
        text={localization.translate('donations.assign_project')}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            flexDirection: 'row',
            alignItems: 'end',
            height: 48,
          }}
        >
          <CampaignsSelector applyValue={handleChange} textFieldProps={{ sx: { width: 200, m: 2 } }} />
        </Box>
      </Popover>
    </>
  );
};

export const AssignItem = (props) => {
  const { variant, onActivated, isDisabled, selectionModel, onUpdate, apiRef } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { localization } = useAppContext();
  const [selectedRowsSize, setSelectedRowsSize] = useState(0);

  useEffect(() => {
    if (selectionModel) {
      setSelectedRowsSize(selectionModel.length);
    }
  }, [selectionModel]);

  const onClick = (e) => {
    if (onActivated) {
      onActivated();
    } else {
      handleClick(e);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = open ? 'assign-project-dialog' : undefined;

  return (
    <>
      <GridToolbarItem
        variant={variant}
        aria-describedby={id}
        onClick={onClick}
        startIcon={<AppIcon icon={ICON.Assign} />}
        disabled={isDisabled !== undefined ? isDisabled : selectedRowsSize <= 1}
        text={localization.translate('app.assign')}
      />
      <AppDropdownMenu id={id} open={open} anchorEl={anchorEl} onClose={handleClose} iconColor='primary'>
        <MenuItem>
          <AssignProjectItem onUpdate={onUpdate} apiRef={apiRef} />
        </MenuItem>
        <MenuItem>
          <AssignStatusItem onUpdate={onUpdate} apiRef={apiRef} />
        </MenuItem>
      </AppDropdownMenu>
    </>
  );
};
