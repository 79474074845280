import { Button } from '@mui/material';
export const AppButton = (props) => {
  const { variant = 'contained', size = 'small', uppercase = false, sx, ...rest } = props;

  return (
    <Button
      sx={{
        ...sx,
        ...(!uppercase && {
          textTransform: 'none',
        }),
        ...(variant === 'contained' && {
          backgroundColor: (theme) => theme.palette.layout?.control || theme.palette.primary.main,
        }),
        fontSize: (theme) => sx?.fontSize || theme.typography.small.fontSize,
        fontWeight: 400,
        borderRadius: '18px',
      }}
      variant={variant}
      size={size}
      {...rest}
    />
  );
};
