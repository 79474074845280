import axios from 'axios';
import { useCallback } from 'react';
import { useAppContext } from '../context/AppContext';
import { useProfileLoader } from './profile';

const TOKEN_SERVICE_STORAGE_KEY = 'tknssk';

class _security_context {
  static _access_token = null;
  static set_security(security) {
    this._access_token = security?.access_token;
    localStorage.setItem(TOKEN_SERVICE_STORAGE_KEY, security?.refresh_token);
  }
  static reset_security() {
    this._access_token = null;
    localStorage.removeItem(TOKEN_SERVICE_STORAGE_KEY);
  }
  static get_access_token() {
    return this._access_token;
  }
  static get_refresh_token() {
    return localStorage.getItem(TOKEN_SERVICE_STORAGE_KEY);
  }
}

function _getPublicHttp(headers) {
  const axiosHeaders = headers ? { ...headers } : { 'Content-Type': 'application/json' };
  const axiosHttp = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    timeout: 30000,
    headers: axiosHeaders,
  });
  return axiosHttp;
}

function _signout() {
  const refreshToken = _security_context.get_refresh_token();
  if (!refreshToken) {
    _security_context.reset_security();
  } else {
    _getPublicHttp()
      .delete(process.env.REACT_APP_LOGOUT_URL, {
        headers: {
          Authorization: `Bearer ${_security_context.get_access_token()}`,
        },
        data: {
          refreshToken,
        },
      })
      .finally(() => {
        _security_context.reset_security();
        //window.location.href = '/';
      });
  }
}

async function _refresh(tenant) {
  try {
    const refreshToken = _security_context.get_refresh_token();
    if (refreshToken) {
      let data = 'client_id=' + process.env.REACT_APP_GOOGLE_CLIENT_ID + '&refresh_token=' + refreshToken + '&grant_type=refresh_token&scope=email';
      if (tenant) {
        data += '&tenant=' + tenant;
      }
      const response = await _getPublicHttp().post(process.env.REACT_APP_AUTH_URL, data, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      });
      _security_context.set_security(response.data);
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);

    if (error.response) {
      console.error('error response data:');
      console.error(error.response.data);
      console.error('error response status:');
      console.error(error.response.status);
      console.error('error response headers:');
      console.error(error.response.headers);
      console.error('error message:');
      console.error(error.message);
      if (error.response.status === 401) {
        _signout();
      }
    }
  }
}

function _getHttp(config = {}) {
  const { headers } = config;
  const http = _getPublicHttp(headers);
  http.interceptors.request.use(
    (config) => {
      if (!config.headers['Authorization']) {
        config.headers['Authorization'] = `Bearer ${_security_context.get_access_token()}`;
      } else {
        console.log('Request has Authorization: ' + config.headers['Authorization']);
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  http.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 401 && !prevRequest?.sent) {
        prevRequest.sent = true;
        const res = await _refresh();
        console.log('Response interceptor after refresh. accessToken: ' + res.access_token);

        // https://github.com/axios/axios/issues/5143
        // https://stackoverflow.com/questions/74166648/setrequestheader-fails-to-execute-with-source-code-as-a-header-value-axios-an/74308583#74308583
        prevRequest.headers = { ...prevRequest.headers };

        prevRequest.headers['Authorization'] = `Bearer ${res.access_token}`;
        return http(prevRequest);
      }
      if (error?.response?.status === 401) {
        console.warn('unable to refresh token in interceptor');
        _signout();
      }
      return Promise.reject(error);
    }
  );
  return http;
}

export const useHttp = () => {
  const { resetUser } = useAppContext();
  const getPublicHttp = (headers) => {
    return _getPublicHttp(headers);
  };

  const requestInterceptor = useCallback(
    (config) => {
      const access_token = _security_context.get_access_token();
      if (!access_token) {
        console.warn('requestInterceptor. No access token - resetting user');
        resetUser();
        return config;
      }
      if (!config.headers['Authorization']) {
        config.headers['Authorization'] = `Bearer ${access_token}`;
      } else {
        console.log('Request has Authorization: ' + config.headers['Authorization']);
      }
      return config;
    },
    [_security_context._access_token]
  );

  const responseInterceptor = useCallback(
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 401 && !prevRequest?.sent) {
        prevRequest.sent = true;
        const res = await _refresh();
        if (!res) {
          console.warn('responseInterceptor. No refresh, resetting user');
          resetUser();
          return Promise.reject(error);
        }
        console.log('Response interceptor after refresh. accessToken: ' + res.access_token);

        // https://github.com/axios/axios/issues/5143
        // https://stackoverflow.com/questions/74166648/setrequestheader-fails-to-execute-with-source-code-as-a-header-value-axios-an/74308583#74308583
        prevRequest.headers = { ...prevRequest.headers };

        prevRequest.headers['Authorization'] = `Bearer ${res.access_token}`;
        return _getPublicHttp(prevRequest);
      }
      if (error?.response?.status === 401) {
        console.warn('unable to refresh token in interceptor');
        _signout();
      }
      return Promise.reject(error);
    },
    [_security_context._access_token]
  );

  const getHttp = (config = {}) => {
    const { headers } = config;
    const http = _getPublicHttp(headers);
    http.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
    http.interceptors.response.use((response) => response, responseInterceptor);
    return http;
  };

  return {
    getPublicHttp,
    getHttp,
  };
};

export const useAuthentication = () => {
  const { setUser, resetUser } = useAppContext();
  const profileLoader = useProfileLoader();

  const setData = (data) => {
    //console.log(data);

    if (data?.profile) {
      profileLoader.loadProfile(data.profile);
    }

    setUser({
      name: data.name,
      email: data.email,
      tenant: data.tenant || null,
      tenants: data.tenants || [],
      roles: data.roles || [],
    });
  };

  const signin = async (clientid, idtoken) => {
    try {
      const data = `client_id=${clientid}&code=${idtoken}&grant_type=authorization_code&scope=email`;
      const response = await _getPublicHttp().post(process.env.REACT_APP_AUTH_URL, data, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      });
      console.log(response.data);
      _security_context.set_security(response.data);
      setData(response.data);
    } catch (error) {
      console.log('auth throwing error' + error);
      throw new Error(error);
    }
  };
  const signout = () => {
    _signout();
    resetUser();
  };

  const refresh = async () => {
    const data = await _refresh();
    if (data) {
      setData(data);
    }
  };

  const switchTenant = async (tenant) => {
    try {
      const data = await _refresh(tenant);
      setData(data);
      window.location.reload();
    } catch (error) {}
  };

  return {
    signin,
    signout,
    refresh,
    switchTenant,
  };
};
