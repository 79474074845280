import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './context/AppContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppThemeProvider from './context/AppTheme';
import { HeaderContextProvider } from './context/HeaderContext';
import { PathContextProvider } from './context/PathContext';
import { DataContextProvider } from './context/DataContext';
import { TenantContextProvider } from './context/TenantContext';
import { DialogsContextProvider } from './context/DialogsContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppContextProvider>
      <TenantContextProvider>
        <AppThemeProvider>
          <HeaderContextProvider>
            <PathContextProvider>
              <DataContextProvider>
                <DialogsContextProvider>
                  <Routes>
                    <Route path='/*' element={<App />} />
                  </Routes>
                </DialogsContextProvider>
              </DataContextProvider>
            </PathContextProvider>
          </HeaderContextProvider>
        </AppThemeProvider>
      </TenantContextProvider>
    </AppContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
