import { Box, Divider, FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material';
import { useRef, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { AppBox } from '../AppBox';
import { useTheme } from '@emotion/react';
import { ELEMENT_POSITION, LANGUAGE_DIRECTION } from '../../Constants';
import { useAppContext } from '../../../context/AppContext';
import { MenuItem } from 'react-pro-sidebar';
import { AppButton } from '../AppButton';
import { AppDropdownMenu, AppDropdownMenuItem } from '../AppDropdownMenu';
import { AppIcon, ICON } from '../AppIcon';
import { useDataContext } from '../../../context/DataContext';
import { gridColumnVisibilityModelSelector } from '@mui/x-data-grid-pro';
import AppSwitch from '../AppSwitch';

export const AppPositionSelector = forwardRef((props, ref) => {
  const { onSelected } = props;
  const theme = useTheme();
  const { localization } = useAppContext();
  const dataContext = useDataContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const selected = dataContext.getProfile()?.position || ELEMENT_POSITION.SIDE;
  const open = Boolean(anchorEl);

  useImperativeHandle(ref, () => ({
    setAnchor: (anchor) => {
      setAnchorEl(anchor);
    },
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (onSelected) {
      onSelected();
    }
  };
  const onPositionSelect = (position) => {
    handleClose();
    dataContext.setProfile({
      position: position,
    });
  };
  return (
    <AppDropdownMenu
      id='details_position_menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: localization.dir === LANGUAGE_DIRECTION.LTR ? 'left' : 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: localization.dir === LANGUAGE_DIRECTION.LTR ? 'right' : 'left' }}
    >
      <AppDropdownMenuItem
        icon={ICON.Side}
        disabled={selected === ELEMENT_POSITION.SIDE}
        onClick={() => onPositionSelect(ELEMENT_POSITION.SIDE)}
        text={localization.translate('app.side')}
      />
      <AppDropdownMenuItem
        icon={ICON.Bottom}
        disabled={selected === ELEMENT_POSITION.BOTTOM}
        onClick={() => onPositionSelect(ELEMENT_POSITION.BOTTOM)}
        text={localization.translate('app.bottom')}
      />
      <AppDropdownMenuItem
        icon={ICON.Off}
        disabled={selected === ELEMENT_POSITION.OFF}
        onClick={() => onPositionSelect(ELEMENT_POSITION.OFF)}
        text={localization.translate('app.off')}
      />
    </AppDropdownMenu>
  );
});

export const AppGridDensitySelector = forwardRef((props, ref) => {
  const { apiRef } = props;
  const { localization } = useAppContext();
  const dataContext = useDataContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(dataContext.getProfile()?.density || 'compact');
  const open = Boolean(anchorEl);

  useImperativeHandle(ref, () => ({
    setAnchor: (anchor) => {
      setAnchorEl(anchor);
    },
  }));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetDensity = (density) => {
    apiRef.current.setDensity(density);
    dataContext.setProfile({
      density: density,
    });
    setSelected(density);
  };

  return (
    <AppDropdownMenu
      id='density_menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: localization.dir === LANGUAGE_DIRECTION.LTR ? 'left' : 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: localization.dir === LANGUAGE_DIRECTION.LTR ? 'right' : 'left' }}
    >
      <AppDropdownMenuItem
        icon={ICON.DensityCompact}
        disabled={selected === 'compact'}
        onClick={() => handleSetDensity('compact')}
        text={localization.translate('app.compact')}
      />
      <AppDropdownMenuItem
        icon={ICON.DensityStandard}
        disabled={selected === 'standard'}
        onClick={() => handleSetDensity('standard')}
        text={localization.translate('app.standard')}
      />
      <AppDropdownMenuItem
        icon={ICON.DensityComfortable}
        disabled={selected === 'comfortable'}
        onClick={() => handleSetDensity('comfortable')}
        text={localization.translate('app.comfortable')}
      />
    </AppDropdownMenu>
  );
});

export const AppGridColumnsSelector = forwardRef((props, ref) => {
  const { onSelected, apiRef } = props;
  const { localization } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [visibilityChanged, setVisibilityChanged] = useState(false);
  const [visibility, setVisibility] = useState([]);
  useImperativeHandle(ref, () => ({
    setAnchor: (anchor) => {
      setAnchorEl(anchor);
    },
  }));

  const handleClose = () => {
    setAnchorEl(null);
    if (onSelected) {
      onSelected();
    }
  };

  const getVisibility = (model) => {
    const all = apiRef.current.getAllColumns().filter((c) => c.hideable);
    const visibilityModel = model ? { ...model } : gridColumnVisibilityModelSelector(apiRef);
    const data = all.map((c, i) => {
      return {
        ...c,
        visible: visibilityModel[c.field] === undefined || !!visibilityModel[c.field],
      };
    });
    return data;
  };

  const handleGridColumnVisibilityModelChanged = (model) => {
    const data = getVisibility(model);
    setVisibility([...data]);
  };

  const onVisibilityChanged = (field, value) => {
    apiRef.current.setColumnVisibility(field, value);
  };

  const onVisibilityChangedAll = (value) => {
    const all = apiRef.current.getAllColumns().filter((c) => c.hideable);
    all.forEach((col, i) => apiRef.current.setColumnVisibility(col.field, value));
  };

  useEffect(() => {
    return apiRef.current.subscribeEvent('columnVisibilityModelChange', handleGridColumnVisibilityModelChanged);
  }, []);

  useEffect(() => {
    const data = getVisibility();
    setVisibility([...data]);
  }, [localization]);

  return (
    <AppDropdownMenu
      menuMinWidth={300}
      menuMaxHeight={500}
      id='columns_menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: localization.dir === LANGUAGE_DIRECTION.LTR ? 'left' : 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: localization.dir === LANGUAGE_DIRECTION.LTR ? 'right' : 'left' }}
    >
      {visibility.map((col, i) => {
        return (
          <MenuItem key={col.field}>
            <FormControlLabel
              control={<AppSwitch checked={col.visible} onChange={(e) => onVisibilityChanged(col.field, e.target.checked)} sx={{ margin: 1 }} />}
              label={col.headerName}
              sx={{ mx: 1 }}
            />
          </MenuItem>
        );
      })}
      <Divider />
      <AppBox flex>
        <AppButton variant='text' sx={{ mx: 1, my: 1 }} onClick={() => onVisibilityChangedAll(false)}>
          {localization.translate('app.hide_all')}
        </AppButton>
        <Box sx={{ flexGrow: 1 }} />
        <AppButton variant='text' sx={{ mx: 1, my: 1 }} onClick={() => onVisibilityChangedAll(true)}>
          {localization.translate('app.show_all')}
        </AppButton>
      </AppBox>
    </AppDropdownMenu>
  );
});

export const AppDataGridLayoutMenu = (props) => {
  const { layoutMenuProps, apiRef } = props;
  const { localization } = useAppContext();
  const theme = useTheme();
  const dataContext = useDataContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const detailsMenuRef = useRef();
  const densityMenuRef = useRef();
  const columnsMenuRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const initialState = dataContext.getProfile()?.initState;
    let _initialState = null;
    if (!initialState?.state) {
      const exportedState = apiRef.current.exportState();
      // we use server side sorting and filtering, so not required
      delete exportedState.sorting;
      delete exportedState.filter;
      delete exportedState.pagination;
      _initialState = {
        state: { ...exportedState },
      };
    }
    if (!initialState?.visibilityModel) {
      _initialState = {
        ..._initialState,
        visibilityModel: { ...gridColumnVisibilityModelSelector(apiRef) },
      };
    }
    if (_initialState) {
      dataContext.setProfile({
        initState: { ..._initialState },
      });
    }
  }, []);

  const handleReset = () => {
    const initState = dataContext.getProfile()?.initState;
    if (initState?.state) {
      delete initState.state.sorting;
      delete initState.state.filter;
      delete initState.state.pagination;
      apiRef.current.restoreState(initState.state);
    }
    if (initState?.visibilityModel) {
      apiRef.current.setColumnVisibilityModel(initState.visibilityModel);
    }

    dataContext.resetProfile();
    handleClose();
  };

  return (
    <AppBox flex center>
      <Tooltip title={localization.translate('app.layout_options')} enterDelay={500}>
        <IconButton
          onClick={handleClick}
          id='lauout_menu_button'
          aria-controls={open ? 'layout_menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <AppIcon icon={ICON.Layout} />
        </IconButton>
      </Tooltip>
      <AppDropdownMenu id='layout_menu' aria-labelledby='layout_menu_button' anchorEl={anchorEl} open={open} onClose={handleClose}>
        <AppDropdownMenuItem
          icon={ICON.Position}
          text={localization.translate('app.details_view_position')}
          onClick={(e) => detailsMenuRef.current.setAnchor(e.currentTarget)}
        />
        <AppDropdownMenuItem
          icon={ICON.Density}
          text={localization.translate('app.density')}
          onClick={(e) => densityMenuRef.current.setAnchor(e.currentTarget)}
        />
        <AppDropdownMenuItem
          icon={ICON.Columns}
          text={localization.translate('app.columns')}
          onClick={(e) => columnsMenuRef.current.setAnchor(e.currentTarget)}
        />
        <Divider variant='middle' />
        <AppDropdownMenuItem icon={ICON.Reset} text={localization.translate('app.reset_layout')} onClick={handleReset} />
      </AppDropdownMenu>
      <AppGridDensitySelector ref={densityMenuRef} apiRef={apiRef} />
      <AppGridColumnsSelector ref={columnsMenuRef} onSelected={() => {}} apiRef={apiRef} />
      <AppPositionSelector ref={detailsMenuRef} onSelected={() => handleClose()} />
    </AppBox>
  );
};
