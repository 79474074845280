import { useState, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AppBox } from '../../../common/components/AppBox';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useHttp } from '../../../hooks/authentication';
import { useAppContext } from '../../../context/AppContext';
import { useJobsContext } from '../../../context/JobsContext';
import AppInnerAlert from '../../../common/components/AppInnerAlert';
import { AppText } from '../../../common/components/AppText';

const FileUploadArea = styled('label', {
  shouldForwardProp: (prop) => prop !== 'isDragOver',
})(({ theme, isDragOver }) => ({
  cursor: 'pointer',
  textAlign: 'center',
  display: 'flex',
  opacity: 0.5,
  '&:hover': {
    opacity: 1,
  },
  ...(isDragOver && {
    opacity: 1,
  }),
}));

const IMPORT_STATE = {
  IDLE: 1,
  UPLOADING: 2,
  UPLOADED: 3,
  ERROR: 4,
};

// see for accept file types: https://stackoverflow.com/questions/11832930/html-input-file-accept-attribute-file-type-csv
const FileUploadImportProvider = forwardRef((props, ref) => {
  const { hoverLabel, dropLabel } = props;
  const { getHttp } = useHttp();
  const { localization } = useAppContext();
  const { loadJobs } = useJobsContext();
  const [isDragOver, setIsDragOver] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [labelText, setLabelText] = useState(hoverLabel);
  const [importState, setImportState] = useState(IMPORT_STATE.IDLE);

  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (event) => {
    upload(event.dataTransfer.files[0]);
  };

  const handleInput = (event) => {
    if (event.target.files !== null && event.target?.files?.length > 0) {
      upload(event.target.files[0]);
    } else {
      console.warn('No file specified');
    }
  };

  const upload = async (file) => {
    setImportState(IMPORT_STATE.UPLOADING);
    const formData = new FormData();
    formData.append('file', file);

    try {
      await getHttp().post('transactions', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setImportState(IMPORT_STATE.UPLOADED);
      loadJobs(); // probably no await here
    } catch (error) {
      console.error('Error uploading donations');
      console.error(error);
      setImportState(IMPORT_STATE.ERROR);
    }
  };

  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      handleDrop(e);
    },
  };

  return (
    <AppBox flex column center sx={{ height: '100%', flexGrow: 1 }}>
      <FileUploadArea {...dragEvents} isDragOver={isDragOver}>
        <input
          onInput={handleInput}
          accept='.csv, .json'
          id='file-upload'
          type='file'
          style={{ display: 'none' }}
          disabled={importState === IMPORT_STATE.UPLOADING}
        />
        <AppBox flex column center height='100px' sx={{ pointerEvents: 'none', width: '100%' }}>
          <CloudUploadIcon fontSize='large' />
          <AppText>{localization.translate(labelText)}</AppText>
        </AppBox>
      </FileUploadArea>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {importState === IMPORT_STATE.UPLOADING && <CircularProgress />}
        {importState === IMPORT_STATE.UPLOADED && (
          <AppInnerAlert
            open={importState === IMPORT_STATE.UPLOADED}
            severity='success'
            onClose={() => setImportState(IMPORT_STATE.IDLE)}
            content={localization.translate('donations.import_transactions_submitted')}
          />
        )}
        {importState === IMPORT_STATE.ERROR && (
          <AppInnerAlert
            open={importState === IMPORT_STATE.ERROR}
            severity='error'
            onClose={() => setImportState(IMPORT_STATE.IDLE)}
            content={`${localization.translate('donations.import_transactions_error')}. ${localization.translate('errors.try_again')}`}
          />
        )}
      </Box>
    </AppBox>
  );
});

export default FileUploadImportProvider;
