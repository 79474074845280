import { useTheme } from '@emotion/react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, IconButton } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { AppQuickFilter } from '../../../../../common/components/AppQuickFilter';
import { AppBox } from '../../../../../common/components/AppBox';
import { AppButton } from '../../../../../common/components/AppButton';
import { AppIcon, ICON, ICON_SIZE } from '../../../../../common/components/AppIcon';
import AppSwitch from '../../../../../common/components/AppSwitch';
import { AppText, InlineTextField } from '../../../../../common/components/AppText';
import { PAYMENT_PROVIDERS } from '../../../../../common/Constants';
import { useAppContext } from '../../../../../context/AppContext';
import { useHttp } from '../../../../../hooks/authentication';
import { IsraelGivesSourceForm } from './IsraelGivesSourceForm';
import { PaypalSourceForm } from './PaypalSourceForm';
import AppDialogForm from '../../../../../common/components/AppDialogForm';

const Providers = [
  {
    name: PAYMENT_PROVIDERS.PAYPAL,
    label: 'app.paypal',
    icon: ICON.Paypal,
    formProps: {
      component: PaypalSourceForm,
    },
  },
  {
    name: PAYMENT_PROVIDERS.ISRAEL_GIVES,
    label: 'app.israelgives',
    icon: ICON.IsraelGives,
    formProps: {
      component: IsraelGivesSourceForm,
    },
  },
];

const Source = (props) => {
  const { source, provider, onUpdate } = props;
  const theme = useTheme();
  const { localization } = useAppContext();
  const [nameEdited, setNameEdited] = useState(false);
  const [data, setData] = useState({});
  const formRef = useRef(null);

  useEffect(() => {
    if (source) {
      setData({ ...source });
    }
  }, [source]);

  const onEditClick = (e) => {
    formRef.current.open();
  };

  const onDeleteClick = (e) => {
    if (onUpdate) {
      onUpdate(data, true);
    }
  };

  const handleEditName = () => {
    const edit = !nameEdited;
    setNameEdited(!nameEdited);
    if (!edit) {
      if (onUpdate) {
        onUpdate(data);
      }
    }
  };

  const handleCancelEditName = () => {
    setData({ ...data, displayName: source.displayName });
    setNameEdited(!nameEdited);
  };

  return (
    <AppBox flex centery sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
      {nameEdited && <AppSwitch checked={data.enabled} onChange={() => setData({ ...data, enabled: !data.enabled })} />}
      <AppIcon
        icon={ICON.Dot}
        size={ICON_SIZE.Small}
        style={{
          color: data.enabled ? theme.palette.success.main : theme.palette.text.disabled,
        }}
      />
      <InlineTextField
        readonly={!nameEdited}
        value={data.displayName || ''}
        placeholder={localization.translate('settings.no_display_name')}
        InputProps={{
          sx: {
            paddingRight: 0,
            width: '300px',
          },
        }}
        inputProps={{
          style: {
            textOverflow: 'ellipsis',
          },
        }}
        onChange={(value) => setData({ ...data, displayName: value })}
      />
      <IconButton aria-label='edit-name-button' onClick={() => handleEditName()}>
        <AppIcon icon={nameEdited ? ICON.Save : ICON.Edit} size={ICON_SIZE.Small} />
      </IconButton>
      {nameEdited && (
        <IconButton aria-label='cancel-edit-name-button' onClick={handleCancelEditName}>
          <AppIcon icon={ICON.Close} />
        </IconButton>
      )}
      <AppBox sx={{ flexGrow: 1 }} />
      <IconButton aria-label='edit-source-button' onClick={onEditClick}>
        <AppIcon icon={ICON.SettingsCog} size={ICON_SIZE.Small} />
      </IconButton>
      <IconButton aria-label='delete-source-button' onClick={onDeleteClick}>
        <AppIcon icon={ICON.Delete} size={ICON_SIZE.Small} />
      </IconButton>
      {provider?.formProps?.component && (
        <AppDialogForm
          ref={formRef}
          details={data}
          onSubmit={onUpdate}
          slot={{
            component: provider.formProps.component,
          }}
          title={localization.translate('settings.edit_account') + ' - ' + localization.translate(provider.label)}
          formId={`edit-account-${provider.label}-form`}
        />
      )}
    </AppBox>
  );
};

const SourceList = (props) => {
  const { sources, provider, onUpdate } = props;
  const { localization } = useAppContext();
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  useEffect(() => {
    if (sources?.length > 0) setData([...sources]);
  }, [sources]);

  const filterSources = (pattern) => {
    setData(pattern ? [...sources.filter((s) => s.displayName?.includes(pattern))] : [...sources]);
  };
  return (
    <AppBox flex column sx={{ pr: 10, maxHeight: '400px', overflow: 'auto' }}>
      <AppBox flex>
        <AppBox sx={{ flexGrow: 1 }} />
        <AppQuickFilter sx={{ mx: 2 }} onChange={filterSources} onReset={filterSources} />
        <AppButton startIcon={<AppIcon icon={ICON.Add} />} onClick={() => formRef.current.open()}>
          {localization.translate('settings.add_account')}
        </AppButton>
        {provider?.formProps?.component && (
          <AppDialogForm
            ref={formRef}
            onSubmit={onUpdate}
            slot={{
              component: provider.formProps.component,
            }}
            title={localization.translate('settings.add_account') + ' - ' + localization.translate(provider.label)}
            formId={`add-account-${provider.label}-form`}
          />
        )}
      </AppBox>
      <AppBox flex centery>
        <AppText variant='smallb'>{localization.translate('settings.display_name')}</AppText>
      </AppBox>
      {data.map((s, i) => {
        return <Source key={i} source={s} provider={provider} onUpdate={onUpdate} />;
      })}
    </AppBox>
  );
};

const ProviderStatusIndicator = (props) => {
  const theme = useTheme();
  const { localization } = useAppContext();
  const { sources = [] } = props;

  const enabled = sources.filter((s) => s.enabled).length;
  const disabled = enabled !== sources.length ? sources.length - enabled : 0;
  return sources.length <= 0 ? (
    <></>
  ) : (
    <AppBox flex centery>
      <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
        {`${localization.translate('settings.total_accounts')} ${sources.length}: `}
      </AppText>
      {!!enabled && (
        <>
          <AppIcon icon={ICON.Dot} size={ICON_SIZE.Small} style={{ color: theme.palette.success.main, float: 'left' }} />
          <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
            {`${localization.translate('settings.fetach_auto')} ${enabled} `}
          </AppText>
          {!!disabled && <Divider orientation='vertical' flexItem sx={{ my: '3px', ml: '6px', mr: '3px' }} />}
        </>
      )}
      {!!disabled && (
        <>
          <Divider orientation='vertical' />
          <AppIcon icon={ICON.Dot} size={ICON_SIZE.Small} style={{ color: theme.palette.text.disabled, float: 'left' }} />
          <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
            {`${localization.translate('settings.fetch_manual')} ${disabled}`}
          </AppText>
        </>
      )}
    </AppBox>
  );
};

const SourceProviderConfiguration = (props) => {
  const { provider, index, onUpdate, sources = [] } = props;
  const { localization } = useAppContext();
  const providerSources = sources.filter((s) => s.provider === provider.name);

  return (
    <Accordion
      sx={{
        mb: 2,
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
      }}
      square
      elevation={1}
      disableGutters
    >
      <AccordionSummary aria-controls={`panel${index}src-content`} id={`panel${index}src-header`}>
        <AppBox flex centery>
          <AppIcon icon={provider.icon} style={{ width: '40px', height: '40px' }} />
          <AppBox flex column centery sx={{ mx: 2 }}>
            <AppText variant='large' sx={{ color: (theme) => theme.palette.text.secondary }}>
              {localization.translate(provider.label)}
            </AppText>
            <ProviderStatusIndicator sources={providerSources} />
          </AppBox>
        </AppBox>
      </AccordionSummary>
      <AccordionDetails>
        <SourceList sources={providerSources} provider={provider} onUpdate={onUpdate} />
      </AccordionDetails>
    </Accordion>
  );
};

export const SourcesConfiguration = (props) => {
  const { getHttp } = useHttp();
  const [sources, setSources] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await getHttp().get('sources');
      setSources(response.data);
    })();
  }, []);

  const handleUpdateSource = async (source, deleted) => {
    try {
      const updated = [...sources];
      if (deleted) {
        const response = await getHttp().request({
          method: 'delete',
          url: `sources/${source.id}`,
        });

        const index = updated.findIndex((e) => e.id === source?.id);
        if (index >= 0) {
          updated.splice(index, 1);
        }
      } else {
        const response = source.id ? await getHttp().put(`sources/${source.id}`, source) : await getHttp().post('sources', source);

        const index = updated.findIndex((e) => e.id === response.data.id);
        if (index >= 0) {
          updated.splice(index, 1, { ...updated[index], ...response.data });
        } else {
          updated.push({ ...response.data });
        }
      }
      setSources([...updated]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {Providers.map((p, i) => {
        return <SourceProviderConfiguration key={i} provider={p} index={i} sources={sources} onUpdate={handleUpdateSource} />;
      })}
    </>
  );
};
