import { useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { AppDropdownMenu, AppDropdownMenuItem } from './AppDropdownMenu';
import { RECORD_STATE } from '../Constants';
import { useAppContext } from '../../context/AppContext';
import { ICON } from './AppIcon';

const StatusItems = [
  {
    text: 'New',
    icon: ICON.AddCircle,
    value: RECORD_STATE.NEW,
  },
  {
    text: 'Approved',
    icon: ICON.Confirmed,
    value: RECORD_STATE.APPROVED,
  },
  {
    text: 'Questioned',
    icon: ICON.Questioned,
    value: RECORD_STATE.QUESTIONED,
  },
];

const getStatusColor = (status, theme) => {
  switch (status) {
    case RECORD_STATE.NEW:
      return theme.palette.status.new;
    case RECORD_STATE.QUESTIONED:
      return theme.palette.status.questioned;
    default:
      return theme.palette.text.primary;
  }
};

const StatusSelector = (props) => {
  const { id, field, value, row, onUpdate } = props;
  const apiRef = useGridApiContext();
  const { localization } = useAppContext();
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(apiRef.current.getCellElement(id, field));

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const onStateSelect = async (state) => {
    handleClose();
    const rows = [{ ...row, trackingState: state }];
    apiRef.current.stopCellEditMode({ id, field });
    if (onUpdate) {
      try {
        await onUpdate(rows);
        apiRef.current.updateRows(rows);
      } catch (error) {
        console.error(error);
      }
    } else {
      apiRef.current.updateRows(rows);
    }
  };

  return (
    <AppDropdownMenu id='status-selector-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
      <AppDropdownMenuItem
        icon={ICON.AddCircle}
        disabled={value === RECORD_STATE.NEW}
        onClick={() => onStateSelect(RECORD_STATE.NEW)}
        text={localization.translate('app.new')}
      />
      <AppDropdownMenuItem
        icon={ICON.Confirmed}
        disabled={value === RECORD_STATE.APPROVED}
        onClick={() => onStateSelect(RECORD_STATE.APPROVED)}
        text={localization.translate('app.approved')}
      />
      <AppDropdownMenuItem
        icon={ICON.Questioned}
        disabled={value === RECORD_STATE.QUESTIONED}
        onClick={() => onStateSelect(RECORD_STATE.QUESTIONED)}
        text={localization.translate('app.questioned')}
      />
    </AppDropdownMenu>
  );
};

export { StatusItems, StatusSelector, getStatusColor };
