import { useState, forwardRef, useEffect, useRef, useImperativeHandle, useMemo } from 'react';

const AppForm = forwardRef((props, ref) => {
  const { details, onSubmit, slot, ...rest } = props;

  const [data, setData] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const [invalidInputs, setInvalidInputs] = useState({});
  const formRef = useRef();

  const hasFieldsToUpdate = () => {
    const keys = Object.keys(fieldsToUpdate);
    if (!keys || keys.length <= 0) {
      return false;
    }
    for (const key of keys) {
      if (fieldsToUpdate[key]) {
        return true;
      }
    }
    return false;
  };

  const hasInvalidInputs = (obj) => {
    const keys = Object.keys(invalidInputs);
    if (!keys || keys.length <= 0) {
      return false;
    }
    for (const key of keys) {
      if (invalidInputs[key]) {
        return true;
      }
    }
    return false;
  };
  const setFieldToUpdate = (name) => {
    setFieldsToUpdate({ ...fieldsToUpdate, [name]: true });
  };
  const unsetFieldToUpdate = (name) => {
    setFieldsToUpdate({ ...fieldsToUpdate, [name]: false });
  };

  const setInvalidInput = (name) => {
    setInvalidInputs({ ...invalidInputs, [name]: true });
  };

  const unsetInvalidInput = (name) => {
    const data = { ...invalidInputs, [name]: false };
    setInvalidInputs(data);
  };

  const hasOriginalField = (key) => {
    return !!details && details[key] !== undefined && details[key] !== null;
  };

  const setDataField = (props) => {
    const { key, value, validateChange = true, validateInput, changeValidator, inputValidator } = props;
    const defaultChangeValidator = (v) => {
      if (!details) {
        return true;
      }
      if (!hasOriginalField(key) && !Boolean(v)) {
        return false;
      }
      return details[key] !== v;
    };
    const defaultInputValidator = (v) => {
      return !Boolean(v);
    };

    unsetInvalidInput(key); // make sure no invalid input marked for this key
    if (validateInput) {
      const validator = inputValidator || defaultInputValidator;
      if (validator(value)) {
        // value is invalid
        setInvalidInput(key);
      }
    }
    if (validateChange) {
      const validator = changeValidator || defaultChangeValidator;
      if (validator(value)) {
        // value has changed
        setFieldToUpdate(key);
      } else {
        // valus hasn't changed
        unsetFieldToUpdate(key);
      }
    }
    setData({ ...data, [key]: value });
  };

  const submitData = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const updatedFields = { ...fieldsToUpdate };
    setFieldsToUpdate({});
    if (setDisableSubmit) {
      setDisableSubmit(true);
    }
    const dataToSubmit = formRef?.current?.submittingData ? formRef.current.submittingData(data) : { ...data };
    try {
      if (onSubmit) {
        const response = await onSubmit(dataToSubmit);
        if (formRef?.current?.onSubmitResponse) {
          await formRef?.current.onSubmitResponse(response);
        }
      }
    } catch (error) {
      setFieldsToUpdate({ ...updatedFields });
      throw error;
    }
  };

  useEffect(() => {
    if (hasInvalidInputs()) {
      setDisableSubmit(true);
      if (rest?.setDisableSubmit) {
        rest.setDisableSubmit(true);
      }
    } else {
      let res = !hasFieldsToUpdate();
      if (res) {
        // nothing to update
        if (rest.onSetDisableSubmit) {
          res = rest.onSetDisableSubmit();
        }
      }
      setDisableSubmit(res);
      if (rest?.setDisableSubmit) {
        rest.setDisableSubmit(res);
      }
    }
  }, [fieldsToUpdate, invalidInputs]);

  useEffect(() => {
    setInvalidInputs({});
    setFieldsToUpdate({});
    const defaultNewData = !!details ? { ...details } : {};
    const newData = formRef?.current?.deployingData ? formRef.current.deployingData(details) : defaultNewData;
    setData(newData);
  }, [details]);

  const api = useMemo(() => {
    return {
      details,
      data,
      disableSubmit,
      submitData,
      setData,
      setDataField,
      setFieldToUpdate,
      setInvalidInput,
      setFieldsToUpdate,
      setInvalidInputs,
      setDisableSubmit,
      unsetFieldToUpdate,
      unsetInvalidInput,
      hasFieldsToUpdate,
      hasInvalidInputs,
    };
  }, [data, details, disableSubmit, fieldsToUpdate, invalidInputs]);

  useImperativeHandle(ref, () => ({
    submitData,
  }));

  return <slot.component ref={formRef} {...slot.props} api={api} />;
});

export default AppForm;
