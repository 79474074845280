import { useEffect, useState } from 'react';
import { AppBox } from '../common/components/AppBox';
import { AppIcon, ICON, ICON_SIZE } from '../common/components/AppIcon';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, useTheme } from '@mui/material';
import { useAppContext } from '../context/AppContext';
import { ROUTE_PATHS } from '../common/Constants';
import { usePathContext, useSetPathContext } from '../context/PathContext';

const NavItems = [
  {
    icon: ICON.DashboardHeader,
    title: 'app.dashboard',
    path: ROUTE_PATHS.Dashboard,
  },
  {
    icon: ICON.CampaignHeader,
    title: 'app.campaigns',
    path: ROUTE_PATHS.Campaigns,
  },
  {
    icon: ICON.DonationHeader,
    title: 'app.donations',
    path: ROUTE_PATHS.Donations,
  },
  {
    icon: ICON.ExpensesHeader,
    title: 'app.expenses',
    path: ROUTE_PATHS.Expenses,
  },
];

const NavbarItem = (props) => {
  const { item, index, onClick, ...rest } = props;
  const { localization } = useAppContext();
  const theme = useTheme();
  const path = usePathContext();
  const setPath = useSetPathContext();
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick(index);
    }
    setPath(item.path);
    navigate(item.path);
  };

  return (
    <AppBox
      flex
      center
      sx={{
        cursor: 'pointer',
        mx: 1,
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.background.default,
        },
      }}
      onClick={handleClick}
    >
      <BottomNavigationAction
        {...rest}
        label={localization.translate(item.title)}
        icon={
          <AppIcon icon={item.icon} size={ICON_SIZE.Large} style={{ color: path === item.path ? theme.palette.primary.main : theme.palette.text.primary }} />
        }
        sx={{
          borderRadius: '4px',
          '&.MuiBottomNavigationAction-root': {
            color: (theme) => (path === item.path ? theme.palette.text.primary : theme.palette.text.secondary),
            /*
            '&.Mui-selected': {
              color: (theme) => theme.palette.primary.main,
            },
            */
            '& .MuiBottomNavigationAction-label': {
              fontSize: (theme) => (path === item.path ? theme.typography.small.fontSize : theme.typography.xsmall.fontSize),
              ...(path === item.path && {
                color: (theme) => theme.palette.text.primary,
                fontWeight: 500,
              }),
              /*
              '&.Mui-selected': {
                color: (theme) => theme.palette.primary.main,
                fontWeight: 600,
                //fontSize: (theme) => theme.typography.large.fontSize,
              },
              */
            },
          },
        }}
      />
      {item?.items && <AppIcon icon={ICON.ChevronDown} size={ICON_SIZE.Small} />}
    </AppBox>
  );
};

const Navbar = (props) => {
  const path = usePathContext();
  const [activeIndex, setActiveIndex] = useState();
  useEffect(() => {
    let index = NavItems.findIndex((i) => i.path === path);
    if (index < 0) {
      index = 0;
    }
    setActiveIndex(index);
  }, [path]);
  return (
    <AppBox flex center sx={{ mx: 1, flexGrow: 1 }}>
      <BottomNavigation showLabels value={activeIndex}>
        {NavItems.map((item, i) => {
          return <NavbarItem key={i} index={i} item={item} onClick={(i) => setActiveIndex(i)} />;
        })}
      </BottomNavigation>
    </AppBox>
  );
};

export default Navbar;
