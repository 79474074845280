import { useEffect, forwardRef, useState, useImperativeHandle, useRef } from 'react';
import Attachments from '../../common/components/Attachments';
import { useHttp } from '../../hooks/authentication';

const ExpenseAttachments = forwardRef((props, ref) => {
  const { id, ...rest } = props;
  const { getHttp } = useHttp();
  const listRef = useRef(null);
  const [items, setItems] = useState([]);

  const load = async () => {
    if (id) {
      try {
        const response = await getHttp().get(`expenses/${id}/attachments`);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const create = async (item, id) => {
    const formData = new FormData();
    formData.append('file', item.file);
    formData.append('displayName', item.displayName || item.file.name);
    formData.append('filename', item.file.name);
    await getHttp({ headers: { 'Content-Type': 'multipart/form-data' } }).post(`expenses/${id}/attachments`, formData);
  };

  useEffect(() => {
    (async () => {
      setItems([]);
      const attachments = await load();
      if (attachments && attachments.length > 0) {
        setItems(attachments);
      }
    })();
  }, [id]);

  const add = async (item) => {
    if (id) {
      try {
        await create(item, id);
        const data = await load();
        setItems(data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const remove = async (item) => {
    try {
      await getHttp().request({
        method: 'delete',
        url: `expenses/${id}/attachments/${item.id}`,
      });
      const data = await load();
      setItems(data);
    } catch (error) {
      console.error(error);
    }
  };

  const update = async (item) => {
    try {
      await getHttp().put(`expenses/${id}/attachments/${item.id}`, { displayName: item.displayName });
      const data = await load();
      setItems(data);
    } catch (error) {
      console.error(error);
    }
  };

  const download = async (item) => {
    try {
      const response = await getHttp().get(`expenses/${id}/attachments/${item.id}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', item.filename || '');
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  useImperativeHandle(ref, () => ({
    upload: async (id) => {
      const attachments = listRef.current.getAttachments();
      if (attachments?.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          try {
            await create(attachments[i], id);
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
  }));

  return <Attachments ref={listRef} {...(!!id && { items: items })} onAdd={add} onUpdate={update} onRemove={remove} onDownload={download} />;
});

export default ExpenseAttachments;
