import { Configuration } from '../Configuration';
import { FinancialConfiguration } from './financial/FinancialConfiguration';
import { ExternalServicesConfiguration } from './externalServices/ExternalServicesConfiguration';
import { TenantSecurityConfiguration } from './security/TenantSecurityConfiguration';
import { GeneralSettings } from './general/GeneralSettings';

const CONFIGURATION_INDEX = {
  GENERAL: 1,
  FINANCIAL: 2,
  SECURITY: 3,
  EXTERNAL: 4,
};
const Configurations = [
  {
    index: CONFIGURATION_INDEX.GENERAL,
    component: GeneralSettings,
    componentProps: {},
    label: 'settings.general',
  },
  {
    index: CONFIGURATION_INDEX.FINANCIAL,
    component: FinancialConfiguration,
    componentProps: {},
    label: 'settings.financial',
  },
  {
    index: CONFIGURATION_INDEX.SECURITY,
    component: TenantSecurityConfiguration,
    componentProps: {},
    label: 'settings.security',
  },
  {
    index: CONFIGURATION_INDEX.EXTERNAL,
    component: ExternalServicesConfiguration,
    label: 'settings.external_services',
  },
];

export const TenantConfiguration = (props) => {
  const { path } = props;
  return <Configuration path={path} title='settings.tenant_configuration_title' configurations={Configurations} indexes={CONFIGURATION_INDEX} />;
};
