import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import { NOTIFICATION_SEVIRITY } from '../Constants';

const AppNotification = (props) => {
  const { open, vertical = 'bottom', horizontal = 'center', onClose, severity, message } = props;

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <Snackbar open={open} anchorOrigin={{ vertical: vertical, horizontal: horizontal }} onClose={handleOnClose} autoHideDuration={6000} sx={{ width: '100%' }}>
      <Alert severity={severity} onClose={handleOnClose} sx={{ width: '50%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

AppNotification.propTypes = {
  vertical: PropTypes.oneOf(['bottom', 'top']),
  horizontal: PropTypes.oneOf(['center', 'left', 'right']),
  onClose: PropTypes.func,
  severity: PropTypes.oneOf(Object.values(NOTIFICATION_SEVIRITY)).isRequired,
  message: PropTypes.string.isRequired,
};

export default AppNotification;
