import { useAppContext } from '../context/AppContext';
import { useHttp } from './authentication';

export const usePaymentProviders = () => {
  const { getHttp } = useHttp();
  const { localization } = useAppContext();

  const load = async () => {
    try {
      const response = await getHttp().get('sources');
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return {
    load,
  };
};
