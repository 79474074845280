import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { AppBox } from './AppBox';
import { AppText } from './AppText';
import AppInnerAlert from './AppInnerAlert';
import { CircularProgress } from '@mui/material';
import { useAppContext } from '../../context/AppContext';
import { AppIcon, ICON, ICON_SIZE } from './AppIcon';

const FileUploadArea = styled('label', {
  shouldForwardProp: (prop) => prop !== 'isDragOver' && prop !== 'useOpacity',
})(({ theme, isDragOver, useOpacity }) => ({
  cursor: 'pointer',
  textAlign: 'center',
  display: 'flex',
  ...(useOpacity && { opacity: 0.5 }),
  '&:hover': {
    opacity: 1,
  },
  ...(isDragOver && {
    opacity: 1,
  }),
}));

const IMPORT_STATE = {
  IDLE: 1,
  UPLOADING: 2,
  UPLOADED: 3,
  ERROR: 4,
};

const FileUploader = (props) => {
  const { showNotification = true, useOpacity = true, content, accept, onUpload, errorNotificationProps, successNotificationProps } = props;
  const { localization } = useAppContext();
  const [isDragOver, setIsDragOver] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [importState, setImportState] = useState(IMPORT_STATE.IDLE);

  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (event) => {
    upload(event.dataTransfer.files[0]);
  };

  const handleInput = (event) => {
    if (event.target.files !== null && event.target?.files?.length > 0) {
      upload(event.target.files[0]);
    } else {
      console.warn('No file specified');
    }
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      handleDrop(e);
    },
  };

  const upload = async (file) => {
    setImportState(IMPORT_STATE.UPLOADING);
    try {
      if (onUpload) {
        await onUpload(file);
      }
      setImportState(IMPORT_STATE.UPLOADED);
    } catch (error) {
      console.error('Error uploading file');
      console.error(error);
      setImportState(IMPORT_STATE.ERROR);
    }
  };

  return (
    <AppBox flex column center sx={{ height: '100%', width: '100%', flexGrow: 1 }}>
      <FileUploadArea {...dragEvents} isDragOver={isDragOver} useOpacity={useOpacity}>
        <input
          onClick={(e) => (e.target.value = null)}
          onInput={handleInput}
          id='file-upload'
          type='file'
          {...(!!accept && { accept: accept })}
          style={{ display: 'none' }}
          disabled={importState === IMPORT_STATE.UPLOADING}
        />

        <AppBox flex column center height='100px' sx={{ pointerEvents: 'none', width: '100%' }}>
          {!!content ? (
            content()
          ) : (
            <>
              <AppIcon icon={ICON.CloudUpload} size={ICON_SIZE.ExtraLarge} />
              <AppText variant='normal'>{localization.translate('app.upload_file_description')}</AppText>
            </>
          )}
        </AppBox>
      </FileUploadArea>

      {showNotification && (
        <AppBox flex center>
          {importState === IMPORT_STATE.UPLOADING && <CircularProgress />}
          {successNotificationProps && importState === IMPORT_STATE.UPLOADED && (
            <AppInnerAlert
              open={importState === IMPORT_STATE.UPLOADED}
              severity='success'
              onClose={() => setImportState(IMPORT_STATE.IDLE)}
              content={successNotificationProps.message || localization.translate('app.data_save_success')}
            />
          )}
          {errorNotificationProps && importState === IMPORT_STATE.ERROR && (
            <AppInnerAlert
              open={importState === IMPORT_STATE.ERROR}
              severity='error'
              onClose={() => setImportState(IMPORT_STATE.IDLE)}
              content={errorNotificationProps.message || `${localization.translate('app.data_save_error')}. ${localization.translate('errors.try_again')}`}
            />
          )}
        </AppBox>
      )}
    </AppBox>
  );
};

export default FileUploader;
