import { useContext, useState, createContext } from 'react';

const pathContext = createContext();
const setPathContext = createContext();

const PathContextProvider = ({ children }) => {
  const [path, setPath] = useState(null);

  return (
    <pathContext.Provider value={path}>
      <setPathContext.Provider value={setPath}>{children}</setPathContext.Provider>
    </pathContext.Provider>
  );
};

const usePathContext = () => {
  const context = useContext(pathContext);
  if (context === undefined) {
    throw new Error('usePathContext must be used within PathContextProvider');
  }
  return context;
};

const useSetPathContext = () => {
  const context = useContext(setPathContext);
  if (context === undefined) {
    throw new Error('useSetPathContext must be used within PathContextProvider');
  }
  return context;
};

export { PathContextProvider, usePathContext, useSetPathContext };
