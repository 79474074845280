import { useTheme } from '@emotion/react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { Link, MenuItem, Typography } from '@mui/material';
import { AppIcon } from '../components/AppIcon';
import { AppText } from './AppText';

const StyledDropdownMenu = styled(Menu, { shouldForwardProp: (prop) => prop !== 'iconColor' && prop !== 'menuMinWidth' && prop !== 'menuMaxHeight' })(
  ({ theme, iconColor, menuMinWidth, menuMaxHeight }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: menuMinWidth ? menuMinWidth : 180,
      ...(menuMaxHeight && {
        maxHeight: menuMaxHeight,
      }),
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: iconColor || theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  })
);

const AppDropdownMenu = (props) => {
  const { anchorOrigin = { vertical: 'bottom', horizontal: 'right' }, transformOrigin = { vertical: 'top', horizontal: 'right' }, iconColor, ...rest } = props;
  const theme = useTheme();
  return (
    <StyledDropdownMenu
      elevation={0}
      {...rest}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      directtion={theme.direction}
      iconColor={iconColor}
    />
  );
};

const AppDropdownMenuItem = (props) => {
  const { icon, iconSize, text, type = 'text', tooltip, iconStyle, disabled = false, onClick, children, ...rest } = props;
  return (
    <MenuItem {...(onClick && { onClick: onClick })} disabled={disabled} {...rest}>
      {icon && <AppIcon icon={icon} tooltip={tooltip} size={iconSize} />}

      {type !== 'link' && <AppText sx={{ mx: 2 }}>{text}</AppText>}
      {type === 'link' && (
        <Link component='button' variant='normal' sx={{ mx: 2 }}>
          {text}
        </Link>
      )}
      {children}
    </MenuItem>
  );
};

export { AppDropdownMenu, AppDropdownMenuItem };
