import { useRef } from 'react';
import { useAppContext } from '../../../../context/AppContext';
import { GridToolbarItem } from './GridToolbarItem';
import { AppIcon, ICON } from '../../AppIcon';

export const AddItem = (props) => {
  const { variant, tooltip, title, itemName, onSubmit, renderForm, onActivated, isDisabled } = props;

  const { localization } = useAppContext();
  const itemRef = useRef(null);
  const onClick = (e) => {
    if (onActivated) {
      onActivated();
    } else {
      itemRef.current.open();
    }
  };

  return (
    <>
      <GridToolbarItem
        variant={variant}
        aria-describedby='open-addnew-dialog'
        onClick={onClick}
        startIcon={<AppIcon icon={ICON.AddCircle} />}
        disabled={isDisabled !== undefined ? isDisabled : false}
        text={localization.translate('common.add')}
      />
      {renderForm &&
        renderForm({
          title: title,
          formId: `new-item-${itemName}-form`,
          onSubmit: onSubmit,
          ref: itemRef,
        })}
    </>
  );
};
