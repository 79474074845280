import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { AppQuickFilter } from '../AppQuickFilter';
import { normalizeQuickFilter } from './GridFilters';

export const AppDataGridQuickFilter = (props) => {
  const { apiRef, filterProps, ...rest } = props;
  const filterRef = useRef();
  const applyQuickFilter = debounce((values) => {
    if (filterProps?.onQuickFilterChange) {
      filterProps.onQuickFilterChange(values);
    }
  }, 500);

  const resetQuickFilter = debounce(() => {
    if (filterProps?.onOnQuickFilterReset) {
      filterProps.onOnQuickFilterReset();
    }
  }, 500);

  const onChange = (value) => {
    applyQuickFilter(normalizeQuickFilter(value ? value.split(' ') : []));
  };

  const onReset = () => {
    applyQuickFilter('');
  };
  const handleEvent = (model, event, details) => {
    if (model.reason === 'reset') {
      filterRef.current.clearValue();
    }
  };
  useEffect(() => {
    apiRef.current.subscribeEvent('filterModelChange', handleEvent);
  }, []);

  return <AppQuickFilter ref={filterRef} onChange={onChange} onReset={onReset} {...rest} />;
};
