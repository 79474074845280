import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

export const AppTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //backgroundColor: theme.palette.layout.header2,
    fontSize: theme.typography.normalb.fontSize,
    fontWeight: theme.typography.normalb.fontWeight,
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.typography.normal.fontSize,
  },
}));

export const AppTableRow = styled(TableRow, { shouldForwardProp: (prop) => prop != 'noHover' })(({ theme, noHover }) => ({
  //'&:last-child td, &:last-child th': { border: 0 },
  '&:last-child td': { border: 0 },
  '&.MuiTableRow-root': {
    cursor: 'default',
    ...(!noHover && {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    }),
  },
}));

export const AppTableHead = styled(TableHead)(({ theme }) => ({}));

export const AppTableBody = styled(TableBody, { shouldForwardProp: (prop) => prop != 'noBorder' })(({ theme, noBorder }) => ({
  ...(noBorder && {
    '& .MuiTableRow-root': {
      border: 0,
      '& .MuiTableCell-root': {
        border: 0,
      },
    },
  }),
}));

export const AppTable = (props) => {
  const { containerProps, size = 'small', children, ...rest } = props;
  return (
    <TableContainer {...containerProps}>
      <Table {...rest} size={size}>
        {children}
      </Table>
    </TableContainer>
  );
};
