import { forwardRef, useState, useEffect } from 'react';
import { AppText, AppTextField } from '../../common/components/AppText';
import { useAppContext } from '../../context/AppContext';
import { AppBox } from '../../common/components/AppBox';
import { REPORT_TYPE } from '../../common/Constants';
import { Grid, MenuItem } from '@mui/material';
import { AppDatePicker } from '../../common/components/AppDatePicker';
import AppSwitch from '../../common/components/AppSwitch';
import LanguageSelect from '../../common/components/LanguageSelect';

const CommonReportSettings = forwardRef((props, ref) => {
  const { config, setConfig } = props;
  const { localization, profile } = useAppContext();

  const setLanguage = async (lang) => {
    const translate = await localization.loadTranslationLanguage(lang);
    setConfig({
      ...config,
      translate,
      language: lang,
    });
  };

  useEffect(() => {
    if (config) {
      if (!config.title) {
        const title = config.type === REPORT_TYPE.Campaign ? `Report for campaign ${config.campaign?.name || ''}` : 'Report for period';
        setConfig({ ...config, title });
      }
    }
  }, [config]);

  return (
    <AppBox flex column sx={{ width: '100%' }}>
      <Grid container columnSpacing={1} rowSpacing={2}>
        <Grid item container xs={12} columnSpacing={1}>
          <Grid item xs={4}>
            <AppTextField select readonly={!!config} label={localization.translate('reports.report_type')} value={config?.type || ''} sx={{ width: '100%' }}>
              {Object.getOwnPropertyNames(REPORT_TYPE).map((t, i) => {
                return (
                  <MenuItem key={i} value={REPORT_TYPE[t]}>
                    <AppText>{localization.translate(`reports.${REPORT_TYPE[t]}`)}</AppText>
                  </MenuItem>
                );
              })}
            </AppTextField>
          </Grid>
          <Grid item xs={4}>
            <LanguageSelect onChange={(v) => setLanguage(v)} code={profile.language} />
          </Grid>
        </Grid>
        <Grid item container xs={12} columnSpacing={1}>
          <Grid item xs={4}>
            <AppDatePicker
              label={localization.translate('app.from_date')}
              inputTextProps={{
                sx: { width: '100%' },
                readonly: !config?.period,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <AppDatePicker
              error={false}
              label={localization.translate('app.to_date')}
              inputTextProps={{
                sx: { width: '100%' },
                readonly: !config?.period,
              }}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <AppSwitch checked={config?.period} onChange={() => {}} sx={{ ml: 2 }} />
            <AppText sx={{ mx: 1 }}>{localization.translate('common.period')}</AppText>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AppTextField label={localization.translate('app.title')} value={config?.title || ''} sx={{ width: '100%' }} />
        </Grid>
      </Grid>
    </AppBox>
  );
});

export default CommonReportSettings;
