import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import SignIn from './views/auth/Signin';
import Dashboard from './views/dashboard/Dashboard';
import Projects from './views/projects/Projects';
import Transactions from './views/transactions/Transactions';
import Layout from './layout/Layout';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { useAppContext } from './context/AppContext';
import { useAuthentication } from './hooks/authentication';
import Expenses from './views/expenses/Expenses';
import { JobsContextProvider } from './context/JobsContext';
import ErrorModalAlert from './common/components/ErrorModalAlert';
import { TenantConfiguration } from './views/config/tenant/TenantConfiguration';
import { ProfileWatchdog } from './hooks/profile';
import { ROUTE_PATHS, USER_ROLES } from './common/Constants';
import UserConfiguration from './views/config/user/UserConfiguration';
import { useDialogsContext } from './context/DialogsContext';
import ReportDialog from './views/reports/ReportDialog';
import GenerateReceiptDialog from './common/components/GenerateReceiptDialog';
import { AppDeletionConfirmationDialog } from './common/components/AppDialog';

function App() {
  const { localization, user } = useAppContext();
  const { refresh } = useAuthentication();
  const dialogs = useDialogsContext();
  const [progress, setProgress] = useState(true);
  const [alert, setAlert] = useState({
    isOpen: false,
    content: 'Some error message',
    title: 'Alert title',
    desctiption: '',
    onClose: () => {},
  });

  const closeAlert = () => {
    setProgress(false);
    setAlert({
      isOpen: false,
      content: '',
      title: '',
    });
  };

  useEffect(() => {
    (async () => {
      if (!user.email) {
        try {
          await refresh();
        } catch (error) {
          console.error(error);
          setAlert({
            isOpen: true,
            title: localization.translate('errors.site_load_error'),
            content: `${localization.translate('errors.site_load_error_content')}. ${localization.translate('errors.contact_admin')}`,
            description: error.message ? error.message : '',
            onClose: closeAlert,
          });
        } finally {
          setProgress(false);
        }
      }
    })();
  }, []);

  return (
    <div dir={localization.dir} className='app'>
      {progress ? (
        <>
          <Box className='container-centered'>
            <CircularProgress />
          </Box>
          <ErrorModalAlert open={alert.isOpen} content={alert.content} title={alert.title} description={alert.description} onClose={alert.onClose} />
        </>
      ) : (
        <>
          <Routes>
            {user.email ? (
              <Route
                path='/'
                element={
                  /*
              Because JobsContextProvider brings data from the server by secured http call,
               it should be initialized here to allow App refresh security first.
            */
                  <JobsContextProvider>
                    <Layout />
                    <ProfileWatchdog />
                    <ReportDialog ref={dialogs.reportDialogRef} />
                    <GenerateReceiptDialog ref={dialogs.receiptDialogRef} />
                    <AppDeletionConfirmationDialog ref={dialogs.deleteConfirmationDialogRef} />
                  </JobsContextProvider>
                }
              >
                <Route index element={<Dashboard path={ROUTE_PATHS.Dashboard} />} />
                <Route path={ROUTE_PATHS.Dashboard} element={<Dashboard path={ROUTE_PATHS.Dashboard} />} />

                <Route path={ROUTE_PATHS.Campaigns} element={<Projects path={ROUTE_PATHS.Campaigns} />} />
                <Route path={ROUTE_PATHS.Donations} element={<Transactions path={ROUTE_PATHS.Donations} />} />
                <Route path={ROUTE_PATHS.Expenses} element={<Expenses path={ROUTE_PATHS.Expenses} />} />
                {user.isInRole(USER_ROLES.Admin) && <Route path={ROUTE_PATHS.TenantConfig} element={<TenantConfiguration path={ROUTE_PATHS.TenantConfig} />} />}

                <Route path={ROUTE_PATHS.UserConfig} element={<UserConfiguration path={ROUTE_PATHS.UserConfig} />} />
              </Route>
            ) : (
              <Route path='/' element={<Outlet />}>
                <Route index element={<SignIn />} />
                <Route path={ROUTE_PATHS.Signin} element={<SignIn />} />
              </Route>
            )}

            {/* catch all */}
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
