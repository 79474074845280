import i18n from '../i18next';
import { useTranslation } from 'react-i18next';
import { useState, createContext, useContext } from 'react';
import { COLOR_MODES, LANGUAGE_CODE, LANGUAGE_DIRECTION, LOCALES, SupportedLanguages, USER_ROLES } from '../common/Constants';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useProfile from '../hooks/profile';
import { useEffect, useMemo } from 'react';
import { getHighestRole } from '../utils/utils';

// see available currency codes:
// https://www.eurochange.co.uk/travel-money/world-currency-abbreviations-symbols-and-codes-travel-money
const DEFAULT_CURRENCY_CODE = 'ILS';

const Languages = {
  [LANGUAGE_CODE.ENGLISH]: LANGUAGE_CODE.ENGLISH,
  [LANGUAGE_CODE.HEBREW]: LANGUAGE_CODE.HEBREW,
  [LANGUAGE_CODE.RUSSIAN]: LANGUAGE_CODE.RUSSIAN,
};

const Locales = {
  [LANGUAGE_CODE.ENGLISH]: LOCALES.EN_US,
  [LANGUAGE_CODE.HEBREW]: LOCALES.HE_IL,
  [LANGUAGE_CODE.RUSSIAN]: LOCALES.RU_RU,
};

const DEFAULT_USER_CONTEXT = {
  name: null,
  email: null,
  tenant: null,
  tenants: [],
  roles: [],
};

const DEFAULT_TENANT_CONTEXT = {
  settings: {},
};

const DEFAULT_LOCALIZATION_CONTEXT = {
  dir: LANGUAGE_DIRECTION.LTR,
  locale: LOCALES.EN_US,
};

const DEFAULT_APP_PROFILE = {
  language: LANGUAGE_CODE.ENGLISH,
  colorMode: COLOR_MODES.LIGHT,
  sideMenu: false,
  colorScheme: null,
};
const appContext = createContext();
const AppContextProvider = ({ children }) => {
  const { t } = useTranslation();

  const [user, setUser] = useState({ ...DEFAULT_USER_CONTEXT });
  const [tenant, setTenant] = useState({ ...DEFAULT_TENANT_CONTEXT });
  const [localization, setLocalization] = useState({ ...DEFAULT_LOCALIZATION_CONTEXT });
  const [profile, setProfile] = useState({ ...DEFAULT_APP_PROFILE });

  const setLanguage = (language) => {
    if (Boolean(Languages[language])) {
      i18n.changeLanguage(language).then(
        () => {
          setProfile({
            ...profile,
            language: language,
          });
          setLocalization({
            dir: i18n.dir(),
            locale: Boolean(Locales[language]) ? Locales[language] : DEFAULT_LOCALIZATION_CONTEXT.locale,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const getCurrency = () => {
    return tenant.settings?.defaultCurrency || DEFAULT_CURRENCY_CODE;
  };

  const loadProfile = (data) => {
    if (data?.app) {
      if (data.app.language !== profile.language) {
        setLanguage(data.app.language);
      }
      setProfile({ ...data.app });
    }
  };

  /*
  useEffect(() => {
    setLanguage(profile.language);
  }, []);
*/
  const resetUser = () => {
    setUser({ ...DEFAULT_USER_CONTEXT });
  };

  const setUserName = (name) => {
    if (name) {
      setUser({
        ...user,
        name: name,
      });
    }
  };

  const isInRole = (role) => {
    if (!role || !user.roles || user.roles.length <= 0) {
      return false;
    }
    const highest = getHighestRole(user.roles);
    return !!highest ? highest.value >= role.value : false;
  };

  const setColorMode = (mode) => {
    if (mode && profile.colorMode !== mode) {
      setProfile({ ...profile, colorMode: mode });
    }
  };

  const setColorScheme = (scheme) => {
    if (scheme && profile.colorScheme !== scheme) {
      setProfile({ ...profile, colorScheme: scheme });
    }
  };

  const setColor = (mode, scheme) => {
    setProfile({
      ...profile,
      colorMode: mode || COLOR_MODES.LIGHT,
      colorScheme: scheme || null,
    });
  };

  const setSideMenu = (side) => {
    const v1 = Boolean(profile.sideMenu);
    const v2 = Boolean(side);
    if (v1 !== v2) {
      setProfile({
        ...profile,
        sideMenu: v2,
      });
    }
  };

  const getLocale = (code) => {
    const language = SupportedLanguages.find((l) => l.code === code);
    return !language ? localization.locale : language.locale;
  };

  const display = (value, format = {}, locale = localization.locale) => {
    if (!!value) {
      if (value instanceof Date) {
        return value.toLocaleDateString(locale, { ...format });
      } else if (typeof value === 'number') {
        return value.toLocaleString(locale, { ...format });
        //return value.toLocaleString({ ...format, maximumFractionDigits: Number.isInteger(value) ? 0 : 2 });
      }
    }
    return value;
  };

  // see available currency codes:
  // https://www.eurochange.co.uk/travel-money/world-currency-abbreviations-symbols-and-codes-travel-money
  const displayCurrency = (value, currency = null, format = {}, locale = localization.locale) => {
    if (!!value) {
      const currencyStyle = {
        style: 'currency',
        currency: currency || getCurrency(),
      };

      if (typeof value === 'number') {
        currencyStyle.maximumFractionDigits = Number.isInteger(value) ? 0 : 2;
        return value.toLocaleString(locale, { ...format, ...currencyStyle });
      } else if (typeof value === 'string') {
        if (isNaN(value)) {
          return value;
        }
        const num = Number(value);
        currencyStyle.maximumFractionDigits = Number.isInteger(num) ? 0 : 2;
        return num.toLocaleString(locale, { ...format, ...currencyStyle });
      } else {
        return value;
      }
    }
    return value;
  };

  const display2 = (props) => {
    const value = props.value;
    const format = props.format || {};
    const locale = props.locale || localization.locale;

    if (!!value) {
      if (value instanceof Date) {
        return value.toLocaleDateString(locale, { ...format });
      } else if (typeof value === 'number') {
        return value.toLocaleString(locale, { ...format });
        //return value.toLocaleString({ ...format, maximumFractionDigits: Number.isInteger(value) ? 0 : 2 });
      }
    }
    return value;
  };

  const displayCurrency2 = (props) => {
    const value = props.value;
    const currency = props.currency || getCurrency();
    const format = props.format || {};
    const locale = props.locale || localization.locale;

    if (!!value) {
      const currencyStyle = {
        style: 'currency',
        currency: currency,
      };
      if (typeof value === 'number') {
        currencyStyle.maximumFractionDigits = Number.isInteger(value) ? 0 : 2;
        return value.toLocaleString(locale, { ...format, ...currencyStyle });
      } else if (typeof value === 'string') {
        if (isNaN(value)) {
          return value;
        }
        const num = Number(value);
        currencyStyle.maximumFractionDigits = Number.isInteger(num) ? 0 : 2;
        return num.toLocaleString(locale, { ...format, ...currencyStyle });
      } else {
        return value;
      }
    }
    return value;
  };

  const translate = (key, options) => {
    return key && key !== '' ? t(key, { ...options }) : '';
  };

  const loadTranslationLanguage = async (lang) => {
    if (!lang || !SupportedLanguages.find((l) => l.code === lang)) {
      return t;
    }
    await i18n.loadLanguages([lang]);
    return i18n.getFixedT(lang);
  };

  const getLanguageDirection = (lang) => {
    return i18n.dir(lang);
  };

  const cache = useMemo(
    () =>
      localization.dir === LANGUAGE_DIRECTION.RTL
        ? createCache({
            key: 'muirtl',
            stylisPlugins: [prefixer, rtlPlugin],
          })
        : createCache({ key: 'css' }),

    [localization.dir]
  );

  const context = {
    user: {
      ...user,
      isInRole,
      setUserName,
    },
    tenant: {
      ...tenant,
      getCurrency,
    },
    profile,
    localization: {
      ...localization,
      getLocale,
      display,
      displayCurrency,
      translate,
      loadTranslationLanguage,
      getLanguageDirection,
    },
    loadProfile,
    setUser,
    setTenant,
    resetUser,
    setLanguage,
    setColor,
    setColorMode,
    setColorScheme,
    setSideMenu,
  };
  return (
    <appContext.Provider value={context}>
      <CacheProvider value={cache}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
      </CacheProvider>
    </appContext.Provider>
  );
};
const useAppContext = () => {
  const context = useContext(appContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within AppContextProvider');
  }
  return context;
};

export { AppContextProvider, useAppContext };
