import { MenuItem, MenuList } from '@mui/material';
import { useState, useEffect } from 'react';
import { AppBox } from '../../common/components/AppBox';
import { AppText } from '../../common/components/AppText';
import { useAppContext } from '../../context/AppContext';
import { useSetHeaderContext } from '../../context/HeaderContext';
import { useSetPathContext } from '../../context/PathContext';

export const Configuration = (props) => {
  const { path, title, configurations, indexes } = props;
  const { localization, profile } = useAppContext();
  const setPath = useSetPathContext();
  const setTitle = useSetHeaderContext();
  const [index, setIndex] = useState(indexes[Object.getOwnPropertyNames(indexes)[0]]);
  const [section, setSection] = useState();

  useEffect(() => {
    setPath(path);
    setTitle({
      primary: localization.translate(title),
    });
  }, [profile.language]);

  useEffect(() => {
    const config = configurations.find((c) => c.index === index) || configurations[0];
    setSection(config);
  }, [index]);
  return (
    <AppBox flex sx={{ height: '100%' }}>
      <div>
        <MenuList sx={{ my: 10 }}>
          {configurations.map((c, i) => {
            return (
              <MenuItem
                key={i}
                onClick={() => setIndex(c.index)}
                disabled={c.disabled}
                selected={index === c.index}
                sx={{
                  mr: 1,
                  minWidth: '100px',
                  '&.Mui-selected': {
                    backgroundColor: (theme) => theme.palette.background.default,
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
              >
                <AppText>{localization.translate(c.label)}</AppText>
              </MenuItem>
            );
          })}
        </MenuList>
      </div>
      {section?.component && <section.component {...section.componentProps} />}
    </AppBox>
  );
};
