import { Tabs, TabsList, Tab } from '@mui/base';
import { styled } from '@mui/material/styles';
import { AppBox } from './AppBox';

export const AppTab = styled(Tab)(({ theme }) => ({
  cursor: 'pointer',
  minWidth: '100px',
  minHeight: '30px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  margin: '0 2px 0 0',
  borderBottom: 'none',
  borderRadius: '4px 4px 0 0',
  backgroundColor: theme.palette.layout.header2,
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transition: 'all .25s',
  },
  '&.Mui-selected': {
    margin: '0 2px -2px 0',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    //fontWeight: theme.typography.bold,
    borderBottom: 'none',
    transition: 'all .25s',
  },
  '&.Mui-disabled': {
    opacity: '0.8',
    cursor: 'default',
  },
}));

const AppTabsList = styled(TabsList)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  alignContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const AppTabs = (props) => {
  const { children, ...rest } = props;
  return (
    <Tabs {...rest}>
      <AppTabsList>{children}</AppTabsList>
    </Tabs>
  );
};

export const AppTabPanel = (props) => {
  const { sx, children, hide, ...rest } = props;
  return (
    <AppBox
      {...(!hide && { flex: true })}
      column
      sx={{
        ...sx,
        ...(hide && {
          display: 'none',
        }),
        flexGrow: 1,
        overflow: 'auto',
        height: '100%',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderTop: 'none',
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      {children}
    </AppBox>
  );
};
