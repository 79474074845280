import { useTheme } from '@emotion/react';
import { Grid, IconButton, InputAdornment, LinearProgress, MenuItem } from '@mui/material';
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { AppQuickFilter } from '../../../../common/components/AppQuickFilter';
import { AppBox } from '../../../../common/components/AppBox';
import { AppButton } from '../../../../common/components/AppButton';
import { AppIcon, ICON, ICON_SIZE } from '../../../../common/components/AppIcon';
import AppSwitch from '../../../../common/components/AppSwitch';
import { AppText, AppTextField, InlineTextField } from '../../../../common/components/AppText';
import { DialogFormContainer } from '../../../../common/components/DialogFormContainer';
import { LANGUAGE_DIRECTION, USER_ROLES } from '../../../../common/Constants';
import { useAppContext } from '../../../../context/AppContext';
import { useHttp } from '../../../../hooks/authentication';
import { getHighestRole, isFewScreenWidth, validateEmailInput } from '../../../../utils/utils';
import { useDialogsContext } from '../../../../context/DialogsContext';

const RoleSelector = (props) => {
  const { role, onUpdate, ...rest } = props;
  const { localization } = useAppContext();
  const [value, setValue] = useState(role);

  useEffect(() => {
    setValue(role);
  }, [role]);

  return (
    <AppTextField
      select
      label={localization.translate('settings.role')}
      value={value}
      onChange={(v) => {
        if (onUpdate) {
          onUpdate(v);
        }
      }}
      sx={{
        width: isFewScreenWidth() ? '150px' : '200px',
      }}
      {...rest}
    >
      {Object.getOwnPropertyNames(USER_ROLES).map((role, i) => {
        return (
          <MenuItem key={i} value={USER_ROLES[role].name}>
            <AppText>{USER_ROLES[role].name}</AppText>
          </MenuItem>
        );
      })}
    </AppTextField>
  );
};

const AccountForm = forwardRef((props, ref) => {
  const { details, onSubmit, setDisableSubmit } = props;
  const { localization } = useAppContext();
  const [data, setData] = useState({});
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const [invalidInputs, setInvalidInputs] = useState({});
  const [useName, setUseName] = useState(false);

  useEffect(() => {
    const res = !hasFieldsToUpdate() || hasInvalidInputs();
    if (setDisableSubmit) {
      setDisableSubmit(res);
    }
  });
  const hasFieldsToUpdate = () => {
    const keys = Object.keys(fieldsToUpdate);
    if (!keys || keys.length <= 0) {
      return false;
    }
    for (const key of keys) {
      if (fieldsToUpdate[key]) {
        return true;
      }
    }
    return false;
  };

  const hasInvalidInputs = (obj) => {
    const target = obj ? obj : invalidInputs;
    const keys = Object.keys(target);
    if (!keys || keys.length <= 0) {
      return false;
    }
    for (const key of keys) {
      if (target[key]) {
        return true;
      }
    }
    return false;
  };
  const setFieldToUpdate = (name) => {
    setFieldsToUpdate({ ...fieldsToUpdate, [name]: true });
  };
  const unsetFieldToUpdate = (name) => {
    setFieldsToUpdate({ ...fieldsToUpdate, [name]: false });
  };

  const setInvalidInput = (name) => {
    setInvalidInputs({ ...invalidInputs, [name]: true });
  };

  const unsetInvalidInput = (name) => {
    const data = { ...invalidInputs, [name]: false };
    setInvalidInputs(data);
  };

  // when onInvalidInputValidate is not set explicitly, the default
  // implementation will be used and field also becomes tested on invalidation.
  // To set field not tested for invalidation, pass null as onInvalidInputValidate.
  const setDataField = (props) => {
    const {
      key,
      value,
      onChangedValidate = (v) => {
        if (!details) {
          return true;
        }
        return details[key] !== v;
      },
      onInvalidInputValidate = (v) => {
        return !v;
      },
    } = props;

    unsetInvalidInput(key); // make sure no invalid input marked for this key

    setData({ ...data, [key]: value });
    if (onInvalidInputValidate) {
      if (onInvalidInputValidate(value)) {
        // value is invalid
        setInvalidInput(key);
      }
    }
    if (onChangedValidate(value)) {
      // value has been changed
      setFieldToUpdate(key);
    } else {
      unsetFieldToUpdate(key);
    }
  };
  const setEmail = (value) => {
    setDataField({
      key: 'email',
      value: value,
      onInvalidInputValidate: (v) => {
        // returns true if value is invalid, otherwise false
        const emailIsEmpty = !Boolean(v);
        const emailIsInvalid = !validateEmailInput(v);
        return emailIsEmpty || emailIsInvalid;
      },
    });
  };

  const setRole = (role) => {
    setDataField({
      key: 'roles',
      value: [role],
    });
  };

  const onFormSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const updatedFields = { ...fieldsToUpdate };
    setFieldsToUpdate({});
    if (setDisableSubmit) {
      setDisableSubmit(true);
    }
    try {
      const toSubmit = { ...data };
      await onSubmit({ ...toSubmit });
    } catch (error) {
      // allow save on error
      setFieldsToUpdate({ ...updatedFields });
      if (setDisableSubmit) {
        setDisableSubmit(false);
      }
      throw error;
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: async (event) => {
      await onFormSubmit(event);
    },
  }));

  useEffect(() => {
    if (details) {
      setData({ ...details });
      setInvalidInputs({});
      setFieldsToUpdate({});
    } else {
      setData({
        roles: ['user'],
      });
      setInvalidInputs({ email: true });
    }
  }, [details]);

  return (
    <Grid container rowSpacing={2} columnSpacing={1}>
      <Grid item xs={12}>
        <AppTextField
          readonly={!!details}
          required
          error={!data.email || (data.email && !validateEmailInput(data.email))}
          fullWidth
          label={localization.translate('auth.email_address')}
          name='email'
          id='email'
          value={data.email || ''}
          onChange={(v) => setEmail(v)}
          InputProps={{
            style: { direction: LANGUAGE_DIRECTION.LTR },
            ...(localization.dir === LANGUAGE_DIRECTION.LTR
              ? {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <AppIcon icon={ICON.Email} />
                    </InputAdornment>
                  ),
                }
              : {
                  startAdornment: (
                    <InputAdornment position='start' sx={{ pr: 1 }}>
                      <AppIcon icon={ICON.Email} />
                    </InputAdornment>
                  ),
                }),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <AppBox flex centery>
          <AppSwitch checked={useName} onChange={() => setUseName(!useName)} />
          <AppBox flex centery column sx={{ mx: 1 }}>
            <AppText>{localization.translate('settings.set_username')}</AppText>
            {!details && (
              <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
                {localization.translate('settings.set_username_optional')}
              </AppText>
            )}
          </AppBox>
        </AppBox>
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          readonly={!useName}
          fullWidth
          label={localization.translate('settings.user_name')}
          name='displayname'
          id='displayname'
          value={data.name || ''}
          onChange={(v) => setDataField({ key: 'name', value: v, onInvalidInputValidate: null })}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <AppIcon icon={ICON.User} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AppBox flex centery sx={{ width: '100%' }}>
          <RoleSelector role={getHighestRole(data.roles)?.name || ''} onUpdate={setRole} sx={{ width: '150px' }} />
          <AppText variant='xsmall' sx={{ mx: 1, color: (theme) => theme.palette.text.secondary }}>
            {data?.roles?.length > 0 ? localization.translate(`settings.${data.roles[0]}_description`) : ''}
          </AppText>
        </AppBox>
      </Grid>
    </Grid>
  );
});

export const AccountDialogForm = forwardRef((props, ref) => {
  const { details, onSubmit } = props;
  const { localization } = useAppContext();
  const formRef = useRef(null);
  const containerRef = useRef(null);
  const [disable, setDisable] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const close = () => {
    setDialogOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setDialogOpen(true);
    },
  }));

  const handleSubmit = async (e) => {
    try {
      setErrorAlert(null);
      await formRef.current.handleSubmit(e);
    } catch (error) {
      if (error.response?.status === 409) {
        setErrorAlert('errors.account_email_conflict');
      }
      throw error;
    }
  };

  const labelTitle = details ? 'edit_account' : 'add_account';

  return (
    <DialogFormContainer
      ref={containerRef}
      title={localization.translate(`settings.${labelTitle}`)}
      open={dialogOpen}
      onClose={close}
      formId='account_form'
      onSubmit={handleSubmit}
      disableSubmit={disable}
      width='sm'
      errorAlert={errorAlert}
    >
      <AccountForm ref={formRef} details={details} onSubmit={onSubmit} setDisableSubmit={setDisable} />
    </DialogFormContainer>
  );
});

const AccountConfiguration = (props) => {
  const { account, onUpdate } = props;
  const theme = useTheme();
  const { localization } = useAppContext();
  const dialogs = useDialogsContext();
  const [nameEdited, setNameEdited] = useState(false);
  const [data, setData] = useState({});
  const formRef = useRef(null);

  useEffect(() => {
    if (account) {
      setData({ ...account });
    }
  }, [account]);

  const onEditClick = (e) => {
    formRef.current.open();
  };

  const onDeleteClick = (e) => {
    if (onUpdate) {
      onUpdate(data, true);
    }
    dialogs.deleteConfirmationDialogRef.current.close();
  };

  const handleEditName = () => {
    const edit = !nameEdited;
    setNameEdited(!nameEdited);
    if (!edit) {
      if (onUpdate) {
        onUpdate(data);
      }
    }
  };

  const handleCancelEditName = () => {
    setData({ ...data, name: account.name });
    setNameEdited(!nameEdited);
  };

  return (
    <AppBox flex centery sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
      <InlineTextField
        readonly={!nameEdited}
        value={data.name || ''}
        placeholder={localization.translate('settings.no_display_name')}
        InputProps={{
          sx: {
            paddingRight: 0,
            width: '300px',
          },
        }}
        inputProps={{
          style: {
            textOverflow: 'ellipsis',
          },
        }}
        onChange={(value) => setData({ ...data, name: value })}
      />
      <IconButton aria-label='edit-name-button' onClick={() => handleEditName()}>
        <AppIcon icon={nameEdited ? ICON.Save : ICON.Edit} size={ICON_SIZE.Small} />
      </IconButton>
      {nameEdited && (
        <IconButton aria-label='cancel-edit-name-button' onClick={handleCancelEditName}>
          <AppIcon icon={ICON.Close} />
        </IconButton>
      )}
      <InlineTextField
        readonly
        value={data.email || ''}
        InputProps={{
          sx: {
            paddingRight: 0,
            width: '300px',
          },
        }}
        inputProps={{
          style: {
            textOverflow: 'ellipsis',
          },
        }}
      />
      <AppBox sx={{ flexGrow: 1 }} />
      <IconButton aria-label='edit-account-button' onClick={onEditClick}>
        <AppIcon icon={ICON.SettingsCog} size={ICON_SIZE.Small} />
      </IconButton>
      <IconButton
        aria-label='delete-account-button'
        onClick={() =>
          dialogs.deleteConfirmationDialogRef.current.open({
            onOk: onDeleteClick,
            title: localization.translate('app.delete_user'),
            message: localization.translate('app.confirm_delete_user'),
          })
        }
      >
        <AppIcon icon={ICON.Delete} size={ICON_SIZE.Small} />
      </IconButton>
      <AccountDialogForm ref={formRef} details={data} onSubmit={onUpdate} />
    </AppBox>
  );
};

const UserAccountsConfiguration = (props) => {
  const { getHttp } = useHttp();
  const { localization, user } = useAppContext();
  const [data, setData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const formRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await getHttp().get('users');
        const toSet = response.data.filter((a) => a.email !== user.email);
        setAccounts(toSet);
        setData(toSet);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleUpdateAccount = async (account, deleted) => {
    try {
      const updated = [...accounts];
      if (deleted) {
        await getHttp().request({
          method: 'delete',
          url: `users/${account.email}`,
        });

        const index = updated.findIndex((e) => e.id === account?.id);
        if (index >= 0) {
          updated.splice(index, 1);
        }
      } else {
        const response = account.id ? await getHttp().put(`users/${account.email}`, account) : await getHttp().post('users', account);

        const index = updated.findIndex((e) => e.id === response.data.id);
        if (index >= 0) {
          updated.splice(index, 1, { ...updated[index], ...response.data });
        } else {
          updated.push({ ...response.data });
        }
      }
      setAccounts([...updated]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const filterAccounts = (pattern) => {
    setAccounts(pattern ? [...data.filter((a) => a.name?.includes(pattern) || a.email?.includes(pattern))] : [...data]);
  };

  return loading ? (
    <LinearProgress sx={{ mt: 0 }} />
  ) : (
    <AppBox flex column sx={{ pr: 10, maxHeight: '400px', overflow: 'auto' }}>
      <AppBox flex>
        <AppBox sx={{ flexGrow: 1 }} />
        <AppQuickFilter sx={{ mx: 2 }} onChange={filterAccounts} onReset={filterAccounts} />
        <AppButton startIcon={<AppIcon icon={ICON.Add} />} onClick={() => formRef.current.open()}>
          {localization.translate('settings.add_user')}
        </AppButton>
        <AccountDialogForm ref={formRef} onSubmit={handleUpdateAccount} />
      </AppBox>
      <AppBox flex centery>
        <AppText variant='smallb'>{localization.translate('settings.user_name')}</AppText>
      </AppBox>
      {accounts.map((a, i) => {
        return <AccountConfiguration key={i} account={a} onUpdate={handleUpdateAccount} />;
      })}
    </AppBox>
  );
};

export default UserAccountsConfiguration;
