import { Box } from '@mui/material';

const InnerCenteredContainer = (props) => {
  const { color, children } = props;

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          color: color || '',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default InnerCenteredContainer;
