import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

export const AppBox = forwardRef((props, ref) => {
  const { flex, column, center, centerx, centery, endx, endy, space, sx, ...rest } = props;
  const bsx = {
    ...sx,
    ...(flex && { display: 'flex' }),
    ...(column && { flexDirection: 'column' }),
    ...(center
      ? {
          justifyContent: space ? `space-${space}` : 'center',
          alignItems: 'center',
        }
      : {
          ...(centerx
            ? {
                ...(column
                  ? {
                      alignItems: 'center',
                      ...(space && { justifyContent: `space-${space}` }),
                    }
                  : { justifyContent: space ? `space-${space}` : 'center' }),
              }
            : {
                ...(endx && {
                  ...(column
                    ? {
                        alignItems: 'flex-end',
                        ...(space && { justifyContent: `space-${space}` }),
                      }
                    : { justifyContent: space ? `space-${space}` : 'flex-end' }),
                }),
              }),
          ...(centery
            ? {
                ...(column
                  ? { justifyContent: space ? `space-${space}` : 'center' }
                  : {
                      alignItems: 'center',
                      ...(space && { justifyContent: `space-${space}` }),
                    }),
              }
            : {
                ...(endy && {
                  ...(column
                    ? { justifyContent: space ? `space-${space}` : 'flex-end' }
                    : {
                        alignItems: 'flex-end',
                        ...(space && { justifyContent: `space-${space}` }),
                      }),
                }),
              }),
        }),
  };
  return <Box ref={ref} {...rest} sx={bsx} />;
});

AppBox.propTypes = {
  flex: PropTypes.bool,
  column: PropTypes.bool,
  center: PropTypes.bool,
  centerx: PropTypes.bool,
  centery: PropTypes.bool,
  endx: PropTypes.bool,
  endy: PropTypes.bool,
  space: PropTypes.oneOf(['between', 'around', 'evenly']),
};
