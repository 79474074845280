import { useState, useRef, useEffect } from 'react';
import { gridPreferencePanelStateSelector, useGridSelector } from '@mui/x-data-grid-pro';
import { useAppContext } from '../../../../context/AppContext';
import { useDataContext } from '../../../../context/DataContext';
import { GridToolbarItem } from './GridToolbarItem';
import { Badge } from '@mui/material';
import { AppIcon, ICON } from '../../AppIcon';
import { isNullOrEmpty } from '../../../../utils/utils';
import { normalizeFilterItems } from '../GridFilters';

export const FilterItem = (props) => {
  const { variant, onActivated, isDisabled, apiRef, filterProps } = props;
  const { localization } = useAppContext();
  const dataContext = useDataContext();

  const preferencePanel = useGridSelector(apiRef, gridPreferencePanelStateSelector);
  const { open, openedPanelValue } = preferencePanel;
  const [showMode, setShowMode] = useState(null);
  const filters = useRef(null);
  const [counter, setCounter] = useState(0);

  const handleOpenFilter = (event) => {
    if (onActivated) {
      onActivated();
    } else {
      setShowMode(showMode === null ? true : !showMode);
    }
  };

  const handleEvent = async (model, event, details) => {
    if (!isNullOrEmpty(model.items)) {
      const filter = normalizeFilterItems(model.items);
      if (filter !== null) {
        if (JSON.stringify(filter) !== JSON.stringify(filters.current)) {
          // filter has changed.
          filters.current = filter;
          setCounter(filter.length);
          if (filterProps.onFilterItemsChange && model.reason !== 'context') {
            await filterProps.onFilterItemsChange(filter);
          }
        }
      } else {
        if (filters.current) {
          filters.current = null;
          setCounter(0);
          if (filterProps.onFilterItemsReset) {
            await filterProps.onFilterItemsReset();
          }
        }
      }
      return;
    }

    if (event.reason === 'deleteFilterItem') {
      // at this point the model.items is empty already, so the last item has been deleted - reset items
      filters.current = null;
      setCounter(0);
      if (filterProps.onFilterItemsReset) {
        await filterProps.onFilterItemsReset();
      }
    }
    if (model.reason === 'reset') {
      filters.current = null;
      setCounter(0);
    }
  };

  useEffect(() => {
    return apiRef.current.subscribeEvent('filterModelChange', handleEvent);
  }, []);

  useEffect(() => {
    if (dataContext.filterModel) {
      apiRef.current.setFilterModel({ ...dataContext.filterModel, reason: 'context' });
      dataContext.setFilterModel(null);
    }
  }, []);

  useEffect(() => {
    if (showMode != null) {
      if (open) {
        apiRef.current.hideFilterPanel();
      } else {
        apiRef.current.showFilterPanel();
      }
    }
  }, [showMode]);

  return (
    <GridToolbarItem
      variant={variant}
      onClick={handleOpenFilter}
      startIcon={
        <Badge badgeContent={counter} color='primary'>
          <AppIcon icon={ICON.Filter} />
        </Badge>
      }
      disabled={isDisabled !== undefined ? isDisabled : false}
      text={localization.translate('common.filter')}
    />
  );
};
