import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useAppContext } from '../../context/AppContext';
import { AppButton } from '../../common/components/AppButton';
import { AppDropdownMenu } from '../../common/components/AppDropdownMenu';
import { SupportedLanguages } from '../../common/Constants';
import { AppText } from '../../common/components/AppText';

const LanguageMenu = (props) => {
  const { useFlagIcon = true } = props;
  const { localization, setLanguage, profile } = useAppContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (lang) => {
    handleClose();
    setLanguage(lang);
  };

  return (
    <div>
      <AppButton variant='text' onClick={handleClick} color='inherit'>
        <AppText variant='small' sx={{ mx: 1, textTransform: 'uppercase' }}>
          {profile.language}
        </AppText>
        {!useFlagIcon && <LanguageIcon />}
        {useFlagIcon && <span className={`fi fi-${SupportedLanguages.find((l) => l.code === profile.language).country}`}></span>}
      </AppButton>
      <AppDropdownMenu
        id='customized-lang-menu'
        MenuListProps={{
          'aria-labelledby': 'customized-lang-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {SupportedLanguages.map((lang) => {
          return (
            <MenuItem
              disableRipple
              variant='small'
              key={lang.code}
              onClick={(e) => handleLanguageSelect(lang.code)}
              disabled={lang.code === localization.language}
            >
              <ListItemIcon>
                <span className={`fi fi-${lang.country}`}></span>
              </ListItemIcon>
              <AppText>{lang.name}</AppText>
            </MenuItem>
          );
        })}
      </AppDropdownMenu>
    </div>
  );
};

export default LanguageMenu;
