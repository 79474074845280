import { AppDialog } from '../../common/components/AppDialog';
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { Box, FormControl, FormLabel, CircularProgress, List, Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import FileUploadSharpIcon from '@mui/icons-material/FileUploadSharp';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import AppInnerAlert from '../../common/components/AppInnerAlert';
import { AppListItemButton } from '../../common/components/AppListItem';
import { useHttp } from '../../hooks/authentication';
import { AppButton } from '../../common/components/AppButton';
import { useAppContext } from '../../context/AppContext';
import { AppText } from '../../common/components/AppText';

const getMime = (contentType) => {
  if (contentType.includes('text/csv')) {
    return 'csv';
  } else {
    return null;
  }
};

const download = (data, mime) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `transactions_${new Date().toISOString()}.${mime}`);
  document.body.appendChild(link);
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

const EXPORT_MODE = {
  ALL_FILTERED: 1,
  SELECTED: 2,
  DISPLAYED: 3,
};
const EXPORT_STATE = {
  IDLE: 1,
  EXPORTING: 2,
  EXPORTED: 3,
  ERROR: 4,
};

const SelectModeButton = (props) => {
  const { icon, selected, onClick, primary, secondary, disabled = false } = props;
  const theme = useTheme();
  return (
    <AppListItemButton selected={selected} onClick={onClick} disabled={disabled}>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{ variant: 'normal' }}
        secondaryTypographyProps={{ variant: 'normal', color: (theme) => theme.palette.text.secondary }}
      />
      {selected && <CheckSharpIcon color='success' sx={{ mx: 2, fontWeight: '900' }} />}
    </AppListItemButton>
  );
};

const ExportTransactionsForm = forwardRef((props, ref) => {
  const { getSearchId, onExportStarted, onExportFinished, apiRef } = props;
  const [mode, setMode] = useState(EXPORT_MODE.ALL_FILTERED);
  const saveId = useRef(null);
  const [exportState, setExportState] = useState(EXPORT_STATE.IDLE);
  const { localization } = useAppContext();
  const { getHttp } = useHttp();
  const theme = useTheme();
  const selectedRows = Array.from(apiRef.current.getSelectedRows().values()).map((r) => r.id);

  const handleSelectMode = (selectedMode) => {
    setMode(selectedMode);
  };

  const handleExportStart = (state) => {
    setExportState(state);
    if (onExportStarted) {
      onExportStarted();
    }
  };

  const handleExportFinish = (state) => {
    setExportState(state);
    if (onExportFinished) {
      onExportFinished();
    }
  };

  const exportTransactions = (uri, http) => {
    if (!http) {
      http = getHttp();
    }
    http
      .get(uri)
      .then((response) => {
        if (response.status === 200) {
          // file is ready to download.
          const contentType = response.headers['content-type'];
          const mime = getMime(contentType);
          if (!mime) {
            handleExportFinish(EXPORT_STATE.ERROR);
          } else {
            handleExportFinish(EXPORT_STATE.IDLE);
            download(response.data, mime);
          }
        } else if (response.status === 201) {
          // export job submitted
          handleExportFinish(EXPORT_STATE.EXPORTED);
        } else {
          console.warning('unknown response status: ' + response.status);
          handleExportFinish(EXPORT_STATE.ERROR);
        }
      })
      .catch(function (error) {
        handleExportFinish(EXPORT_STATE.ERROR);
      });
  };

  const exportAllFiltered = () => {
    let uri = 'transactions?export=csv';
    const searchId = getSearchId();
    if (searchId) {
      uri = `${uri}&searchId=${searchId}`;
    }
    exportTransactions(uri);
  };

  const exportRows = (rows) => {
    const query = { query: [...rows] };
    const http = getHttp();
    const savePromise = saveId.current ? http.put(`transactions/search/${saveId.current}`, query) : http.post('transactions/search', query);

    savePromise
      .then((response) => {
        if (response.data?.id) {
          saveId.current = response.data.id;
        }
        const uri = `transactions?export=csv&searchId=${saveId.current}`;
        exportTransactions(uri, http);
      })
      .catch((error) => {
        handleExportFinish(EXPORT_STATE.ERROR);
      });
  };

  const exportSelected = () => {
    exportRows(selectedRows);
  };

  const exportDisplayed = () => {
    const rows = apiRef.current.getAllRowIds();
    exportRows(rows);
  };

  useImperativeHandle(ref, () => ({
    handleExport: () => {
      handleExportStart(EXPORT_STATE.EXPORTING);
      switch (mode) {
        case EXPORT_MODE.SELECTED:
          exportSelected();
          break;
        case EXPORT_MODE.DISPLAYED:
          exportDisplayed();
          break;
        default:
          exportAllFiltered();
      }
    },
  }));

  return (
    <Box sx={{ minHeight: '300px' }}>
      {exportState === EXPORT_STATE.EXPORTING && (
        <Box className='container-centered'>
          <CircularProgress />
        </Box>
      )}
      <FormControl disabled={exportState === EXPORT_STATE.EXPORTING} sx={{ width: '100%' }}>
        <AppText variant='normalb' id='select-mode-label'>
          {localization.translate('donations.select_donations_to_export')}
        </AppText>
        <List>
          <SelectModeButton
            onClick={() => handleSelectMode(EXPORT_MODE.ALL_FILTERED)}
            disabled={exportState === EXPORT_STATE.EXPORTING}
            selected={mode === EXPORT_MODE.ALL_FILTERED}
            icon={<FilterListSharpIcon />}
            primary={localization.translate('app.all_or_filtered')}
            secondary={localization.translate('app.all_or_filtered_description')}
          />
          <SelectModeButton
            onClick={() => handleSelectMode(EXPORT_MODE.SELECTED)}
            disabled={exportState === EXPORT_STATE.EXPORTING || selectedRows.length <= 0}
            selected={mode === EXPORT_MODE.SELECTED}
            icon={<DoneAllSharpIcon />}
            primary={localization.translate('app.selected')}
            secondary={localization.translate('app.selected_description')}
          />
          <SelectModeButton
            onClick={() => handleSelectMode(EXPORT_MODE.DISPLAYED)}
            disabled={exportState === EXPORT_STATE.EXPORTING}
            selected={mode === EXPORT_MODE.DISPLAYED}
            icon={<ListAltSharpIcon />}
            primary={localization.translate('app.displayed_on_page')}
            secondary={localization.translate('app.displayed_on_page_description')}
          />
        </List>
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {exportState === EXPORT_STATE.EXPORTED && (
          <AppInnerAlert
            open={exportState === EXPORT_STATE.EXPORTED}
            severity='success'
            onClose={() => setExportState(EXPORT_STATE.IDLE)}
            content='Request to import transactions has been submitted. When done, the list of transactions will be updated automatically'
          />
        )}
        {exportState === EXPORT_STATE.ERROR && (
          <AppInnerAlert
            open={exportState === EXPORT_STATE.ERROR}
            severity='error'
            onClose={() => setExportState(EXPORT_STATE.IDLE)}
            content='An error occured exporting transactions. Please, try again later'
          />
        )}
      </Box>
    </Box>
  );
});

const ExportTransactionsDialog = (props) => {
  const { open, onClose, getSearchId, apiRef, ...other } = props;
  const { localization } = useAppContext();
  const formRef = useRef(null);
  const [exportDisabled, setExportDisabled] = useState(false);

  const onExportStarted = () => {
    setExportDisabled(true);
  };
  const onExportFinished = () => {
    setExportDisabled(false);
  };

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={localization.translate('donations.export_donations')}
      dialogContent={
        <ExportTransactionsForm ref={formRef} getSearchId={getSearchId} onExportStarted={onExportStarted} onExportFinished={onExportFinished} apiRef={apiRef} />
      }
      dialogActionsContent={
        <AppButton disabled={exportDisabled} endIcon={<FileUploadSharpIcon />} onClick={() => formRef.current.handleExport()}>
          {localization.translate('app.export')}
        </AppButton>
      }
      hasActions
      useClose
    />
  );
};

export default ExportTransactionsDialog;
