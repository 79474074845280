import { useEffect } from 'react';
import { AppBox } from '../../common/components/AppBox';
import { AppIcon, ICON } from '../../common/components/AppIcon';
import { AppText } from '../../common/components/AppText';
import { loadScript } from '../../utils/utils';

const FacebookSignin = (props) => {
  const { onIdpResponse } = props;

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '204507408936126',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v16.0',
      });
    };

    loadScript(
      'https://connect.facebook.net/en_US/sdk.js',
      () => {
        console.log('facebook sdk script was loaded successfully');
      },
      (error) => {
        console.error('An error occured loading facebook sdk script');
        if (error) {
          console.error(error);
        }
      },
      {
        id: 'facebook-client-script',
        async: true,
        defer: true,
        crossorigin: 'anonymous',
      }
    );
    return () => {
      // Cleanup function that runs when component unmounts
      document.getElementById('facebook-client-script')?.remove();
    };
  }, []);

  const handleFacebookResponse = () => {
    window.FB.getLoginStatus((response) => {
      console.log(response);
      if (response.status === 'connected') {
        handleIdpResponse(response.authResponse.accessToken);
      } else {
        window.FB.login(
          (response) => {
            if (response.authResponse?.accessToken) {
              handleIdpResponse(response.authResponse.accessToken);
            }
          },
          { scope: 'public_profile,email,openid', return_scopes: true }
        );
      }
    });
  };

  const handleIdpResponse = async (id_token) => {
    if (onIdpResponse) {
      await onIdpResponse(process.env.REACT_APP_FACEBOOK_CLIENT_ID, id_token);
    }
  };

  // #0571E6 - facebook orig
  return (
    <AppBox
      flex
      center
      sx={{ backgroundColor: '#345E9E', width: '220px', height: '38px', borderRadius: '50px', cursor: 'pointer' }}
      onClick={handleFacebookResponse}
    >
      <AppIcon icon={ICON.Facebook} style={{ color: 'white' }} />
      <AppText sx={{ ml: 2, color: 'white', fontFamily: 'Arial' }}>Sign in with Facebook</AppText>
    </AppBox>
  );
};

export default FacebookSignin;
