import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, DialogActions, ListItemIcon, ListItemText, ListItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAppContext } from '../../context/AppContext';

const ErrorModalAlert = (props) => {
  const { open, title, content, onClose } = props;
  const theme = useTheme();
  const { localization } = useAppContext();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description' fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogContent>
        <ListItem>
          <ListItemIcon>
            <ErrorOutlineIcon color='error' sx={{ fontSize: '2.3em' }} />
          </ListItemIcon>
          <ListItemText primary={title} primaryTypographyProps={{ fontSize: 18 }} secondary={content} secondaryTypographyProps={{ fontSize: 14 }} />
        </ListItem>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{localization.translate('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

ErrorModalAlert.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default ErrorModalAlert;
