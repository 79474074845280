import { Box, CircularProgress, FormControlLabel, Grid, LinearProgress, MenuItem, Radio, Skeleton, Typography } from '@mui/material';
import { useAppContext } from '../../context/AppContext';
import { AppDialog } from './AppDialog';
import { AppContainer } from './AppContainer';
import { AppBox } from './AppBox';
import { AppText, AppTextField } from './AppText';
import { AppButton } from './AppButton';
import { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { useJobsContext } from '../../context/JobsContext';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import AppNotification from './AppNotification';
import { INVOICE_STATE, NOTIFICATION_SEVIRITY, RECEIPT_PROVIDERS } from '../Constants';
import { useHttp } from '../../hooks/authentication';
import { AppIcon, ICON, ICON_SIZE } from './AppIcon';
import AppSwitch from './AppSwitch';

// for resizable: https://codesandbox.io/s/lp8q9r92x9?file=/demo.js

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function range(count) {
  return Array(count)
    .fill(0)
    .map((_, idx) => idx);
}

const ReceiptFieldView = (props) => {
  const { title, value } = props;

  return (
    <AppBox flex column>
      <AppText variant='xsmall' sx={{ lineHeight: 1.2, color: (theme) => theme.palette.text.secondary }}>
        {title}
      </AppText>
      <AppText variant='small' sx={{ lineHeight: 1.1 }}>
        {value}
      </AppText>
    </AppBox>
  );
};

const dialogMediaStyle = {
  '@media screen and (max-height: 995px)': {
    height: '700px',
  },
  '@media screen and (max-height: 800px)': {
    height: '500px',
  },
};

const GenerateReceiptDialog = forwardRef((props, ref) => {
  const { localization } = useAppContext();
  const { getHttp } = useHttp();
  const jobs = useJobsContext();
  const [submitting, setSubmitting] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [previewSource, setPreviewSource] = useState(null);
  const [error, setError] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [isNew, setIsNew] = useState(true);
  const [existingNumber, setExistingNumber] = useState('');
  const [linking, setLinking] = useState(false);
  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState();
  const [open, setOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [config, setConfig] = useState();
  const [loading, setLoading] = useState(true);
  const [stateErrorDescription, setStateErrorDescription] = useState(null);
  const comments = useRef(null);

  const openDialog = async (config) => {
    setLoading(true);
    setOpen(true);

    try {
      if (config?.transaction?.invoice?.state === INVOICE_STATE.failed && !!config?.transaction?.invoice?.jobId) {
        const response = await getHttp().get(`jobs/${config.transaction.invoice.jobId}`);
        if (response.data?.stateDescription) {
          setStateErrorDescription(response.data.stateDescription);
        }
      }

      const response = await getHttp().get('configurations/receipts');
      const items = response.data.map((p, i) => {
        return {
          ...p,
          name: RECEIPT_PROVIDERS.find((e) => e.provider === p.provider)?.name || p.provider,
        };
      });

      setProviders([...items]);
      setProvider(items.find((p) => p.isDefault));

      setConfig({ ...config });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const closeDialog = () => {
    setOpen(false);
    setPreviewSource(null);
    setProvider(null);
    setIsNew(true);
    setExistingNumber('');
    setSendEmail(false);
    setConfig(null);
    setStateErrorDescription(null);
    comments.current = null;
  };

  useImperativeHandle(ref, () => ({
    open: async (config) => await openDialog(config),
  }));

  const onPreviewLoadSuccess = (doc) => {
    setNumPages(doc.numPages);
  };

  const submitGenerateInvoice = async () => {
    setSubmitting(true);
    try {
      await getHttp().post(`/transactions/${config.transaction.id}/receipts`, {
        remark: comments.current,
        ...(sendEmail && { mail: true }),
      });
      await jobs.loadJobs();
      closeDialog();
    } catch (error) {
      console.error(error);
      setError(1);
    } finally {
      setSubmitting(false);
    }
  };

  const submitLinkInvoice = async () => {
    setLinking(true);
    try {
      await getHttp().put(`/transactions/${config.transaction.id}/receipts`, {
        number: existingNumber,
        ...(sendEmail && { mail: true }),
      });
      await jobs.loadJobs();
      closeDialog();
    } catch (error) {
      console.error(error);
      setError(1);
    } finally {
      setLinking(false);
    }
  };

  const submitPreviewInvoice = async () => {
    setPreviewing(true);
    setPreviewSource(null);
    try {
      const response = await getHttp().post(`/transactions/${config.transaction.id}/receipts?preview`, { remark: comments.current });
      setPreviewSource(response.data);
    } catch (error) {
      console.error(error);
      setError(2);
    } finally {
      setPreviewing(false);
    }
  };

  const setSelectedOption = (e) => {
    setIsNew(e.target.value === 'new');
  };

  return (
    <AppDialog
      maxWidth='lg'
      open={open}
      onClose={closeDialog}
      title={localization.translate('donations.generate_receipt')}
      useClose
      hasActions
      dialogActionsContent={
        <>
          <Box sx={{ mx: 1, position: 'relative' }}>
            <AppButton variant='outlined' onClick={submitPreviewInvoice} disabled={!provider?.data?.supportsPreview || !isNew || previewing || submitting}>
              {localization.translate('app.preview')}
            </AppButton>
            {previewing && (
              <CircularProgress
                size={20}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-10px',
                  marginLeft: '-10px',
                }}
              />
            )}
          </Box>
          <Box sx={{ mx: 1, position: 'relative' }}>
            <AppButton onClick={submitGenerateInvoice} disabled={!providers?.length || !isNew || submitting || previewing}>
              {localization.translate('donations.generate_receipt')}
            </AppButton>
            {submitting && (
              <CircularProgress
                size={20}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-10px',
                  marginLeft: '-10px',
                }}
              />
            )}
          </Box>
        </>
      }
      dialogContent={
        loading === undefined ? (
          <></>
        ) : loading ? (
          <AppBox
            flex
            center
            sx={{
              width: '100%',
              ...dialogMediaStyle,
            }}
          >
            <CircularProgress size={40} color='primary' />
          </AppBox>
        ) : (
          <AppBox
            flex
            sx={{
              ...dialogMediaStyle,
            }}
          >
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={12} md={5}>
                <AppContainer sx={{ width: '100%', height: '100%', px: 2, py: 1 }} variant='outlined' useBorderRadius={false}>
                  <Grid container item rowSpacing={1} columnSpacing={1}>
                    <Grid item xs={12}>
                      {providers?.length <= 0 ? (
                        <AppBox flex center sx={{ color: (theme) => theme.palette.error.main }}>
                          <AppIcon icon={ICON.Error2} />
                          <AppText sx={{ mx: 1 }}>{localization.translate('errors.no_receipt_providers')}</AppText>
                        </AppBox>
                      ) : (
                        <AppTextField
                          sx={{ mt: 1 }}
                          fullWidth
                          select
                          //readonly={providers.length <= 1}
                          readonly
                          label={localization.translate('settings.provider')}
                          value={provider?.provider || ''}
                          onChange={(v) => {
                            setProvider(providers?.find((p) => p.provider === v));
                          }}
                        >
                          {providers.map((t, i) => {
                            return (
                              <MenuItem key={i} value={t.provider}>
                                <AppBox flex centery sx={{ width: '100%' }}>
                                  {RECEIPT_PROVIDERS.find((p) => p.provider === t.provider)?.icon && (
                                    <AppIcon
                                      icon={ICON[RECEIPT_PROVIDERS.find((p) => p.provider === t.provider)?.icon]}
                                      size={ICON_SIZE.ExtraSmall}
                                      sx={{ mx: 1 }}
                                    />
                                  )}
                                  <AppText>{t.name}</AppText>
                                  <AppBox flex sx={{ flexGrow: 1 }} />
                                  {t.isDefault && <AppText>{localization.translate('common.default')}</AppText>}
                                </AppBox>
                              </MenuItem>
                            );
                          })}
                        </AppTextField>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value='new'
                        control={<Radio checked={isNew} onClick={setSelectedOption} />}
                        label={localization.translate('donations.new_receipt')}
                        componentsProps={{ typography: { fontSize: (theme) => theme.typography.small.fontSize } }}
                      />
                    </Grid>
                    {stateErrorDescription && (
                      <Grid item xs={12}>
                        <AppBox flex center sx={{ width: '100%' }}>
                          <AppText variant='small' sx={{ color: (theme) => theme.palette.error.main }}>
                            {stateErrorDescription}
                          </AppText>
                        </AppBox>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <ReceiptFieldView title={localization.translate('donations.source_name')} value={config?.transaction?.sourceName} />
                    </Grid>
                    <Grid item xs={12}>
                      <ReceiptFieldView
                        title={localization.translate('app.campaign')}
                        value={config?.campaigns.find((e) => e.projectId === config?.transaction?.projectId)?.projectName || ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ReceiptFieldView title={localization.translate('app.sum')} value={localization.displayCurrency(config?.transaction?.amount)} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ReceiptFieldView title={localization.translate('app.currency')} value={config?.transaction.currency} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ReceiptFieldView
                        title={localization.translate('donations.payment_method')}
                        value={localization.translate(`app.${config?.transaction.sourceType}`)}
                      />
                    </Grid>

                    {config?.transaction.sourceType === 'paypal' && (
                      <>
                        <Grid item xs={12}>
                          <ReceiptFieldView title={localization.translate('donations.source_email')} value={config?.transaction?.sourceEmail || '-'} />
                        </Grid>
                        <Grid item xs={12}>
                          <ReceiptFieldView
                            title={localization.translate('donations.source_amount')}
                            value={localization.displayCurrency(config?.transaction?.sourceAmount) || '-'}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} md={4}>
                      <ReceiptFieldView title={localization.translate('donations.source_bank')} value={config?.transaction?.sourceBank || '-'} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ReceiptFieldView title={localization.translate('donations.source_branch')} value={config?.transaction?.sourceBranch || '-'} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ReceiptFieldView title={localization.translate('donations.source_account')} value={config?.transaction?.sourceAccount || '-'} />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <ReceiptFieldView title={localization.translate('donations.bank_reference')} value={config?.transaction?.bankReference || '-'} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ReceiptFieldView title={localization.translate('donations.source_reference')} value={config?.transaction.sourceReference || '-'} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ReceiptFieldView
                        title={localization.translate('donations.transaction_date')}
                        value={localization.display(new Date(config?.transaction.transactionDate)) || '-'}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <AppTextField
                        readonly={!isNew}
                        sx={{ mt: 2 }}
                        fullWidth
                        multiline
                        label={localization.translate('app.comments')}
                        placeholder={localization.translate('donations.receipt_comments')}
                        rows={5}
                        onChange={(v) => (comments.current = v)}
                      ></AppTextField>
                    </Grid>
                    {provider?.data?.supportsLinking && (
                      <>
                        <Grid item xs={12}>
                          <FormControlLabel
                            value='existing'
                            control={<Radio checked={!isNew} onClick={setSelectedOption} />}
                            label={localization.translate('donations.link_existing_receipt')}
                            componentsProps={{ typography: { fontSize: (theme) => theme.typography.small.fontSize } }}
                          />
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <AppTextField
                              readonly={isNew || linking}
                              sx={{
                                width: '100%',
                              }}
                              id='invoice-number'
                              name='invoice-number'
                              label={localization.translate('donations.receipt_number')}
                              value={existingNumber}
                              onChange={(v) => {
                                setExistingNumber(v);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                            <AppBox sx={{ mx: 2, position: 'relative' }}>
                              <AppButton
                                sx={{ width: '100px' }}
                                startIcon={<AppIcon icon={ICON.Link} size={ICON_SIZE.Small} />}
                                disabled={isNew || linking || !existingNumber}
                                onClick={submitLinkInvoice}
                              >
                                {localization.translate('common.link')}
                              </AppButton>
                              {linking && (
                                <CircularProgress
                                  size={20}
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-10px',
                                  }}
                                />
                              )}
                            </AppBox>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {config?.transaction.sourceEmail && (
                      <Grid item container xs={12} sx={{ mt: 3 }}>
                        <Grid item xs={6}>
                          <ReceiptFieldView title={localization.translate('donations.source_email')} value={config?.transaction.sourceEmail} />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                          <AppBox flex centery sx={{ ml: 2 }}>
                            <AppSwitch checked={sendEmail} onClick={() => setSendEmail(!sendEmail)} />
                            <AppText variant='small' sx={{ mx: 1 }}>
                              {localization.translate('donations.send_receipt_byemail')}
                            </AppText>
                          </AppBox>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </AppContainer>
              </Grid>
              <Grid item xs={12} md={7}>
                {previewing && !previewSource && <LinearProgress />}
                <AppContainer
                  sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                    backgroundColor: (theme) => (!previewSource ? theme.palette.layout.empty : theme.palette.common.white),
                    ...dialogMediaStyle,
                  }}
                  variant='outlined'
                  useBorderRadius={false}
                >
                  {!previewSource && {
                    ...(!!providers?.length && !provider?.data?.supportsPreview ? (
                      <AppBox flex center sx={{ width: '100%', height: '100%' }}>
                        <AppText>{localization.translate('donations.no_preview_supported')}</AppText>
                      </AppBox>
                    ) : (
                      <Skeleton
                        variant='rectangular'
                        sx={{ height: '100%', width: '100%', bgcolor: (theme) => theme.palette.layout.empty }}
                        animation={false}
                      />
                    )),
                  }}
                  {previewSource && (
                    <Document
                      loading=''
                      renderMode='svg'
                      className='pdfdoc'
                      file={`data:application/pdf;base64,${previewSource}`}
                      onLoadError={console.error}
                      onLoadSuccess={onPreviewLoadSuccess}
                    >
                      {range(numPages).map((pg) => (
                        <div key={pg}>
                          {pg !== numPages && <div style={{ margin: '1em' }} />}
                          <Page pageNumber={pg + 1} />
                        </div>
                      ))}
                    </Document>
                  )}
                </AppContainer>
              </Grid>
            </Grid>
            <AppNotification
              severity={NOTIFICATION_SEVIRITY.ERROR}
              vertical='top'
              open={error > 0}
              onClose={() => setError(0)}
              message={
                error === 2 ? localization.translate('donations.load_receipt_preview_error') : localization.translate('donations.generate_receipt_error')
              }
            />
          </AppBox>
        )
      }
    />
  );
});

export default GenerateReceiptDialog;
