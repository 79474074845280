import { useTheme } from '@emotion/react';
import { TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';
import { validateNumericInput } from '../../utils/utils';

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'hovered',
})(({ theme, hovered }) => ({
  '& .MuiInputBase-input': {
    fontSize: theme.typography.normal.fontSize,
  },
  '& .MuiFormLabel-root': {
    fontSize: theme.typography.normal.fontSize,
    '&.MuiInputLabel-root': {
      '&.Mui-disabled': {
        color: theme.palette.grey[600],
        fontSize: theme.typography.normal.fontSize,
      },
      '&.MuiInputLabel-shrink': {
        color: theme.palette.text.label,
        fontSize: theme.typography.xsmall.fontSize,
        transform: 'translate(14px, -9px) ',
      },
    },
  },
  ...(hovered && {
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        border: `1px solid ${theme.palette.grey[400]}`,

        transition: '.6s',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: `1px solid ${theme.palette.grey[600]}`,
      },
    },
    '& .MuiOutlinedInput-root': {
      '& > fieldset': {
        border: '0px solid transparent',
      },
    },
  }),
}));

const StyledTextFieldForStandard = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: theme.typography.normal.fontSize,
    '&.MuiInput-input': {
      '&.Mui-disabled': {
        WebkitTextFillColor: theme.palette.grey[600],
      },
    },
  },
  '& .MuiFormLabel-root': {
    fontSize: theme.typography.normal.fontSize,
    '&.MuiInputLabel-root': {
      '&.Mui-disabled': {
        color: theme.palette.grey[600],
        fontSize: theme.typography.normal.fontSize,
      },
      '&.MuiInputLabel-shrink': {
        color: theme.palette.text.label,
        fontSize: theme.typography.normal.fontSize,
        transform: 'scale(0.85)',
      },
    },
  },
}));

export const AppText = forwardRef((props, ref) => {
  const { variant = 'normal', ...rest } = props;
  return <Typography ref={ref} variant={variant} {...rest} />;
});

export const AppTextField = (props) => {
  const { readonly, variant = 'outlined', size = 'small', validateInput, onChange, ...rest } = props;

  const validateValue = (e) => {
    let allowInput = validateInput ? validateInput(e.target.value) : true;

    if (!allowInput) {
      e.preventDefault();
    } else {
      if (onChange) {
        onChange(e.target.value);
      }
    }
  };

  return variant === 'standard' ? (
    <StyledTextFieldForStandard {...rest} size={size} variant={variant} disabled={readonly} onChange={(e) => validateValue(e)} />
  ) : (
    <StyledTextField {...rest} size={size} disabled={readonly} variant={variant} onChange={(e) => validateValue(e)} />
  );
};

export const AppNumericField = (props) => {
  const { validateInput, ...rest } = props;
  return (
    <AppTextField
      {...props}
      validateInput={(v) => {
        const res = validateInput ? validateInput(v) : true;
        return res ? validateNumericInput(v) : false;
      }}
    />
  );
};

export const InlineTextField = (props) => {
  const { sx, readonly, validateInput, numeric, onChange, ...rest } = props;
  const theme = useTheme();

  const validate = (v) => {
    const res = validateInput ? validateInput(v) : true;
    return res ? (numeric ? validateNumericInput(v) : res) : false;
  };

  const validateValue = (e) => {
    let allowInput = validate(e.target.value);
    if (!allowInput) {
      e.preventDefault();
    } else {
      if (onChange) {
        onChange(e.target.value);
      }
    }
  };
  const fsx = {
    ...sx,
    '& .MuiFormLabel-root': {
      '&.MuiInputLabel-root': {
        '&.Mui-disabled': {
          color: theme.palette.grey[600],
          fontSize: theme.typography.normal.fontSize,
        },
        '&.MuiInputLabel-shrink': {
          fontSize: theme.typography.normal.fontSize,
          transform: 'translate(14px, -9px) scale(0.85)',
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      '& > fieldset': {
        //border: "none",
        border: '0px solid transparent',
      },
    },
    ...(!readonly && {
      '& .MuiOutlinedInput-root:hover': {
        '& > fieldset': {
          border: `1px solid ${theme.palette.grey[400]}`,

          transition: '.6s',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        '& > fieldset': {
          border: `1px solid ${theme.palette.grey[600]}`,
          //borderColor: "orange"
        },
      },
    }),
  };
  return <TextField {...rest} sx={{ ...fsx }} size='small' variant='outlined' disabled={readonly} onChange={(e) => validateValue(e)} />;
};

// Example how to style compenent with props:
/*
const HeaderStyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    //backgroundColor: theme.palette.layout.secondary,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

*/
