import { styled } from '@mui/material/styles';
import { AppBar, Box, Grid, IconButton, ListItemText, Toolbar, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { MainAreaHeader } from './MainAreaHeader';
import { useAppContext } from '../context/AppContext';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const MainArea = () => {
  const { profile } = useAppContext();
  return (
    <>
      {profile.sideMenu && <MainAreaHeader />}

      <Main sx={{ padding: '10px 15px 10px 15px', overflow: 'auto' }}>
        <Outlet />
      </Main>
    </>
  );
};

export default MainArea;
