import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Box, Grid, IconButton, InputAdornment, MenuItem, Tooltip, Typography } from '@mui/material';
import { RECORD_STATE, RECORD_CREATION_MODE, INVOICE_STATE, LANGUAGE_DIRECTION } from '../../common/Constants';
import { useAppContext } from '../../context/AppContext';
import { compareDates, getCurrencySymbol, validateEmailInput } from '../../utils/utils';
import { AppIcon, ICON, ICON_SIZE } from '../../common/components/AppIcon';
import { AppNumericField, AppText, AppTextField } from '../../common/components/AppText';
import { getStatusColor, StatusItems } from '../../common/components/StatusSelector';
import CurrencySelector from '../../common/components/CurrencySelector';
import PaymentSourceSelector from '../../common/components/PaymentSourceSelector';
import ReceiptDownloader from '../../common/components/ReceiptDownloader';
import GenerateReceiptSubmitter from '../../common/components/GenerateReceiptSubmitter';
import { AppBox } from '../../common/components/AppBox';
import { AppCheckbox } from '../../common/components/AppCheckbox';
import { AppFormLayout } from '../../common/components/AppFormLayout';
import { useTheme } from '@emotion/react';
import { AppButton } from '../../common/components/AppButton';
import CampaignsSelector from '../../common/components/CampaignsSelector';
import { PaymentProviderSelector } from '../../common/components/PaymentProviderSelector';
import { AppDatePicker } from '../../common/components/AppDatePicker';
import { getCampaignName } from '../../common/components/datagrid/AppDataGridUtils';
import AppSwitch from '../../common/components/AppSwitch';

const DonationForm = forwardRef((props, ref) => {
  const { api, campaigns, splitProps, maxSplitAmount, ...rest } = props;
  const theme = useTheme();
  const { localization, tenant } = useAppContext();

  const deployingData = (details) => {
    let toDeploy = null;
    if (details) {
      toDeploy = { ...details };
    } else {
      if (!splitProps?.parent) {
        toDeploy = {
          creationMode: RECORD_CREATION_MODE.MANUAL,
          transactionDate: new Date(),
          excludeFromStatistics: false,
          invoice: {
            state: INVOICE_STATE.absent,
          },
        };
        api.setInvalidInputs({ amount: true, sourceName: true });
      } else {
        toDeploy = {
          ...splitProps.parent,
          creationMode: RECORD_CREATION_MODE.MANUAL,
          amount: maxSplitAmount,
          invoice: {
            state: INVOICE_STATE.absent,
          },
          parentId: splitProps.parent.id,
        };
        delete toDeploy['id'];
        delete toDeploy['childrenCount'];
        delete toDeploy['hierarchy'];
      }
    }
    return toDeploy;
  };

  useEffect(() => {
    api.setData(deployingData(api.details));
  }, [maxSplitAmount]);

  const submittingData = (data) => {
    return {
      ...data,
      ...(data.sourceType !== 'paypal' && {
        sourceAmount: null,
      }),
    };
  };

  useImperativeHandle(ref, () => ({
    deployingData,
    submittingData,
  }));

  const setCurrency = (value) => {
    api.setDataField({ key: 'currency', value: value });
  };

  const setAmount = (value) => {
    if (splitProps?.parent) {
      api.setDataField({
        key: 'amount',
        value: value,
        validateInput: true,
        inputValidator: (v) => {
          return !v || v > maxSplitAmount || v <= 0;
        },
      });
    } else {
      api.setDataField({
        key: 'amount',
        value: value,
        validateInput: true,
        inputValidator: (v) => {
          return v <= 0;
        },
      });
    }
  };

  const setProjectId = (value) => {
    api.setDataField({ key: 'projectId', value: value, validateInput: true });
  };

  const setDate = (value) => {
    api.setDataField({
      key: 'transactionDate',
      value: value,
      changeValidator: (v) => {
        if (!(v instanceof Date)) {
          console.log('not a date');
          return false;
        }
        return !compareDates(v, new Date(api.details?.transactionDate || new Date()));
      },
    });
  };

  const setSourceName = (value) => {
    api.setDataField({ key: 'sourceName', value: value, validateInput: true });
  };

  const setSourceComment = (value) => {
    api.setDataField({ key: 'sourceComment', value: value || null });
  };

  const setStatus = (value) => {
    api.setDataField({ key: 'trackingState', value: value, validateInput: true });
  };

  const setSourceType = (value) => {
    api.setDataField({ key: 'sourceType', value: value, validateInput: true });
    const email = api.data.sourceEmail;
    const emailIsEmpty = !Boolean(email);
    const emailIsInvalid = !validateEmailInput(email);

    if (value === 'paypal') {
      if (emailIsEmpty || emailIsInvalid) {
        api.setInvalidInput('sourceEmail');
      } else {
        api.unsetInvalidInput('sourceEmail');
      }
      if (!api.data.sourceAmount) {
        api.setInvalidInput('sourceAmount');
      } else {
        api.unsetInvalidInput('sourceAmount');
      }
    } else {
      if (emailIsEmpty || !emailIsInvalid) {
        api.unsetInvalidInput('sourceEmail');
      } else {
        api.setInvalidInput('sourceEmail');
      }
      api.unsetInvalidInput('sourceAmount');
    }
  };

  const setSourceEmail = (value) => {
    api.setDataField({
      key: 'sourceEmail',
      value: value,
      validateInput: true,
      inputValidator: (v) => {
        // returns true if value is invalid, otherwise false
        const emailIsEmpty = !Boolean(v);
        const emailIsInvalid = !validateEmailInput(v);
        if (api.data?.sourceType === 'paypal') {
          return emailIsEmpty || emailIsInvalid;
        }
        return emailIsEmpty ? false : emailIsInvalid;
      },
    });
  };

  const setSourceAmount = (value) => {
    api.setDataField({
      key: 'sourceAmount',
      value: value,
      validateInput: true,
      inputValidator: (v) => {
        return !Boolean(v);
      },
    });
  };

  const setInvoiceState = (state) => {
    api.setData({
      ...api.data,
      invoice: {
        ...api.data.invoice,
        state: state,
      },
    });
    if (api.details) {
      if (state !== api.details.invoice?.state) {
        api.setFieldToUpdate('invoiceState');
      } else {
        api.unsetFieldToUpdate('invoiceState');
      }
    }
  };

  const setPaymentSourceProvider = (source) => {
    api.setData({
      ...api.data,
      sourceId: source.id,
      source: {
        id: source.id,
        displayName: source.displayName,
        provider: source.provider,
      },
    });
    if (api.details?.sourceId !== source.id) {
      api.setFieldToUpdate('sourceId');
    } else {
      api.unsetFieldToUpdate('sourceId');
    }
  };
  const renderInvoice = (localization) => {
    const state = !api.data.invoice?.state
      ? INVOICE_STATE.absent
      : api.data.invoice?.state === INVOICE_STATE.ready && !api.data.invoice?.links && !api.data.invoice?.variations
      ? INVOICE_STATE.invalid
      : api.data.invoice.state;

    switch (state) {
      case INVOICE_STATE.absent:
        return (
          <AppBox flex>
            <AppCheckbox
              title={localization.translate('donations.receipt_required')}
              checked
              onChange={(e) => setInvoiceState(e.target.checked ? INVOICE_STATE.absent : INVOICE_STATE.notrequired)}
            />
            <GenerateReceiptSubmitter ids={[api.data.id]} data={api.data} campaigns={campaigns} />
          </AppBox>
        );
      case INVOICE_STATE.ready:
        return (
          <ReceiptDownloader
            data={api.data}
            buttonProps={{ color: 'primary', width: '100%' }}
            text={localization.translate('donations.download_invoice')}
            tooltip={localization.translate('donations.download_invoice')}
            filename={`invoice_${api.data.invoice.number || ''}`}
          />
        );
      case INVOICE_STATE.inprogress:
        return (
          <Tooltip title={localization.translate('donations.receipt_generating')}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
              <IconButton disabled>
                <AppIcon icon={ICON.InProgress} />
              </IconButton>
              <AppText sx={{ color: (theme) => theme.palette.text.disabled }} variant='small'>
                {localization.translate('donations.receipt_generating')}
              </AppText>
            </Box>
          </Tooltip>
        );
      case INVOICE_STATE.failed:
        return <GenerateReceiptSubmitter color='error' ids={[api.data.id]} data={api.data} campaigns={campaigns} />;
      default:
        return (
          <AppBox flex>
            <AppCheckbox
              checked={false}
              title={localization.translate('donations.receipt_required')}
              onChange={(e) => setInvoiceState(e.target.checked ? INVOICE_STATE.absent : INVOICE_STATE.notrequired)}
            />
            <GenerateReceiptSubmitter disabled ids={[api.data.id]} data={api.data} campaigns={campaigns} />
          </AppBox>
        );
    }
  };

  return api.data ? (
    <AppFormLayout
      inline={!!api.details}
      {...(api.details && {
        headerProps: {
          content: (
            <>
              <AppBox flex column sx={{ minWidth: '40%' }}>
                <AppText variant='normal'>{localization.translate('app.donation')}</AppText>
                <AppText variant='small' sx={{ color: (theme) => theme.palette.text.secondary }}>
                  {api.details.sourceName}
                </AppText>
                <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
                  {localization.displayCurrency(api.details.amount)}
                </AppText>
              </AppBox>
              <AppBox flex sx={{ width: '100%', justifyContent: 'flex-end' }}>
                {renderInvoice(localization)}
              </AppBox>
            </>
          ),
        },
      })}
      contentProps={{
        content: (
          <Grid container rowSpacing={2} columnSpacing={1} sx={{ pr: 1 }}>
            {splitProps?.parent && (
              <Grid container item xs={12} rowSpacing={1} columnSpacing={1}>
                <Grid item sm={12} md={6}>
                  <AppTextField
                    readonly
                    variant='standard'
                    fullWidth
                    label={localization.translate('donations.parent_donation')}
                    value={`${localization.displayCurrency(splitProps.parent.amount)} - ${localization.display(
                      new Date(splitProps.parent.transactionDate)
                    )} - ${splitProps.parent.sourceName}`}
                    inputProps={{
                      sx: { textOverflow: 'ellipsis' },
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <AppNumericField
                    fullWidth
                    readonly
                    variant='standard'
                    label={localization.translate('donations.amount_for_split')}
                    value={maxSplitAmount}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>{getCurrencySymbol(localization.locale, api.data.currency || tenant.getCurrency())}</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12} rowSpacing={1} columnSpacing={1}>
              <Grid item sm={12} md={6}>
                <AppTextField
                  select
                  required
                  fullWidth
                  label={localization.translate('app.status')}
                  value={api.data.trackingState || RECORD_STATE.NEW}
                  onChange={(v) => setStatus(v)}
                  InputProps={{
                    sx: { color: (theme) => getStatusColor(api.data.trackingState || RECORD_STATE.NEW, theme) },
                  }}
                >
                  {StatusItems.map((status) => {
                    return (
                      <MenuItem
                        sx={{ color: (theme) => getStatusColor(status.value, theme) }}
                        value={status.value}
                        disableRipple
                        variant='small'
                        key={status.value}
                        disabled={status.value === api.data.trackingState}
                      >
                        <AppBox flex center>
                          <AppIcon icon={status.icon} size={ICON_SIZE.Small} />
                          <AppText sx={{ mx: 1 }}> {localization.translate(`app.${status.text.toLowerCase()}`)}</AppText>
                        </AppBox>
                      </MenuItem>
                    );
                  })}
                </AppTextField>
              </Grid>
              <Grid item sm={12} md={6}>
                <AppDatePicker
                  label={localization.translate('common.date')}
                  name='date'
                  id='date'
                  required
                  value={api.data.transactionDate}
                  onChange={(date) => {
                    setDate(date);
                  }}
                  inputTextProps={{
                    required: true,
                    sx: { width: '100%' },
                    error: !api.data.transactionDate || isNaN(new Date(api.data.transactionDate).getTime()),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
              <Grid item sm={12} md={6}>
                <AppNumericField
                  sx={{
                    width: '100%',
                  }}
                  required
                  label={localization.translate('app.sum')}
                  name='amount'
                  id='amount'
                  value={api.data.amount || ''}
                  error={
                    splitProps?.parent ? !api.data.amount || api.data.amount > maxSplitAmount || api.data.amount <= 0 : !api.data.amount || api.data.amount <= 0
                  }
                  onChange={(v) => {
                    setAmount(v);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>{getCurrencySymbol(localization.locale, api.data.currency || tenant.getCurrency())}</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <CurrencySelector required selectedCode={api.data.currency} onSelected={(c) => setCurrency(c.code)} />
              </Grid>
            </Grid>

            <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
              <Grid item sm={12} md={6}>
                <PaymentSourceSelector required selectedCode={api.data.sourceType} onSelected={(s) => setSourceType(s.code)} />
              </Grid>
              <Grid item sm={12} md={6}>
                <AppTextField
                  fullWidth
                  label={localization.translate('donations.business_number')}
                  name='business_number'
                  id='business_number'
                  value={api.data.businessNumber || ''}
                  onChange={(v) => api.setDataField({ key: 'businessNumber', value: v })}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} rowSpacing={2} columnSpacing={1}>
              <Grid item sm={12} md={6}>
                <CampaignsSelector
                  item={{
                    value: {
                      projectName: getCampaignName(campaigns, api.data.projectId),
                      projectId: api.data.projectId || null,
                    },
                  }}
                  applyValue={(item) => {
                    setProjectId(item.value.projectId);
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <PaymentProviderSelector
                  disabled={api.data.creationMode !== RECORD_CREATION_MODE.MANUAL}
                  item={{
                    value: {
                      id: api.data.source?.id || null,
                      displayName: api.data.source?.displayName || '',
                      provider: api.data.source?.provider || '',
                    },
                  }}
                  applyValue={(item) => {
                    setPaymentSourceProvider(item.value);
                  }}
                />
              </Grid>
            </Grid>
            <fieldset
              style={{
                width: '100%',
                margin: localization.dir === LANGUAGE_DIRECTION.LTR ? '20px 0px 10px 8px' : '20px 8px 10px 0px',
                padding: '15px 7px 15px 7px',
                borderRadius: '4px',
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <legend style={{ fontSize: theme.typography.small.fontSize }}>{localization.translate('donations.source')}</legend>
              <Grid container item xs={12} rowSpacing={2} columnSpacing={1} sx={{ mb: 1 }}>
                <Grid item sm={12} md={6}>
                  <AppTextField
                    fullWidth
                    required
                    label={localization.translate('donations.source_name')}
                    name='source_name'
                    id='source_name'
                    value={api.data.sourceName || ''}
                    error={!api.data.sourceName}
                    onChange={(v) => {
                      setSourceName(v);
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <AppTextField
                    required={api.data.sourceType === 'paypal'}
                    error={(api.data.sourceType === 'paypal' && !api.data.sourceEmail) || (api.data.sourceEmail && !validateEmailInput(api.data.sourceEmail))}
                    fullWidth
                    label={localization.translate('donations.source_email')}
                    name='source_email'
                    id='source_email'
                    value={api.data.sourceEmail || ''}
                    onChange={(v) => setSourceEmail(v)}
                    InputProps={{
                      style: { direction: LANGUAGE_DIRECTION.LTR },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item sm={12} sx={{ mb: 1 }}>
                <AppTextField
                  multiline
                  rows={4}
                  fullWidth
                  label={localization.translate('donations.source_comment')}
                  id='source_comment'
                  name='source_comment'
                  value={api.data.sourceComment || ''}
                  onChange={(v) => {
                    setSourceComment(v);
                  }}
                />
              </Grid>
              <Grid container item xs={12} rowSpacing={2} columnSpacing={1} sx={{ mt: 1 }}>
                {api.data.sourceType === 'paypal' && (
                  <Grid item sm={12} md={6}>
                    <AppNumericField
                      required
                      error={!api.data.sourceAmount}
                      fullWidth
                      label={localization.translate('donations.source_amount')}
                      name='source_amount'
                      id='source_amount'
                      value={api.data.sourceAmount || ''}
                      onChange={(v) => setSourceAmount(v)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>{getCurrencySymbol(localization.locale, api.data.currency || tenant.getCurrency())}</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                <Grid item sm={12} md={6}>
                  <AppTextField
                    readonly={api.data.creationMode !== RECORD_CREATION_MODE.MANUAL}
                    fullWidth
                    label={localization.translate('donations.source_reference')}
                    name='source_reference'
                    id='source_reference'
                    value={api.data.sourceReference || ''}
                    onChange={(v) => api.setDataField({ key: 'sourceReference', value: v, validateInput: true, inputValidator: (v) => isNaN(v) })}
                    error={api.data.sourceReference && isNaN(api.data.sourceReference)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} rowSpacing={2} columnSpacing={1} sx={{ mt: 1 }}>
                <Grid item sm={12} md={6}>
                  <AppTextField
                    readonly={api.data.creationMode !== RECORD_CREATION_MODE.MANUAL}
                    fullWidth
                    label={localization.translate('donations.source_bank')}
                    name='source_bank'
                    id='source_bank'
                    value={api.data.sourceBank || ''}
                    onChange={(v) => api.setDataField({ key: 'sourceBank', value: v })}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <AppTextField
                    readonly={api.data.creationMode !== RECORD_CREATION_MODE.MANUAL}
                    fullWidth
                    label={localization.translate('donations.source_branch')}
                    name='source_branch'
                    id='source_branch'
                    value={api.data.sourceBranch || ''}
                    onChange={(v) => api.setDataField({ key: 'sourceBranch', value: v })}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} rowSpacing={2} columnSpacing={1} sx={{ mt: 1 }}>
                <Grid item sm={12} md={6}>
                  <AppTextField
                    readonly={api.data.creationMode !== RECORD_CREATION_MODE.MANUAL}
                    fullWidth
                    label={localization.translate('donations.source_account')}
                    name='source_account'
                    id='source_account'
                    value={api.data.sourceAccount || ''}
                    onChange={(v) => api.setDataField({ key: 'sourceAccount', value: v })}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <AppTextField
                    readonly={api.data.creationMode !== RECORD_CREATION_MODE.MANUAL}
                    fullWidth
                    label={localization.translate('donations.bank_reference')}
                    name='bank_reference'
                    id='bank_reference'
                    value={api.data.bankReference || ''}
                    onChange={(v) => api.setDataField({ key: 'bankReference', value: v, validateInput: true, inputValidator: (v) => isNaN(v) })}
                    error={api.data.bankReference && isNaN(api.data.bankReference)}
                  />
                </Grid>
              </Grid>
            </fieldset>

            <Grid item sm={12} md={6}>
              <AppTextField
                readonly
                fullWidth
                label={localization.translate('app.created_by')}
                name='created_by'
                id='created_by'
                value={api.data.createdBy || ''}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <AppBox flex centery>
                <AppSwitch
                  checked={!api.data?.excludeFromStatistics}
                  onChange={() => {
                    api.setDataField({ key: 'excludeFromStatistics', value: !api.data?.excludeFromStatistics });
                  }}
                />
                <AppText sx={{ mx: 1 }}>{localization.translate('donations.include_to_statistic')}</AppText>
              </AppBox>
            </Grid>
          </Grid>
        ),
      }}
      {...(api.details && {
        footerProps: {
          actionProps: {
            content: (
              <AppButton disabled={api.disableSubmit} onClick={api.submitData}>
                {localization.translate('common.save')}
              </AppButton>
            ),
          },
        },
      })}
    />
  ) : (
    <></>
  );
});

export default DonationForm;
