import { Badge, Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { AppIcon, ICON } from '../../common/components/AppIcon';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import { useJobsContext } from '../../context/JobsContext';
import { TasksListDialog } from './TasksListDialog';
import { TASK_VIEWSTATE } from '../../common/Constants';

export const TasksMenu = (props) => {
  const theme = useTheme();
  const { localization } = useAppContext();
  const jobs = useJobsContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newTasksCount, setNewTasksCount] = useState(0);

  useEffect(() => {
    jobs.subscribeOnViewStateChanged({
      subscriber: 'TaskMenu',
      callback: (jobs) => {
        setNewTasksCount(jobs ? (jobs.length < 100 ? jobs.length : '100+') : 0);
      },
    });
    return () => {
      jobs.unsubscribe({ subscriber: 'TaskMenu' });
    };
  }, []);

  const handleClose = async () => {
    setDialogOpen(false);
    const toUpdatedOnServer = [];
    jobs.getJobs().forEach((e, i) => {
      if (e.viewState === TASK_VIEWSTATE.NEW) {
        e.viewState = TASK_VIEWSTATE.READ;
        toUpdatedOnServer.push({ id: e.id, viewState: TASK_VIEWSTATE.READ });
      }
    });
    if (toUpdatedOnServer.length > 0) {
      await jobs.updateJobs(toUpdatedOnServer);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      <IconButton onClick={() => setDialogOpen(true)} disabled={jobs.data?.length <= 0}>
        <Badge badgeContent={newTasksCount} color='secondary'>
          <AppIcon
            tooltip={localization.translate('tasks.tasks')}
            icon={ICON.Tasks}
            style={{
              color: jobs.data?.length <= 0 ? theme.palette.text.disabled : theme.palette.primary.main,
            }}
          />
        </Badge>
      </IconButton>
      <TasksListDialog open={dialogOpen} onClose={handleClose} />
    </Box>
  );
};
