import { useTheme } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { Box, Divider, Grid, IconButton, MenuItem, Tooltip, useMediaQuery } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { forwardRef, Fragment, useImperativeHandle, useState } from 'react';
import { AppDropdownMenu } from '../AppDropdownMenu';
import { AppBox } from '../AppBox';
import { AppDataGridLayoutMenu } from './AppDataGridLayoutMenu';
import { useAppContext } from '../../../context/AppContext';
import { AppDataGridQuickFilter } from './AppDataGridQuickFilter';
import { AppIcon, ICON, ICON_SIZE } from '../AppIcon';
import { ReloadItem } from './toolbarItems/ReloadItem';
import { FilterItem } from './toolbarItems/FilterItem';
import { DeleteItem } from './toolbarItems/DeleteItem';
import { AddItem } from './toolbarItems/AddItem';
import { PrintItem } from './toolbarItems/PrintItem';

/*
[
	{
		addReload: {
			onActivated: ...,
			isDisabled: ...
            rest: {
                ...
            }
		},
		addFilter: {
			onActivated: ...,
			isDisabled: ...,
            rest: {
                ...
            }
		},
	},
	{
		addPrint: {
			onActivated: ...,
			isDisabled: ...,
            rest: {
                ...
            }
		}
	},
	{
		addButton: {
			option:...,
			onActivated: ...,
			isDisabled: ...,
            rest: {
                ...
            }
		}
	}
]
*/

export const STANDARD_TOOLBAR_BUTTONS = {
  RELOAD: 'reload',
  FILTER: 'filter',
  PRINT: 'print',
  ADD: 'add',
  DELETE: 'delete',
};

const STANDARD_TOOLBAR_BUTTON_OPTIONS = {
  [STANDARD_TOOLBAR_BUTTONS.RELOAD]: ReloadItem,
  [STANDARD_TOOLBAR_BUTTONS.FILTER]: FilterItem,
  [STANDARD_TOOLBAR_BUTTONS.PRINT]: PrintItem,
  [STANDARD_TOOLBAR_BUTTONS.ADD]: AddItem,
  [STANDARD_TOOLBAR_BUTTONS.DELETE]: DeleteItem,
};

const optionToButton = (props) => {
  const { onActivated, isDisabled, onClose, ...rest } = props;
  if (props.component) {
    return <props.component onActivated={onActivated} isDisabled={isDisabled} onClose={onClose} {...rest} />;
  }
};

const optionsToSubBar = (options) => {
  const subBar = [];
  if (options) {
    const propNames = Object.getOwnPropertyNames(options);
    for (let i = 0; i < propNames.length; i++) {
      const option = { ...options[propNames[i]] };
      if (!option.component) {
        // no concrete elementType of button option specified
        if (Boolean(STANDARD_TOOLBAR_BUTTON_OPTIONS[propNames[i]])) {
          // concrete elementType of button option is a standard option - use default
          option.component = STANDARD_TOOLBAR_BUTTON_OPTIONS[propNames[i]];
        } else {
          continue;
        }
      }
      subBar.push(option);
    }
    return subBar;
  }
};

const AppDataGridToolbarDropdownMenu = (props) => {
  const { buttons, apiRef } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let items = [];
  if (buttons) {
    if (buttons.items) {
      items = items.concat(buttons.items);
    }
    if (buttons.more) {
      items = items.concat(buttons.more);
    }
  }

  //const items = [...buttons?.items, ...buttons?.more];
  return (
    <div>
      <Tooltip title='Toolbar' enterDelay={500}>
        <IconButton
          id='toolbar-dropdown-button'
          aria-controls={open ? 'toolbar-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ textTransform: 'none' }}
        >
          <MenuOutlinedIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <AppDropdownMenu
        id='toolbar-menu'
        aria-labelledby='toolbar-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {items.map((options, i) => {
          const subbar = optionsToSubBar(options);
          if (subbar.length > 0) {
            return subbar.map((b, j) => {
              return <MenuItem key={j}>{optionToButton({ ...b, apiRef: apiRef, filterProps: props.filterProps })}</MenuItem>;
            });
          }
          return [];
        })}
      </AppDropdownMenu>
    </div>
  );
};

const AppDataGridToolbarMenuMore = (props) => {
  const { buttons, selectionModel } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const parseMore = (buttons) => {
    const items = [];
    for (let i = 0; i < buttons.length; i++) {
      const bar = optionsToSubBar(buttons[i]);
      for (let j = 0; j <= bar.length; j++) {
        if (j < bar.length) {
          items.push(
            <MenuItem key={`${i}_${j}`}>
              {optionToButton({
                ...bar[j],
                apiRef: props.apiRef,
                filterProps: props.filterProps,
                variant: 'menuitem',
                sx: { width: '100%' },
                ...(bar[j].selectionAware && {
                  selectionModel: selectionModel,
                }),
                onClose: () => setAnchorEl(null),
              })}
            </MenuItem>
          );
        } else {
          if (i !== buttons.length - 1) {
            items.push(<Divider key={`${i}_${j}`} orientation='horizontal' variant='middle' flexItem sx={{ mx: 1 }} />);
          }
        }
      }
    }
    return items;
  };

  return buttons?.length > 0 ? (
    <>
      <IconButton
        size='small'
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertIcon fontSize='small' color='primary' id='more-toolbar-button' />
      </IconButton>
      <AppDropdownMenu id='more-toolbar-menu' aria-labelledby='more-toolbar-button' anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {parseMore(buttons)}
      </AppDropdownMenu>
    </>
  ) : (
    <></>
  );
};

const AppDataGridToolbarMenu = (props) => {
  const { buttons, selectionModel } = props;
  return buttons?.items?.length > 0 ? (
    <>
      {buttons.items.map((options, i) => {
        const subbar = optionsToSubBar(options);
        if (subbar.length > 0) {
          return (
            <Fragment key={i}>
              <ToolbarDivider />
              {subbar.map((b, j) =>
                optionToButton({
                  ...b,
                  key: j,
                  apiRef: props.apiRef,
                  ...(b.selectionAware && {
                    selectionModel: selectionModel,
                  }),
                  filterProps: props.filterProps,
                })
              )}
            </Fragment>
          );
        }
        return [];
      })}
      {buttons.more?.length > 0 && <AppDataGridToolbarMenuMore buttons={buttons.more} apiRef={props.apiRef} selectionModel={selectionModel} />}
    </>
  ) : (
    <></>
  );
};

const AppDataGridUpDownToolbarMenu = (props) => {
  const { onMoveRow, apiRef } = props;
  const handleMoveRow = (up) => {
    if (onMoveRow) {
      let selectedId = -1;
      const selected = apiRef.current.getSelectedRows();
      if (selected.size <= 0) {
        selectedId = apiRef.current.getRowIdFromRowIndex(0);
      } else {
        if (selected.size === 1) {
          selectedId = selected.keys().next().value;
        } else {
          selectedId = up
            ? selected.keys().next().value // first in the list of selected (to move up)
            : Array.from(selected.keys()).pop(); // last in the list of selected (to move down)
        }
      }
      onMoveRow(selectedId, up ? 'ArrowUp' : 'ArrowDown');
    }
  };
  return (
    <AppBox flex sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
      <IconButton color='primary' onClick={() => handleMoveRow(true)}>
        <AppIcon icon={ICON.ChevronUp} size={ICON_SIZE.Medium} />
      </IconButton>
      <IconButton color='primary' onClick={() => handleMoveRow(false)}>
        <AppIcon icon={ICON.ChevronDown} size={ICON_SIZE.Medium} />
      </IconButton>
    </AppBox>
  );
};

const StyledToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '&.MuiDataGrid-toolbarContainer': {
    height: 'auto',
  },
  '& .MuiGrid-root': {
    '&.MuiGrid-item': {
      py: 1,
    },
  },
}));

const ToolbarDivider = (props) => {
  return <Divider orientation='vertical' variant='middle' flexItem sx={{ mx: 1 }} />;
};

export const AppDataGridToolbar = forwardRef((props, ref) => {
  const { layoutMenuProps, name, filterProps, upDownProps, apiRef } = props;
  const theme = useTheme();
  const { localization } = useAppContext();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [selectionModel, setSelectionModel] = useState([]);
  useImperativeHandle(ref, () => ({
    propagateSelectionModel: (model) => {
      setSelectionModel(model);
    },
  }));

  return (
    <Box sx={{ flexGrow: 1, px: 1 }}>
      {!isLgUp && (
        <Grid container columnSpacing={1} rowSpacing={2}>
          <Grid item xs={12}>
            <AppDataGridToolbarDropdownMenu {...props} />
          </Grid>
        </Grid>
      )}
      {isLgUp && (
        <AppBox flex>
          <AppDataGridLayoutMenu layoutMenuProps={layoutMenuProps} name={name} apiRef={apiRef} />
          <ToolbarDivider />
          {filterProps?.addQuickFilter && (
            <AppDataGridQuickFilter
              apiRef={apiRef}
              filterProps={filterProps}
              placeholder={localization.translate('common.quick_search')}
              width='300px'
              sx={{ mx: 0 }}
            />
          )}
          <AppDataGridToolbarMenu {...props} selectionModel={selectionModel} />
          {upDownProps && <AppDataGridUpDownToolbarMenu {...upDownProps} apiRef={apiRef} />}
        </AppBox>
      )}
    </Box>
  );
});
