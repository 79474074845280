import { forwardRef, useEffect } from 'react';
import { useAppContext } from '../../../../context/AppContext';
import { LANGUAGE_DIRECTION } from '../../../../common/Constants';
import { Grid } from '@mui/material';
import { AppTextField } from '../../../../common/components/AppText';
import LanguageSelect from '../../../../common/components/LanguageSelect';

const GreenInvoiceProviderForm = forwardRef((props, ref) => {
  const { data, setDataField, setInvalidInputs } = props;
  const { localization, profile } = useAppContext();

  useEffect(() => {
    let invalidInputs = null;
    if (data.id) {
      if (!data?.apiKey) {
        invalidInputs = {
          apiKey: true,
        };
      }
    } else {
      if (!data?.apiKey) {
        invalidInputs = {
          apiKey: true,
        };
      }
      if (!data?.apiSecret) {
        invalidInputs = { ...invalidInputs, apiSecret: true };
      }
    }
    if (invalidInputs) {
      setInvalidInputs(invalidInputs);
    }
  }, [data]);

  useEffect(() => {
    if (!data.lang) {
      setDataField({ key: 'lang', value: profile.language, onInvalidInputValidate: null });
    }
  }, []);

  return (
    <Grid container rowSpacing={2} columnSpacing={1}>
      <Grid item xs={12} md={6}>
        <LanguageSelect onChange={(v) => setDataField({ key: 'lang', value: v, onInvalidInputValidate: null })} code={data.lang} />
      </Grid>
      <Grid item xs={12}>
        <AppTextField
          InputProps={{
            style: { direction: LANGUAGE_DIRECTION.LTR },
          }}
          required
          error={!data.apiKey}
          fullWidth
          label='apiKey'
          name='apiKey'
          id='apiKey'
          value={data.apiKey || ''}
          onChange={(v) => setDataField({ key: 'apiKey', value: v, onInvalidInputValidate: null })}
        />
      </Grid>
      <Grid item xs={12}>
        <AppTextField
          InputProps={{
            style: { direction: LANGUAGE_DIRECTION.LTR },
          }}
          fullWidth
          required={!data.id}
          error={!data.id ? !data.apiSecret : false}
          label='apiSecret'
          name='apiSecret'
          id='apiSecret'
          onChange={(v) => setDataField({ key: 'apiSecret', value: v, onInvalidInputValidate: null })}
        />
      </Grid>
    </Grid>
  );
});

export default GreenInvoiceProviderForm;
