import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { AppText, AppTextField } from './AppText';

export const AutocompleteSelector = (props) => {
  const {
    item,
    valuePropName,
    labelPropName,
    options,
    applyValue,
    renderOption,
    renderInput,
    optionToValueComparer,
    multiple = true,
    label = '',
    width = 200,
    size = 'small',
    open,
    apiRef,
    focusElementRef,
    ...rest
  } = props;
  const [value, setValue] = useState(multiple ? [] : null);

  useEffect(() => {
    setValue(item?.value ? item.value : multiple ? [] : null);
  }, [item, multiple]);

  const onSelectedValueChanged = (event, newValue) => {
    if (applyValue) {
      applyValue({ ...item, value: newValue });
    }
    setValue(newValue);
  };

  return (
    <Autocomplete
      {...(open !== undefined ? { open: open } : {})}
      size={size}
      multiple={multiple}
      value={value}
      onChange={onSelectedValueChanged}
      options={options}
      getOptionLabel={(option) => {
        return option ? option[labelPropName] || '' : '';
      }}
      isOptionEqualToValue={(option, value) => {
        return optionToValueComparer ? optionToValueComparer(option, value) : option[valuePropName] === value[valuePropName];
      }}
      autoHighlight
      fullWidth
      disableClearable
      renderOption={(props, option) => {
        return renderOption ? (
          renderOption(props, option)
        ) : (
          <li {...props} key={option[valuePropName]}>
            {option[labelPropName]}
          </li>
        );
      }}
      disableListWrap
      renderInput={(params) => {
        return renderInput ? renderInput(params) : <AppTextField {...params} label={label} size={size} sx={{ width: width }} />;
      }}
      {...rest}
    />
  );
};

AutocompleteSelector.propTypes = {
  multiple: PropTypes.bool,
  item: PropTypes.shape({
    value: PropTypes.any,
  }),
  options: PropTypes.array.isRequired,
  valuePropName: PropTypes.string.isRequired,
  labelPropName: PropTypes.string.isRequired,
  applyValue: PropTypes.func,
  optionToValueComparer: PropTypes.func,
  label: PropTypes.string,
  width: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  renderOption: PropTypes.func,
  renderInput: PropTypes.func,
  open: PropTypes.bool,
};
