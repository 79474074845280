import { Box, Chip, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ROUTE_PATHS, WORKING_STATE } from '../../common/Constants';
import InnerCenteredCircularProgress from '../../common/components/InnerCenteredCircularProgress';
import InnerCenteredContainer from '../../common/components/InnerCenteredContainer';
import { useHttp } from '../../hooks/authentication';
import { useAppContext } from '../../context/AppContext';
import { CAMPAIGN_TILE_DIMENSIONS, ControlledDashboardTile } from './Common';
import CreditScoreSharpIcon from '@mui/icons-material/CreditScoreSharp';
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useTheme } from '@emotion/react';
import { useSetPathContext } from '../../context/PathContext';
import { AppText } from '../../common/components/AppText';

const InfoChip = (props) => {
  const { title, icon, label, iconSize, labelFontSize, labelFontWeight, backgroundColor } = props;

  return (
    <Tooltip title={title} placement='right'>
      <Chip
        icon={icon}
        label={label}
        sx={{
          color: (theme) => theme.palette.grey[50],
          backgroundColor: backgroundColor,
          '& .MuiChip-label': {
            fontSize: (theme) => labelFontSize || theme.typography.xlarge,
            mx: 1,
          },
          '& .MuiChip-icon': {
            color: (theme) => theme.palette.grey[50],
            fontSize: (theme) => iconSize || theme.typography.xlarge,
          },
        }}
      />
    </Tooltip>
  );
};

const EmptyTransactionsTile = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getHttp } = useHttp();
  const { localization } = useAppContext();
  const setPath = useSetPathContext();
  const [workingState, setWorkingState] = useState(WORKING_STATE.INPROGRESS);
  const [data, setData] = useState({});

  const calculateColor = (number) => {
    let color = 'dashboard.success';

    if (number > 0 && number <= 1000) {
      color = 'dashboard.info';
    } else if (number > 1000 && number <= 3000) {
      color = 'dashboard.warning';
    } else if (number > 3000) {
      color = 'dashboard.error';
    }
    return color;
  };

  const calculateFontSize = (first, second) => {
    let size = 'h5';
    const length = first.toLocaleString(localization.locale).length + second.toLocaleString(localization.locale).length;
    if (length <= 8) {
      size = 'h2';
    } else if (length <= 11) {
      size = 'h3';
    } else if (length <= 15) {
      size = 'h4';
    }
    return size;
  };

  const loadData = async () => {
    setWorkingState(WORKING_STATE.INPROGRESS);
    try {
      const response = await getHttp().get(`projects/null/statistics`);
      setWorkingState(WORKING_STATE.SUCCESS);
      const data = { ...response.data };
      data.color = calculateColor(data.sum);
      data.fontSize = calculateFontSize(data.sum, data.count);
      return data;
    } catch (error) {
      console.error(error);
      setWorkingState(WORKING_STATE.ERROR);
      return {};
    }
  };

  const reloadData = () => {
    (async () => {
      const data = await loadData();
      setData(data);
    })();
  };

  const handleNavigate = () => {
    const to = `/${ROUTE_PATHS.Donations}`;
    navigate(to, {
      state: {
        items: [
          {
            id: 1,
            field: 'projectId',
            operator: 'isEmpty',
          },
        ],
      },
    });
    setPath(to);
  };

  useEffect(() => {
    (async () => {
      const data = await loadData();
      setData(data);
    })();
  }, []);

  return (
    <ControlledDashboardTile
      title={localization.translate('dashboard.donations_without_campaign')}
      titleColor={(theme) => theme.palette.grey[50]}
      sx={{ width: `${CAMPAIGN_TILE_DIMENSIONS.WIDTH}px`, height: `${CAMPAIGN_TILE_DIMENSIONS.HEIGHT}px` }}
      background={data.color}
      onReload={reloadData}
      onSeeMore={handleNavigate}
      enableSeeMoreExpenses={false}
      iconSx={{
        color: (theme) => theme.palette.grey[50],
      }}
    >
      {workingState === WORKING_STATE.INPROGRESS && (
        <InnerCenteredContainer>
          <CircularProgress size={80} sx={{ color: (theme) => theme.palette.grey[50] }} />
        </InnerCenteredContainer>
      )}
      {workingState === WORKING_STATE.ERROR && (
        <InnerCenteredContainer>
          <AppText>{localization.translate('app.no_data')}</AppText>
        </InnerCenteredContainer>
      )}
      {(workingState == WORKING_STATE.SUCCESS || workingState === WORKING_STATE.IDLE) && (
        <>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '40%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Stack direction='column' sx={{ px: 1 }}>
                <Box>
                  <InfoChip
                    title={localization.translate('app.total_amount')}
                    icon={<AccountBalanceWalletOutlinedIcon />}
                    label={localization.displayCurrency(data.sum)}
                    labelFontWeight={700}
                    backgroundColor={data.color}
                  />
                </Box>
                <Box>
                  <InfoChip
                    title={localization.translate('dashboard.number_of_donations')}
                    icon={<CreditScoreSharpIcon />}
                    label={localization.display(data.count)}
                    labelFontWeight={700}
                    backgroundColor={data.color}
                  />
                </Box>
                <Box>
                  <InfoChip
                    title={localization.translate('dashboard.last_transaction_date')}
                    icon={<CalendarMonthSharpIcon />}
                    label={data.last ? localization.display(new Date(data.last)) : '-'}
                    labelFontSize={theme.typography.normal.fontSize}
                    backgroundColor={data.color}
                  />
                </Box>
              </Stack>
            </Box>
          </Box>
        </>
      )}
    </ControlledDashboardTile>
  );
};

export default EmptyTransactionsTile;
