import { InputAdornment } from '@mui/material';
import { useState, useEffect } from 'react';
import { AppTable, AppTableBody, AppTableCell, AppTableRow } from '../../../../common/components/AppTable';
import { AppNumericField, InlineTextField } from '../../../../common/components/AppText';
import CurrencySelector from '../../../../common/components/CurrencySelector';
import { useAppContext } from '../../../../context/AppContext';
import { useTenantContext } from '../../../../context/TenantContext';
import { InlineValueEditor } from '../../InlineValueEditor';

const CommonFinancialConfiguration = (props) => {
  const { localization, tenant } = useAppContext();
  const tenantContext = useTenantContext();
  const [settings, setSettings] = useState(null);
  const [commissionEdited, setCommissionEdited] = useState(false);
  const [currencyEdited, setCurrencyEdited] = useState(false);

  useEffect(() => {
    setSettings({
      ...tenantContext.tenantSettings,
      defaultCommissionPercentage: tenantContext.tenantSettings?.defaultCommissionPercentage || 0,
      defaultCurrency: tenantContext.tenantSettings?.defaultCurrency || tenant.getCurrency(),
    });
  }, [tenantContext.tenantSettings]);

  const handleEditCommission = async () => {
    const edit = !commissionEdited;
    setCommissionEdited(!commissionEdited);
    if (!edit) {
      await tenantContext.setTenantSettings({
        ...settings,
        defaultCommissionPercentage: settings.defaultCommissionPercentage || 0,
      });
    }
  };

  const cancelEditCommission = () => {
    setSettings({ ...settings, defaultCommissionPercentage: tenantContext.tenantSettings?.defaultCommissionPercentage || 0 });
    setCommissionEdited(!commissionEdited);
  };

  const handleEditCurrency = async () => {
    const edit = !currencyEdited;
    setCurrencyEdited(!currencyEdited);
    if (!edit) {
      await tenantContext.setTenantSettings({
        ...settings,
        defaultCurrency: settings.defaultCurrency || tenant.getCurrency(),
      });
    }
  };

  const cancelEditCurrency = () => {
    setSettings({ ...settings, defaultCurrency: tenantContext.tenantSettings?.defaultCurrency || tenant.getCurrency() });
    setCurrencyEdited(!currencyEdited);
  };

  return settings ? (
    <AppTable containerProps={{ sx: { width: '60%' } }}>
      <AppTableBody noBorder>
        <AppTableRow noHover>
          <AppTableCell sx={{ minWidth: '200px' }}>{`${localization.translate('campaigns.commission')} (%)`}</AppTableCell>
          <AppTableCell>
            {commissionEdited && (
              <AppNumericField
                sx={{ width: '130px' }}
                id='commission'
                name='commission'
                value={settings?.defaultCommissionPercentage}
                error={settings?.defaultCommissionPercentage < 0 || settings?.defaultCommissionPercentage > 100}
                validateInput={(v) => v >= 0 && v <= 100}
                onChange={(value) => setSettings({ ...settings, defaultCommissionPercentage: value })}
                InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
              />
            )}
            {!commissionEdited && <InlineTextField sx={{ width: '100%', mr: 1 }} readonly value={settings?.defaultCommissionPercentage} />}
          </AppTableCell>
          <AppTableCell>
            <InlineValueEditor edited={commissionEdited} name='commission' onEdit={handleEditCommission} onCancel={cancelEditCommission} />
          </AppTableCell>
        </AppTableRow>
        <AppTableRow noHover>
          <AppTableCell>{localization.translate('app.default_currency')}</AppTableCell>
          <AppTableCell sx={{ minWidth: '250px' }}>
            {currencyEdited && (
              <CurrencySelector selectedCode={settings?.defaultCurrency} onSelected={(v) => setSettings({ ...settings, defaultCurrency: v.code })} />
            )}
            {!currencyEdited && (
              <InlineTextField
                readonly
                value={settings?.defaultCurrency}
                InputProps={{
                  sx: {
                    paddingRight: 0,
                  },
                }}
              />
            )}
          </AppTableCell>
          <AppTableCell>
            <InlineValueEditor edited={currencyEdited} name='currency' onEdit={handleEditCurrency} onCancel={cancelEditCurrency} />
          </AppTableCell>
        </AppTableRow>
      </AppTableBody>
    </AppTable>
  ) : (
    <></>
  );
};

export default CommonFinancialConfiguration;
