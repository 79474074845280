import { forwardRef } from 'react';
import { Paper } from '@mui/material';

export const AppContainer = forwardRef((props, ref) => {
  const { sx, elevation, useBorderRadius = true, ...rest } = props;
  return (
    <Paper
      ref={ref}
      {...rest}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        ...sx,
        ...(useBorderRadius && { borderRadius: (theme) => theme.shape.borderRadius }),
      }}
      elevation={0}
    />
  );
});
