import { useTheme } from '@mui/material/styles';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useCallback, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { PERIOD } from '../../common/Constants';
import { useAppContext } from '../../context/AppContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ExpensesStatisticDetailed = (props) => {
  const { statistic, height, dataColor, onDrillDown } = props;

  const { localization } = useAppContext();
  const theme = useTheme();

  const handleDrillDown = (start) => {
    const end = new Date(start);
    end.setMonth(end.getMonth() + 1);
    onDrillDown(start, end);
  };

  const labels = useMemo(() => {
    if (!statistic.data?.data) {
      return [];
    }
    if (statistic.period === PERIOD.ONE_MONTH) {
      return [...statistic.data?.data?.map((e) => localization.display(e.date, { month: 'numeric', day: 'numeric' }))];
    }
    return [...statistic.data?.data?.map((e) => localization.display(e.date, { month: 'numeric', year: 'numeric' }))];
  }, [statistic, localization.locale]);

  const data = useMemo(() => {
    return {
      labels,
      dates: statistic.data?.data ? [...statistic.data.data.map((e) => e.date)] : [],
      datasets: [
        {
          label: `${localization.translate('app.expenses')}`,
          data: statistic.data?.data ? [...statistic.data.data.map((e) => e.sum)] : [],
          backgroundColor: dataColor,
          dates: statistic.data?.data ? [...statistic.data.data.map((e) => e.date)] : [],
          stack: 'Stack 0',
        },
      ],
    };
  }, [statistic, localization.locale]);

  const options = {
    locale: localization.locale,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: theme.palette.text.secondary,
          font: {
            family: theme.typography.fontFamily,
            size: 12,
          },
        },
        grid: {
          borderColor: theme.palette.divider,
          color: theme.palette.divider,
          borderDash: [2, 1],
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: theme.palette.text.secondary,
          font: {
            family: theme.typography.fontFamily,
            size: 12,
          },
          callback: (value, index, ticks) => {
            return localization.displayCurrency(value);
          },
        },
        grid: {
          borderColor: theme.palette.divider,
          color: theme.palette.divider,
          borderDash: [2, 1],
        },
      },
    },
    onClick(e) {
      if (statistic.period !== PERIOD.ONE_MONTH) {
        const activePoints = e.chart.getElementsAtEventForMode(
          e,
          'nearest',
          {
            intersect: true,
          },
          false
        );
        const [{ index }] = activePoints;
        handleDrillDown(data.dates[index]);
      }
    },
    plugins: {
      title: {
        display: true,
        text: `${localization.translate('dashboard.total_expenses_in_period')} ${localization.displayCurrency(statistic.data.sum)}`,
        color: theme.palette.text.primary,
        font: {
          family: theme.typography.fontFamily,
          weight: 'normal',
        },
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          color: theme.palette.text.secondary,
          font: {
            family: theme.typography.fontFamily,
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += localization.displayCurrency(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
  };

  return <Bar options={options} data={data} height={height} />;
};

export default ExpensesStatisticDetailed;
