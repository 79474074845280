import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useAppContext } from '../../context/AppContext';
import { AppText } from '../../common/components/AppText';
import { AppFormLayout } from '../../common/components/AppFormLayout';
import { AppBox } from '../../common/components/AppBox';
import { useTheme } from '@emotion/react';
import { AppButton } from '../../common/components/AppButton';
import { AppTab, AppTabPanel, AppTabs } from '../../common/components/AppTabs';
import ExpenseDetails from './ExpenseDetails';
import ExpenseAttachments from './ExpenseAttachments';

const ExpenseForm = forwardRef((props, ref) => {
  const { api, ...rest } = props;
  const theme = useTheme();
  const { localization } = useAppContext();
  const detailsRef = useRef(null);
  const attachmentsRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);

  const deployingData = (details) => {
    return detailsRef.current?.deployingData(details);
  };

  const submittingData = (data) => {
    return detailsRef.current?.submittingData(data);
  };

  const onSubmitResponse = async (response) => {
    if (!api.details) {
      await attachmentsRef.current.upload(response.id);
    }
  };

  useImperativeHandle(ref, () => ({
    deployingData,
    submittingData,
    onSubmitResponse,
  }));

  return (
    <AppFormLayout
      inline={!!api.details}
      {...(api.details && {
        headerProps: {
          content: (
            <AppBox flex column sx={{ minWidth: '40%' }}>
              <AppText variant='normal'>{localization.translate('app.expense')}</AppText>
              <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>{`${localization.displayCurrency(
                api.details.amount
              )}`}</AppText>
            </AppBox>
          ),
        },
      })}
      contentProps={{
        containerStyle: {
          paddingTop: '0px',
          marginBottom: '0px',
        },
        content: (
          <AppBox flex column sx={{ width: '100%', height: '100%' }}>
            <AppTabs value={tabIndex} onChange={(e, v) => setTabIndex(v)} aria-label='expense-form-tabs'>
              <AppTab value={0}>
                <AppText variant='small'>{localization.translate('app.details')}</AppText>
              </AppTab>
              <AppTab value={1}>
                <AppText variant='small'>{localization.translate('app.attachments')}</AppText>
              </AppTab>
            </AppTabs>
            <AppTabPanel hide={tabIndex !== 0} sx={{ padding: '10px' }}>
              <ExpenseDetails ref={detailsRef} api={api} {...rest} />
            </AppTabPanel>

            <AppTabPanel hide={tabIndex !== 1} sx={{ padding: '10px' }}>
              <ExpenseAttachments ref={attachmentsRef} id={api.details?.id} />
            </AppTabPanel>
          </AppBox>
        ),
      }}
      {...(api.details && {
        footerProps: {
          actionProps: {
            content: (
              <AppButton disabled={api.disableSubmit} onClick={api.submitData}>
                {localization.translate('common.save')}
              </AppButton>
            ),
          },
        },
      })}
    />
  );
});

export default ExpenseForm;
