import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { AppBox } from '../../common/components/AppBox';
import { AppIcon, ICON } from '../../common/components/AppIcon';
import { AppText } from '../../common/components/AppText';
import { useAppContext } from '../../context/AppContext';
import { AppDropdownMenu, AppDropdownMenuItem } from '../../common/components/AppDropdownMenu';
import { usePathContext, useSetPathContext } from '../../context/PathContext';
import { COLOR_MODES, DARK_COLOR_SCHEMES, LIGHT_COLOR_SCHEMES } from '../../common/Constants';
import { useNavigate } from 'react-router-dom';
import AppSwitch from '../../common/components/AppSwitch';
import { IconButton } from '@mui/material';

const UISettingsMenu = (props) => {
  const theme = useTheme();
  const appContext = useAppContext();
  const navigate = useNavigate();
  const path = usePathContext();
  const setPath = useSetPathContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [switchTenantDialogOpen, setSwitchTenantDialogOpen] = useState(false);
  const [schemes, setSchemes] = useState(appContext.profile.colorMode === COLOR_MODES.LIGHT ? [...LIGHT_COLOR_SCHEMES] : [...DARK_COLOR_SCHEMES]);
  const open = Boolean(anchorEl);

  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleColorModeChange = () => {
    //handleClose();
    const mode = appContext.profile.colorMode === COLOR_MODES.DARK ? COLOR_MODES.LIGHT : COLOR_MODES.DARK;
    try {
      if (mode === COLOR_MODES.LIGHT) {
        setSchemes([...LIGHT_COLOR_SCHEMES]);
        appContext.setColor(mode, LIGHT_COLOR_SCHEMES[0].name);
      } else {
        setSchemes([...DARK_COLOR_SCHEMES]);
        appContext.setColor(mode, DARK_COLOR_SCHEMES[0].name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSideMenuChange = () => {
    const side = Boolean(appContext.profile.sideMenu);
    appContext.setSideMenu(!side);
  };

  return (
    <AppBox flex centery>
      <IconButton onClick={handleClick} color='inherit'>
        <AppIcon icon={ICON.SettingsSlide} style={{ color: theme.palette.primary.main }} />
      </IconButton>
      <AppDropdownMenu
        id='tenant-menu'
        MenuListProps={{
          'aria-labelledby': 'uisettings-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <AppDropdownMenuItem
          sx={{
            '&.MuiMenuItem-root': {
              paddingLeft: '0px',
              '& .MuiTypography-root': {
                marginLeft: '0px',
              },
            },
          }}
        >
          <AppBox flex centery>
            <AppSwitch checked={appContext.profile.colorMode === COLOR_MODES.DARK} onChange={handleColorModeChange} sx={{ mr: 1 }} />
            <AppText variant='small' sx={{ mx: 1 }}>
              {appContext.localization.translate('settings.dark_theme')}
            </AppText>
          </AppBox>
        </AppDropdownMenuItem>
        <AppDropdownMenuItem
          sx={{
            '&.MuiMenuItem-root': {
              paddingLeft: '0px',
              '& .MuiTypography-root': {
                marginLeft: '0px',
              },
            },
          }}
        >
          <AppBox flex centery>
            <AppSwitch checked={Boolean(appContext.profile.sideMenu)} onChange={handleSideMenuChange} sx={{ mr: 1 }} />
            <AppText variant='small' sx={{ mx: 1 }}>
              {appContext.localization.translate('settings.side_menu')}
            </AppText>
          </AppBox>
        </AppDropdownMenuItem>
      </AppDropdownMenu>
    </AppBox>
  );
};

export default UISettingsMenu;
