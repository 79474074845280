import { IconButton, LinearProgress, Stack } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { useHttp } from '../../hooks/authentication';
import { AppQuickFilter } from './AppQuickFilter';
import { WORKING_STATE } from '../Constants';
import { AppBox } from './AppBox';
import { AppButton } from './AppButton';
import { AppDialog } from './AppDialog';
import { AppIcon, ICON, ICON_SIZE } from './AppIcon';
import AppInnerAlert from './AppInnerAlert';
import AppLink from './AppLink';
import { AppTextField, InlineTextField } from './AppText';
import FileUploader from './FileUploader';
import { useDialogsContext } from '../../context/DialogsContext';

const AttachmentDialogForm = forwardRef((props, ref) => {
  const { onAdd, ...rest } = props;
  const { localization } = useAppContext();
  const { getHttp } = useHttp();
  const [disable, setDisable] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const close = () => {
    setDialogOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setDialogOpen(true);
    },
  }));

  const onUpload = async (file) => {
    if (file) {
      if (onAdd) {
        await onAdd({
          file: file,
          displayName: displayName || file.name,
          filename: file.name,
        });
      }
      setDisplayName('');
    }
  };

  return (
    <AppDialog
      maxWidth='sm'
      title={localization.translate('app.attach_file')}
      open={dialogOpen}
      onClose={close}
      hasActions
      useClose
      closeTitle={localization.translate('common.close')}
      dialogContent={
        <AppBox flex center column sx={{ flexGrow: 1, px: 1, mt: 2 }}>
          <AppTextField value={displayName} onChange={(v) => setDisplayName(v)} fullWidth label={localization.translate('settings.display_name')} />
          <FileUploader
            onUpload={onUpload}
            hoverLabel={localization.translate('app.upload_file_description')}
            dropLabel={localization.translate('app.upload_file_location_description')}
            successNotificationProps={{}}
            errorNotificationProps={{}}
          />
        </AppBox>
      }
    />
  );
});

const Attachment = (props) => {
  const { item, onUpdate, onRemove, onDownload } = props;
  const { localization } = useAppContext();
  const dialogs = useDialogsContext();
  const [nameEdited, setNameEdited] = useState(false);
  const [data, setData] = useState({});
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (item) {
      setData({ ...item });
    }
  }, [item]);

  const handleCancelEditName = () => {
    setData({ ...data, displayName: item.displayName });
    setNameEdited(!nameEdited);
  };

  const handleEdit = async () => {
    const edit = !nameEdited;
    setNameEdited(!nameEdited);
    if (!edit) {
      setUpdating(true);
      if (onUpdate) {
        await onUpdate(data);
      }
      setUpdating(false);
    }
  };

  const handleRemove = async (e) => {
    setUpdating(true);
    if (onRemove) {
      await onRemove(data);
    }
    setUpdating(false);
    dialogs.deleteConfirmationDialogRef.current.close();
  };

  const handleDownload = async () => {
    if (onDownload) {
      await onDownload(data);
    }
  };
  return (
    <>
      <Stack direction='row'>
        <AppBox flex centery sx={{ width: '40%' }}>
          <InlineTextField
            readonly={!nameEdited}
            value={data.displayName || ''}
            placeholder={localization.translate('settings.no_display_name')}
            sx={{ width: '100%' }}
            InputProps={{
              sx: {
                paddingRight: 0,
              },
            }}
            inputProps={{
              style: {
                textOverflow: 'ellipsis',
              },
            }}
            onChange={(value) => setData({ ...data, displayName: value })}
          />
        </AppBox>
        <AppBox flex center sx={{ flexGrow: 1 }}>
          <IconButton aria-label='edit-name-button' onClick={handleEdit} disabled={updating}>
            <AppIcon icon={nameEdited ? ICON.Save : ICON.Edit} size={ICON_SIZE.Small} />
          </IconButton>
          {nameEdited && (
            <IconButton aria-label='cancel-edit-name-button' onClick={handleCancelEditName}>
              <AppIcon icon={ICON.Close} />
            </IconButton>
          )}
        </AppBox>
        <AppBox flex centery sx={{ width: '40%' }}>
          <AppLink onClick={handleDownload} variant='small' sx={{ mx: 2 }}>
            {data.filename}
          </AppLink>
        </AppBox>
        <AppBox flex center sx={{ width: '10%' }}>
          <IconButton
            aria-label='delete-source-button'
            onClick={() =>
              dialogs.deleteConfirmationDialogRef.current.open({
                onOk: handleRemove,
                title: localization.translate('app.delete_file'),
                message: localization.translate('app.confirm_delete_file'),
              })
            }
            disabled={updating}
          >
            <AppIcon icon={ICON.Delete} size={ICON_SIZE.Small} />
          </IconButton>
        </AppBox>
      </Stack>
    </>
  );
};

const Attachments = forwardRef((props, ref) => {
  const { items, onRemove, onUpdate, onAdd, onDownload } = props;
  const { localization } = useAppContext();
  const [data, setData] = useState([]);
  const [workingState, setWorkingState] = useState(WORKING_STATE.IDLE);
  const formRef = useRef(null);
  useEffect(() => {
    if (items?.length >= 0) setData([...items]);
  }, [items]);

  const filter = (pattern) => {
    setData(pattern ? [...items.filter((s) => s.displayName?.includes(pattern))] : [...items]);
  };

  useImperativeHandle(ref, () => ({
    getAttachments: () => {
      return data;
    },
  }));

  const handleAdd = async (item) => {
    if (!items) {
      const updated = [...data];
      if (!item.key) {
        item.key = updated.length;
      }
      updated.push(item);
      setData(updated);
    } else {
      setWorkingState(WORKING_STATE.INPROGRESS);
      try {
        await onAdd(item);
        setWorkingState(WORKING_STATE.IDLE);
      } catch (error) {
        setWorkingState(WORKING_STATE.ERROR);
      }
    }
  };

  const handleUpdate = async (item) => {
    if (!items) {
      const updated = [...data];
      const index = updated.findIndex((e) => e.key === item.key);
      if (index >= 0) {
        updated.splice(index, 1, { ...item });
        setData(updated);
      }
    } else {
      setWorkingState(WORKING_STATE.INPROGRESS);
      try {
        await onUpdate(item);
        setWorkingState(WORKING_STATE.IDLE);
      } catch (error) {
        setWorkingState(WORKING_STATE.ERROR);
      }
    }
  };

  const handleRemove = async (item) => {
    if (!items) {
      const updated = [...data];
      const index = updated.findIndex((e) => e.key === item.key);
      if (index >= 0) {
        updated.splice(index, 1);
        setData(updated);
      }
    } else {
      setWorkingState(WORKING_STATE.INPROGRESS);
      try {
        await onRemove(item);
        setWorkingState(WORKING_STATE.IDLE);
      } catch (error) {
        setWorkingState(WORKING_STATE.ERROR);
      }
    }
  };

  const handleDownload = async (item) => {
    if (items) {
      if (onDownload) {
        await onDownload(item);
      }
    }
  };

  return (
    <AppBox flex column style={{ height: '100%' }}>
      {workingState === WORKING_STATE.INPROGRESS && (
        <AppBox sx={{ width: '100%' }}>
          <LinearProgress sx={{ mt: 0 }} />
        </AppBox>
      )}
      <AppBox flex sx={{ mb: 2, mt: 1 }}>
        <AppBox sx={{ flexGrow: 1 }} />
        <AppQuickFilter sx={{ mx: 2 }} onChange={filter} onReset={filter} />
        <AppButton startIcon={<AppIcon icon={ICON.Attach} size={ICON_SIZE.Small} />} onClick={() => formRef.current.open()}>
          {localization.translate('app.attach')}
        </AppButton>
        <AttachmentDialogForm ref={formRef} onAdd={handleAdd} />
      </AppBox>
      <AppBox flex column sx={{ flexGrow: 1 }}>
        {data.map((item, i) => {
          return <Attachment key={i} item={{ ...item, key: i }} onUpdate={handleUpdate} onRemove={handleRemove} onDownload={handleDownload} />;
        })}
      </AppBox>
      {workingState === WORKING_STATE.ERROR && (
        <AppInnerAlert
          open
          horizontal='right'
          severity='error'
          onClose={() => setWorkingState(WORKING_STATE.IDLE)}
          content={localization.translate('app.data_save_error')}
        />
      )}
    </AppBox>
  );
});

export default Attachments;
