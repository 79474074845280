import { useState, useEffect } from 'react';
import { AppDialog } from '../../../common/components/AppDialog';
import { useAppContext } from '../../../context/AppContext';
import { AppText } from '../../../common/components/AppText';
import { AppTab, AppTabPanel, AppTabs } from '../../../common/components/AppTabs';
import FileUploadImportProvider from './FileUploadImportProvider';
import PaymentSourceImportProvider from './PaymentSourceImportProvider';
import { AppButton } from '../../../common/components/AppButton';
import { AppIcon, ICON } from '../../../common/components/AppIcon';
import { AppBox } from '../../../common/components/AppBox';
import { CircularProgress } from '@mui/material';
import { useRef } from 'react';
import AppNotification from '../../../common/components/AppNotification';
import { NOTIFICATION_SEVIRITY } from '../../../common/Constants';

const ImportProviders = [
  {
    component: PaymentSourceImportProvider,
    tabTitle: 'app.import_from_payment_source',
  },
  {
    component: FileUploadImportProvider,
    tabTitle: 'app.upload_file',
    componentProps: {
      hoverLabel: 'app.upload_file_description',
      dropLabel: 'app.upload_file_location_description',
    },
  },
];

const ImportTransactionsDialog = (props) => {
  const { localization } = useAppContext();
  const { open, onClose, ...other } = props;
  const [index, setIndex] = useState(0);
  const [importProvider, setImportProvider] = useState();
  const [showImportButton, setShowImportButton] = useState(false);
  const [enableImportButton, setEnableImportButton] = useState(false);
  const [importing, setImporting] = useState(false);
  const providerRef = useRef(null);
  const [notificationSeverity, setNotificationSeverity] = useState(null);

  useEffect(() => {
    const provider = ImportProviders[index] || ImportProviders[0];
    setImportProvider(provider);
  }, [index]);

  const handleImport = async () => {
    if (providerRef.current?.handleImport) {
      setImporting(true);
      try {
        await providerRef.current.handleImport();
        setNotificationSeverity(NOTIFICATION_SEVIRITY.SUCCESS);
      } catch (error) {
        console.error(error);
        setNotificationSeverity(NOTIFICATION_SEVIRITY.ERROR);
      } finally {
        setImporting(false);
      }
    }
  };

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={localization.translate('donations.import_donations')}
      dialogContent={
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '300px' }}>
          <AppTabs value={index} onChange={(e, v) => setIndex(v)} aria-label='import-donations-tabs'>
            {ImportProviders.map((p, i) => {
              return (
                <AppTab key={i} value={i}>
                  <AppText>{localization.translate(p.tabTitle) || ''}</AppText>
                </AppTab>
              );
            })}
          </AppTabs>
          <AppTabPanel sx={{ p: 2 }}>
            {importProvider?.component && (
              <importProvider.component
                ref={providerRef}
                {...importProvider.componentProps}
                setShowImportButton={setShowImportButton}
                setEnableImportButton={setEnableImportButton}
              />
            )}
          </AppTabPanel>
          {notificationSeverity && (
            <AppNotification
              open={!!notificationSeverity}
              onClose={() => {
                setNotificationSeverity(null);
              }}
              severity={notificationSeverity}
              message={
                notificationSeverity === NOTIFICATION_SEVIRITY.SUCCESS
                  ? localization.translate('donations.import_transactions_submitted')
                  : `${localization.translate('donations.import_transactions_error')}. ${localization.translate('errors.try_again')}`
              }
            />
          )}
        </div>
      }
      hasActions
      useClose
      maxWidth='md'
      dialogActionsContent={
        showImportButton ? (
          <AppBox sx={{ mx: 1, position: 'relative' }}>
            <AppButton disabled={!enableImportButton || importing} startIcon={<AppIcon icon={ICON.Import} />} onClick={handleImport}>
              {localization.translate('app.import')}
            </AppButton>
            {importing && (
              <CircularProgress
                size={20}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-10px',
                  marginLeft: '-10px',
                }}
              />
            )}
          </AppBox>
        ) : (
          <></>
        )
      }
    />
  );
};

export default ImportTransactionsDialog;
