import { IconButton, useTheme } from '@mui/material';
import { useAppContext } from '../../../../context/AppContext';
import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { useTenantContext } from '../../../../context/TenantContext';
import { LANGUAGE_DIRECTION, SupportedLanguages } from '../../../../common/Constants';
import { AppBox } from '../../../../common/components/AppBox';
import { AppIcon, ICON, ICON_SIZE } from '../../../../common/components/AppIcon';
import { AppButton } from '../../../../common/components/AppButton';
import { AppText, AppTextField, InlineTextField } from '../../../../common/components/AppText';
import { InlineValueEditor } from '../../InlineValueEditor';
import { DialogFormContainer } from '../../../../common/components/DialogFormContainer';
import LanguageSelect from '../../../../common/components/LanguageSelect';

const OrganizationNameDialogForm = forwardRef((props, ref) => {
  const { onSubmit } = props;
  const { localization } = useAppContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState();
  const [name, setName] = useState('');
  const containerRef = useRef();

  const close = () => {
    setDialogOpen(false);
    setLanguages([]);
    setLanguage('');
    setName('');
  };
  const handleSubmit = async (e) => {
    if (onSubmit) {
      await onSubmit(language, name);
    }
  };
  useImperativeHandle(ref, () => ({
    open: (config) => {
      if (config?.languages) {
        setLanguages([...config.languages]);
      }
      setDialogOpen(true);
    },
  }));

  return (
    <DialogFormContainer
      ref={containerRef}
      open={dialogOpen}
      title={localization.translate('settings.tenant_display_name')}
      formId='tenant-form'
      onClose={close}
      onSubmit={handleSubmit}
      disableSubmit={!language || !name}
      width='sm'
    >
      <AppBox flex column center sx={{ width: '100%' }}>
        <AppBox flex centery sx={{ width: '50%' }}>
          <LanguageSelect sx={{ width: '50%' }} {...(languages.length && { languages: languages })} onChange={(v) => setLanguage(v)} />
        </AppBox>
        <AppBox flex centery sx={{ width: '100%', mt: 3 }}>
          <AppTextField
            fullWidth
            label={localization.translate('settings.tenant_display_name')}
            sx={{ direction: localization.getLanguageDirection(language) || LANGUAGE_DIRECTION.LTR }}
            onChange={(v) => setName(v)}
          />
        </AppBox>
      </AppBox>
    </DialogFormContainer>
  );
});

const OrganizationNameSettings = (props) => {
  const theme = useTheme();
  const { localization } = useAppContext();
  const tenantContext = useTenantContext();
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [names, setNames] = useState({});
  const [edited, setEdited] = useState({});
  const formRef = useRef();

  useEffect(() => {
    const namesToSet = { ...(tenantContext.tenantSettings.displayName || {}) };
    setNames(namesToSet);
    const namesToEdit = {};
    Object.getOwnPropertyNames(namesToSet).forEach((n) => (namesToEdit[n] = false));
    setEdited({ ...namesToEdit });
    const languages = [];
    const configured = Object.getOwnPropertyNames(tenantContext.tenantSettings.displayName || {});
    SupportedLanguages.forEach((lang) => {
      if (!configured.find((e) => e === lang.code)) {
        languages.push({ ...lang });
      }
    });
    setAvailableLanguages([...languages]);
  }, [tenantContext.tenantSettings]);

  const updateName = async (lang) => {
    const edit = !edited[lang];
    setEdited({ ...edited, [lang]: edit });
    if (!edit) {
      await tenantContext.setTenantSettings({
        ...tenantContext.tenantSettings,
        displayName: { ...names },
      });
    }
  };

  const deleteName = async (lang) => {
    const toUpdate = { ...(tenantContext.tenantSettings.displayName || {}) };
    if (toUpdate[lang]) {
      delete toUpdate[lang];
      await tenantContext.setTenantSettings({
        ...tenantContext.tenantSettings,
        displayName: { ...toUpdate },
      });
    }
  };

  const addName = async (lang, name) => {
    if (!!lang && !!name) {
      await tenantContext.setTenantSettings({
        ...tenantContext.tenantSettings,
        displayName: { ...names, [lang]: name },
      });
    }
  };

  return (
    <AppBox flex column sx={{ pr: 10, maxHeight: '400px', overflow: 'auto', width: '100%' }}>
      <AppBox flex>
        <AppBox flex centery sx={{ flexGrow: 1 }}>
          <AppText variant='smallb'>{localization.translate('settings.tenant_display_name')}</AppText>
        </AppBox>

        <AppButton
          disabled={availableLanguages.length <= 0}
          startIcon={<AppIcon icon={ICON.Add} />}
          onClick={() =>
            formRef.current.open({
              languages: availableLanguages,
            })
          }
        >
          {localization.translate('settings.add_name')}
        </AppButton>
        <OrganizationNameDialogForm ref={formRef} onSubmit={addName} />
      </AppBox>
      <AppBox flex column centery sx={{ mt: 2, width: '100%' }}>
        {Object.getOwnPropertyNames(names).map((n, i) => {
          return (
            <AppBox key={i} flex centery sx={{ width: '100%' }}>
              <AppBox flex centery sx={{ minWidth: '100px' }}>
                <span className={`fi fi-${SupportedLanguages.find((l) => l.code === n)?.country}`}></span>
                <AppText variant='small' sx={{ mx: 1 }}>
                  {SupportedLanguages.find((l) => l.code === n)?.name}
                </AppText>
              </AppBox>
              <AppBox flex centery sx={{ mx: 2, flexGrow: 1 }}>
                <InlineTextField
                  sx={{ width: '100%', mr: 1 }}
                  readonly={!edited[n]}
                  value={names[n]}
                  onChange={(value) => setNames({ ...names, [n]: value })}
                />
              </AppBox>
              <AppBox flex centery sx={{ width: '100px' }}>
                <InlineValueEditor
                  edited={edited[n]}
                  name='commission'
                  onEdit={() => updateName(n)}
                  onCancel={() => {
                    setNames({ ...names, [n]: tenantContext.tenantSettings.displayName?.[n] || '' });
                    setEdited({ ...edited, [n]: false });
                  }}
                />
              </AppBox>
              <AppBox flex centery sx={{ width: '100px' }}>
                <IconButton aria-label='delete-tenant-name' onClick={() => deleteName(n)}>
                  <AppIcon icon={ICON.Delete} size={ICON_SIZE.Small} />
                </IconButton>
              </AppBox>
            </AppBox>
          );
        })}
      </AppBox>
    </AppBox>
  );
};

export default OrganizationNameSettings;
