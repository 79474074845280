import { Tooltip, useTheme } from '@mui/material';
import { AppBox } from '../../common/components/AppBox';
import AmountChart from '../../common/components/charts/AmountChart';
import PercentChart from '../../common/components/charts/PercentChart';
import { useAppContext } from '../../context/AppContext';
import { AppText } from '../../common/components/AppText';

const calculateFontSize = (text) => {
  let size = '';

  if (text && text.length > 0) {
    const length = text.length;
    if (length <= 8) {
      size = '.8rem';
    } else if (length > 8 && length <= 10) {
      size = '.7rem';
    } else {
      size = '.65rem';
    }
  }
  return size;
};

const CampaignExpensesChart = (props) => {
  const { data, campaign, size } = props;
  const { localization } = useAppContext();
  const theme = useTheme();
  const calculateColor = (percent) => {
    return percent < 0 ? theme.palette.primary.main : theme.palette.dashboard.info;
  };
  const color = calculateColor(data.expensePercent);
  const amountText = !!data.expenseAmount ? localization.displayCurrency(data.expenseAmount) : '-';

  return (
    <AppBox column flex center>
      <AppBox flex column centery>
        <AppText variant='xsmall' sx={{ color: (theme) => theme.palette.text.secondary }}>
          {localization.translate('app.expenses')}
        </AppText>
      </AppBox>
      {!!data.amount ? (
        <AppBox flex column center>
          <PercentChart
            size={size}
            color={color}
            percent={!!data.goal ? (data.expenseAmount / data.goal) * 100 : (data.expenseAmount / data.amount) * 100}
            tooltip={!!data.goal ? localization.translate('dashboard.percent_of_goal') : localization.translate('dashboard.percent_of_donations')}
            goal={data.goal}
            current={data.expenseAmount}
          />
          <AppBox flex column center sx={{ flex: 1 }}>
            <AppBox flex column center>
              <Tooltip title={localization.translate('dashboard.amount_achieved')} placement='top-end'>
                <AppText variant='small' sx={{ cursor: 'default' }}>
                  {amountText}
                </AppText>
              </Tooltip>
              <Tooltip title={localization.translate('dashboard.number_of_donations')} placement='top-end'>
                <AppText variant='small' sx={{ cursor: 'default' }}>
                  {localization.display(data.expenseCount)}
                </AppText>
              </Tooltip>
            </AppBox>
          </AppBox>
        </AppBox>
      ) : (
        <AppBox flex column center sx={{ position: 'relative' }}>
          <AmountChart size={size} />
          <AppBox flex column centery endx sx={{ position: 'absolute', width: '50%', top: '14%', left: '0%', px: 1 }}>
            <Tooltip title={localization.translate('dashboard.amount_achieved')} placement='top-end'>
              <AppText variant='small' sx={{ cursor: 'default', fontSize: calculateFontSize(amountText) }}>
                {amountText}
              </AppText>
            </Tooltip>
            <Tooltip title={localization.translate('dashboard.number_of_donations')} placement='top-end'>
              <AppText variant='small' sx={{ cursor: 'default', fontSize: calculateFontSize(localization.display(data.expenseCount)) }}>
                {localization.display(data.expenseCount)}
              </AppText>
            </Tooltip>
          </AppBox>
          <AppBox flex column center sx={{ flex: 1 }}>
            <AppBox flex column center>
              <Tooltip title={localization.translate('dashboard.amount_achieved')} placement='top-end'>
                <AppText variant='small' sx={{ cursor: 'default' }}>
                  {amountText}
                </AppText>
              </Tooltip>
              <Tooltip title={localization.translate('dashboard.number_of_donations')} placement='top-end'>
                <AppText variant='small' sx={{ cursor: 'default' }}>
                  {localization.display(data.expenseCount)}
                </AppText>
              </Tooltip>
            </AppBox>
          </AppBox>
        </AppBox>
      )}
    </AppBox>
  );
};

export default CampaignExpensesChart;
