import * as Bi from 'react-icons/bi';
import {
  BsCheckCircleFill,
  BsCollectionPlay,
  BsPlusCircleFill,
  BsQuestionCircleFill,
  BsCloudSlash,
  BsPlay,
  BsCheck,
  BsFacebook,
  BsApple,
} from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import { RiBillLine } from 'react-icons/ri';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImAttachment, ImMenu } from 'react-icons/im';
import { IoMenuOutline, IoOptionsOutline } from 'react-icons/io5';
import { MdDownloading, MdOutlineAccountBalanceWallet, MdOutlineCampaign, MdOutlineMarkEmailRead } from 'react-icons/md';
import { VscCircleFilled, VscListTree, VscOrganization, VscEdit } from 'react-icons/vsc';
import { AiOutlineImport, AiOutlineMail, AiOutlineCloudUpload, AiOutlineCloudDownload } from 'react-icons/ai';
import { TbFaceIdError, TbFileOff, TbReportAnalytics, TbZoomMoney } from 'react-icons/tb';
import { VscPieChart } from 'react-icons/vsc';
import { AppTooltip } from './AppTooltip';
import IsraelGives from '../../assets/images/icons/israelgives.png';
import Paypal from '../../assets/images/icons/paypal.png';
import GreenInvoice from '../../assets/images/icons/greeninvoice.png';
import EasyCount from '../../assets/images/icons/easycount.png';
import ProximaWhite from '../../assets/images/icons/proxima_white.png';
import CampaignPng from '../../assets/images/icons/campaign.png';
import DonationPng from '../../assets/images/icons/donation.png';
import ExpensesPng from '../../assets/images/icons/expenses.png';
import DashboardPng from '../../assets/images/icons/dashboard.png';
import { AppBox } from './AppBox';

export const ICON_SIZE = {
  ExtraSmall: 18,
  Small: 20,
  Normal: 22,
  Medium: 26,
  Large: 32,
  ExtraLarge: 50,
};

const ICON_SIZE1 = {
  ExtraSmall: 16,
  Small: 18,
  Normal: 20,
  Medium: 24,
  Large: 30,
  ExtraLarge: 48,
};

export const ICON = {
  Add: 'icon_add',
  AddCircle: 'icon_addcircle',
  Edit: 'icon_edit',
  Close: 'icon_close',
  Assign: 'icon_assign',
  AssignProject: 'icon_assign_project',
  AssignStatus: 'icon_assign_status',
  Bottom: 'icon_bottom',
  Confirmed: 'icon_confirmed',
  Delete: 'icon_delete',
  Download: 'icon_download',
  GenerateInvoice: 'icon_generate_invoice',
  Filter: 'icon_filter',
  Reload: 'icon_reload',
  Questioned: 'icon_questioned',
  Import: 'icon_import',
  Export: 'icon_export',
  Layout: 'icon_layout',
  Side: 'icon_side',
  Off: 'icon_off',
  Density: 'icon_density',
  SettingsSlide: 'icon_settings_slide',
  SettingsCog: 'icon_settings_cog',
  Logout: 'icon_logout',
  More: 'icon_more',
  Position: 'icon_position',
  Columns: 'icon_columns',
  Link: 'icon_link',
  SeeMore: 'icon_seemore',
  Search: 'icon_search',
  CloudUpload: 'icon_cloud_upload',
  CloudDownload: 'icon_cloud_download',
  Wait: 'icon_wait',
  Sort: 'icon_sort',
  ChevronUp: 'icon_chevron_up',
  ChevronDown: 'icon_chevron_down',
  ChevronLeft: 'icon_chevron_left',
  ChevronRight: 'icon_chevron_right',
  Dashboard: 'icon_dashboard',
  DashboardHeader: 'icon_dashboard_header',
  Donation: 'icon_donation',
  DonationHeader: 'icon_donation_header',
  Print: 'icon_print',
  Reset: 'icon_reset',
  Save: 'icon_save',

  Report: 'icon_report',
  User: 'icon_user',
  Bill: 'icon_bill',
  Attach: 'icon_attach',
  Email: 'icon_email',
  EmailOk: 'icon_emailok',
  EmailSend: 'icon_emailsend',
  ProximaWhite: 'icon_proxima',
  Apple: 'icon_apple',
  Facebook: 'icon_facebook',
  Dot: 'icon_dot',
  OnlineAccount: 'icon_online_account',
  IsraelGives: 'icon_israelgives',
  Paypal: 'icon_paypal',
  GreenInvoice: 'icon_greeninvoice',
  EasyCount: 'icon_easycount',
  Split: 'icon_split',
  Error: 'icon_error',
  Error2: 'icon_error2',
  Menu: 'icon_menu',
  NoFile: 'icon_nofile',
  Expenses: 'icon_expenses',
  ExpensesHeader: 'icon_expenses_header',
  Campaign: 'icon_campaign',
  CampaignHeader: 'icon_campaign_header',
  Check: 'icon_check',
  Play: 'icon_play',
  Imported: 'icon_imported',
  NoData: 'icon_nodata',
  DensityStandard: 'icon_density_standard',
  ConfirmedFill: 'icon_confirmed_fill',
  DensityComfortable: 'icon_density_comfortable',
  AddFill: 'icon_add_fill',
  DensityCompact: 'icon_density_compact',
  Tenant: 'icon_tenant',
  Tasks: 'icon_tasks',
  InProgress: 'icon_inprogress',
  QuestionedFill: 'icon_questioned_fill',
};

const ICONS = {
  [ICON.Add]: { icon: Bi.BiPlus },
  [ICON.AddCircle]: { icon: Bi.BiPlusCircle },
  [ICON.Edit]: { icon: VscEdit },
  [ICON.Close]: { icon: Bi.BiX },
  [ICON.Assign]: { icon: Bi.BiSelectMultiple },
  [ICON.AssignProject]: { icon: Bi.BiFolderPlus },
  [ICON.AssignStatus]: { icon: Bi.BiSelectMultiple },
  [ICON.Bottom]: { icon: Bi.BiDockBottom },
  [ICON.Confirmed]: { icon: Bi.BiChevronDownCircle },
  [ICON.Delete]: { icon: Bi.BiTrashAlt },
  [ICON.Download]: { icon: Bi.BiDownload },
  [ICON.GenerateInvoice]: { icon: Bi.BiReceipt },
  [ICON.Filter]: { icon: Bi.BiFilterAlt },
  [ICON.Reload]: { icon: Bi.BiRevision },
  [ICON.Questioned]: { icon: Bi.BiHelpCircle },
  [ICON.Import]: { icon: AiOutlineCloudUpload },
  [ICON.Export]: { icon: AiOutlineCloudDownload },
  [ICON.Layout]: { icon: Bi.BiCustomize },
  [ICON.Side]: { icon: Bi.BiDockRight },
  [ICON.Off]: { icon: Bi.BiToggleLeft },
  [ICON.Density]: { icon: Bi.BiMenu },
  [ICON.SettingsSlide]: { icon: IoOptionsOutline },
  [ICON.SettingsCog]: { icon: Bi.BiCog },
  [ICON.Logout]: { icon: Bi.BiLogOut },
  [ICON.More]: { icon: Bi.BiDotsVerticalRounded },
  [ICON.Position]: { icon: Bi.BiLayout },
  [ICON.Columns]: { icon: Bi.BiColumns },
  [ICON.Link]: { icon: Bi.BiLink },
  [ICON.SeeMore]: { icon: Bi.BiListUl },
  [ICON.Search]: { icon: Bi.BiSearch },
  [ICON.CloudUpload]: { icon: Bi.BiCloudUpload },
  [ICON.CloudDownload]: { icon: Bi.BiCloudDownload },
  [ICON.Wait]: { icon: Bi.BiHourglass },
  [ICON.Sort]: { icon: Bi.BiSortAlt2 },
  [ICON.ChevronUp]: { icon: Bi.BiChevronUp },
  [ICON.ChevronDown]: { icon: Bi.BiChevronDown },
  [ICON.ChevronLeft]: { icon: Bi.BiChevronLeft },
  [ICON.ChevronRight]: { icon: Bi.BiChevronRight },
  [ICON.Dashboard]: { icon: VscPieChart },
  [ICON.DashboardHeader]: { icon: DashboardPng, type: 'img' },
  [ICON.Donation]: { icon: Bi.BiDonateHeart },
  [ICON.DonationHeader]: { icon: DonationPng, type: 'img' },
  [ICON.Print]: { icon: Bi.BiPrinter },
  [ICON.Reset]: { icon: Bi.BiReset },
  [ICON.Save]: { icon: Bi.BiSave },

  [ICON.Report]: { icon: TbReportAnalytics },
  [ICON.User]: { icon: FaRegUser },
  [ICON.Bill]: { icon: RiBillLine },
  [ICON.Attach]: { icon: ImAttachment },
  [ICON.Email]: { icon: AiOutlineMail },
  [ICON.EmailOk]: { icon: MdOutlineMarkEmailRead },
  [ICON.EmailSend]: { icon: Bi.BiMailSend },
  [ICON.Apple]: { icon: BsApple },
  [ICON.Facebook]: { icon: BsFacebook },
  [ICON.Dot]: { icon: VscCircleFilled },
  [ICON.OnlineAccount]: { icon: MdOutlineAccountBalanceWallet },
  [ICON.ProximaWhite]: {
    icon: ProximaWhite,
    type: 'img',
    size: {
      ExtraSmall: 18,
      Small: 20,
      Normal: 32,
      Medium: 36,
      Large: 42,
      ExtraLarge: 50,
    },
  },
  [ICON.IsraelGives]: {
    icon: IsraelGives,
    type: 'img',
    size: {
      ...ICON_SIZE,
    },
  },
  [ICON.Paypal]: {
    icon: Paypal,
    type: 'img',
    size: {
      ExtraSmall: 18,
      Small: 20,
      Normal: 36,
      Medium: 36,
      Large: 32,
      ExtraLarge: 50,
    },
  },
  [ICON.GreenInvoice]: {
    icon: GreenInvoice,
    type: 'img',
  },
  [ICON.EasyCount]: {
    icon: EasyCount,
    type: 'img',
  },
  [ICON.Split]: { icon: VscListTree },
  [ICON.Error]: { icon: TbFaceIdError },
  [ICON.Error2]: { icon: Bi.BiError },
  [ICON.Menu]: { icon: IoMenuOutline },
  [ICON.NoFile]: {
    icon: TbFileOff,
    size: {
      ...ICON_SIZE1,
    },
  },
  [ICON.Expenses]: { icon: TbZoomMoney },
  [ICON.ExpensesHeader]: { icon: ExpensesPng, type: 'img' },
  [ICON.Campaign]: { icon: MdOutlineCampaign },
  [ICON.CampaignHeader]: { icon: CampaignPng, type: 'img' },
  [ICON.Check]: { icon: BsCheck },
  [ICON.Play]: { icon: BsPlay },
  [ICON.InProgress]: { icon: MdDownloading },
  [ICON.DensityCompact]: { icon: IoMenuOutline },
  [ICON.Imported]: {
    icon: AiOutlineImport,
  },
  [ICON.NoData]: {
    icon: BsCloudSlash,
    size: {
      ...ICON_SIZE1,
    },
  },
  [ICON.DensityStandard]: {
    icon: GiHamburgerMenu,
    size: {
      ...ICON_SIZE1,
    },
  },
  [ICON.ConfirmedFill]: {
    icon: BsCheckCircleFill,
    size: {
      ...ICON_SIZE1,
    },
  },
  [ICON.DensityComfortable]: {
    icon: ImMenu,
    size: {
      ...ICON_SIZE1,
    },
  },
  [ICON.AddFill]: {
    icon: BsPlusCircleFill,
    size: {
      ...ICON_SIZE1,
    },
  },

  [ICON.Tenant]: {
    icon: VscOrganization,
    size: {
      ...ICON_SIZE1,
    },
  },
  [ICON.Tasks]: {
    icon: BsCollectionPlay,
    size: {
      ...ICON_SIZE1,
    },
  },

  [ICON.QuestionedFill]: {
    icon: BsQuestionCircleFill,
    size: {
      ...ICON_SIZE1,
    },
  },
};

const sizeToName = (size) => {
  return Object.getOwnPropertyNames(ICON_SIZE).find((s) => ICON_SIZE[s] === size);
};

export const AppIcon = (props) => {
  const { icon, size = ICON_SIZE.Normal, style, tooltip, ...rest } = props;

  const sizeName = sizeToName(size);

  const normalSize = Boolean(ICONS[icon]) && Boolean(ICONS[icon].size) ? ICONS[icon].size[sizeToName(ICON_SIZE.Normal)] || ICON_SIZE.Normal : ICON_SIZE.Normal;

  const requestedSize = sizeName
    ? Boolean(ICONS[icon]) && Boolean(ICONS[icon].size)
      ? ICONS[icon].size[sizeName] || ICON_SIZE[sizeName]
      : ICON_SIZE[sizeName]
    : undefined;

  const styleProps =
    !size && !style
      ? undefined
      : {
          ...style,
          fontSize: requestedSize ? `${requestedSize}px` : `${normalSize}px`,
        };

  if (!Boolean(ICONS[icon])) {
    return <></>;
  }
  switch (ICONS[icon].type) {
    case 'img':
      return (
        <AppBox flex center {...rest}>
          <img src={ICONS[icon].icon} style={{ ...style }} {...(size && { width: `${size}px`, height: `${size}px` })} />
        </AppBox>
      );
    default:
      const Icon = ICONS[icon].icon;
      return tooltip ? (
        <AppTooltip useBox title={tooltip}>
          <Icon {...rest} {...(styleProps && { style: { ...styleProps } })} />
        </AppTooltip>
      ) : (
        <Icon {...rest} {...(styleProps && { style: { ...styleProps } })} />
      );
  }
  //return Boolean(ICONS[icon]) ? createIcon({ icon: ICONS[icon].icon, sprops: styleProps, tooltip: tooltip }) : <></>;
};
