import { useEffect, useRef, useMemo, forwardRef, useState } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import getProjectsColumns from './Columns';
import { useAppContext } from '../../context/AppContext';
import CampaignForm from './CampaignForm';
import { AppDataGrid2 } from '../../common/components/datagrid/AppDataGrid2';
import { useSetHeaderContext } from '../../context/HeaderContext';
import { useSetPathContext } from '../../context/PathContext';
import { useDataContext } from '../../context/DataContext';
import { AppDadtaGridFooter } from '../../common/components/datagrid/AppDataGridFooter';
import AppDialogForm from '../../common/components/AppDialogForm';
import { useDialogsContext } from '../../context/DialogsContext';
import { useHttp } from '../../hooks/authentication';
import { AppDialog } from '../../common/components/AppDialog';
import { useImperativeHandle } from 'react';
import { AppText } from '../../common/components/AppText';
import { AppBox } from '../../common/components/AppBox';
import CampaignsSelector from '../../common/components/CampaignsSelector';
import { AppButton } from '../../common/components/AppButton';
import { DeleteCampaignsItem } from '../../common/components/datagrid/toolbarItems/DeleteItem';

export const CampaignDeleteConfirmationDialog = forwardRef((props, ref) => {
  const { onDelete } = props;
  const { localization } = useAppContext();
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState();
  const [manageType, setManageType] = useState('leave');
  const [assignTo, setAssignTo] = useState();

  const close = () => {
    setOpen(false);
    setManageType('leave');
    setAssignTo(null);
  };

  const deleteCampaigns = () => {
    close();
    if (onDelete) {
      onDelete({
        id: [...items],
        permanent: true,
        ...(assignTo && { newProjectId: assignTo }),
      });
    }
  };

  useImperativeHandle(ref, () => ({
    open: (items) => {
      setItems(items);
      setOpen(true);
    },
    close,
  }));
  return (
    <AppDialog
      open={open}
      onClose={close}
      hasActions
      useClose
      title={localization.translate('campaigns.delete_campaigns')}
      closeTitle={localization.translate('common.cancel')}
      dialogActionsContent={
        <AppButton disabled={manageType === 'assign' && !assignTo} onClick={() => deleteCampaigns()}>
          {localization.translate('common.delete')}
        </AppButton>
      }
      dialogContent={
        <Grid container>
          <Grid item xs={12} align='center'>
            <AppText>{localization.translate('campaigns.campaign_delete_confirmation')}</AppText>
          </Grid>
          <Grid item xs={12} align='center'>
            <RadioGroup aria-labelledby='delete-type-group' name='delete-type-group' value={manageType} onChange={(e) => setManageType(e.target.value)}>
              <AppBox flex centery column sx={{ mt: 2, ml: 2 }}>
                <FormControlLabel
                  value='leave'
                  control={<Radio />}
                  label={localization.translate('campaigns.leave_unassigned')}
                  slotProps={{
                    typography: {
                      variant: 'normal',
                      color: 'textSecondary',
                    },
                  }}
                />
                <AppBox flex centery>
                  <FormControlLabel
                    sx={{ width: 'auto', whiteSpace: 'nowrap' }}
                    value='assign'
                    control={<Radio />}
                    label={localization.translate('campaigns.leave_assigned')}
                    slotProps={{
                      typography: {
                        variant: 'normal',
                        color: 'textSecondary',
                      },
                    }}
                  />
                  <CampaignsSelector
                    filter={(c) => !items.includes(c.id)}
                    disabled={manageType !== 'assign'}
                    applyValue={(item) => {
                      setAssignTo(item.value.projectId);
                    }}
                    textFieldProps={{ sx: { width: 300 }, readonly: manageType !== 'assign' }}
                  />
                </AppBox>
              </AppBox>
            </RadioGroup>
          </Grid>
        </Grid>
      }
    />
  );
});

export default function Projects({ path }) {
  const theme = useTheme();
  const { getHttp } = useHttp();
  const breakpoint = useMediaQuery(theme.breakpoints.up('lg'));
  const { localization, profile } = useAppContext();
  const dataContext = useDataContext();
  const setTitle = useSetHeaderContext();
  const setPath = useSetPathContext();
  const dialogs = useDialogsContext();
  const gridRef = useRef(null);
  const deleteConfirmRef = useRef();

  const columns = useMemo(() => {
    return getProjectsColumns({ localization: localization, displayCurrency: localization.displayCurrency });
  }, [localization, theme]);

  useEffect(() => {
    dataContext.setName('campaigns');
  }, []);

  useEffect(() => {
    setPath(path);
    setTitle({
      primary: localization.translate('app.campaigns'),
      secondary: localization.translate('campaigns.subtitle'),
    });
  }, [profile.language]);

  const deleteCampaigns = async (config) => {
    await gridRef.current.deleteItems({ ...config });
  };

  const deleteProjects = async (items) => {
    if (items?.length === 1) {
      const item = items[0].id;
      try {
        const response = await getHttp().get(`/projects/${item}/statistics`);
        if (!response.data?.count && !response.data?.countExpenses) {
          dialogs.deleteConfirmationDialogRef.current.open({
            onOk: () =>
              deleteCampaigns({
                id: [item],
                permanent: true,
              }),
            title: localization.translate('campaigns.delete_campaigns'),
            message: localization.translate('campaigns.delete_campaigns_confirm'),
          });
        } else {
          deleteConfirmRef.current.open([item]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <AppDataGrid2
        ref={gridRef}
        urlPath='projects'
        errorAlertProps={{
          title: localization.translate('campaigns.load_campaigns_error'),
          content: `${localization.translate('campaigns.load_campaigns_error_content')}. ${localization.translate(
            'errors.try_again'
          )}. ${localization.translate('errors.contact_admin')}`,
        }}
        notificationProps={{
          onDataError: localization.translate('campaigns.update_campaigns_error'),
          onSuccess: localization.translate('campaigns.update_campaigns_success'),
        }}
        detailsFormProps={{
          component: CampaignForm,
          componentProps: {
            formId: 'campaign-details-form',
          },
        }}
        noRecordsTitle={localization.translate('campaigns.no_campaigns')}
        columns={columns.data}
        initialState={{
          columns: {
            columnVisibilityModel: { ...columns.visibilityModel },
          },
        }}
        slots={{
          footer: AppDadtaGridFooter,
        }}
        toolbarProps={{
          filterProps: {
            addQuickFilter: true,
          },
          layoutMenuProps: {},
          buttons: {
            items: [
              {
                reload: {
                  onActivated: () => gridRef.current.onReload(),
                },
                filter: {},
              },
              {
                add: {
                  tooltip: localization.translate('campaigns.add_new_campaign'),
                  title: localization.translate('campaigns.new_campaign'),
                  itemName: 'campaign',
                  onSubmit: (item) => gridRef.current.addItem(item),
                  renderForm: (params) => {
                    return (
                      <AppDialogForm
                        {...params}
                        slot={{
                          component: CampaignForm,
                        }}
                      />
                    );
                  },
                },

                delete: {
                  component: DeleteCampaignsItem,
                  text: localization.translate('common.delete'),
                  tooltip: localization.translate('campaigns.delete_campaigns'),
                  onActivated: deleteProjects,
                  confirmationTitle: localization.translate('campaigns.delete_campaigns'),
                  confirmationMessage: localization.translate('campaigns.delete_campaigns_confirm'),
                },
              },
            ],
          },
        }}
        hideFooterSelectedRowCount
        getRowClassName={(params) => `status-highlighted--${params.row.state.toLowerCase()}`}
      />
      <CampaignDeleteConfirmationDialog ref={deleteConfirmRef} onDelete={deleteCampaigns} />
    </>
  );
}
